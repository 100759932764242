import { Element, ElementContent, Text } from 'hast';
import trimStart from 'lodash/trimStart';
import uniq from 'lodash/uniq';
import { visit } from 'unist-util-visit';
import { Note, User } from '@/types';

export interface MentionUser {
  id: string;
  display: string;
  avatar: string | null;
}

const PREFIXES: Record<string, string> = {
  AR: 'art-requests',
  PO: 'purchase-orders',
  SO: 'orders',
  DES: 'designs',
  CUST: 'customers',
  LAY: 'design-layouts',
  QTE: 'quotes',
  LIB: 'design-library',
};

export function buildIncrementId(prefix: string, id: number | null) {
  if (id == null) {
    return null;
  }
  return `${prefix}-${String(id).padStart(7, '0')}`;
}

const getUrlFromIncrementId = (incrementId: string) => {
  const matches = incrementId.match(/[0-9]+$/);
  if (matches) {
    const id = trimStart(matches[0], '0');
    const path = PREFIXES[incrementId.split('-')[0]];

    if (path) {
      return `/${path}/${id}`;
    }
  }
  return null;
};

const REGEX = {
  mention: '@\\[.*?\\]\\{.*?\\}',
  hashtag: '#\\[[a-z]+\\]',
  incrementId: '[A-Z]+-[0-9]{7,8}',
} as const;

type MatchingType = keyof typeof REGEX;
const CALLBACKS: Record<MatchingType, (m: string, onUser: (u: string) => void) => Element | Text> =
  {
    mention(mention: string, onUser: (u: string) => void): Element {
      const [_, display, id] = mention.match(/@\[(.*)\]\{(.*)\}/) || [];
      return {
        type: 'element',
        tagName: 'span',
        properties: {
          // @ts-expect-error span props
          style: { cursor: 'pointer' },
          class: 'mention-span',
          // @ts-expect-error span props
          onClick() {
            onUser(id);
          },
        },
        children: [
          {
            type: 'text',
            value: `@${display}`,
          },
        ],
      };
    },
    hashtag(hashtag: string): Element {
      const display = hashtag.match(/#\[(.*)\]/)?.[1] || '';
      return {
        type: 'element',
        tagName: 'span',
        properties: { class: 'hashtag-span' },
        children: [
          {
            type: 'text',
            value: `#${display}`,
          },
        ],
      };
    },
    incrementId(incrementId: string): Element | Text {
      const url = getUrlFromIncrementId(incrementId);
      const child: Text = {
        type: 'text',
        value: incrementId,
      };

      if (!url) {
        return child;
      }
      return {
        type: 'element',
        tagName: 'a',
        properties: {
          href: getUrlFromIncrementId(incrementId),
        },
        children: [child],
      };
    },
  };

const MATCHING_TYPES = Object.keys(REGEX) as MatchingType[];

const FULL_REGEX = new RegExp(
  Object.values(REGEX)
    .map((v) => `(${v})`)
    .join('|'),
);

export function mentionHighlighter(onUser: (u: string) => void) {
  return function mentionHighlighterFunc() {
    function transformer(hast: any) {
      const toChange: [Element, ElementContent[]][] = [];

      visit(hast, 'element', (node: Element) => {
        const newChildren = node.children.flatMap((child) => {
          if (child.type === 'text' && FULL_REGEX.test(child.value)) {
            return String(child.value)
              .split(FULL_REGEX)
              .map((part) => {
                const matchingType = MATCHING_TYPES.find((key) =>
                  new RegExp(REGEX[key]).test(part),
                );

                return matchingType
                  ? CALLBACKS[matchingType](part, onUser)
                  : ({
                      type: 'text',
                      value: part,
                    } as Text);
              });
          }
          return [child];
        });
        toChange.push([node, newChildren]);
      });

      toChange.forEach(([node, newChildren]) => {
        node.children = newChildren;
      });
    }

    return transformer;
  };
}

export function getReplyText(note: Note, me: Pick<User, 'id' | 'name'>): string {
  const markups: string[] = [];
  if (note.created_by_user) {
    markups.push(`@[${note.created_by_user.name}]{user:${note.created_by_user.id}}`);
  }
  const matches = [...note.note.matchAll(new RegExp(REGEX.mention, 'g'))];
  matches.forEach((m) => {
    markups.push(m[0]);
  });
  return uniq(markups)
    .filter((u) => !u.includes(`{user:${me.id}}`))
    .join(' ');
}
