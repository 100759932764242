import mapValues from 'lodash/mapValues';
import { FieldFactory, StackLayout } from '@/classes';
import EventableCard from '@/components/Notes/EventableCard';
import { ComplexityUnit, DesignLayoutWithProofs, OrderDesign, ReportResultsRow } from '@/types';
import { useRecordId } from '@/utils/genericResource';

export function getEventableConfigForResource(resource: string) {
  return new StackLayout('_activity', [
    FieldFactory.custom('_activity', () => (
      <EventableCard resource={resource} resourceId={useRecordId()} />
    )).setCreatable(false),
  ]);
}

// This is all for orders, which are refunds.
// If we were returning 4 hoodies to the vendor, we put in an order but make all the
// qtys negative. However, when we do this, we want to treat -2 as < -3
// even though that's not the case
export function abscomp(val1: any, op: '>' | '>=' | '<' | '<=', val2: any) {
  switch (op) {
    case '>=':
      return val2 >= 0 ? val1 >= val2 : val1 <= val2;
    case '>':
      return val2 >= 0 ? val1 > val2 : val1 < val2;
    case '<=':
      return val2 >= 0 ? val1 <= val2 : val1 >= val2;
    case '<':
      return val2 >= 0 ? val1 < val2 : val1 > val2;
    default:
      throw new Error('Invalid operator for comparison');
  }
}

export const shortenNum = (n: any) => Number(n).toFixed(4).replace(/0+$/, '').replace(/\.$/, '');

export function randomElement(arr: any[]) {
  return arr[Math.floor(Math.random() * arr.length)];
}

export function isNumeric(value: string) {
  return /^-?[\d.,]+$/.test(value);
}

export function coerceReportResults(results: ReportResultsRow[]) {
  return results.map((r) =>
    mapValues(r, (v: number | string | null) => {
      if (typeof v === 'string' && isNumeric(v)) {
        return Number(v);
      }
      return v;
    }),
  );
}

export function download(filename: string, text: string) {
  const element = document.createElement('a');
  element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`);
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export const odHasDrops = (od: OrderDesign) => Boolean(od?.design?.decoration_type?.has_drops);

export function layoutIsRoster(layout: DesignLayoutWithProofs) {
  return layout.order_designs.some(odHasDrops);
}

export const isScreenPrint = (decorationUnit: ComplexityUnit | null | undefined) =>
  decorationUnit === 'colors';
