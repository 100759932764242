import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Close, DragHandle, Edit } from '@mui/icons-material';
import { IconButton, styled, TableBody, TableCell, TableRow, Tooltip } from '@mui/material';
import axios from 'axios';
import get from 'lodash/get';
import map from 'lodash/map';
import pick from 'lodash/pick';
import startCase from 'lodash/startCase';
import truncate from 'lodash/truncate';
import { z } from 'zod';
import { FieldFactory } from '@/classes';
import OrderItemRow, { BaseOrderLineProps } from '@/components/Orders/Items/OrderItemRow';
import ProductField from '@/components/Products/ProductField';
import TextButton from '@/components/Text/TextButton';
import { genericModelReferenceSchema, OrderItem, orderItemPayloadSchema } from '@/types';
import { useOrderableApiUrl } from '@/utils/hooks/useApiSegment';
import useDialogs from '@/utils/hooks/useDialogs';
import numString from '@/utils/numString';
import OrderItemProduct from './OrderItemProduct';

const HeaderTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.action.hover,
}));

export default function OrderItemGroup(
  props: {
    items: OrderItem[];
    groupKey: string;
    onSortEnd: (groupKey: string, e: DragEndEvent) => void;
  } & BaseOrderLineProps,
) {
  const { isOrderLocked, items, onReload, groupKey, onSortEnd } = props;
  const { confirm, prompt } = useDialogs();
  const baseUrl = useOrderableApiUrl('items');

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: groupKey,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const onRemove = () => {
    const hasDesigns = items.some((i) => Object.keys(i.order_design_ids).length > 0);
    let description = 'Are you sure you want to delete all items of this product?';
    if (hasDesigns) {
      description += ' At least one of these items has design imprints associated with it.';
    }
    confirm({
      title: 'Remove Product',
      description,
      color: hasDesigns ? 'warning' : undefined,
    }).then(() => {
      Promise.all(items.map((item) => axios.delete(`${baseUrl}/${item.id}`))).then(() => {
        onReload();
      });
    });
  };

  const onEditField = (fieldName: 'price' | 'cost' | 'discount') => {
    prompt({
      title: `Edit ${startCase(fieldName)}`,
      fields: [
        (fieldName === 'discount'
          ? FieldFactory.percent(fieldName)
          : FieldFactory.curr(fieldName)
        ).with({
          size: 'medium',
        }),
      ],
      initialValues: pick(items[0], fieldName),
      schema: orderItemPayloadSchema
        .pick({
          price: true,
          cost: true,
          discount: true,
        })
        .partial(),
      onSubmit: (v) => Promise.all(items.map((item) => axios.put(`${baseUrl}/${item.id}`, v))),
    }).then(() => {
      onReload();
    });
  };

  const onChangeProduct = () => {
    prompt({
      title: 'Change Product',
      description: `This will change the style/color of these ${numString(
        items.length,
        'items',
      )} to the same size of the product you specify below.`,
      fields: [new ProductField('product')],
      schema: z.object({
        product: genericModelReferenceSchema,
      }),
      onSubmit: (v) =>
        axios.post(`${baseUrl}/change-product`, {
          order_item_ids: map(items, 'id'),
          new_product_id: v.product.id,
        }),
    }).then(() => {
      onReload();
    });
  };

  const getEditButtonForField = (fieldName: 'price' | 'cost' | 'discount') => {
    if (isOrderLocked) {
      return null;
    }

    const values = items.map((i) => i[fieldName]);
    const defaultValue = values.every((v) => v === values[0]) ? values[0] : null;
    const onClick = () => onEditField(fieldName);

    if (defaultValue != null) {
      return (
        <TextButton onClick={onClick}>
          {(fieldName === 'discount'
            ? FieldFactory.percent(fieldName)
            : FieldFactory.curr(fieldName).with({ maxDecimals: 4 })
          ).renderCell(defaultValue)}
        </TextButton>
      );
    }

    return (
      <IconButton onClick={onClick} size="small">
        <Edit fontSize="small" />
      </IconButton>
    );
  };

  return (
    <TableBody style={style} ref={setNodeRef}>
      <HeaderTableRow>
        <TableCell sx={{ pr: 0 }}>
          <DragHandle {...attributes} {...listeners} />
        </TableCell>
        <TableCell style={{ minWidth: 400 }}>
          <OrderItemProduct
            item={items[0]}
            actions={
              !isOrderLocked &&
              items.some((i) => i.qty_sourced === 0) && (
                <div>
                  <IconButton onClick={onChangeProduct}>
                    <Edit fontSize="small" />
                  </IconButton>
                </div>
              )
            }
          />
        </TableCell>
        <TableCell>{truncate(get(items[0], 'vendor.name'), { length: 25 })}</TableCell>
        <TableCell colSpan={2} />
        <TableCell>{getEditButtonForField('price')}</TableCell>
        <TableCell>{getEditButtonForField('discount')}</TableCell>
        <TableCell />
        <TableCell>{getEditButtonForField('cost')}</TableCell>
        <TableCell colSpan={3} />
        <TableCell>
          {!isOrderLocked && items.every((i) => i.qty_sourced === 0) && (
            <Tooltip title="Remove Product">
              <IconButton size="small" onClick={onRemove}>
                <Close fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      </HeaderTableRow>
      <DndContext onDragEnd={(e) => onSortEnd(groupKey, e)}>
        <SortableContext items={items.map((i) => i.id)}>
          {items.map((item) => (
            <OrderItemRow key={item.id} item={item} isGrouped {...props} />
          ))}
        </SortableContext>
      </DndContext>
    </TableBody>
  );
}
