import { ArrowForward, Delete } from '@mui/icons-material';
import { IconButton, ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';
import Can from '@/components/Permissions/Can';
import StatusChip from '@/components/Shared/StatusChip';
import { PACKOUT_STATUS_COLOR } from '@/constants';
import { Packout } from '@/types';

const getPackoutText = (packout: Packout) => {
  if (packout.completed_at) {
    return `Completed: ${moment(packout.completed_at).format('l')}`;
  }
  if (packout.started_at) {
    return `Started: ${moment(packout.started_at).format('l')}`;
  }
  if (packout.scheduled_date) {
    return `Scheduled: ${moment(packout.scheduled_date).format('l')}`;
  }
  return null;
};

export default function PackoutListItem({
  packout,
  invoicedAt,
  onDelete,
}: {
  packout: Packout;
  invoicedAt: string | null;
  onDelete: (p: Packout) => void;
}) {
  return (
    <ListItem>
      <ListItemText primary={`Packout #${packout.id}`} secondary={getPackoutText(packout)} />
      <ListItemSecondaryAction>
        <StatusChip
          status={packout.status}
          size="small"
          colors={PACKOUT_STATUS_COLOR}
          sx={{ mr: 1 }}
        />

        {!invoicedAt && (
          <Can permission="write:shipments">
            <IconButton onClick={() => onDelete(packout)}>
              <Delete />
            </IconButton>
          </Can>
        )}
        <Can permission="write:shipments">
          <IconButton component={Link} to={`/packouts/${packout.id}`}>
            <ArrowForward />
          </IconButton>
        </Can>
      </ListItemSecondaryAction>
    </ListItem>
  );
}
