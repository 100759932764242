import { useFormContext } from 'react-hook-form';
import StackLayout from '@/classes/Layouts/StackLayout';
import EditForm from '@/components/Form/EditForm';
import { useAppContext } from '@/contexts/AppContext';
import useGetResource from '@/utils/hooks/useGetResource';
import ClosableDrawer from './ClosableDrawer';

export default function CreateResourceDrawer() {
  const { createResource, setCreateResource } = useAppContext();
  const getResource = useGetResource();
  const form = useFormContext();

  if (!createResource) {
    return null;
  }
  const { resourceName, name, initialValues = {}, multiple } = createResource;
  const resource = getResource(resourceName);

  const onClose = () => setCreateResource(undefined);

  const onSuccess = (data: object) => {
    if (multiple) {
      form.setValue(name, [...form.watch(name), data]);
    } else {
      form.setValue(name, data);
    }
    onClose();
  };

  return (
    <ClosableDrawer open onClose={onClose} title={`Create ${resource.singularName}`}>
      {name && (
        <EditForm
          resource={resource}
          isCreate
          onSuccess={onSuccess}
          initialValues={initialValues}
          defaultLayout={StackLayout}
        />
      )}
    </ClosableDrawer>
  );
}
