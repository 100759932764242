import { Publish } from '@mui/icons-material';
import { Button, CircularProgress } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { z } from 'zod';
import { FieldFactory } from '@/classes';
import Paper from '@/components/Shared/Paper';
import { Order } from '@/types';
import { useRecord } from '@/utils/genericResource';
import useDialogs from '@/utils/hooks/useDialogs';

interface FormShape {
  name: string;
  closes_at: string;
}

export default function WebstoreTab() {
  const order = useRecord<Order>();
  const { prompt } = useDialogs();

  const { mutate: publish, isLoading: isPublishing } = useMutation((payload: Partial<FormShape>) =>
    axios.post(`/api/orders/${order.id}/publish-webstore`, payload).then(({ data }) => {
      if (data.webstore_admin_url) {
        window.open(data.webstore_admin_url, '_blank');
      }
    }),
  );

  const onPublish = () => {
    if (order.webstore_id) {
      return publish({});
    }

    return prompt({
      title: 'Publish New Webstore',
      fields: [
        FieldFactory.text('name')
          .withLabel('Webstore Name')
          .withHelp('This name will be used to create the URL'),
        FieldFactory.date('closes_at').withHelp(
          'The store will automatically close at 11:59 PM EST on this day',
        ),
      ],
      schema: z.object({
        name: z.string(),
        closes_at: z.string().date(),
      }),
      initialValues: {
        name: order.description,
      },
    }).then((values) => {
      publish(values);
    });
  };

  return (
    <Paper title="Webstore Actions">
      <Button variant="contained" size="large" onClick={onPublish} disabled={isPublishing}>
        {isPublishing ? (
          <>
            Loading <CircularProgress color="inherit" size={16} style={{ marginLeft: 4 }} />
          </>
        ) : (
          <>
            {!order.webstore_id ? 'Create' : 'Open'} in Store Platform <Publish />
          </>
        )}
      </Button>
    </Paper>
  );
}
