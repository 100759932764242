import { Fragment } from 'react';
import {
  CardContent,
  CardHeader,
  CircularProgress,
  TableBody,
  Table,
  Typography,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
} from '@mui/material';
import { amber, deepOrange, green, lime, pink, red, teal } from '@mui/material/colors';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import set from 'lodash/set';
import sumBy from 'lodash/sumBy';
import uniq from 'lodash/uniq';
import moment from 'moment';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { drawerColor, primaryColor, secondaryColor } from '@/MuiProvider';
import OrderLabel from '@/components/Orders/OrderLabel';
import OrderStatus from '@/components/Orders/OrderStatus';
import CardWithGutter from '@/components/Shared/CardWithGutter';
import Text from '@/components/Text/Text';
import { DesignLayout, Order } from '@/types';
import getBusinessName from '@/utils/getBusinessName';
import useTitle from '@/utils/hooks/useTitle';

const colors = [
  primaryColor,
  secondaryColor,
  drawerColor,
  amber[500],
  pink[500],
  lime[500],
  red[500],
  green[500],
  deepOrange[500],
  teal[500],
];

interface OrderWithProduction extends Order {
  design_layouts: DesignLayout[];
  schedule_dates: string;
}

export default function ProductionForecast() {
  useTitle('Production Forecast');

  const { data: orders, isLoading } = useQuery(['productionForecast'], () =>
    axios
      .get<{ data: OrderWithProduction[] }>('/api/orders', {
        params: {
          production_due_in_days: 14,
          with: 'sales_rep,customer,order_type,design_layouts.order_designs.design.decoration_type',
          count: 500,
        },
      })
      .then(({ data }) => data.data),
  );

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!orders) {
    return null;
  }

  const ordersById = keyBy(orders, 'id');

  const orderDesigns = orderBy(
    flatMap(orders, (o) =>
      flatMap(o.design_layouts, 'order_designs').map((od) => ({
        ...od,
        order_id: o.id,
        date: o.committed_ship_date,
      })),
    ),
    ['date', 'design.decoration_type.name'],
    ['asc', 'asc'],
  ).filter((o) => o.imprint_qty > 0);

  const types = uniq(map(orderDesigns, 'design.decoration_type.name'));

  const data: Record<string, Record<string, number>> = {};

  const groupedContent = (
    <div>
      {map(
        groupBy(orderDesigns, (o) => o.date.substr(0, 10)),
        (odForDate, dateString) => (
          <CardWithGutter key={dateString} id={dateString}>
            <CardHeader
              title={moment(odForDate[0].date).format('dddd, LL')}
              subheader={`Total Imprints: ${sumBy(odForDate, 'imprint_qty').toLocaleString()}`}
            />

            {map(groupBy(odForDate, 'design.decoration_type.name'), (odForType, type) => {
              set(data, `${dateString}.${type}`, sumBy(odForType, 'imprint_qty'));
              return (
                <Fragment key={type}>
                  <CardContent>
                    <Typography variant="h6">{type}</Typography>
                    <Typography variant="body2">
                      Total Imprints: {sumBy(odForType, 'imprint_qty').toLocaleString()}
                    </Typography>
                  </CardContent>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Order #</TableCell>
                          <TableCell>Customer</TableCell>
                          <TableCell>Sales Rep</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell># Designs</TableCell>
                          <TableCell>Total Imprints</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {map(groupBy(odForType, 'order_id'), (odForOrder, orderId) => {
                          const order = ordersById[orderId];

                          return (
                            <TableRow key={orderId}>
                              <TableCell>
                                <OrderLabel orderId={Number(orderId)}>
                                  {order.increment_id}
                                </OrderLabel>
                                <Typography variant="body2" color="textSecondary">
                                  {order.description}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Text
                                  primary={order.customer.name}
                                  secondary={getBusinessName(order.business)}
                                />
                              </TableCell>
                              <TableCell>
                                <Text primary={get(order, 'sales_rep.name')} />
                              </TableCell>
                              <TableCell style={{ width: 1, textAlign: 'center' }}>
                                <OrderStatus status={order.status} />
                                <Typography
                                  variant="caption"
                                  component="div"
                                  color="textSecondary"
                                  style={{ marginTop: 2 }}
                                >
                                  {order.schedule_dates
                                    ? `Scheduled ${order.schedule_dates}`
                                    : 'Not Scheduled'}
                                </Typography>
                              </TableCell>
                              <TableCell>{odForOrder.length.toLocaleString()}</TableCell>
                              <TableCell>
                                {sumBy(odForOrder, 'imprint_qty').toLocaleString()}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Fragment>
              );
            })}
          </CardWithGutter>
        ),
      )}
    </div>
  );

  return (
    <div>
      <Typography gutterBottom>
        This page shows committed orders for the next 14 days that have yet to start production.
      </Typography>
      <CardWithGutter>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={map(data, (counts, date) => ({ ...counts, date }))}>
            <XAxis dataKey="date" />
            <YAxis tickFormatter={(t) => t.toLocaleString()} />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip formatter={(t) => t.toLocaleString()} />
            {types.map((t, idx) => (
              <Bar
                key={t}
                dataKey={t}
                fill={colors[idx]}
                stackId={['Screenprint', 'Embroidery'].includes(t) ? t : 'secondary'}
                onClick={(bar) => document.getElementById(bar.date)?.scrollIntoView()}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </CardWithGutter>
      {groupedContent}
    </div>
  );
}
