import { Close } from '@mui/icons-material';
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useShowLoading } from '@/contexts/AppContext';
import { FieldProps } from '@/types';
import curr from '@/utils/curr';
import { useOnReloadRecord, useRecord } from '@/utils/genericResource';
import useDialogs from '@/utils/hooks/useDialogs';
import PlaidLink from './PlaidLink';

export default function PlaidConnection({ field }: FieldProps) {
  const { id, type, plaidName, plaidBalance, plaidLogo } = useRecord('accounts', true);
  const onReload = useOnReloadRecord('accounts');

  const isBank = ['bank', 'credit_card'].includes(type);
  const { confirm } = useDialogs();
  const showLoading = useShowLoading();

  const { data: plaidToken } = useQuery(
    ['plaidToken', id],
    () => axios.get(`/api/accounts/${id}/plaid`).then(({ data }) => data.link_token),
    {
      enabled: isBank,
    },
  );

  const onDisconnect = () => {
    confirm({
      title: 'Disconnect From Plaid',
      description: 'Are you sure you want to disconnect this account from Plaid?',
    }).then(() => {
      showLoading(axios.delete(`/api/accounts/${id}/plaid`)).then(() => {
        onReload();
      });
    });
  };

  if (!isBank && !field.value) {
    return null;
  }

  return (
    <div>
      {field.value && (
        <List>
          <ListItem>
            <ListItemAvatar>
              <Avatar src={`data:image/png;base64, ${plaidLogo}`}>
                {plaidName ? plaidName[0] : 'A'}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <span>
                  Connected to <i>{plaidName}</i> via Plaid
                </span>
              }
              secondary={`Balance: ${plaidBalance ? curr(plaidBalance) : '?'}`}
            />
            <ListItemSecondaryAction>
              <Tooltip title="Disconnect">
                <IconButton onClick={onDisconnect} size="large">
                  <Close />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      )}

      {plaidToken && <PlaidLink token={plaidToken} id={id} isUpdate={!!field.value} />}
    </div>
  );
}
