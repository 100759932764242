import { FieldFactory, Resource } from '@/classes';
import { PurchaseOrderItem } from '@/types';

export default function purchaseOrderItems() {
  return new Resource<PurchaseOrderItem>('Purchase Order Items')
    .setReadOnly()
    .setCanExport()
    .withFieldsAndColumns([
      FieldFactory.timestamp('created_at').sortable().filterable(),
      FieldFactory.belongsTo('purchase_order', 'purchaseOrders')
        .filterable('purchase_order_id')
        .sortable('purchase_order_id'),
      FieldFactory.text('description'),
      FieldFactory.text('number'),
      FieldFactory.text('color'),
      FieldFactory.text('size'),
      FieldFactory.number('qty').sortable().setAggregatable(true),
      FieldFactory.curr('cost').sortable(),
    ])
    .withFilters([
      FieldFactory.belongsTo('order', 'orders').withFilterKey('order_id'),
      FieldFactory.belongsTo('customer', 'customers').withFilterKey('customer_id'),
      FieldFactory.boolean('to_receive').withFilterKey('to_receive'),
    ]);
}
