import { CloudDownload } from '@mui/icons-material';
import {
  Action,
  Resource,
  CustomAction,
  AnchorAction,
  FieldFactory,
  SidebarLayout,
} from '@/classes';
import OrderSummaryField from '@/components/Orders/OrderSummaryField';
import PrintMenu from '@/components/Print/PrintMenu';
import OrderShipmentsCard from '@/components/Shipping/OrderShipmentsCard';
import ShippingForOrder from '@/components/Shipping/ShippingForOrder';
import { Order } from '@/types';
import { AppResponse } from '@/types';
import getApiUrl from '@/utils/getApiUrl';
import { INITIAL_ORDER_COLUMNS, getOrderColumns } from './orders';

export default function ordersToShip(appConfig: AppResponse) {
  return new Resource<Order>('Orders to Ship')
    .getTitleUsing((o) => `Shipping: ${o.increment_id}`)
    .withApiEndpoint('/api/orders')
    .getSingleActionsUsing((v) => {
      const actions: Action[] = [
        new CustomAction('Print', () => <PrintMenu model="order" id={v.id} />),
      ];
      if (v.shipping_mode === 'kits') {
        actions.push(
          new AnchorAction(
            'Download Addresses',
            getApiUrl(`/api/orders/${v.id}/addresses/download`),
          ).withIcon(CloudDownload),
        );
      }
      return actions;
    })
    .setReadOnly()
    .setCanExport()
    .withDefaultSort('committed_ship_date')
    .withInitialColumns(INITIAL_ORDER_COLUMNS)
    .withDefaultFilters({
      bucket: 'to_ship',
    })
    .withFilters([
      FieldFactory.radio('bucket', {
        to_ship: 'All',
        ready_to_ship: 'Ready',
        blanks_to_ship: 'Blanks',
        drop_ship_to_ship: 'Drop Shipped',
      }).asQuickFilter(),
      FieldFactory.select(
        'shipping_method',
        appConfig.shippingMethods.map((m) => m.name),
      ).withFilterKey('shipping_method'),
    ])
    .withColumns(getOrderColumns())
    .withFields([
      new SidebarLayout('grid')
        .withoutDefaultLayout()
        .withFields([
          FieldFactory.custom('items', ShippingForOrder),
          FieldFactory.custom('shipments', () => <OrderShipmentsCard />),
        ])
        .withSidebarFields([FieldFactory.custom('summary', OrderSummaryField)]),
    ]);
}
