import {
  ForwardedRef,
  forwardRef,
  ReactNode,
  Reducer,
  SyntheticEvent,
  useCallback,
  useEffect,
  useImperativeHandle,
  useReducer,
  useRef,
} from 'react';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import get from 'lodash/get';
import includes from 'lodash/includes';
import type { FieldValues } from 'react-hook-form';
import { Field, Resource } from '@/classes';
import LinkWithContext from '@/components/Shared/LinkWithContext';
import { DataTableState } from '@/types';
import useGetParamsForIndexRequest from '@/utils/hooks/useGetParamsForIndexRequest';
import usePushWithContext, { MappedRow } from '@/utils/hooks/usePushWithContext';
import renderCell from '@/utils/renderCell';
import { DataTableContext, INITIAL_DATA_TABLE_STATE } from './DataTableContext';
import DataTableHead from './DataTableHead';
import DataTableToolbar from './DataTableToolbar';
import EmptyState from './EmptyState';
import ErrorState from './ErrorState';
import TableBodyLoadingOverlay from './TableBodyLoadingOverlay';

type ReducerAction =
  | {
      type: 'SORT';
      sort: string | null;
    }
  | {
      type: 'SET_FILTERS';
      filters: Record<string, any>;
    }
  | {
      type: 'FILTER';
      filterKey: string;
      filterValue: any;
    }
  | {
      type: 'PAGE';
      page: number;
    }
  | {
      type: 'QUERY';
      query: string;
    }
  | {
      type: 'COUNT';
      count: number;
    }
  | {
      type: 'SELECTED';
      selected: (string | number)[];
    }
  | {
      type: 'COLUMNS';
      columns: string[];
    }
  | {
      type: 'AGGREGATION';
      name: string;
      aggregation: 'sum' | 'avg';
    };

const tableReducer: Reducer<DataTableState, ReducerAction> = (state, action) => {
  switch (action.type) {
    case 'SORT':
      return {
        ...state,
        sort: action.sort,
        page: 1,
      };
    case 'FILTER':
      return {
        ...state,
        filters: { ...state.filters, [action.filterKey]: action.filterValue },
        page: 1,
      };
    case 'SET_FILTERS':
      return {
        ...state,
        filters: action.filters,
        page: 1,
      };
    case 'PAGE':
      return {
        ...state,
        page: action.page,
      };
    case 'QUERY':
      return {
        ...state,
        query: action.query,
        page: 1,
      };
    case 'COUNT':
      return {
        ...state,
        count: action.count,
        page: 1,
      };
    case 'SELECTED':
      return {
        ...state,
        selected: action.selected,
      };
    case 'COLUMNS':
      return {
        ...state,
        columns: action.columns,
      };
    case 'AGGREGATION':
      // eslint-disable-next-line no-case-declarations
      const values = state.aggregations[action.aggregation];

      return {
        ...state,
        aggregations: {
          ...state.aggregations,
          [action.aggregation]: includes(values, action.name)
            ? values.filter((f) => f !== action.name)
            : [...values, action.name],
        },
      };
    default:
      throw new Error();
  }
};

export interface DataTableHandle {
  onReload: () => void;
}

export interface DataTableProps<T extends FieldValues = FieldValues> {
  resource: Resource<T>;
  getHref?: (row: T) => string;
  onEdit?: (row: T) => void;
  initialState?: Partial<DataTableState>;
  children?: ReactNode;
  onStateUpdated?: (s: DataTableState) => void;
}

function DataTable<T extends FieldValues = FieldValues>(
  props: DataTableProps<T>,
  ref: ForwardedRef<DataTableHandle>,
) {
  const { resource, getHref, onEdit, initialState = {}, children, onStateUpdated } = props;
  const getParamsForIndexRequest = useGetParamsForIndexRequest();
  const [state, dispatch] = useReducer(tableReducer, {
    ...INITIAL_DATA_TABLE_STATE,
    columns: resource.getInitialTableColumnNames(),
    filters: resource.defaultFilters,
    sort: resource.defaultSort,
    ...initialState,
  });

  const { page, count, sort, selected, columns, query } = state;
  const tableBodyRef = useRef<HTMLTableSectionElement | null>(null);

  const requestParams = getParamsForIndexRequest(resource, state, query);
  const { data, isLoading, isFetching, isError, refetch } = useQuery(
    ['dataTable', resource.key, JSON.stringify(requestParams)],
    () => resource.getIndexRequest(requestParams),
  );
  const records = data?.data.data || [];
  const meta = data?.data.meta;
  const total = data?.data.meta.total;

  useEffect(() => {
    if (onStateUpdated) {
      onStateUpdated(state);
    }
  }, [state]);

  const onSortChange = useCallback(
    (property: string) => {
      const getNewSort = () => {
        if (sort === `-${property}`) {
          return null;
        }
        if (sort === property) {
          return `-${property}`;
        }
        return property;
      };

      dispatch({
        type: 'SORT',
        sort: getNewSort(),
      });
    },
    [dispatch, sort],
  );

  const onPageChange = useCallback(
    (nextPage: number) => {
      if (nextPage !== page) {
        dispatch({
          type: 'PAGE',
          page: nextPage,
        });
      }
    },
    [dispatch, page],
  );

  const onCountChange = useCallback(
    (nextCount: number) => {
      dispatch({
        type: 'COUNT',
        count: nextCount,
      });
    },
    [dispatch],
  );

  const onQuery = useCallback(
    (nextQuery: string) => {
      dispatch({
        type: 'QUERY',
        query: nextQuery,
      });
    },
    [dispatch],
  );

  const onColumnsChange = useCallback(
    (nextColumns: string[]) => {
      dispatch({
        type: 'COLUMNS',
        columns: nextColumns,
      });
    },
    [dispatch],
  );

  const onFilter = useCallback(
    (name: string, value: any) => {
      dispatch({
        type: 'FILTER',
        filterKey: name,
        filterValue: value,
      });
    },
    [dispatch],
  );

  const onSelected = useCallback(
    (newSelected: (string | number)[]) => {
      dispatch({
        type: 'SELECTED',
        selected: newSelected,
      });
    },
    [dispatch],
  );

  const onSetFilters = useCallback(
    (filters: Record<string, any>) => {
      dispatch({
        type: 'SET_FILTERS',
        filters,
      });
    },
    [dispatch],
  );

  const onAggregation = useCallback(
    (name: string, aggregation: 'sum' | 'avg' = 'sum') => {
      dispatch({
        type: 'AGGREGATION',
        name,
        aggregation,
      });
    },
    [dispatch],
  );

  const onReload = useCallback(() => {
    refetch();
    onSelected([]);
  }, [refetch, onSelected]);

  useImperativeHandle(ref, () => ({
    onReload,
  }));

  const otherRows: MappedRow[] = getHref
    ? records.map((row) => ({
        label: resource.getTitle(row),
        key: row[resource.primaryKey],
        href: getHref(row),
      }))
    : [];
  const pushWithContext = usePushWithContext(otherRows);

  const handleClick = (event: SyntheticEvent, id: string | number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: (string | number)[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    onSelected(newSelected);
  };

  const isIdSelected = (id: string | number) => selected.indexOf(id) !== -1;

  const visibleColumns = columns
    .map((c) => resource.columns.find((col) => col.name === c))
    .filter((c) => !!c) as Field[];

  const { bulkActions } = resource;
  const showCheckbox =
    bulkActions &&
    (typeof bulkActions === 'function' || resource.deletable || bulkActions.length > 0);

  const onRowClick = (n: T) => {
    if (onEdit) {
      return onEdit(n);
    }
    if (getHref) {
      return pushWithContext(getHref(n));
    }
    return null;
  };

  return (
    <DataTableContext.Provider
      value={{
        ...state,
        showCheckbox,
        visibleColumns,
        onPageChange,
        onCountChange,
        onSortChange,
        onColumnsChange,
        onFilter,
        onSelected,
        onAggregation,
        onSetFilters,
        onQuery,
        onReload,
        isLoading,
        isFetching,
        isError,
        records,
        meta,
        total,
      }}
    >
      <div>
        <DataTableToolbar resource={resource} />

        <TableContainer sx={{ position: 'relative' }}>
          <Table>
            <DataTableHead resource={resource} />

            {!isLoading && !isError && records.length === 0 && <EmptyState resource={resource} />}

            {isError && <ErrorState resource={resource} />}

            <TableBody ref={tableBodyRef}>
              {records.map((record) => {
                const pk = record[resource.primaryKey];
                const isSelected = isIdSelected(pk);

                const onSelect = (event: SyntheticEvent) => {
                  event.stopPropagation();
                  handleClick(event, pk);
                };

                return (
                  <TableRow
                    hover={resource.editable}
                    onClick={() => onRowClick(record)}
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={pk}
                    selected={isSelected}
                  >
                    {showCheckbox && (
                      <TableCell padding="checkbox">
                        <Checkbox checked={isSelected} onClick={onSelect} />
                      </TableCell>
                    )}
                    {visibleColumns.map((field, i) => {
                      const value = get(record, field.name, '');
                      const contents = renderCell(field, value, record);

                      return (
                        <TableCell key={field.name}>
                          {i === 0 && getHref ? (
                            <LinkWithContext
                              to={getHref(record)}
                              otherRows={otherRows}
                              style={{
                                textDecoration: 'none',
                                color: 'inherit',
                              }}
                            >
                              {contents}
                            </LinkWithContext>
                          ) : (
                            contents
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
              {total ? (
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100, 250, 1000, 2500]}
                    count={total}
                    rowsPerPage={Number(count)}
                    page={page - 1}
                    onPageChange={(e, p) => onPageChange(p + 1)}
                    onRowsPerPageChange={(e) => onCountChange(Number(e.target.value))}
                  />
                </TableRow>
              ) : null}
            </TableBody>
          </Table>

          <TableBodyLoadingOverlay
            el={tableBodyRef.current}
            show={records.length > 0 && isFetching}
          />
        </TableContainer>

        {children}
      </div>
    </DataTableContext.Provider>
  );
}

export default forwardRef(DataTable) as <T extends FieldValues = FieldValues>(
  props: DataTableProps<T> & { ref?: ForwardedRef<DataTableHandle> },
) => JSX.Element;
