import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Grid2 as Grid, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import startCase from 'lodash/startCase';
import { FieldFactory } from '@/classes';
import FormField from '@/components/Form/FormField';
import Can from '@/components/Permissions/Can';
import ProductField from '@/components/Products/ProductField';
import { useConfig } from '@/contexts/AppConfigContext';
import { isScreenPrint } from '@/helpers';
import { DecorationType } from '@/types';
import useToast from '@/utils/hooks/useToast';
import AttachmentsCard from '../Attachments/AttachmentsCard';
import DesignColors from './DesignColors';

export default function DesignSection({
  id,
  decorationType,
}: {
  id?: number;
  decorationType?: Pick<DecorationType, 'unit' | 'name' | 'has_drops'> | null;
}) {
  const decorationUnit = decorationType?.unit;
  const decorationTypeName = decorationType?.name;
  const hasDrops = decorationType?.has_drops;
  const { decorationLocations } = useConfig();
  const toast = useToast();
  const copyDstFile = useMutation(() =>
    axios.post(`/api/designs/${id}/copy-dst-file`).then(() => {
      toast('If there was a DST file, it has been pushed to embroidery server.');
    }),
  );

  return (
    <Grid container spacing={4}>
      <Grid size={{ xs: 12, md: 5 }}>
        <Box mb={3}>
          <FormField field={FieldFactory.image('image').withoutLabel()} />
        </Box>

        <Box mb={1}>
          <FormField field={FieldFactory.text('name')} />
        </Box>

        <Box mb={1}>
          <FormField field={FieldFactory.belongsTo('customer', 'customers')} />
        </Box>
        <Box mb={1}>
          <FormField field={FieldFactory.belongsTo('decoration_type', 'decorationTypes')} />
        </Box>

        <Box mb={1}>
          <FormField
            field={FieldFactory.autocomplete(
              'location',
              (decorationLocations[decorationTypeName || ''] || []).map((l) => l.name),
            )}
          />
        </Box>

        {id && (
          <>
            <AttachmentsCard
              resourceId={id}
              resource="designs"
              group="files"
              title="Production Files"
              action={
                <Can permission="write:designs">
                  <LoadingButton
                    onClick={() => copyDstFile.mutate()}
                    loading={copyDstFile.isLoading}
                    size="small"
                  >
                    Push DST File
                  </LoadingButton>
                </Can>
              }
              elevation={0}
            />
            <Can permission="write:designs">
              <AttachmentsCard
                resourceId={id}
                resource="designs"
                group="internal"
                title="Internal Art Files"
                elevation={0}
              />
            </Can>
          </>
        )}
      </Grid>
      <Grid size={{ xs: 12, md: 7 }}>
        <Typography variant="h6" gutterBottom>
          Details
        </Typography>

        <Box mb={1}>
          <FormField field={FieldFactory.textarea('description').with({ rows: 4 })} />
        </Box>

        <Box mb={1} display="flex">
          <FormField
            field={FieldFactory.number('complexity').withLabel(
              decorationUnit ? `# of ${startCase(decorationUnit)}` : 'Complexity',
            )}
          />
          <div style={{ width: 16 }} />
          <FormField field={FieldFactory.text('design_code')} />
        </Box>
        <Box mb={1} display="flex">
          <FormField field={FieldFactory.text('width')} />
          <div style={{ width: 16 }} />
          <FormField field={FieldFactory.text('height')} />
        </Box>
        <Box mb={1}>
          <FormField
            field={FieldFactory.text('product_colors').withHelp(
              'What garment color(s) should this design be applied to?',
            )}
          />
        </Box>
        <Box mb={1}>
          <FormField field={FieldFactory.belongsTo('special_design_type', 'specialDesignTypes')} />
        </Box>
        {hasDrops && (
          <Box mb={1}>
            <FormField
              field={new ProductField('product')
                .withLabel('Name/Number Product')
                .withHelp('The product to order from Stahls (or other)')}
            />
          </Box>
        )}

        <Typography variant="h6" gutterBottom>
          Colors
        </Typography>
        {isScreenPrint(decorationUnit) && (
          <Box mt={1}>
            <FormField field={FieldFactory.boolean('has_underbase', 'Has Underbase')} />
          </Box>
        )}

        <DesignColors decorationUnit={decorationUnit || null} freeSolo />
      </Grid>
    </Grid>
  );
}
