import { ChangeEvent, ClipboardEvent, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { FileDownload, UploadFile } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import Box from '@mui/material/Box';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';
import set from 'lodash/set';
import times from 'lodash/times';
import DrawerButtons from '@/components/Form/DrawerButtons';
import ClosableDrawer from '@/components/Shared/ClosableDrawer';
import { DesignLayoutWithProofs, OrderDesign, OrderItem } from '@/types';
import getApiUrl from '@/utils/getApiUrl';
import getValueFromEvent from '@/utils/getValueFromEvent';
import useDebounce from '@/utils/hooks/useDebounce';

export default function RosterDrawer({
  layout,
  onSaved,
  onClose,
}: {
  layout?: DesignLayoutWithProofs;
  onSaved: () => void;
  onClose: () => void;
}) {
  const [items, setItems] = useState<OrderItem[]>();
  const [originalItems, setOriginalItems] = useState<OrderItem[]>();
  const debouncedItems = useDebounce(items, 100);
  const layoutId = layout?.id;

  const orderDesigns = layout
    ? orderBy(
        layout.order_designs.filter((od) => od.design.decoration_type.has_drops),
        'id',
      )
    : [];

  const isDirty = useMemo(
    () => !isEqual(debouncedItems, originalItems),
    [debouncedItems, originalItems],
  );

  useEffect(() => {
    if (layoutId)
      axios
        .get<{ data: OrderItem[] }>(`/api/design-layouts/${layoutId}/roster?format=json`)
        .then(({ data }) => {
          setItems(data.data);
          setOriginalItems(data.data);
        });
  }, [layoutId]);

  const saveRequest = useMutation((items: OrderItem[]) =>
    axios.put(`/api/design-layouts/${layout!.id}/roster`, { items }).then(({ data }) => {
      setItems(data.data);
      setOriginalItems(data.data);
      onSaved();
    }),
  );

  const onUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    const formData = new FormData();
    formData.append('file', file);
    axios.post(`/api/design-layouts/${layout!.id}/roster`, formData).then(({ data }) => {
      setItems(data.data);
      setOriginalItems(data.data);
    });
    e.target.value = '';
  };

  const createListener =
    (item: OrderItem, od: OrderDesign, dIdx: number) => (e: SyntheticEvent) => {
      const value = getValueFromEvent(e);
      setItems((prev) =>
        prev!.map((i) => {
          if (item.id !== i.id) {
            return i;
          }

          const newItem = cloneDeep(i);
          set(newItem, `order_design_ids[${od.id}].drops[${dIdx}]`, value);
          return newItem;
        }),
      );
    };

  const createPasteListener =
    (itemIdx: number, odIdx: number, dIdx: number) => (e: ClipboardEvent<HTMLInputElement>) => {
      const text = e.clipboardData.getData('Text').split(/\r?\n/);

      if (text.length > 1) {
        e.preventDefault();
        setItems((prev) => {
          if (!prev) return prev;
          const newItems = cloneDeep(prev);

          const addNextValue = (itemIdx: number, qtyIdx: number) => {
            const nextValue = text.shift();
            if (nextValue != null) {
              const item = newItems[itemIdx]!;
              nextValue.split(/\t/).forEach((cell, i) => {
                const od = orderDesigns[odIdx + i];
                set(item, `order_design_ids[${od.id}].drops[${qtyIdx}]`, cell);
              });
            }
          };

          for (let d = dIdx; d < newItems[itemIdx]!.qty; d++) {
            addNextValue(itemIdx, d);
          }
          for (let i = itemIdx + 1; i < newItems.length; i++) {
            for (let d = 0; d < newItems[i].qty; d++) {
              addNextValue(i, d);
            }
          }
          return newItems;
        });
      }
    };

  return (
    <ClosableDrawer open={!!layout} onClose={onClose} title="Roster for Names/Numbers" width={800}>
      <Box>
        <Box mb={2} display="flex" justifyContent="end">
          <Button
            component="a"
            href={getApiUrl(`/api/design-layouts/${layout?.id}/roster`)}
            size="small"
            startIcon={<FileDownload />}
          >
            Download
          </Button>
          <Button component="label" size="small" sx={{ ml: 2 }} endIcon={<UploadFile />}>
            <input
              type="file"
              style={{ display: 'none' }}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={onUpload}
            />
            Upload
          </Button>
        </Box>
        {!items ? (
          <CircularProgress />
        ) : (
          <>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Number</TableCell>
                  <TableCell>Color</TableCell>
                  <TableCell>Size</TableCell>
                  {orderDesigns.map((od) => (
                    <TableCell key={od.id}>
                      {od.design.decoration_type.drop_label || 'Drop'}: {od.design.increment_id}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {items.map((item, itemIdx) => (
                <TableBody key={item.id}>
                  {times(item.qty, (di) => (
                    <TableRow key={di}>
                      <TableCell>{item.number}</TableCell>
                      <TableCell>{item.color}</TableCell>
                      <TableCell>{item.size}</TableCell>
                      {orderDesigns.map((od, odIdx) => (
                        <TableCell key={od.id}>
                          <TextField
                            value={item.order_design_ids[od.id]?.drops?.[di] ?? ''}
                            onChange={createListener(item, od, di)}
                            onPaste={createPasteListener(itemIdx, odIdx, di)}
                            sx={{ width: 180 }}
                            size="small"
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              ))}
            </Table>

            {isDirty && (
              <DrawerButtons>
                <LoadingButton
                  type="button"
                  variant="contained"
                  onClick={() => saveRequest.mutate(items)}
                  loading={saveRequest.isLoading}
                >
                  Save
                </LoadingButton>
              </DrawerButtons>
            )}
          </>
        )}
      </Box>
    </ClosableDrawer>
  );
}
