import { DatePicker } from '@mui/x-date-pickers-pro';
import moment from 'moment';
import { UseControllerReturn } from 'react-hook-form';
import DateRangeField from '@/classes/Fields/DateRangeField';
import { FieldProps } from '@/types';
import { getDateString, getMoment } from '@/utils/dates';
import Field from '../Field';

function Date({ field, fieldState, fieldModel }: FieldProps<DateField>) {
  return (
    <DatePicker
      label={fieldModel.label}
      value={getMoment(field.value)}
      onChange={(v) => field.onChange(getDateString(v))}
      readOnly={Boolean(fieldModel.readOnly)}
      disabled={fieldModel.disabled}
      slotProps={{
        textField: fieldModel.getTextFieldProps(fieldState),
      }}
    />
  );
}

export default class DateField extends Field {
  format = 'll';

  getFilterFieldForType() {
    return new DateRangeField(this.name);
  }

  renderEditComponent(props: UseControllerReturn) {
    return <Date {...props} fieldModel={this} />;
  }

  renderCell(value: any) {
    if (value) {
      return <span>{moment(value).format(this.format)}</span>;
    }
    return null;
  }
}
