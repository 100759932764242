import { Check, LockReset } from '@mui/icons-material';
import { Card, CardHeader, IconButton, Tooltip } from '@mui/material';
import startCase from 'lodash/startCase';
import { useGetKitsForOrder, usePerformKitAction } from '@/api/packout';
import KitsTable from '@/components/Kitting/KitsTable';
import PrintMenu from '@/components/Print/PrintMenu';
import ShipActions from '@/components/Shipping/ShipActions';
import { Kit } from '@/types';
import { useRecord } from '@/utils/genericResource';
import useDialogs from '@/utils/hooks/useDialogs';

export default function PackoutShippingStatus() {
  const { order } = useRecord('orderPackouts', true);
  const { confirm } = useDialogs();

  const query = useGetKitsForOrder(order.id);
  const performKitAction = usePerformKitAction(order.id);

  const handleKitAction = (kit: Kit, action: 'start' | 'complete' | 'reset') => {
    confirm({
      title: `${startCase(action)} Kit: ${kit.number || kit.name}`,
      description: 'Are you sure you want to take this action?',
    }).then(() => {
      performKitAction.mutate({ id: kit.id, action });
    });
  };

  return (
    <Card>
      <CardHeader title="Kits in Order" titleTypographyProps={{ variant: 'h6' }} />

      <KitsTable
        kitsQuery={query}
        columns={[
          {
            id: 'actions',
            header: '',
            enableHiding: false,
            enableSorting: false,
            cell: ({ row: { original: kit } }) => (
              <>
                <PrintMenu model="kit" id={kit.id} />

                {kit.pick_status === 'issued' ? (
                  <Tooltip title="Start Picking">
                    <IconButton onClick={() => handleKitAction(kit, 'start')}>
                      <Check />
                    </IconButton>
                  </Tooltip>
                ) : kit.pick_status === 'picking' ? (
                  <Tooltip title="Start Packing">
                    <IconButton onClick={() => handleKitAction(kit, 'complete')}>
                      <Check />
                    </IconButton>
                  </Tooltip>
                ) : kit.pick_status === 'pending_shipment' ? (
                  <ShipActions
                    size="small"
                    payload={{
                      shippable_id: order.id,
                      shippable_type: 'order',
                      address_id: kit.address_id,
                      items: [{ kit_id: kit.id, qty_shipped: 1 }],
                    }}
                    customerId={order.customer.id}
                    title={`Ship ${kit.number || kit.name}`}
                    qty={1}
                    onSuccess={() => query.refetch()}
                  />
                ) : (
                  <Tooltip title="Reset Pick">
                    <IconButton onClick={() => handleKitAction(kit, 'reset')}>
                      <LockReset />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            ),
          },
        ]}
        columnVisibility={{
          group: false,
          email: false,
          pick_started_at: false,
          pick_completed_at: false,
        }}
        filename={`kits-${order.increment_id.toLowerCase()}`}
      />
    </Card>
  );
}
