import { Box } from '@mui/material';
import { BoxOwnProps } from '@mui/system/Box/Box';

export default function DrawerButtons({ children, ...props }: BoxOwnProps) {
  return (
    <Box
      mt={1}
      py={2}
      mb={-3}
      display="flex"
      justifyContent="end"
      position="sticky"
      bottom={0}
      gap={1}
      bgcolor="background.paper"
      zIndex={1}
      {...props}
    >
      {children}
    </Box>
  );
}
