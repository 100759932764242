import { List, Button, Typography, Skeleton, Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import { z } from 'zod';
import { FieldFactory } from '@/classes';
import SubMenu from '@/components/Shared/SubMenu';
import { useConfig } from '@/contexts/AppConfigContext';
import { ActivityEvent, CustomerContact, ReceivablesCustomer } from '@/types';
import { getSingleHeldAction } from '@/utils/disabled';
import useDialogs from '@/utils/hooks/useDialogs';
import numString from '@/utils/numString';
import ActivityEventListItem from '../Notes/ActivityEventListItem';

export default function CustomerReceivableEvents({
  customer,
  contacts,
  onUpdated,
}: {
  customer: ReceivablesCustomer;
  contacts: CustomerContact[];
  onUpdated: (c: Partial<ReceivablesCustomer>) => void;
}) {
  const dialogs = useDialogs();
  const { confirm, prompt } = dialogs;
  const { user } = useConfig();

  const { data: events, refetch } = useQuery(['customerEvents', customer.id], () =>
    axios
      .get<{
        data: ActivityEvent[];
      }>(`/api/receivables/${customer.id}/events?sort=-created_at&count=7`)
      .then(({ data }) => data.data),
  );

  const addNote = () => {
    prompt({
      title: 'Add Note',
      fields: [FieldFactory.textarea('note')],
      schema: z.object({
        note: z.string().min(1),
      }),
      onSubmit: (v) => axios.post(`/api/receivables/${customer.id}/notes`, v),
    }).then(() => {
      refetch();
    });
  };

  const sendEmail = () => {
    const emails = uniq(contacts.filter((i) => i.is_invoice_contact).map((i) => i.email));

    let subject = customer.business.name;

    if (customer.past_due_count === 1) {
      subject += ` Invoice #${customer.invoices.find((i) => i.days_past_due >= 0)?.label} Past Due`;
    } else {
      subject += ' Invoices Past Due';
    }

    const hasOne = customer.past_due_count === 1;

    const message =
      `Hello,\n\n` +
      `This is a friendly reminder that you have ${numString(
        customer.past_due_count,
        'invoices',
      )} that ${hasOne ? 'is' : 'are'} past due. I have attached the ${
        hasOne ? 'invoice' : 'invoices'
      } for your review.\n\n` +
      `Please let me know when ${hasOne ? 'it' : 'they'} will be paid.\n\n` +
      `If you would like to pay by credit card, you can call me directly at 704-225-1800 or pay online using the links below.\n\n` +
      `Thank you for your help.\n\n${user.name}`;

    prompt({
      title: 'Send Collections Email',
      maxWidth: 'md',
      fields: [
        FieldFactory.select(
          'emails',
          orderBy(contacts, ['is_invoice_contact', 'email'], ['desc', 'asc']).map(
            (c) => c.email || '',
          ),
        ).with({ multiple: true }),
        FieldFactory.text('subject'),
        FieldFactory.textarea('message')
          .withHelp(
            'The past due invoices will be attached and payment links displayed at the bottom of the email.',
          )
          .with({ rows: 14 }),
      ],
      schema: z.object({
        emails: z.array(z.string()),
        subject: z.string().min(1),
        message: z.string().min(1),
      }),
      initialValues: { subject, message, emails },
      onSubmit: (v) => axios.post(`/api/receivables/${customer.id}/email`, v),
    }).then(() => {
      refetch();
    });
  };

  const onDelete = (event: ActivityEvent) => {
    confirm({
      title: 'Delete Note',
      description: 'Are you sure you want to delete this note?',
    }).then(() => {
      axios.delete(`/api/receivables/${customer.id}/notes/${event.id}`).then(() => {
        refetch();
      });
    });
  };

  if (!events) {
    return (
      <>
        <Skeleton />
        <Skeleton />
      </>
    );
  }

  return (
    <div>
      {events.length > 0 ? (
        <List dense>
          {events.map((event) => (
            <ActivityEventListItem
              key={event.id}
              event={event}
              disableGutters
              size="small"
              onDelete={
                event.event_type === 'note' && event.user?.id === user.id
                  ? () => onDelete(event)
                  : undefined
              }
            />
          ))}
        </List>
      ) : (
        <Typography variant="body2" gutterBottom>
          No activity found for this customer.
        </Typography>
      )}

      <Box mt={1}>
        {customer.past_due_count > 0 && (
          <Button type="button" sx={{ mr: 1 }} onClick={sendEmail} variant="outlined" size="small">
            Email
          </Button>
        )}
        <Button type="button" onClick={addNote} size="small" sx={{ mr: 1 }}>
          Add Note
        </Button>

        <SubMenu
          size="small"
          items={[
            getSingleHeldAction('Customer', 'customers', customer, (c) =>
              onUpdated({ is_disabled: c.is_disabled }),
            ),
          ]}
        />
      </Box>
    </div>
  );
}
