import { useQuery } from '@tanstack/react-query';
import { DesignLayout, PartiallyRequired } from 'avail-types';
import axios from 'axios';
import { DesignLayoutWithProofs } from '@/types';
import { makeResourceQueryKey } from '@/utils/genericResource';
import useMutateQueryData from '@/utils/hooks/useMutateQueryData';

export function useDesignLayoutsForOrder(orderId: number) {
  return useQuery(makeResourceQueryKey('orders', orderId, 'designLayouts'), () =>
    axios
      .get<{
        data: DesignLayoutWithProofs[];
      }>(
        `/api/design-layouts?with=order_designs,production_proofs,customer_proofs&order_id=${orderId}&count=100`,
      )
      .then(({ data }) => data.data),
  );
}

export function useSetDesignLayouts(orderId: number) {
  return useMutateQueryData<DesignLayoutWithProofs[]>(
    makeResourceQueryKey('orders', orderId, 'designLayouts'),
  );
}

export function useDesignLayoutsById(ids: number[]) {
  const idString = ids.join();
  return useQuery(
    ['designLayoutsById', idString],
    () =>
      axios
        .get<{
          data: PartiallyRequired<DesignLayout, 'production_proofs'>[];
        }>(
          `/api/design-layouts?with=production_proofs&filter[id][in]=${idString}&count=${ids.length}`,
        )
        .then(({ data }) => data.data),
    {
      enabled: ids.length > 0,
    },
  );
}
