import { useCallback } from 'react';
import { AlertColor } from '@mui/material';
import { useAppContext } from '@/contexts/AppContext';

export default function useToast() {
  const { setToast } = useAppContext();

  return useCallback(
    (message: string, status?: AlertColor) => {
      setToast({ message, status: status || 'success' });
    },
    [setToast],
  );
}
