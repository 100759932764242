import { SyntheticEvent, useEffect } from 'react';
import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid2 as Grid,
} from '@mui/material';
import { ReportColumnSettings } from 'avail-types/models';
import cloneDeep from 'lodash/cloneDeep';
import map from 'lodash/map';
import set from 'lodash/set';
import size from 'lodash/size';
import startCase from 'lodash/startCase';
import { useFormContext } from 'react-hook-form';
import { FieldFactory } from '@/classes';
import { NonFormField } from '@/components/Form/FormField';
import { FieldProps } from '@/types';
import getValueFromEvent from '@/utils/getValueFromEvent';

export default function ColumnSettings({ field }: FieldProps) {
  const columns = field.value || {};
  const newColumns = useFormContext().watch('_columns') as string[];

  useEffect(() => {
    if (newColumns && size(newColumns) > 0) {
      field.onChange(
        newColumns.reduce((acc, key) => {
          acc[key] = columns[key] || {
            label: startCase(key),
          };
          return acc;
        }, {} as ReportColumnSettings),
      );
    }
  }, [JSON.stringify(newColumns)]);

  const createHandler = (key: string, name: string) => (e: SyntheticEvent) => {
    const payload = cloneDeep(columns);
    const value = getValueFromEvent(e);
    set(payload, `${key}.${name}`, value);
    field.onChange(payload);
  };

  if (!columns || size(columns) === 0) {
    return null;
  }

  return (
    <div>
      {map(columns, (settings, key) => (
        <Accordion key={key}>
          <AccordionSummary expandIcon={<ExpandMore />}>{key}</AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1}>
              <Grid size={12}>
                <NonFormField
                  value={settings.format}
                  onChange={createHandler(key, 'format')}
                  field={FieldFactory.select('format', [
                    { value: '', label: 'None' },
                    { value: 'curr', label: 'Currency ($)' },
                    { value: 'short_curr', label: 'Short Currency ($1.1k)' },
                    { value: 'percent', label: 'Percent (%)' },
                    { value: 'number', label: 'Number (1,000)' },
                  ])}
                />
              </Grid>
              <Grid size={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!settings.total_row}
                      onChange={createHandler(key, 'total_row')}
                    />
                  }
                  label="Has Total Row"
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
