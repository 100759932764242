import { ReactNode } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid2 as Grid,
  Typography,
} from '@mui/material';
import startCase from 'lodash/startCase';
import { FieldFactory, GridLayout } from '@/classes';
import EditFormFields from '@/components/Form/EditFormFields';
import FormField from '@/components/Form/FormField';
import Can from '@/components/Permissions/Can';
import PurchaseOrderDesignLayoutsCard from '@/components/Purchasing/PurchaseOrderDesignLayoutsCard';
import PurchaseOrderInboundShipmentsCard from '@/components/Purchasing/PurchaseOrderInboundShipmentsCard';
import CardWithGutter from '@/components/Shared/CardWithGutter';
import StatusChip from '@/components/Shared/StatusChip';
import TextLink from '@/components/Text/TextLink';
import { PURCHASE_ORDER_STATUS_COLORS } from '@/constants';
import { Vendor } from '@/types';
import { useOnReloadRecord, useRecord, useUpdateRecord } from '@/utils/genericResource';
import { useHasPermission } from '@/utils/hooks/permissions';
import GenericAddressField from '../Addresses/GenericAddressField';
import EventableCard from '../Notes/EventableCard';
import PurchaseOrderActions from './PurchaseOrderActions';
import PurchaseOrderItems from './PurchaseOrderItems';
import PurchaseOrderMatchingCard from './PurchaseOrderMatchingCard';
import PurchaseOrderStatuses from './PurchaseOrderStatuses';

function VendorField({ field, value }: { field: keyof Vendor; value: ReactNode }) {
  return (
    <Grid size={{ xs: 6, lg: 3 }}>
      <Typography variant="subtitle2" color="textSecondary">
        {field === 'url' ? 'Website' : startCase(field)}
      </Typography>
      <Typography>{value || '(none)'}</Typography>
    </Grid>
  );
}

export default function PurchaseOrderPage() {
  const po = useRecord('purchaseOrders');
  const onUpdated = useUpdateRecord('purchaseOrders');
  const onReload = useOnReloadRecord('purchaseOrders');
  const hasPermission = useHasPermission();

  const orderIds = po.items.reduce((orderIds, poItem) => {
    if (poItem.order_id && orderIds.includes(poItem.order_id)) {
      return [...orderIds, poItem.order_id];
    }
    return [];
  }, [] as number[]);

  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12, lg: 9 }}>
        <Box mb={2}>
          <Card>
            <CardHeader
              title={
                <>
                  <TextLink
                    to={`/vendors/${po.vendor.id}`}
                    disabled={!hasPermission('write:vendors')}
                  >
                    {po.vendor.name}
                  </TextLink>
                  {` ${po.increment_id}`}
                </>
              }
              action={<StatusChip status={startCase(po.type)} />}
            />
            <CardContent>
              <Grid container spacing={3}>
                <VendorField field="account_number" value={po.vendor.account_number} />
                <VendorField field="email" value={po.vendor.email} />
                <VendorField field="phone" value={po.vendor.phone} />
                <VendorField field="url" value={po.vendor.url} />
              </Grid>
            </CardContent>
            <Divider />
            <CardContent>
              <EditFormFields
                fields={[
                  FieldFactory.text('description'),
                  FieldFactory.text('invoice_number'),
                  FieldFactory.date('expected_date'),
                ]}
                defaultLayout={GridLayout}
              />
            </CardContent>
          </Card>
        </Box>
        <Card>
          <CardContent>
            <Grid container spacing={3} alignItems="center">
              <Grid size={{ xs: 12, lg: 4 }}>
                <Typography variant="subtitle2" color="textSecondary">
                  Dates on PO
                </Typography>
                <FormField field={FieldFactory.date('issued_date')} />
                <FormField field={FieldFactory.date('requested_ship_date')} />
                <FormField field={FieldFactory.date('drop_dead_date')} />
              </Grid>
              <Grid size={{ xs: 0, lg: 3 }} />
              <Grid size={{ xs: 12, lg: 5 }}>
                <Typography variant="subtitle2" color="textSecondary">
                  Shipping To
                </Typography>
                <FormField field={new GenericAddressField('address', orderIds, true)} />
              </Grid>
            </Grid>
          </CardContent>
          <PurchaseOrderItems purchaseOrder={po} onUpdated={onUpdated} onReload={onReload} />
        </Card>
      </Grid>
      <Grid size={{ xs: 12, lg: 3 }}>
        <CardWithGutter>
          <CardContent>
            <StatusChip
              status={po.status}
              style={{ display: 'flex' }}
              colors={PURCHASE_ORDER_STATUS_COLORS}
            />
            <Can permission="write:purchase_orders">
              <Box my={1}>
                <PurchaseOrderActions />
              </Box>
            </Can>
          </CardContent>

          <EventableCard resource="purchase-orders" resourceId={po.id} elevation={0} size="small" />
        </CardWithGutter>

        <Can permission="write:purchase_orders">
          <PurchaseOrderDesignLayoutsCard poId={po.id} />
        </Can>

        <Card sx={{ mb: 2 }}>
          <PurchaseOrderStatuses purchaseOrder={po} />
        </Card>

        <PurchaseOrderInboundShipmentsCard poId={po.id} />

        <Can permission="write:transactions">
          <PurchaseOrderMatchingCard poId={po.id} vendorId={po.vendor.id} />
        </Can>

        <Card sx={{ mb: 2 }}>
          <CardHeader title="Advanced" />
          <CardContent>
            <FormField
              field={FieldFactory.belongsTo('business', 'businesses').withHelp(
                'This is the logo that will display on the PO',
              )}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
