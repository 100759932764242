import { useMemo, useState } from 'react';
import {
  Avatar,
  Card,
  CardContent,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid2 as Grid,
  InputLabel,
  LinearProgress,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
} from '@mui/material';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import { z } from 'zod';
import { useDesignLayoutsById } from '@/api/designLayouts';
import {
  useGetKitItemsForPackout,
  useKitProductionOrders,
  usePickKitItem,
  useWatchKitItems,
} from '@/api/packout';
import { FieldFactory } from '@/classes';
import OrderItemProduct from '@/components/Orders/Items/OrderItemProduct';
import OrderLabel from '@/components/Orders/OrderLabel';
import PaginatedTable from '@/components/Shared/PaginatedTable';
import { KitItem, OrderItem } from '@/types';
import { useRecord } from '@/utils/genericResource';
import useDialogs from '@/utils/hooks/useDialogs';
import { buildIncrementId } from '@/utils/notes';

const LABELS = {
  order_id: 'Order ID',
  number: 'Style Number',
  color: 'Color',
  size: 'Size',
  design_layout_id: 'Design Layout',
  upc: 'UPC',
  id: 'Order Item ID',
} as const;

export default function PackoutStartedStatus() {
  const { order } = useRecord('orderPackouts');
  const orderId = order.id;

  useWatchKitItems(orderId);
  const { data = [], isFetching } = useGetKitItemsForPackout(orderId);
  const { mutate, isLoading } = usePickKitItem(orderId);
  const { data: designLayouts = [] } = useDesignLayoutsById(
    uniq(data.map((item) => item.order_item.design_layout_id).filter(Boolean) as number[]),
  );
  const { data: orders = [] } = useKitProductionOrders(orderId);

  const someDrops = data.some((item) => item.drops && item.drops.length > 0);
  const [filters, setFilters] = useState<Partial<Pick<OrderItem, keyof typeof LABELS>>>({});
  const [hidePicked, setHidePicked] = useState(false);
  const { prompt } = useDialogs();

  const filteredData = useMemo(() => {
    let newData = [...data];

    if (hidePicked) {
      newData = newData.filter((item) => item.picked_qty < item.qty);
    }

    return newData.filter((item) => {
      return Object.entries(filters).every(([key, value]) => {
        if (!value) return true;
        return item.order_item[key as keyof OrderItem] == value;
      });
    });
  }, [data, filters, hidePicked]);

  const handleCheckboxChange = (item: KitItem) => async () => {
    if (item.picked_qty < item.qty) {
      let qty = item.qty;
      if (item.qty > 1) {
        qty = await prompt({
          title: 'Enter Quantity',
          description: `This item has a quantity of ${item.qty}. Please enter the quantity you would like to pick:`,
          fields: [
            FieldFactory.number('qty')
              .withSize('medium')
              .withHelp('Defaults to current picked quantity plus one')
              .withInputProps({
                inputProps: { min: 1, max: item.qty, step: 1 },
              }),
          ],
          initialValues: { qty: item.picked_qty + 1 },
          schema: z.object({ qty: z.coerce.number().int().positive().max(item.qty) }),
        }).then((result) => result.qty);
      }

      if (qty) {
        mutate({ id: item.id, picked_qty: qty });
      }
    } else {
      mutate({ id: item.id, picked_qty: 0 });
    }
  };

  const getOptionsForKey = (key: keyof typeof LABELS) => {
    return Array.from(new Set(filteredData.map((item) => item.order_item[key]))).sort();
  };

  return (
    <Stack spacing={2}>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid size={12}>
              {Object.entries(filters).map(([key, value]) => (
                <Chip
                  key={key}
                  label={
                    <>
                      {LABELS[key as keyof typeof LABELS]}: <b>{value}</b> &times;
                    </>
                  }
                  clickable
                  onClick={() => {
                    setFilters((prev) => omit(prev, key));
                  }}
                />
              ))}
            </Grid>
            {Object.keys(LABELS).map((k) => {
              const key = k as keyof typeof LABELS;
              const label = LABELS[key];
              const options = getOptionsForKey(key);

              if (options.length <= 1) {
                return null;
              }

              return (
                <Grid size={{ xs: 6, lg: 4 }} key={key}>
                  <FormControl fullWidth size="small">
                    <InputLabel>{label}</InputLabel>

                    {['id', 'upc'].includes(key) ? (
                      <OutlinedInput
                        label={label}
                        defaultValue={filters[key]}
                        onBlur={(e) => setFilters({ ...filters, [key]: e.target.value })}
                      />
                    ) : (
                      <Select
                        label={label}
                        value={filters[key] || ''}
                        onChange={(e) => setFilters({ ...filters, [key]: e.target.value })}
                        variant="outlined"
                        sx={{
                          '& .MuiSelect-select': { display: 'flex', alignItems: 'center' },
                        }}
                      >
                        {getOptionsForKey(key).map((value) => {
                          const designLayout =
                            key === 'design_layout_id' && designLayouts.find((l) => l.id == value);
                          const order = key === 'order_id' && orders.find((l) => l.id == value);
                          return (
                            // @ts-expect-error this is fine
                            <MenuItem key={value} value={value}>
                              {order ? (
                                <ListItemText
                                  primary={order.increment_id}
                                  secondary={order.description}
                                />
                              ) : designLayout ? (
                                <>
                                  {designLayout.image && (
                                    <ListItemAvatar>
                                      <Avatar src={designLayout.image} />
                                    </ListItemAvatar>
                                  )}
                                  <ListItemText
                                    primary={designLayout.increment_id}
                                    secondary={designLayout.name}
                                  />
                                </>
                              ) : (
                                <ListItemText primary={value} />
                              )}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                  </FormControl>
                </Grid>
              );
            })}
            <Grid size={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hidePicked}
                    onChange={(e) => setHidePicked(e.target.checked)}
                  />
                }
                label="Hide Picked"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card>
        {isFetching && <LinearProgress />}

        <PaginatedTable
          rows={filteredData}
          searchable={['order_item.description']}
          filename={`packout-${order.increment_id.toLowerCase()}`}
          columns={[
            {
              id: 'checkbox',
              header: '',
              enableHiding: false,
              cell: ({ row: { original: item } }) => (
                <Checkbox
                  disabled={isLoading}
                  checked={item.picked_qty === item.qty}
                  indeterminate={item.picked_qty > 0 && item.picked_qty < item.qty}
                  onChange={handleCheckboxChange(item)}
                />
              ),
            },
            {
              accessorKey: 'kit.bin_string',
              header: 'Bin',
              enableHiding: false,
              cell: ({ getValue }) => <Chip label={getValue<string>()} variant="outlined" />,
            },
            { accessorKey: 'qty', header: 'Qty', enableHiding: false },
            someDrops && {
              accessorKey: 'drops',
              header: 'Name/Number',
              enableHiding: false,
              cell: ({ getValue }) => getValue<string[] | null>()?.join(' / '),
            },
            {
              accessorKey: 'order_item.description',
              header: 'Item',
              cell: ({ row }) => <OrderItemProduct item={row.original.order_item} />,
            },
            {
              accessorKey: 'order_item.order_id',
              header: 'Order ID',
              cell: ({ getValue }) => <OrderLabel orderId={getValue<number>()} />,
            },
            {
              accessorKey: 'order_item.design_layout_id',
              header: 'Design Layout',
              cell: ({ getValue }) => buildIncrementId('LAY', getValue<number | null>()),
            },
            {
              accessorKey: 'order_item.number',
              header: 'Style Number',
            },
            {
              accessorKey: 'order_item.color',
              header: 'Color',
            },
            {
              accessorKey: 'order_item.size',
              header: 'Size',
            },
            {
              accessorKey: 'order_item.variant.product.color_code',
              header: 'Color Code',
            },
            {
              accessorKey: 'order_item.upc',
              header: 'UPC',
            },
            {
              accessorKey: 'order_item.id',
              header: 'Order Item ID',
            },
            {
              accessorKey: 'kit.name',
              header: 'Kit Name',
            },
            {
              accessorKey: 'kit.number',
              header: 'Kit Number',
            },
            {
              accessorKey: 'kit.group',
              header: 'Kit Group',
            },
            {
              accessorKey: 'kit.email',
              header: 'Kit Email',
            },
          ]}
          columnVisibility={{
            checkbox: true,
            order_item_number: false,
            order_item_color: false,
            order_item_size: false,
            order_item_variant_product_color_code: false,
            order_item_upc: false,
            order_item_id: false,
            kit_name: false,
            kit_number: false,
            kit_group: false,
            kit_email: false,
          }}
        />
      </Card>
    </Stack>
  );
}
