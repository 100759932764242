import { ChangeEvent, useMemo } from 'react';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';
import ColoredCurrency from '@/components/Shared/ColoredCurrency';
import ArrayBullets from '@/components/Text/ArrayBullets';
import Text from '@/components/Text/Text';
import { getAssociatedLinkForEntry } from '@/resources/ledgerEntries';
import { LedgerEntry } from '@/types';
import curr from '@/utils/curr';

type OnSelect = (e: ChangeEvent<HTMLInputElement>, id: number) => void;

function ReconciliationRow({
  entry,
  isSelected,
  onSelect,
}: {
  entry: LedgerEntry;
  isSelected: boolean;
  onSelect: OnSelect;
}) {
  return useMemo(
    () => (
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox checked={isSelected} onChange={(e) => onSelect(e, entry.id)} />
        </TableCell>
        <TableCell>
          <a
            target="_blank"
            href={getAssociatedLinkForEntry(entry)}
            style={{ textDecoration: 'none' }}
            rel="noreferrer"
          >
            <Text
              primary={entry.description}
              secondary={
                entry.associated_type === 'transaction' && entry.associated ? (
                  <ArrayBullets
                    elements={[entry.associated.vendor?.name, entry.associated.payment_method]}
                  />
                ) : null
              }
            />
          </a>
        </TableCell>
        <TableCell>{moment(entry.transaction_date).format('L')}</TableCell>
        <TableCell>{entry.debit_amount && curr(entry.debit_amount)}</TableCell>
        <TableCell>{entry.credit_amount && curr(entry.credit_amount)}</TableCell>
        <TableCell>
          <ColoredCurrency amount={entry.amount} />
        </TableCell>
      </TableRow>
    ),
    [isSelected, entry.id],
  );
}

export default function ReconciliationTable({
  entries,
  selected,
  onSelect,
  setSelected,
}: {
  entries: LedgerEntry[];
  selected: number[];
  onSelect: OnSelect;
  setSelected: (entries: number[]) => void;
}) {
  const handleCheckAll = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelected(entries.map((entry) => entry.id));
    } else {
      setSelected([]);
    }
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                checked={selected.length === entries.length}
                indeterminate={selected.length !== entries.length && selected.length > 0}
                onChange={handleCheckAll}
              />
            </TableCell>
            <TableCell>Transaction</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Debit Amount</TableCell>
            <TableCell>Credit Amount</TableCell>
            <TableCell>Net Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {entries.map((entry) => (
            <ReconciliationRow
              key={entry.id}
              entry={entry}
              isSelected={selected.includes(entry.id)}
              onSelect={onSelect}
            />
          ))}

          {entries.length === 0 && (
            <TableRow>
              <TableCell colSpan={6}>
                <Typography variant="body2" color="textSecondary">
                  There are no unreconciled transactions during this period.
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
