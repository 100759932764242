import { useState } from 'react';
import { Edit } from '@mui/icons-material';
import {
  Box,
  CardContent,
  CardHeader,
  Grid2 as Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import axios from 'axios';
import filter from 'lodash/filter';
import startCase from 'lodash/startCase';
import moment from 'moment';
import { Link } from 'react-router-dom';
import AddressBlock from '@/components/Addresses/AddressBlock';
import AddressDrawer from '@/components/Addresses/AddressDrawer';
import PickItemAvailablility from '@/components/Inventory/PickItemAvailability';
import EventableCard from '@/components/Notes/EventableCard';
import SkuLabel from '@/components/Products/SkuLabel';
import CardWithGutter from '@/components/Shared/CardWithGutter';
import StatusChip from '@/components/Shared/StatusChip';
import WithLabel from '@/components/Shared/WithLabel';
import ShipmentsCard from '@/components/Shipping/ShipmentsCard';
import TextLink from '@/components/Text/TextLink';
import { WEBSTORE_ORDER_STATUS_COLORS } from '@/constants';
import { AddressPayload } from '@/types';
import { useRecord, useUpdateRecord } from '@/utils/genericResource';

export default function FulfillmentOrderPage() {
  const order = useRecord('fulfillmentOrders');
  const updateRecord = useUpdateRecord('fulfillmentOrders');
  const [editing, setEditing] = useState(false);
  const showAvailability = order.status === 'pending' || order.status === 'backordered';

  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12, md: 9 }}>
        <CardWithGutter>
          <CardHeader
            title={order.number}
            subheader={order.webstore_name}
            action={<StatusChip status={order.status} colors={WEBSTORE_ORDER_STATUS_COLORS} />}
          />
          <CardContent>
            <Grid container spacing={3}>
              <Grid size={{ xs: 12, md: 3 }}>
                <WithLabel label="Date">
                  <Typography>{moment(order.date).format('lll')}</Typography>
                  {order.earliest_ship_date && (
                    <Typography color="textSecondary">
                      Wait Until: {moment(order.earliest_ship_date).format('l')}
                    </Typography>
                  )}
                </WithLabel>
              </Grid>

              <Grid size={{ xs: 12, md: 3 }}>
                <WithLabel label="Avail Customer">
                  {order.customer ? (
                    <TextLink to={`/customers/${order.customer.id}`}>
                      {order.customer.name}
                    </TextLink>
                  ) : (
                    '(none)'
                  )}
                </WithLabel>
              </Grid>

              <Grid size={{ xs: 12, md: 3 }}>
                <WithLabel label="Contact Information">
                  <Typography>{order.name}</Typography>
                  <Typography variant="body2" sx={{ mt: 0.5 }}>
                    {order.email}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 0.5 }}>
                    {order.phone}
                  </Typography>
                </WithLabel>
              </Grid>

              <Grid size={{ xs: 12, md: 3 }}>
                <Box display="flex" alignItems="center">
                  <WithLabel label="Shipping Address">
                    <AddressBlock address={order.address} showMethod />
                  </WithLabel>
                  {order.status !== 'shipped' && order.status !== 'voided' && (
                    <Box ml="auto">
                      <IconButton onClick={() => setEditing(true)}>
                        <Edit />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </CardWithGutter>
        <CardWithGutter>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>SKU</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Qty</TableCell>
                {showAvailability && <TableCell>Available</TableCell>}
                <TableCell>Notes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {order.items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>
                    <SkuLabel variant={item.variant} withoutDescription />
                  </TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>{item.qty}</TableCell>
                  {showAvailability && (
                    <TableCell>
                      <PickItemAvailablility item={item} />
                    </TableCell>
                  )}
                  <TableCell>{item.notes}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardWithGutter>
        <ShipmentsCard shippableId={order.id} shippableType="fulfillment_order" showActions />
      </Grid>
      <Grid size={{ xs: 12, md: 3 }}>
        <CardWithGutter>
          <CardHeader title="Picks" />

          {order.inventory_picks.length === 0 ? (
            <CardContent>
              <Typography variant="body2" color="textSecondary">
                There are no picks for this order.
              </Typography>
            </CardContent>
          ) : (
            <List>
              {order.inventory_picks.map((pick) => (
                <ListItemButton key={pick.id} component={Link} to={`/inventory-picks/${pick.id}`}>
                  <ListItemText primary={pick.number} secondary={startCase(pick.status)} />
                </ListItemButton>
              ))}
            </List>
          )}
        </CardWithGutter>
        <EventableCard resource="fulfillment-orders" resourceId={order.id} size="small" />
      </Grid>
      <AddressDrawer
        open={editing}
        onClose={() => setEditing(false)}
        initialValues={order.address}
        onSubmit={(address: AddressPayload) =>
          axios.put(`/api/fulfillment-orders/${order.id}/address`, { address }).then(({ data }) => {
            updateRecord(data);
            setEditing(false);
          })
        }
        excludeFields={filter(['is_billing', 'is_taxable_address'])}
        showServiceLevel
      />
    </Grid>
  );
}
