import { UseControllerReturn, useFormContext } from 'react-hook-form';
import { Field } from '@/classes';
import { FieldProps } from '@/types';

function ConditionallyRender({ fieldModel, ...props }: FieldProps<ConditionallyRenderField>) {
  const values = useFormContext().watch();
  const shouldRender = fieldModel.renderIf(values);

  if (shouldRender) {
    return fieldModel.subField.renderEditComponent(props);
  }
  return null;
}

export default class ConditionallyRenderField extends Field {
  subField: Field;
  renderIf: (s: any) => boolean;

  constructor(name: string, subField: Field, renderIf: (s: any) => boolean) {
    super(name);
    this.subField = subField;
    this.renderIf = renderIf;
  }

  renderEditComponent(props: UseControllerReturn): React.ReactElement {
    return <ConditionallyRender {...props} fieldModel={this} />;
  }
}
