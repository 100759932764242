import { useMemo } from 'react';
import { Delete } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import sumBy from 'lodash/sumBy';
import { UseControllerReturn, useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { FieldFactory } from '@/classes';
import { NonFormField } from '@/components/Form/FormField';
import PaginatedTable from '@/components/Shared/PaginatedTable';
import {
  genericModelReferenceSchema,
  Transaction,
  TransactionItemPayload,
  TransactionPayload,
} from '@/types';
import curr from '@/utils/curr';
import getValueFromEvent from '@/utils/getValueFromEvent';
import useDialogs from '@/utils/hooks/useDialogs';
import wrap from '@/utils/wrap';

export default function PaymentItems({ field }: UseControllerReturn<TransactionPayload, 'items'>) {
  const items = wrap(field.value) as TransactionItemPayload[];
  const onChange = (value: TransactionItemPayload[]) => field.onChange(value);

  const form = useFormContext<TransactionPayload>();
  const { prompt } = useDialogs();

  const vendor = form.watch('vendor');

  const onAppend = (value: TransactionItemPayload) => {
    onChange([...items, value]);
  };

  const onRemove = (index: number) => {
    onChange(items.filter((_, i) => i !== index));
  };

  const handleRowChange = (index: number, key: keyof TransactionItemPayload, e: any) => {
    const value = getValueFromEvent(e);
    onChange(items.map((item, i) => (i === index ? { ...item, [key]: value } : item)));
  };

  const transactableField = FieldFactory.belongsTo('transactable', 'transactions')
    .withRequestParams({ bucket: 'to_pay', 'filter[vendor_id]': vendor?.id })
    .withoutLabel()
    .with({
      getLink: (value) => `/transactions/${value.id}`,
    });

  const onAdd = () => {
    prompt({
      title: 'Add Bill',
      fields: [transactableField],
      schema: z.object({
        transactable: genericModelReferenceSchema.passthrough(),
      }),
    }).then((row) => {
      const bill = row.transactable as unknown as Transaction;
      onAppend({
        transactable: bill,
        amount: bill.balance,
      });
    });
  };

  const columns = useMemo(
    () =>
      [
        {
          header: 'Bill',
          accessorKey: 'transactable',
          cell: ({ getValue, row }) => (
            <NonFormField
              field={transactableField}
              value={getValue<Transaction>()}
              onChange={(v) => handleRowChange(row.index, 'transactable', v)}
            />
          ),
          footer: () => <Button onClick={onAdd}>Add Bill</Button>,
        },
        {
          header: 'Amount Owed',
          accessorKey: 'transactable.balance',
          cell: ({ getValue }) => curr(getValue<number>()),
          footer: 'Total',
        },
        {
          header: 'Amount to Pay',
          accessorKey: 'amount',
          cell: ({ row, getValue }) => (
            <NonFormField
              field={FieldFactory.curr('amount').withoutLabel()}
              value={getValue<number>()}
              onChange={(v) => handleRowChange(row.index, 'amount', v)}
            />
          ),
          footer: () => <b>{curr(sumBy(items, (r) => Number(r.amount || 0)))}</b>,
        },
        {
          id: 'actions',
          header: '',
          cell: ({ row }) => (
            <IconButton onClick={() => onRemove(row.index)} size="small">
              <Delete fontSize="small" />
            </IconButton>
          ),
        },
      ] as ColumnDef<TransactionItemPayload>[],
    [],
  );

  return <PaginatedTable rows={items} columns={columns} />;
}
