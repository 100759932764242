import { useState } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import uniqBy from 'lodash/uniqBy';
import { useFormContext } from 'react-hook-form';
import ColorChooser from '@/components/Designs/ColorChooser';
import StockColorIcon from '@/components/Designs/StockColorIcon';
import { isScreenPrint } from '@/helpers';
import { Design, DesignColor, InkChange } from '@/types';
import { FieldProps } from '@/types';
import useChangeEffect from '@/utils/hooks/useChangeEffect';
import wrap from '@/utils/wrap';

export default function InkChangeField({ field }: FieldProps) {
  const inkChanges = wrap(field.value || []) as InkChange[];
  const [hasInkChange, setHasInkChange] = useState(inkChanges.length > 0);
  const design = useFormContext<{ design?: Pick<Design, 'id' | 'decoration_type'> }>().watch(
    'design',
  );
  const designId = design?.id;
  const decorationUnit = design?.decoration_type?.unit || null;
  const isScreenPrintCheck = isScreenPrint(decorationUnit);

  const { data: colors } = useQuery(
    ['designColors', designId],
    () =>
      axios
        .get<{ colors: DesignColor[] }>(`/api/designs/${designId}?with=colors`)
        .then(({ data }) => data.colors),
    {
      enabled: isScreenPrintCheck && hasInkChange,
    },
  );

  useChangeEffect(() => {
    field.onChange([]);
  }, [designId, hasInkChange]);

  const handleSelectChange = (colorId: number | string, color: string | null) => {
    field.onChange(
      uniqBy([{ design_color_id: colorId, color }, ...inkChanges], 'design_color_id').filter(
        (ic) => !!ic.color,
      ),
    );
  };

  if (!isScreenPrintCheck) {
    return null;
  }

  if (design) {
    return (
      <div>
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={hasInkChange}
              onChange={(e) => setHasInkChange(e.target.checked)}
            />
          }
          label="Apply ink changes to this design"
        />

        {hasInkChange && !colors && <LinearProgress />}

        {hasInkChange && colors && (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Original Color</TableCell>
                <TableCell>Override Color</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {colors.map((c, i) => {
                const inkChange = inkChanges.find((ic) => ic.design_color_id === c.id);
                return (
                  <TableRow key={c.id}>
                    <TableCell>{i + 1}</TableCell>
                    <TableCell>{c.color}</TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Box flexGrow={1}>
                          <ColorChooser
                            onChange={(value) => handleSelectChange(c.id, value)}
                            value={inkChange?.color || null}
                            decorationUnit={decorationUnit}
                            size="small"
                            freeSolo
                          />
                        </Box>
                        {inkChange?.stock_color && (
                          <StockColorIcon stockColor={inkChange.stock_color} />
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </div>
    );
  }

  return null;
}
