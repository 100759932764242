import { useEffect, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import isNumber from 'lodash/isNumber';
import parseInt from 'lodash/parseInt';
import { UseControllerReturn } from 'react-hook-form';
import { Field, FieldFactory } from '@/classes';
import { FieldProps } from '@/types';
import curr from '@/utils/curr';

function AmountToApply({ fieldModel, ...props }: FieldProps<AmountToApplyField>) {
  const { field } = props;
  const [option, setOption] = useState(0);
  const { orderBalance, amountToApply } = fieldModel;

  useEffect(() => {
    if (option === 0) {
      field.onChange(amountToApply);
    } else if (option === 1) {
      field.onChange(orderBalance);
    }
  }, [option, orderBalance, amountToApply]);

  return (
    <div>
      <RadioGroup
        aria-label="amount"
        name="amount"
        value={option}
        onChange={(e) => setOption(parseInt(e.target.value))}
      >
        <FormControlLabel
          value={0}
          control={<Radio />}
          label={`Payment Amount - ${curr(amountToApply)}`}
        />
        {isNumber(orderBalance) && (
          <FormControlLabel
            value={1}
            control={<Radio />}
            label={`Order Balance - ${curr(orderBalance)}`}
          />
        )}
        <FormControlLabel value={2} control={<Radio />} label="Other Amount" />
      </RadioGroup>
      {option === 2 &&
        FieldFactory.curr('amount').withLabel('Other Amount').renderEditComponent(props)}
    </div>
  );
}

export default class AmountToApplyField extends Field {
  amountToApply: number;
  orderBalance: number;

  constructor(name: string, amountToApply: number, orderBalance: number) {
    super(name);
    this.amountToApply = amountToApply;
    this.orderBalance = orderBalance;
  }

  renderEditComponent(props: UseControllerReturn): React.ReactElement {
    return <AmountToApply {...props} fieldModel={this} />;
  }
}
