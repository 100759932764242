import { useState } from 'react';
import { Button, List, Box, Tabs, Tab } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import flatten from 'lodash/flatten';
import pickBy from 'lodash/pickBy';
import { SelectField } from '@/classes';
import { NonFormField } from '@/components/Form/FormField';
import ClosableDrawer from '@/components/Shared/ClosableDrawer';
import { Address, Business } from '@/types';
import { useHasPermission } from '@/utils/hooks/permissions';
import useGetResource from '@/utils/hooks/useGetResource';
import AddressListItem from './AddressListItem';

export default function GenericAddressChooser({
  open,
  onClose,
  onChoose,
  orderIds,
  showMethod,
}: {
  open: boolean;
  onClose: () => void;
  onChoose: (a: Address) => void;
  orderIds?: number[];
  showMethod?: boolean;
}) {
  const hasPermission = useHasPermission();
  const getResource = useGetResource();

  const [selected, setSelected] = useState<Address | null>(null);
  const [tab, setTab] = useState('us');
  const [method, setMethod] = useState(null);
  const defaultedOrderIds = orderIds || [];

  const { data: orderAddresses = [] } = useQuery(
    ['orderAddresses', defaultedOrderIds.join('|')],
    () =>
      Promise.all(
        defaultedOrderIds.map((id) =>
          axios
            .get<{ data: Address[] }>(`/api/orders/${id}/addresses`)
            .then(({ data }) => data.data),
        ),
      ).then((addresses) => flatten(addresses)),
    {
      enabled: Boolean(open && orderIds),
    },
  );

  const { data: businesses = [] } = useQuery(
    ['businessesWithAddress'],
    () =>
      axios
        .get<{ data: Business[] }>('/api/businesses')
        .then(({ data }) => data.data.filter((b) => !!b.address)),
    {
      enabled: open && hasPermission('read:businesses'),
    },
  );

  const onSubmit = () => {
    if (selected) {
      onChoose({
        ...selected,
        ...pickBy({ method }),
      });
    }
  };

  const onToggle = (a: Address) => setSelected((prev) => (prev?.id === a.id ? null : a));

  return (
    <ClosableDrawer open={open} onClose={onClose} title="Choose PO Shipping Address">
      {showMethod && (
        <Box mb={2}>
          <NonFormField
            field={getResource('addresses').fields.find((f) => f.name === 'method') as SelectField}
            onChange={(e) => setMethod(e.target.value)}
            value={method}
          />
        </Box>
      )}

      <Tabs value={tab} onChange={(e, t) => setTab(t)}>
        <Tab value="us" label="Us" />
        {orderIds && <Tab value="order" label="Order" />}
      </Tabs>

      <div
        style={{
          maxHeight: 580,
          overflowY: 'auto',
          marginBottom: 16,
        }}
      >
        {tab === 'us' && (
          <List>
            {businesses.map((b) => {
              if (!b.address) {
                return null;
              }
              return (
                <AddressListItem
                  key={b.id}
                  address={b.address}
                  onToggle={onToggle}
                  isSelected={selected?.id === b.address.id}
                />
              );
            })}
          </List>
        )}

        {tab === 'order' && (
          <List>
            {orderAddresses.map((a) => (
              <AddressListItem
                key={a.id}
                address={a}
                onToggle={onToggle}
                isSelected={selected?.id === a.id}
              />
            ))}
          </List>
        )}
      </div>

      <Button
        variant="contained"
        onClick={onSubmit}
        disabled={!selected || (showMethod && !method)}
      >
        Choose
      </Button>
    </ClosableDrawer>
  );
}
