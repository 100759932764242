import { useMemo } from 'react';
import { Box, Paper } from '@mui/material';
import DataTable from '@/components/DataTable/DataTable';
import { useRecordId } from '@/utils/genericResource';
import useGetResource from '@/utils/hooks/useGetResource';
import CustomerNumbers from './CustomerNumbers';

export default function CustomerOrders() {
  const customerId = useRecordId();
  const getResource = useGetResource();

  const resource = useMemo(
    () =>
      getResource('orders').cloneWith({
        queryParams: {
          index: {
            'filter[customer_id]': String(customerId),
          },
          single: {},
        },
      }),
    [customerId, getResource],
  );

  return (
    <div>
      <Box mb={2}>
        <CustomerNumbers customerId={customerId} />
      </Box>

      <Paper>
        <DataTable key="customerOrders" resource={resource} getHref={(o) => `/orders/${o.id}`} />
      </Paper>
    </div>
  );
}
