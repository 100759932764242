import { Table, TableHead, TableRow, TableCell, TableBody, Chip, Stack } from '@mui/material';
import { isScreenPrint } from '@/helpers';
import { ComplexityUnit, DesignColor, StockColor } from '@/types';

function getMixLabel(stockColor: StockColor | null): string {
  if (stockColor?.priority === 1) {
    return 'Priority Color';
  }
  if (stockColor?.priority === 2) {
    return 'Secondary Color';
  }
  return 'Custom Mix';
}

export default function DesignColorsTable({
  colors,
  hasUnderbase,
  decorationUnit,
}: {
  colors: DesignColor[];
  hasUnderbase: boolean;
  decorationUnit: ComplexityUnit | null;
}) {
  const isScreenPrintCheck = isScreenPrint(decorationUnit);
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Seq #</TableCell>
          <TableCell>Code</TableCell>
          <TableCell>Colors</TableCell>
          {isScreenPrintCheck && (
            <>
              <TableCell>Squeegee</TableCell>
              <TableCell>Mesh</TableCell>
            </>
          )}
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {isScreenPrintCheck && hasUnderbase && (
          <TableRow>
            <TableCell>0</TableCell>
            <TableCell />
            <TableCell>Underbase</TableCell>
            <TableCell />
            <TableCell />
            <TableCell>
              <Chip size="small" label="FLASH" />
            </TableCell>
          </TableRow>
        )}
        {colors.map((color, index) => (
          <TableRow key={color.id}>
            <TableCell>{index + 1}</TableCell>
            <TableCell>{color.code}</TableCell>
            <TableCell>{color.color}</TableCell>
            {isScreenPrintCheck && (
              <>
                <TableCell>{color.squeegee}</TableCell>
                <TableCell>{color.mesh}</TableCell>
              </>
            )}
            <TableCell>
              <Stack direction="row" spacing={1}>
                {isScreenPrintCheck && color.flash && <Chip size="small" label="FLASH" />}
                {isScreenPrintCheck && <Chip size="small" label={getMixLabel(color.stock_color)} />}
              </Stack>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
