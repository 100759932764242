import {
  CardLayout,
  FieldFactory,
  GroupLayout,
  Resource,
  SelectField,
  SidebarLayout,
  StackLayout,
  Tab,
  TabLayout,
} from '@/classes';
import CustomerActions from '@/components/Customers/CustomerActions';
import CustomerAddresses from '@/components/Customers/CustomerAddresses';
import CustomerCertificates from '@/components/Customers/CustomerCertificates';
import CustomerCommissions from '@/components/Customers/CustomerCommissions';
import CustomerOrders from '@/components/Customers/CustomerOrders';
import CustomerPaymentMethods from '@/components/Customers/CustomerPaymentMethods';
import CustomerUsers from '@/components/Customers/CustomerUsers';
import SalesRepField from '@/components/Customers/SalesRepField';
import ColorChooserField from '@/components/Designs/ColorChooserField';
import UserLabel from '@/components/Users/UserLabel';
import { FULFILLMENT_FEES, PORTAL_SHOPPING_MODES } from '@/constants';
import { getEventableConfigForResource } from '@/helpers';
import {
  AppResponse,
  CustomerDetail,
  customerPayloadSchema,
  customerProjectFieldPayloadSchema,
} from '@/types';
import { getBulkDisableAction } from '@/utils/disabled';
import { getBusinessNameById } from '@/utils/getBusinessName';

export default function customers(appConfig: AppResponse): Resource<CustomerDetail> {
  const INFO_TAB = new Tab('info', [
    new CardLayout('', [
      FieldFactory.text('name').withSize('medium').withColumnSpan(12).sortable(),
      !appConfig.business &&
        FieldFactory.belongsTo('business', 'businesses')
          .filterable('filter[business_id]')
          .sortable('business.name'),
      FieldFactory.belongsTo('customer_segment', 'customerSegments')
        .filterable()
        .sortable('customer_segment.path'),
      FieldFactory.belongsTo('terms', 'terms'),
      FieldFactory.email('email'),
      FieldFactory.phone('phone'),
      FieldFactory.text('website'),
      new GroupLayout('', [
        FieldFactory.boolean('mail_invoices', 'Should Mail Invoices via USPS').filterable(),
      ]).withColumnSpan(12),
    ]),
    new CardLayout('Contacts')
      .withSubheader('Please setup at least one AP contact')
      .withoutPadding()
      .withoutDefaultLayout()
      .withFields([
        FieldFactory.hasManyCRUD(
          'contacts',
          'customerContacts',
          (c) => `/api/customers/${c.id}/contacts`,
        ),
      ])
      .setCreatable(false),
    new CardLayout('Sales Reps', [
      FieldFactory.custom('sales_rep', SalesRepField)
        .withColumnSpan(6)
        .renderCellUsing((u) => <UserLabel user={u} />)
        .filterable(FieldFactory.belongsTo('rep', 'users').withFilterKey('filter[rep_id]')),
      FieldFactory.custom('commissions', CustomerCommissions).withColumnSpan(12),
    ]),
    getEventableConfigForResource('customers'),
  ]);

  const PORTAL_TAB = new Tab('portal', [
    new CardLayout('settings', [
      FieldFactory.image('logo'),
      FieldFactory.color('color'),
      FieldFactory.color('drawer_color'),
      FieldFactory.optIn(
        'portal_shopping_mode',
        new SelectField('portal_shopping_mode', PORTAL_SHOPPING_MODES),
      ).with({
        optInLabel: 'Enable Shopping',
      }),
    ]),
    new CardLayout('pricing')
      .withSubheader('How to price self-service shopping')
      .withFields([
        FieldFactory.percent('blank_discount')
          .withLabel('Blank Product Discount')
          .withColumnSpan(3),
        FieldFactory.percent('target_margin')
          .withHelp('Applicable for the "storal" shopping mode')
          .withColumnSpan(3),
        FieldFactory.percent('inbound_freight_percent')
          .withHelp('As a percentage of the blank product cost')
          .withColumnSpan(3),
        FieldFactory.percent('outbound_freight_percent')
          .withHelp('As a percentage of the blank product cost')
          .withColumnSpan(3),
      ]),
    new CardLayout('products')
      .withSubheader('Only products meeting these criteria will be accessible.')
      .withFields([
        FieldFactory.list('blank_vendors', [
          FieldFactory.belongsTo('', 'vendors').withLabel('Vendor').withColumnSpan(10),
        ]).with({
          addNewValue: undefined,
          addNewLabel: 'Add Vendor',
        }),
        FieldFactory.list('blank_products', [
          FieldFactory.belongsTo('', 'products').withLabel('Product').withColumnSpan(10),
        ]).with({
          addNewValue: undefined,
          addNewLabel: 'Add Product',
        }),
      ]),
    new CardLayout('users', [FieldFactory.custom('users', CustomerUsers)]).withoutDefaultLayout(),
    new CardLayout('fulfillment_pricing')
      .withSubheader(
        'How to price fulfillment fees in monthly orders. Any charges not specified here will charged at the default rate.',
      )
      .withFields([
        FieldFactory.list('fulfillment_prices', [
          FieldFactory.select('number', FULFILLMENT_FEES),
          FieldFactory.curr('price'),
        ]).withColumnSpan(8),
      ]),
    new CardLayout('project_fields')
      .withSubheader('Custom fields collected during project creation.')
      .withoutPadding()
      .withoutDefaultLayout()
      .withFields([
        FieldFactory.table(
          'project_fields',
          [
            FieldFactory.text('label'),
            FieldFactory.select('type', ['text', 'email', 'number', 'select']),
            FieldFactory.text('help'),
            FieldFactory.list('options', [FieldFactory.text('')]).with({
              addNewValue: '',
            }),
            FieldFactory.boolean('required', 'Required'),
          ],
          customerProjectFieldPayloadSchema,
        ),
      ]),
    new CardLayout('domains')
      .withoutPadding()
      .withoutDefaultLayout()
      .withSubheader(
        'Verified email domains to ensure users with specific email addresses join the correct customer.',
      )
      .withFields([
        FieldFactory.hasManyCRUD(
          'domains',
          'customerDomains',
          (c) => `/api/customers/${c.id}/domains`,
        ),
      ])
      .setCreatable(false),
  ]);
  return new Resource<CustomerDetail>('Customers')
    .getSubtitleUsing((c) => getBusinessNameById(appConfig.businesses, c.business_id))
    .withBulkActions([getBulkDisableAction('Customers', 'customers')])
    .getSingleActionsUsing(CustomerActions)
    .withSchema(customerPayloadSchema)
    .withInitialColumns([
      'increment_id',
      'business',
      'name',
      'customer_segment',
      'sales_rep',
      'email',
    ])
    .withDefaultSort('-id')
    .setCanExport()
    .withColumns([
      FieldFactory.text('increment_id').withLabel('#').sortable(),
      FieldFactory.boolean('is_disabled')
        .withLabel('On Hold')
        .sortable('disabled_at')
        .filterable('is_disabled'),
    ])
    .addFieldsAndColumns([
      new TabLayout('tab', [
        INFO_TAB,
        new Tab('orders', [FieldFactory.custom('orders', CustomerOrders)]).setCreatable(false),
        new Tab('addresses', [FieldFactory.custom('addresses', CustomerAddresses)]).setCreatable(
          false,
        ),
        new Tab('payment_methods', [
          new SidebarLayout(
            'payment_layout',
            [FieldFactory.custom('payment_methods', CustomerPaymentMethods)],
            [
              new CardLayout('Payment Settings', [
                FieldFactory.curr('credit_card_limit')
                  .withLabel('Monthly Credit Card Limit Override')
                  .withHelp('Defaults to $20,000'),
              ]).withoutDefaultLayout(),
            ],
          ),
        ])
          .setCreatable(false)
          .withPermission('write:payments'),
        PORTAL_TAB.setCreatable(false),
        new Tab('sales_tax')
          .withFields([FieldFactory.custom('certificates', CustomerCertificates)])
          .withPermission('write:customers')
          .setCreatable(false),
        new Tab('brand', [
          new CardLayout('Colors', [
            new StackLayout('brand_colors', [
              FieldFactory.list('brand_colors', [
                FieldFactory.text('rgb')
                  .withLabel('RGB')
                  .withPlaceholder('eg. 255, 255, 0')
                  .withColumnSpan(2),
                FieldFactory.text('cmyk')
                  .withLabel('CMYK')
                  .withPlaceholder('eg. 0%, 0%, 100%, 0%')
                  .withColumnSpan(2),
                FieldFactory.color('hex').withColumnSpan(2),

                new ColorChooserField('ink_color')
                  .with({ decorationUnit: 'colors', freeSolo: true })
                  .withColumnSpan(2),
                new ColorChooserField('thread_color')
                  .with({ decorationUnit: 'stitches', freeSolo: true })
                  .withColumnSpan(2),
              ]),
              new GroupLayout('brand_settings')
                .withoutLabel()
                .withFields([
                  FieldFactory.boolean('exempt_from_pms_charges', 'Exempt from PMS Charges'),
                ]),
            ]),
          ]),
          new CardLayout('Files', [
            FieldFactory.list('brand_files', [
              FieldFactory.select('type', {
                logo: 'Logo',
                font: 'Font',
              }).withColumnSpan(2),
              FieldFactory.text('name').withLabel('File Name').withColumnSpan(3),
              FieldFactory.file('file').renderCellUsing((f) => (
                <a href={f} target="_blank" rel="noreferrer">
                  <img
                    alt=""
                    src={f}
                    style={{
                      width: 400,
                      height: 100,
                      objectFit: 'contain',
                    }}
                  />
                </a>
              )),
            ]),
          ]).withoutDefaultLayout(),
          new CardLayout('Franchise', [
            FieldFactory.belongsTo('franchise_parent', 'customers').filterable(
              'filter[franchise_parent_id]',
            ),
          ]),
        ]),
      ]),
    ]);
}
