import { CSSProperties, useCallback, useMemo, useState } from 'react';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import AddBoxIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import { z } from 'zod';
import { FieldFactory } from '@/classes';
import AddressBlock from '@/components/Addresses/AddressBlock';
import AddressDrawer from '@/components/Addresses/AddressDrawer';
import Can from '@/components/Permissions/Can';
import DebouncedTextField from '@/components/Shared/DebouncedTextField';
import { Address, AddressPayload, Order, Quote } from '@/types';
import { useRecord } from '@/utils/genericResource';
import getApiUrl from '@/utils/getApiUrl';
import { useOrderableApiUrl } from '@/utils/hooks/useApiSegment';
import useDialogs from '@/utils/hooks/useDialogs';
import useMutateQueryData from '@/utils/hooks/useMutateQueryData';
import numString from '@/utils/numString';
import searchCollection from '@/utils/searchCollection';
import AddressChooser from './AddressChooser';

export default function OrderShippingAddresses({
  onSelect,
  isKit,
}: {
  onSelect?: (a: Address) => void;
  isKit?: boolean;
}) {
  const customerId = useRecord<Order | Quote>().customer.id;
  const [editing, setEditing] = useState<{ values: Partial<Address>; mode: 'edit' | 'create' }>();
  const [query, setQuery] = useState('');
  const { confirm, prompt } = useDialogs();

  const baseUrl = useOrderableApiUrl('addresses');
  const setAddresses = useMutateQueryData<Address[]>([baseUrl]);
  const queryClient = useQueryClient();
  const queryKey = [baseUrl];
  const {
    data: addresses = [],
    isLoading,
    refetch,
  } = useQuery(queryKey, () =>
    axios.get<{ data: Address[] }>(baseUrl).then(({ data }) => data.data),
  );

  const filteredAddresses = useMemo(() => {
    if (addresses.length <= 7) {
      return addresses;
    }

    return searchCollection(addresses, query, [
      'name',
      'company',
      'address_1',
      'address_2',
      'city',
      'zip',
      'kits.*.bin_string',
      'kits.*.number',
      'kits.*.name',
    ]);
  }, [addresses, query]);

  const onEdit = (a: Address) => {
    setEditing({
      values: a,
      mode: 'edit',
    });
  };

  const onCreate = () => {
    setEditing({
      values: {},
      mode: 'create',
    });
  };

  const onImport = () => {
    prompt({
      title: 'Import Addresses',
      description: (
        <div>
          Using{' '}
          <a target="_blank" href={getApiUrl(`${baseUrl}/export`)} rel="noreferrer">
            this template
          </a>
          , upload your excel file below.
        </div>
      ),
      fields: [
        FieldFactory.file('file').with({
          accept:
            '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
        }),
      ],
      schema: z.object({
        file: z.string(),
      }),
      onSubmit: (v) => axios.post(`${baseUrl}/import`, v),
    }).then(() => {
      refetch();
      queryClient.invalidateQueries(['kitsForAddress']);
      queryClient.invalidateQueries(['orderKitItems']);
    });
  };

  const onDelete = (address: Address) => {
    confirm({
      title: 'Delete Address',
      description:
        'Are you sure you want to delete this address? This will delete the address and any kits associated with it.',
      color: 'error',
    }).then(() => {
      axios.delete(`${baseUrl}/${address.id}`).then(() => {
        setAddresses((prev) => prev.filter((a) => a.id !== address.id));
      });
    });
  };

  const onSubmit = (values: AddressPayload) => {
    if (!editing) {
      return Promise.resolve();
    }

    const promise =
      editing.mode === 'create'
        ? axios.post(baseUrl, values)
        : axios.put(`${baseUrl}/${editing.values.id}`, values);

    return promise.then(() => {
      refetch();
      setEditing(undefined);
    });
  };

  const actions = (
    <Can permission="write:orders">
      {isKit && (
        <>
          <Tooltip title="Export Addresses/Kits">
            <IconButton
              component="a"
              href={getApiUrl(`${baseUrl}/export`)}
              target="_blank"
              color="primary"
              size="large"
            >
              <ArrowDownward />
            </IconButton>
          </Tooltip>
          <Tooltip title="Import Addresses/Kits">
            <IconButton onClick={onImport} color="primary" size="large">
              <ArrowUpward />
            </IconButton>
          </Tooltip>
        </>
      )}
      <AddressChooser customerId={customerId} onSuccess={() => refetch()} />
      <Tooltip title="Add New Address">
        <IconButton onClick={onCreate} color="primary" size="large">
          <AddBoxIcon />
        </IconButton>
      </Tooltip>
    </Can>
  );

  const AddressListItem = useCallback(
    ({ index, style = {} }: { index: number; style?: CSSProperties }) => {
      const a = filteredAddresses[index];
      const onClick = onSelect ? () => onSelect(a) : undefined;

      const inner = (
        <>
          <ListItemText
            style={{
              flexGrow: 0,
              marginRight: 16,
            }}
          >
            <AddressBlock address={a} showMethod />
          </ListItemText>

          <ListItemSecondaryAction onClick={(e) => e.stopPropagation()}>
            <IconButton onClick={() => onEdit(a)} size="small" style={{ marginRight: 4 }}>
              <EditIcon fontSize="small" />
            </IconButton>
            <IconButton onClick={() => onDelete(a)} size="small">
              <DeleteIcon fontSize="small" />
            </IconButton>
          </ListItemSecondaryAction>
        </>
      );
      return (
        <div style={style}>
          {onClick ? (
            <ListItemButton onClick={onClick}>{inner}</ListItemButton>
          ) : (
            <ListItem component="div">{inner}</ListItem>
          )}
        </div>
      );
    },
    [filteredAddresses],
  );

  const getAddressHeight = (index: number) => {
    const a = filteredAddresses[index];
    const numExtraLines = [a.company, a.address_2, a.country, a.phone].filter(
      (el) => el && el !== 'US',
    ).length;

    return 112 + numExtraLines * 21;
  };

  const renderInner = () => {
    if (isLoading) {
      return <CircularProgress />;
    }

    if (addresses.length <= 7) {
      return (
        <>
          {addresses.map((a, i) => (
            <AddressListItem key={a.id} index={i} />
          ))}
        </>
      );
    }

    return (
      <>
        <Box px={2} pb={1}>
          <DebouncedTextField
            type="search"
            fullWidth
            size="small"
            variant="outlined"
            placeholder="Search for address"
            initialValue={query}
            onChange={setQuery}
          />
        </Box>
        <div style={{ height: 'calc(100vh - 332px)', minHeight: 400 }}>
          <AutoSizer>
            {({ height, width }) => (
              <VariableSizeList
                height={Number(height)}
                width={Number(width)}
                itemSize={getAddressHeight}
                itemCount={filteredAddresses.length}
                itemKey={(index) => filteredAddresses[index].id}
              >
                {AddressListItem}
              </VariableSizeList>
            )}
          </AutoSizer>
        </div>
      </>
    );
  };

  return (
    <div>
      <Card>
        <CardHeader
          title="Customer Addresses"
          subheader={isLoading ? '' : numString(addresses.length, 'Addresses')}
          action={actions}
        />
        {renderInner()}
      </Card>

      <AddressDrawer
        open={!!editing}
        onClose={() => setEditing(undefined)}
        onSubmit={onSubmit}
        initialValues={editing ? editing.values : {}}
        excludeFields={['is_billing']}
      />
    </div>
  );
}
