import { OpenInNew } from '@mui/icons-material';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  LinearProgress,
  List,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import truncate from 'lodash/truncate';
import OrderListItem from '@/components/Orders/OrderListItem';
import PrintMenu from '@/components/Print/PrintMenu';
import StatusChip from '@/components/Shared/StatusChip';
import { PROJECT_STATUS_COLORS } from '@/constants';
import { AvailProject } from '@/types';

export default function ProjectCard({ projectId }: { projectId: number }) {
  const { data: project, isLoading } = useQuery(['project', projectId], () =>
    axios.get<AvailProject>(`/api/projects/${projectId}`).then(({ data }) => data),
  );

  return (
    <Card sx={{ mb: 2 }}>
      <CardHeader
        title="Project"
        action={
          <>
            {project && <StatusChip status={project.status} colors={PROJECT_STATUS_COLORS} />}
            <PrintMenu model="project" id={projectId} />
          </>
        }
      />
      {isLoading && <LinearProgress />}
      {project && (
        <>
          <CardContent sx={{ pt: 0 }}>
            <Typography variant="subtitle1" gutterBottom>
              {project.name}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {truncate(project.description, { length: 280 })}
            </Typography>
          </CardContent>
          {project.orders.length > 0 && (
            <List>
              {project.orders.map((order) => (
                <OrderListItem key={order.id} order={order} />
              ))}
            </List>
          )}
          <CardActions>
            <Button component="a" target="_blank" href={project.portal_url}>
              View in the Portal <OpenInNew sx={{ ml: 1 }} />
            </Button>
          </CardActions>
        </>
      )}
    </Card>
  );
}
