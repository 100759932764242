import type { FieldValues } from 'react-hook-form';
import { AlertOptions, ConfirmOptions, PromptOptions, useAppContext } from '@/contexts/AppContext';

export type UseDialogsResult = ReturnType<typeof useDialogs>;

export default function useDialogs() {
  const { setPromptDialog, setConfirmDialog, setAlertDialog, setPromptDrawer } = useAppContext();

  const prompt = <T extends FieldValues, Res = T>(options: PromptOptions<T, Res>) =>
    new Promise<Res>((resolve, reject) => {
      setPromptDialog({
        options,
        promise: {
          resolve,
          reject,
        },
        open: true,
      });
    });

  const drawerPrompt = <T extends FieldValues, Res = T>(options: PromptOptions<T, Res>) =>
    new Promise<Res>((resolve, reject) => {
      setPromptDrawer({
        options,
        promise: {
          resolve,
          reject,
        },
        open: true,
      });
    });

  const alert = (options: AlertOptions) =>
    new Promise<void>((resolve, reject) => {
      setAlertDialog({
        options,
        promise: {
          resolve,
          reject,
        },
        open: true,
      });
    });

  const confirm = (options: ConfirmOptions) =>
    new Promise<void>((resolve, reject) => {
      setConfirmDialog({
        options,
        promise: {
          resolve,
          reject,
        },
        open: true,
      });
    });

  return {
    prompt,
    confirm,
    alert,
    drawerPrompt,
  };
}
