import { Alert, Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { AddressWithKits } from '@/types';
import { useRecord } from '@/utils/genericResource';
import { isInventory } from '@/utils/shipping';
import NoteViewer from '../Notes/NoteViewer';
import OrderShippingInventory from './OrderShippingInventory';
import OrderShippingKit from './OrderShippingKit';
import OrderShippingSimple from './OrderShippingSimple';

export default function ShippingForOrder() {
  const order = useRecord('ordersToShip');

  const { data: addresses } = useQuery(['orderAddresses', order.id], () =>
    axios
      .get<{ data: AddressWithKits[] }>(`/api/orders/${order.id}/addresses`)
      .then(({ data }) => data.data),
  );

  if (!addresses) {
    return null;
  }

  if (addresses.length === 0) {
    return <Alert severity="error">There are no addresses for this order.</Alert>;
  }

  return (
    <Box mb={2}>
      <NoteViewer resource="orders" resourceId={order.id} tag="shipping" />

      {order.shipping_mode === 'bulk' && (
        <OrderShippingSimple
          orderId={order.id}
          customerId={order.customer.id}
          address={addresses[0]}
        />
      )}

      {order.shipping_mode === 'kits' && (
        <OrderShippingKit orderId={order.id} customerId={order.customer.id} addresses={addresses} />
      )}

      {isInventory(order.shipping_mode) && <OrderShippingInventory order={order} />}
    </Box>
  );
}
