import { SyntheticEvent, useState } from 'react';
import { Add } from '@mui/icons-material';
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  LinearProgress,
  List,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import AddressDrawer from '@/components/Addresses/AddressDrawer';
import PackoutListItem from '@/components/Packouts/PackoutListItem';
import { AddressPayload, Packout } from '@/types';
import { useOnReloadRecord } from '@/utils/genericResource';

export default function OrderPackouts({
  orderId,
  invoicedAt,
}: {
  orderId: number;
  invoicedAt: string | null;
}) {
  const reloadOrder = useOnReloadRecord();
  const [deleting, setDeleting] = useState<Packout>();

  const {
    data: packouts,
    refetch,
    isFetching,
  } = useQuery(['order', orderId, 'packouts'], () =>
    axios
      .get<{ data: Packout[] }>(`/api/order-packouts?filter[order_id]=${orderId}`)
      .then(({ data }) => data.data),
  );

  const onDeletePackout = (values: AddressPayload) => {
    return axios
      .post(`/api/order-packouts/${deleting!.id}/delete`, { address: values })
      .then(() => {
        setDeleting(undefined);
        refetch();
        reloadOrder();
      });
  };

  const onCreatePackout = (e: SyntheticEvent) => {
    e.stopPropagation();
    axios.post('/api/order-packouts', { order_id: orderId }).then(() => {
      refetch();
    });
  };

  const createButton = (
    <IconButton onClick={onCreatePackout} size="large">
      <Add />
    </IconButton>
  );

  return (
    <Card>
      <CardHeader
        title="Packout"
        titleTypographyProps={{ variant: 'h6' }}
        action={packouts && packouts.length === 0 ? createButton : null}
        style={{ paddingBottom: 2 }}
      />
      {isFetching && <LinearProgress />}

      {packouts && packouts.length === 0 ? (
        <CardContent>
          <Typography color="textSecondary">There are no packouts for this order.</Typography>
        </CardContent>
      ) : null}

      {packouts && packouts.length > 0 ? (
        <List>
          {packouts.map((packout) => (
            <PackoutListItem
              key={packout.id}
              packout={packout}
              invoicedAt={invoicedAt}
              onDelete={() => setDeleting(packout)}
            />
          ))}
        </List>
      ) : null}

      <AddressDrawer
        open={!!deleting}
        onClose={() => setDeleting(undefined)}
        onSubmit={onDeletePackout}
        initialValues={{}}
        title="Delete Packout"
        description={
          <Alert severity="warning" sx={{ mb: 2, mt: -2 }}>
            This will make all the child orders shippable and charge a fee so the LOB can still use
            the packout paperwork. This action is not reversible. If you want to proceed, please
            enter an address to ship the production orders to (instead of the Packout room) below.
          </Alert>
        }
        excludeFields={['is_taxable_address', 'is_billing']}
      />
    </Card>
  );
}
