import { Chip, LinearProgress } from '@mui/material';
import { UseQueryResult } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import moment from 'moment/moment';
import PaginatedTable from '@/components/Shared/PaginatedTable';
import StatusChip from '@/components/Shared/StatusChip';
import { PICK_STATUS_COLORS } from '@/constants';
import { Kit } from '@/types';

function toTimestamp(timestamp: string | null): string {
  return timestamp ? moment(timestamp).format('lll') : '-';
}

export default function KitsTable({
  kitsQuery,
  columns,
  columnVisibility,
  filename,
}: {
  kitsQuery: UseQueryResult<Kit[]>;
  columns: ColumnDef<Kit>[];
  columnVisibility: Record<string, boolean>;
  filename: string;
}) {
  const { data: kits = [], isFetching, refetch } = kitsQuery;

  return (
    <>
      {isFetching && <LinearProgress />}

      <PaginatedTable
        searchable={['name', 'number']}
        rows={kits}
        initialSortBy={{ id: 'bin_string', desc: false }}
        onReload={refetch}
        columnVisibility={columnVisibility}
        columns={[
          {
            header: 'Bin',
            accessorKey: 'bin_string',
            enableHiding: false,
            cell: ({ getValue }) => <Chip variant="outlined" label={getValue<string>()} />,
          },
          { header: 'Name', accessorKey: 'name' },
          { header: 'Number', accessorKey: 'number' },
          { header: 'Group', accessorKey: 'group' },
          { header: 'Email', accessorKey: 'email' },
          {
            header: 'Status',
            accessorKey: 'pick_status',
            cell: ({ getValue }) => (
              <StatusChip
                status={getValue<Kit['pick_status']>()}
                colors={PICK_STATUS_COLORS}
                size="small"
              />
            ),
          },
          {
            accessorKey: 'pick_started_at',
            header: 'Binned At',
            cell: ({ getValue }) => toTimestamp(getValue<string | null>()),
          },
          {
            accessorKey: 'pick_completed_at',
            header: 'Packed At',
            cell: ({ getValue }) => toTimestamp(getValue<string | null>()),
          },
          ...columns,
        ]}
        filename={filename}
      />
    </>
  );
}
