import { Card, CardHeader, Grid2 as Grid, LinearProgress, styled } from '@mui/material';
import axios from 'axios';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import { z } from 'zod';
import { useGetProductionEventsForOrder } from '@/api/production';
import { FieldFactory } from '@/classes';
import OrderDesignCard from '@/components/Designs/OrderDesignCard';
import OrderSummaryField from '@/components/Orders/OrderSummaryField';
import OrderScraps from '@/components/Production/OrderScraps';
import StatusChip from '@/components/Shared/StatusChip';
import { DEPARTMENT_STATUS_COLORS } from '@/constants';
import { Order, orderDepartmentStatusSchema, ProductionEvent } from '@/types';
import { useRecord, useUpdateRecord } from '@/utils/genericResource';
import useDialogs from '@/utils/hooks/useDialogs';
import NoteManagerCard from '../Notes/NoteManagerCard';
import NoteViewer from '../Notes/NoteViewer';
import ItemDesignTable from './ItemDesignTable';
import ProductionEventList from './ProductionEventList';

const StagedButton = styled('button')({
  background: 'none',
  border: 'none',
  '&:focus': {
    outline: 'none',
  },
  '& > div': {
    cursor: 'pointer',
  },
});

const DEFAULT_EVENTS: ProductionEvent[] = [];

export default function ProductionOrder() {
  const { id: orderId, items, staged_status: stagedStatus, scraps } = useRecord('ordersToProduce');
  const onUpdateRecord = useUpdateRecord('ordersToProduce');
  const { prompt } = useDialogs();

  const { data: events = DEFAULT_EVENTS, isLoading } = useGetProductionEventsForOrder(orderId);

  const [eventId, setEventId] = useQueryParam('event_id', withDefault(NumberParam, events[0]?.id));
  const event = events.find((e) => e.id === eventId);

  const orderDesign = event ? event.order_design : null;

  const onEventSelect = (ev: ProductionEvent) => setEventId(ev.id);

  const onToggledStaged = () => {
    prompt({
      title: 'Update Staging Status',
      fields: [
        FieldFactory.radio('staged_status', {
          no: 'No',
          partial: 'Started',
          yes: 'Yes',
        }).withoutLabel(),
      ],
      schema: z.object({
        staged_status: orderDepartmentStatusSchema,
      }),
      initialValues: { staged_status: stagedStatus },
      onSubmit: (v) => axios.post<Order>(`/api/orders/${orderId}/stage`, v),
    }).then(({ data }) => {
      onUpdateRecord(data);
    });
  };

  const stagedActions = (
    <>
      <span>Staged: </span>
      <StagedButton type="button" onClick={onToggledStaged}>
        <StatusChip status={stagedStatus} colors={DEPARTMENT_STATUS_COLORS} />
      </StagedButton>
    </>
  );

  return (
    <div>
      <NoteViewer resource="orders" resourceId={orderId} tag="production" />
      <Grid container spacing={3}>
        <Grid size={{ xs: 12, md: 9 }}>
          {isLoading ? (
            <LinearProgress />
          ) : (
            <ProductionEventList
              orderId={orderId}
              events={events}
              selected={event}
              onSelect={onEventSelect}
            />
          )}
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <OrderSummaryField />
        </Grid>

        {orderDesign && (
          <>
            <Grid size={{ xs: 12, md: 5 }}>
              <OrderDesignCard orderDesign={orderDesign} sx={{ mb: 2 }} />

              <NoteManagerCard
                resource="designs"
                resourceId={orderDesign.design.id}
                title="Design Notes"
              />
            </Grid>
            <Grid size={{ xs: 12, md: 7 }}>
              <Card>
                <CardHeader title="Items" action={stagedActions} />
                <ItemDesignTable items={items} orderDesign={orderDesign} />
              </Card>
              <OrderScraps scraps={scraps} />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}
