import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Box, Button, Grid2 as Grid, TextField, Typography } from '@mui/material';
import get from 'lodash/get';
import pick from 'lodash/pick';
import startCase from 'lodash/startCase';
import toString from 'lodash/toString';
import io, { Socket } from 'socket.io-client';
import { ButtonAction } from '@/classes';
import DrawerFormSection from '@/components/Form/DrawerFormSection';
import SubMenu from '@/components/Shared/SubMenu';
import { Parcel } from '@/types';

export default function ParcelFields({
  parcels,
  onChange,
  errors = {},
}: {
  parcels: Parcel[];
  onChange: (p: Parcel[]) => void;
  errors?: Record<string, any>;
}) {
  const socket = useRef<Socket>();
  const [lastFocused, setLastFocused] = useState<number>();
  const indexToUpdate = lastFocused === undefined ? parcels.length - 1 : lastFocused;
  const stateRef = useRef<{
    parcels: typeof parcels;
    indexToUpdate: number;
    onChange: typeof onChange;
  }>();

  stateRef.current = {
    parcels,
    indexToUpdate,
    onChange,
  };

  const receivedData = (data: Parcel) => {
    if (!stateRef.current) {
      return;
    }

    // eslint-disable-next-line no-shadow
    const { parcels, indexToUpdate, onChange } = stateRef.current;
    const newData = pick(data, ['length', 'width', 'height', 'weight']);
    onChange(
      parcels.map((parcel, index) => {
        if (index === indexToUpdate) {
          return { ...parcel, ...newData };
        }
        return parcel;
      }),
    );
  };

  useEffect(() => {
    socket.current = io('http://127.0.0.1:2323');
    socket.current.on('measurements', receivedData);
    return () => {
      socket.current?.close();
      socket.current = undefined;
    };
  }, []);

  const onMeasure = () => {
    socket.current?.emit('measure');
  };

  const handleParcelChange =
    (index: number, field: keyof Parcel) => (e: ChangeEvent<HTMLInputElement>) => {
      onChange(
        parcels.map((parcel, i) => {
          if (i === index) {
            return { ...parcel, [field]: e.target.value };
          }
          return parcel;
        }),
      );
    };

  const handleFocus = (index: number) => () => {
    setLastFocused(index);
  };

  const getParcelField = (index: number, name: keyof Parcel) => (
    <TextField
      variant="outlined"
      size="small"
      label={startCase(name)}
      value={toString(parcels[index][name])}
      sx={{ m: 1 }}
      onChange={handleParcelChange(index, name)}
      onFocus={handleFocus(index)}
      error={!!get(errors, `parcels[${index}].${name}`)}
      helperText={get(errors, `parcels[${index}].${name}`)}
      required
      type="number"
      InputProps={{
        endAdornment: name === 'weight' ? 'lbs' : 'in',
      }}
    />
  );

  return (
    <DrawerFormSection title="Parcels">
      {parcels.map((parcel, index) => (
        <div key={index}>
          <Typography
            variant="subtitle2"
            color={indexToUpdate === index ? 'primary' : 'text'}
            gutterBottom
          >
            Parcel {index + 1}
          </Typography>
          <Grid container sx={{ my: 1 }}>
            <Grid size={6}>{getParcelField(index, 'length')}</Grid>
            <Grid size={6}>{getParcelField(index, 'width')}</Grid>
            <Grid size={6}>{getParcelField(index, 'height')}</Grid>
            <Grid size={6}>{getParcelField(index, 'weight')}</Grid>
          </Grid>
        </div>
      ))}
      <Box display="flex" justifyContent="flex-end">
        <Button
          onClick={() =>
            onChange([
              ...parcels,
              parcels[0] || {
                length: 14,
                width: 10,
                height: 6,
                weight: 2,
              },
            ])
          }
        >
          Add
        </Button>

        <SubMenu
          items={[
            parcels.length > 1 && new ButtonAction('Remove', () => onChange(parcels.slice(0, -1))),
            parcels.length > 0 &&
              new ButtonAction('Copy', () => onChange([...parcels, parcels[parcels.length - 1]])),
            new ButtonAction('Manual Measure', onMeasure),
          ]}
        />
      </Box>
    </DrawerFormSection>
  );
}
