import { Fragment, useState } from 'react';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { ArtTrack } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Grid2 as Grid,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import arrayMove from 'array-move';
import axios from 'axios';
import pick from 'lodash/pick';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { FieldFactory, GroupLayout } from '@/classes';
import DesignLayoutCard from '@/components/Designs/DesignLayoutCard';
import InkChangeField from '@/components/Designs/InkChangeField';
import OrderDesignListItem from '@/components/Designs/OrderDesignListItem';
import LoadingBackdrop from '@/components/Shared/LoadingBackdrop';
import Paper from '@/components/Shared/Paper';
import { randomElement } from '@/helpers';
import {
  DesignLayout,
  DesignLayoutPayload,
  designLayoutPayloadSchema,
  DesignLayoutWithProofs,
  FieldProps,
  genericModelReferenceSchema,
  Order,
  OrderDesign,
  OrderDesignCreatePayload,
  orderDesignCreatePayloadSchema,
  orderDesignUpdatePayloadSchema,
} from '@/types';
import { useRecord } from '@/utils/genericResource';
import { useOrderableApiUrl } from '@/utils/hooks/useApiSegment';
import useDialogs from '@/utils/hooks/useDialogs';
import useMutateQueryData from '@/utils/hooks/useMutateQueryData';
import ImprintsDrawer from './ImprintsDrawer';
import RequestedDesignDrawer from './RequestedDesignDrawer';
import RosterDrawer from './RosterDrawer';

function ExistingLayoutField({ fieldModel, ...props }: FieldProps) {
  const allCustomers = useFormContext<{ all_customers?: boolean }>().watch('all_customers');
  const customerId = useRecord<Order>().customer.id;
  const requestParams: Record<string, string> = {};

  if (!allCustomers) {
    requestParams.customer_id = String(customerId);
  }

  const newField = FieldFactory.belongsTo(fieldModel.name, 'designLayouts').withRequestParams(
    requestParams,
  );

  return newField.renderEditComponent(props);
}

function ExistingDesignField({ fieldModel, ...props }: FieldProps) {
  const form = useFormContext<{ all_customers?: boolean; include_incomplete?: boolean }>();
  const allCustomers = form.watch('all_customers');
  const includeIncomplete = form.watch('include_incomplete');
  const customerId = useRecord<Order>().customer.id;

  const requestParams: Record<string, string> = {};

  if (!allCustomers) {
    requestParams.customer_id = String(customerId);
  }
  if (!includeIncomplete) {
    requestParams.complete = '1';
  }

  const newField = FieldFactory.belongsTo(fieldModel.name, 'designs').withRequestParams(
    requestParams,
  );
  return (
    <Box width="500px" maxWidth="100%">
      {newField.renderEditComponent(props)}
    </Box>
  );
}

export default function OrderArt() {
  const {
    id: orderId,
    order_type: { is_blank: isBlank, is_webstore: isWebstore },
    invoiced_at: invoicedAt,
    released_at: releasedAt,
    cancelled_at: cancelledAt,
  } = useRecord<Order>();
  const baseUrl = useOrderableApiUrl('design-layouts');
  const [editing, setEditing] = useState<OrderDesign>();
  const [editingImprints, setEditingImprints] = useState<OrderDesign>();
  const [layoutForRoster, setLayoutForRoster] = useState<DesignLayoutWithProofs>();
  const { confirm, prompt } = useDialogs();

  const setDesignLayouts = useMutateQueryData<DesignLayoutWithProofs[]>([baseUrl]);
  const {
    data: designLayouts,
    isLoading,
    refetch,
  } = useQuery([baseUrl], () =>
    axios.get<{ data: DesignLayoutWithProofs[] }>(baseUrl).then(({ data }) => data.data),
  );

  const canEdit = (layout?: DesignLayout): boolean => {
    if (invoicedAt || releasedAt || cancelledAt) {
      return false;
    }
    if (layout && layout.subcontract_po_id) {
      return false;
    }
    return true;
  };

  const onLayoutUpdated = (layout: DesignLayout) => {
    setDesignLayouts((prev) =>
      prev.map((d) => {
        if (d.id === layout.id) {
          return { ...d, ...layout };
        }
        return d;
      }),
    );
  };

  const onLayoutAdded = (layout: DesignLayoutWithProofs) =>
    setDesignLayouts((prev) => [...prev, layout]);

  const onRoster = (layout: DesignLayoutWithProofs) => {
    setLayoutForRoster(layout);
  };

  const createNew = () => {
    prompt({
      title: 'Create New Design Layout',
      fields: [
        FieldFactory.text('name')
          .withSize('medium')
          .withHelp(
            randomElement([
              'e.g. 2021 Basketball Shooting Shirts',
              'e.g. 2020 Holiday Shirts',
              'e.g. Reversible Practice Jerseys',
              'e.g. Turkey Trot Shirts',
            ]),
          ),
      ],
      schema: designLayoutPayloadSchema,
      onSubmit: (v) => axios.post<DesignLayoutWithProofs>(`${baseUrl}`, v),
    }).then(({ data }) => {
      onLayoutAdded(data);
    });
  };

  const addExisting = () => {
    prompt({
      title: 'Start From Existing Layout',
      fields: [
        FieldFactory.custom('design_layout', ExistingLayoutField),
        FieldFactory.boolean('all_customers', 'Include designs from other customers'),
      ],
      schema: z.object({
        design_layout: genericModelReferenceSchema,
        all_customers: z.boolean().nullish(),
      }),
      onSubmit: (v) => {
        const payload: DesignLayoutPayload = {
          design_layout_id: v.design_layout?.id,
        };
        return axios.post<DesignLayoutWithProofs>(baseUrl, payload);
      },
    }).then(({ data }) => {
      onLayoutAdded(data);
    });
  };

  const onDesignRemove = (layout: DesignLayout, orderDesign: OrderDesign) => {
    confirm({
      title: 'Remove Design From Layout',
      description: 'Are you sure you want to remove this design from this layout?',
      color: 'error',
    }).then(() => {
      axios.delete(`/api/order-designs/${orderDesign.id}`).then(() => {
        setDesignLayouts((prev) =>
          prev.map((d) => {
            if (d.id === layout.id) {
              return {
                ...d,
                order_designs: d.order_designs.filter((od) => od.id !== orderDesign.id),
              };
            }
            return d;
          }),
        );
      });
    });
  };

  const addNewToLayout = (layout: DesignLayout) => {
    prompt({
      title: `Request New Design For ${layout.increment_id}`,
      fields: [
        FieldFactory.text('name').withSize('medium'),
        FieldFactory.belongsTo('decoration_type', 'decorationTypes'),
      ],
      schema: orderDesignCreatePayloadSchema
        .omit({
          decoration_type_id: true,
          design_layout_id: true,
        })
        .extend({
          decoration_type: genericModelReferenceSchema,
        }),
      onSubmit: (v) => {
        const payload: OrderDesignCreatePayload = {
          ...v,
          decoration_type_id: v.decoration_type.id,
          design_layout_id: layout.id,
        };
        return axios.post<OrderDesign>('/api/order-designs', payload);
      },
    }).then(({ data }) => {
      setEditing(data);
      refetch();
    });
  };

  const addExistingToLayout = (layout: DesignLayout) => {
    prompt({
      title: `Add Existing Design To ${layout.increment_id}`,
      fields: [
        FieldFactory.custom('design', ExistingDesignField),
        new GroupLayout('', [
          FieldFactory.boolean('all_customers', 'Include designs from other customers'),
          FieldFactory.boolean('include_incomplete', 'Include incomplete designs'),
        ]),
        FieldFactory.custom('ink_changes', InkChangeField),
      ],
      initialValues: {
        all_customers: false,
        include_incomplete: false,
        ink_changes: [],
      },
      schema: orderDesignCreatePayloadSchema.pick({ ink_changes: true }).extend({
        design: genericModelReferenceSchema,
        all_customers: z.boolean().nullish(),
        include_incomplete: z.boolean().nullish(),
      }),
      onSubmit: (v) => {
        const payload: OrderDesignCreatePayload = {
          design_id: v.design.id,
          design_layout_id: layout.id,
          ink_changes: v.ink_changes,
        };
        return axios.post('/api/order-designs', payload);
      },
    }).then(() => {
      refetch();
    });
  };

  const onEditOrderDesign = (orderDesign: OrderDesign) => {
    prompt({
      title: 'Edit Linked Design',
      fields: [
        FieldFactory.belongsTo('design', 'designs'),
        FieldFactory.custom('ink_changes', InkChangeField),
      ],
      initialValues: pick(orderDesign, ['design', 'ink_changes']),
      schema: orderDesignUpdatePayloadSchema,
      onSubmit: (v) => axios.put(`/api/order-designs/${orderDesign.id}`, v),
    }).then(() => {
      refetch();
    });
  };

  const onDelete = (layout: DesignLayout) => {
    confirm({
      title: 'Delete Design Layout',
      description: 'Are you sure you want to delete from the order?',
      color: 'error',
    }).then(() => {
      axios.delete(`/api/design-layouts/${layout.id}`);
      setDesignLayouts((prev) => prev.filter((p) => p.id !== layout.id));
    });
  };

  const actions = (
    <Box py={1}>
      <Button variant="contained" onClick={addExisting} sx={{ mr: 1 }}>
        Start From Existing
      </Button>
      <Button onClick={createNew}>Create New Design Layout</Button>
    </Box>
  );

  const createSortHandler =
    (l: DesignLayout) =>
    ({ active, over }: DragEndEvent) => {
      setDesignLayouts((prev) =>
        prev.map((layout) => {
          if (layout.id === l.id && over) {
            const oldIndex = layout.order_designs.findIndex((od) => od.id === active.id);
            const newIndex = layout.order_designs.findIndex((od) => od.id === over.id);
            const newOrderDesigns = arrayMove(layout.order_designs, oldIndex, newIndex);
            axios.put(`/api/design-layouts/${layout.id}/order-designs`, {
              ids: newOrderDesigns.map((od) => od.id),
            });
            return { ...layout, order_designs: newOrderDesigns };
          }
          return layout;
        }),
      );
    };

  if (!designLayouts) {
    return <CircularProgress />;
  }

  if (isBlank) {
    return (
      <Paper>
        <Typography>This order type does not allow for decoration.</Typography>
      </Paper>
    );
  }

  return (
    <div>
      {designLayouts.length === 0 ? (
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box textAlign="center" py={2} maxWidth="sm">
            <ArtTrack sx={{ color: 'grey.400', fontSize: 100 }} />
            <Typography variant="h6">Get Started with Art</Typography>
            <Typography color="textSecondary" sx={{ mb: 3 }}>
              Create layouts for your order consisting of new or existing designs. Each layout will
              be proofed separately.
            </Typography>
            {canEdit() && actions}
          </Box>
        </Box>
      ) : (
        <div>
          {canEdit() && actions}

          <Grid container spacing={2}>
            {designLayouts.map((layout) => (
              <Fragment key={layout.id}>
                <Grid size={{ xs: 12, md: 4 }}>
                  <DesignLayoutCard
                    layout={layout}
                    orderId={orderId}
                    isWebstore={isWebstore}
                    invoicedAt={invoicedAt}
                    onDelete={onDelete}
                    onRoster={onRoster}
                    canEdit={canEdit}
                    onLayoutUpdated={onLayoutUpdated}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 8 }}>
                  <Card>
                    <CardHeader
                      title={`Designs for ${layout.increment_id}`}
                      action={
                        canEdit(layout) && (
                          <>
                            <Button
                              variant="contained"
                              size="small"
                              onClick={() => addExistingToLayout(layout)}
                              sx={{ mr: 1 }}
                            >
                              Add Existing
                            </Button>
                            <Button size="small" onClick={() => addNewToLayout(layout)}>
                              Request New
                            </Button>
                          </>
                        )
                      }
                    />
                    <TableContainer>
                      <Table>
                        <TableBody>
                          <DndContext onDragEnd={createSortHandler(layout)}>
                            <SortableContext items={layout.order_designs.map((od) => od.id)}>
                              {layout.order_designs.map((orderDesign) => (
                                <OrderDesignListItem
                                  key={orderDesign.id}
                                  orderDesign={orderDesign}
                                  sortable={layout.order_designs.length > 1}
                                  onEdit={
                                    canEdit(layout)
                                      ? () => onEditOrderDesign(orderDesign)
                                      : undefined
                                  }
                                  onEditDesign={
                                    canEdit(layout) ? () => setEditing(orderDesign) : undefined
                                  }
                                  onRemove={
                                    canEdit(layout)
                                      ? () => onDesignRemove(layout, orderDesign)
                                      : undefined
                                  }
                                  onEditImprints={() => setEditingImprints(orderDesign)}
                                />
                              ))}
                            </SortableContext>
                          </DndContext>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Card>
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </div>
      )}
      <ImprintsDrawer
        orderDesign={editingImprints}
        onClose={() => setEditingImprints(undefined)}
        onSave={canEdit() ? refetch : undefined}
      />
      <RequestedDesignDrawer
        orderDesign={editing}
        onClose={() => setEditing(undefined)}
        onSave={() => {
          setEditing(undefined);
          refetch();
        }}
      />
      <RosterDrawer
        layout={layoutForRoster}
        onClose={() => setLayoutForRoster(undefined)}
        onSaved={() => {
          setLayoutForRoster(undefined);
          refetch();
        }}
      />
      <LoadingBackdrop loading={isLoading} />
    </div>
  );
}
