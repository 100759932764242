import { useCallback } from 'react';
import flow from 'lodash/flow';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';
import moment from 'moment';
import { DateRangeField, RelationField, Resource } from '@/classes';
import { DataTableState } from '@/types';
import useGetResource from '@/utils/hooks/useGetResource';
import wrap from '@/utils/wrap';

const toDateString = (v: any) => moment(v).toISOString();
const isEmpty = (v: any) => v == null || v === '';

export default function useGetParamsForIndexRequest() {
  const getResource = useGetResource();

  return useCallback(
    (resource: Resource, state: Partial<DataTableState>, query: string) => {
      const fromState = pick(state, ['count', 'page', 'sort']);

      const filters = flow(
        (filters) =>
          mapValues(filters, (value, fieldName) => {
            const field = resource
              .getFilterableFields()
              .find((f) => f.getNameForFilterForm() === fieldName);
            if (!field) {
              return value;
            }

            if (field instanceof RelationField) {
              const pk = getResource(field.resource).primaryKey;
              return wrap(value)
                .map((v) => v[pk])
                .join(',');
            }

            if (field instanceof DateRangeField) {
              if (Array.isArray(value)) {
                return value
                  .slice(0, 2)
                  .filter((v) => !!v)
                  .map(toDateString)
                  .join(',');
              }
            }

            if (Array.isArray(value)) {
              return value.join(',');
            }
            return value;
          }),
        (filters) =>
          mapKeys(filters, (value, fieldName) => {
            const field = resource
              .getFilterableFields()
              .find((f) => f.getNameForFilterForm() === fieldName);

            return field?.getFilterKey() || fieldName;
          }),
      )(state.filters);

      const aggregations = flow(
        (a) => mapKeys(a, (v, k) => `with_${k}`),
        (a) => mapValues(a, (a) => a.join(',')),
      )(state.aggregations);

      return omitBy(
        {
          ...fromState,
          ...filters,
          ...aggregations,
          ...resource.getQueryParamsForIndex(),
          query,
        },
        isEmpty,
      );
    },
    [getResource],
  );
}
