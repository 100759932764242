import { useMemo, useState } from 'react';
import { Add, Download, Sort } from '@mui/icons-material';
import { Box, Button, Card, CardHeader, Fab, FormControlLabel, Switch } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import pick from 'lodash/pick';
import startCase from 'lodash/startCase';
import { StringParam, useQueryParam } from 'use-query-params';
import { AnchorAction, ButtonAction, FieldFactory } from '@/classes';
import Can from '@/components/Permissions/Can';
import LoadingBackdrop from '@/components/Shared/LoadingBackdrop';
import SubMenu from '@/components/Shared/SubMenu';
import { Order, OrderItem, OrderTotals, orderUpdatePayloadSchema, OrderWithDetails } from '@/types';
import { useRecord, useUpdateRecord } from '@/utils/genericResource';
import getApiUrl from '@/utils/getApiUrl';
import useApiSegment, { useOrderableApiUrl } from '@/utils/hooks/useApiSegment';
import useDialogs from '@/utils/hooks/useDialogs';
import useMutateQueryData from '@/utils/hooks/useMutateQueryData';
import useSessionState from '@/utils/hooks/useSessionState';
import CreateBackorderDrawer from './CreateBackorderDrawer';
import OrderItemEditDrawer from './OrderItemEditDrawer';
import OrderItemWizardDrawer from './OrderItemWizardDrawer';
import OrderItemsTable from './OrderItemsTable';
import OrderTotalsBlock from './OrderTotalsBlock';

const TOTAL_FIELDS = [
  'tax',
  'precollected_tax',
  'total_tax',
  'total_exempt',
  'shipping',
  'inbound_freight',
  'total',
  'balance',
  'paid',
  'subtotal',
  'items_sum',
  'updated_at',
  'last_tax_hash',
  'current_tax_hash',
];

export const getGroupedItems = (items: OrderItem[]) =>
  groupBy(items, (i) =>
    [i.description, i.number, i.color, i.is_custom, i.vendor?.id, i.variant?.product.id].join('|'),
  );

export default function OrderItemsField() {
  const order = useRecord<OrderWithDetails>();
  const updateRecord = useUpdateRecord<'orders' | 'quotes'>();
  const totals = pick(order, TOTAL_FIELDS) as OrderTotals;
  const {
    id: orderId,
    order_type: orderType,
    items: propItems,
    invoiced_at: invoicedAt,
    cancelled_at: cancelledAt,
  } = order;
  const isOrderLocked = Boolean(invoicedAt || cancelledAt);

  const { confirm, prompt } = useDialogs();
  const [creating, setCreating] = useState(false);
  const [editing, setEditing] = useState<OrderItem>();
  const [showWizard, setShowWizard] = useState(false);
  const [isGrouped, setIsGrouped] = useSessionState(
    `${orderId}.grouped`,
    isEmpty(propItems) || keys(getGroupedItems(propItems)).length < propItems.length,
  );
  const [action, setAction] = useQueryParam('action', StringParam);
  const segment = useApiSegment(1);
  const orderUrl = useOrderableApiUrl();
  const itemsUrl = useOrderableApiUrl('items');

  const handleUpdatedOrder = (data: Order) => {
    updateRecord(pick(data, TOTAL_FIELDS));
  };

  const QUERY_KEY = [segment, orderId, 'withItems'];
  const setOrder = useMutateQueryData<OrderWithDetails>(QUERY_KEY);
  const setItems = (newItems: OrderItem[] | ((prev: OrderItem[]) => OrderItem[])) =>
    setOrder((prev) => ({
      ...prev,
      items: typeof newItems === 'function' ? newItems(prev.items) : newItems,
    }));
  const itemsQuery = useQuery<OrderWithDetails>(
    QUERY_KEY,
    () => axios.get<OrderWithDetails>(`${orderUrl}?with=items`).then(({ data }) => data),
    {
      onSuccess: handleUpdatedOrder,
    },
  );
  const items = itemsQuery.data?.items || [];

  const handleUpdatedItem = (item: OrderItem) => {
    setItems((prevItems) =>
      prevItems.map((prev) => {
        if (prev.id === item.id) {
          return { ...prev, ...item };
        }
        return prev;
      }),
    );
  };

  const setItemsAndPersist = (newItems: OrderItem[]) => {
    setItems(newItems);
    axios.post(`${itemsUrl}/resort`, {
      ids: newItems.map((i) => i.id),
    });
  };

  const reloadItems = () => {
    setEditing(undefined);
    setShowWizard(false);
    itemsQuery.refetch();
  };

  const sortRequest = useMutation(
    () => axios.post<{ data: OrderItem[] }>(`${itemsUrl}/sort`).then(({ data }) => data.data),
    {
      onSuccess: (items) => setItems(items),
    },
  );

  const updateOrderRequest = useMutation(
    (v: Partial<Order>) => axios.put(orderUrl, v).then(({ data }) => data),
    {
      onSuccess: handleUpdatedOrder,
    },
  );

  const createItemRequest = useMutation((v: Partial<OrderItem>) => axios.post(itemsUrl, v), {
    onSuccess: reloadItems,
  });

  const deleteItemRequest = useMutation(
    (item: OrderItem) => axios.delete(`${itemsUrl}/${item.id}`),
    {
      onSuccess: reloadItems,
    },
  );

  const isLoading =
    itemsQuery.isFetching ||
    sortRequest.isLoading ||
    updateOrderRequest.isLoading ||
    createItemRequest.isLoading ||
    deleteItemRequest.isLoading;

  const DEFAULTS = {
    show_on_invoice: get(orderType, 'default_show_on_invoice', true),
    can_apply_designs: get(orderType, 'default_can_apply_designs', true),
    is_purchasable: get(orderType, 'default_is_purchasable', true),
    is_shippable: get(orderType, 'default_is_shippable', true),
    can_drop_ship: get(orderType, 'default_can_drop_ship', true),
    classification: 'product',
    is_customer_supplied: false,
    use_database_cost: true,
    is_custom: false,
  } as const;

  const onCreate = () => setCreating(true);

  const onEdit = (field: 'shipping' | 'inbound_freight') => {
    const initialValues = pick(totals, field);
    prompt({
      title: `Update ${startCase(field)}`,
      description: `How much should be charged for ${startCase(field)}?`,
      fields: [FieldFactory.curr(field).withSize('medium')],
      schema: orderUpdatePayloadSchema
        .pick({
          shipping: true,
          inbound_freight: true,
        })
        .partial(),
      initialValues,
      onSubmit: (v) => updateOrderRequest.mutateAsync(v),
    });
  };

  const onRemove = (item: OrderItem) => {
    const hasDesigns = Object.keys(item.order_design_ids).length > 0;
    let description = 'Are you sure you want to remove this item from the order?';
    if (hasDesigns) {
      description += ' This item has design imprints associated with it.';
    }
    confirm({
      title: 'Delete Line Item',
      description,
      color: hasDesigns ? 'warning' : undefined,
    }).then(() => {
      deleteItemRequest.mutate(item);
    });
  };

  const onDuplicate = (item: OrderItem) => {
    createItemRequest.mutate(item);
  };

  const onNextItem = useMemo(() => {
    if (!editing) {
      return undefined;
    }
    const index = items.findIndex((i) => i.id === editing.id);
    if (index !== -1 && index < items.length - 1) {
      return () => setEditing(items[index + 1]);
    }
    return undefined;
  }, [editing, items, setEditing]);

  const onPrevItem = useMemo(() => {
    if (!editing) {
      return undefined;
    }
    const index = items.findIndex((i) => i.id === editing.id);
    if (index > 0) {
      return () => setEditing(items[index - 1]);
    }
    return undefined;
  }, [editing, items, setEditing]);

  const onSortBySize = () => {
    confirm({
      title: 'Sort Items By Size',
      description: 'Are you sure you want to do this? This will reorder all items in the order.',
    }).then(() => {
      sortRequest.mutate();
    });
  };

  const groupSwitch = (
    <FormControlLabel
      style={{ margin: '0 24px' }}
      control={<Switch checked={isGrouped} onChange={(e) => setIsGrouped(e.target.checked)} />}
      label="Grouped"
    />
  );

  const actions = isOrderLocked ? (
    groupSwitch
  ) : (
    <div>
      {groupSwitch}
      <Can permission="write:orders">
        <Button variant="contained" onClick={() => setShowWizard(true)} sx={{ mr: 1 }}>
          Add Product
        </Button>
      </Can>
      <SubMenu
        items={[
          new ButtonAction('Add Line Item', onCreate).withIcon(Add).withPermission('write:orders'),
          new ButtonAction('Sort Items by Size', onSortBySize)
            .withIcon(Sort)
            .withPermission('write:orders'),
          new AnchorAction(
            'Download Excel',
            getApiUrl(`/api/orders/${orderId}/items?format=xlsx`),
          ).withIcon(Download),
        ]}
      />
    </div>
  );

  return (
    <Card sx={{ position: 'relative' }}>
      <CardHeader title="Line Items" action={actions} />

      <LoadingBackdrop loading={isLoading} />

      <OrderItemsTable
        isGrouped={isGrouped}
        items={items}
        setItemsAndPersist={setItemsAndPersist}
        handleUpdatedItem={handleUpdatedItem}
        onDuplicate={onDuplicate}
        onRemove={onRemove}
        setEditing={setEditing}
        onReload={reloadItems}
        isOrderLocked={isOrderLocked}
        totals={totals}
      />

      <Fab
        size="small"
        sx={{ position: 'fixed', bottom: 8, right: 56 }}
        color="primary"
        onClick={() => setShowWizard(true)}
      >
        <Add />
      </Fab>

      <Box display="flex" justifyContent="flex-end" p={2}>
        <OrderTotalsBlock
          totals={totals}
          handleUpdatedOrder={handleUpdatedOrder}
          isOrderLocked={isOrderLocked}
          onEditShipping={() => onEdit('shipping')}
          onEditInbound={() => onEdit('inbound_freight')}
        />
      </Box>

      <OrderItemEditDrawer
        open={creating}
        onClose={() => setCreating(false)}
        title="Add Line Item"
        item={{ ...DEFAULTS, qty: 12 }}
        onSuccess={reloadItems}
      />

      <OrderItemEditDrawer
        open={!!editing}
        onClose={() => setEditing(undefined)}
        title="Edit Line Item"
        item={editing}
        onSuccess={reloadItems}
        onNext={onNextItem}
        onPrev={onPrevItem}
      />

      <OrderItemWizardDrawer
        open={showWizard}
        onClose={() => setShowWizard(false)}
        onSuccess={reloadItems}
        initialValues={{
          ...DEFAULTS,
          items: [],
        }}
      />

      <CreateBackorderDrawer
        open={action === 'backorder'}
        onClose={() => setAction('')}
        items={items}
        orderId={orderId}
      />
    </Card>
  );
}
