import MoneyIcon from '@mui/icons-material/AttachMoney';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import {
  useCreatePaymentMethod,
  useDeletePaymentMethod,
  useGetPaymentMethods,
} from '@/api/paymentMethods';
import StripePaymentMethodListItem from '@/components/Payments/StripePaymentMethodListItem';
import curr from '@/utils/curr';
import { useRecordId } from '@/utils/genericResource';
import useDialogs from '@/utils/hooks/useDialogs';

export default function CustomerPaymentMethods() {
  const customerId = useRecordId();
  const { confirm } = useDialogs();

  const { data, isFetching } = useGetPaymentMethods(Number(customerId));
  const createMethodRequest = useCreatePaymentMethod(Number(customerId));
  const removeMethodRequest = useDeletePaymentMethod(Number(customerId));
  const methods = data?.data;
  const cashBalance = data?.cash_balance.available?.usd;

  const onRemove = (methodId: string) => {
    confirm({
      title: 'Remove Payment Method',
      description: 'Are you sure you want to remove this payment method?',
    }).then(() => {
      removeMethodRequest.mutate(methodId);
    });
  };

  const isLoading = isFetching || removeMethodRequest.isLoading;

  return (
    <Card>
      <CardHeader
        title="Customer Payment Methods"
        action={
          <LoadingButton
            loading={createMethodRequest.isLoading}
            color="primary"
            variant="contained"
            onClick={() =>
              createMethodRequest.mutate({
                url: window.location.href,
              })
            }
          >
            Add Method
          </LoadingButton>
        }
      />
      {isLoading && <LinearProgress />}

      {methods && methods.length === 0 && !cashBalance && (
        <CardContent>
          <Typography color="textSecondary">This customer has no saved payment methods.</Typography>
        </CardContent>
      )}

      {methods && (
        <List style={{ maxWidth: 500 }}>
          {cashBalance ? (
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <MoneyIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="USD Cash Balance"
                secondary={`Available: ${curr(cashBalance / 100)}`}
              />{' '}
            </ListItem>
          ) : null}
          {methods.map((method) => (
            <StripePaymentMethodListItem key={method.id} method={method} onRemove={onRemove} />
          ))}
        </List>
      )}
    </Card>
  );
}
