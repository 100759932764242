import { InputAdornment, TextField as MaterialTextField } from '@mui/material';
import { UseControllerReturn } from 'react-hook-form';
import TextInputField from '@/classes/Fields/TextInputField';
import { FieldProps } from '@/types';

export function TextArea({ field, fieldModel, fieldState }: FieldProps<TextAreaField>) {
  const InputProps = fieldModel.InputProps || {};
  if (fieldModel.prefix) {
    InputProps.startAdornment = (
      <InputAdornment position="start">{fieldModel.prefix}</InputAdornment>
    );
  }
  if (fieldModel.postfix) {
    InputProps.endAdornment = <InputAdornment position="end">{fieldModel.postfix}</InputAdornment>;
  }

  return (
    <MaterialTextField
      {...field}
      value={field.value || ''}
      {...fieldModel.getTextFieldProps(fieldState)}
      type={fieldModel.type}
      InputProps={InputProps}
      multiline
      rows={fieldModel.rows}
    />
  );
}

export default class TextAreaField extends TextInputField {
  rows = 5;

  renderEditComponent(props: UseControllerReturn) {
    return <TextArea {...props} fieldModel={this} />;
  }
}
