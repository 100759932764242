import { Edit } from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  Grid2 as Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import { useFormContext } from 'react-hook-form';
import { useOnReloadRecord } from '@/utils/genericResource';
import useDialogs from '@/utils/hooks/useDialogs';
import useGetResource from '@/utils/hooks/useGetResource';

export default function DesignLibraryEditor() {
  const item = useFormContext().watch();
  const getResource = useGetResource();
  const onReload = useOnReloadRecord();
  const { prompt } = useDialogs();

  const onEdit = () => {
    prompt({
      title: 'Edit Library Item',
      fields: getResource('designLibrary').fields,
      schema: getResource('designLibrary').schema,
      initialValues: item,
      onSubmit: (v) => axios.put(`/api/design-library-items/${item.id}`, v),
    }).then(() => {
      onReload();
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12, md: 6 }}>
        <Card>
          {item.image ? (
            <img alt="" src={item.image} style={{ width: '100%' }} />
          ) : (
            <CardContent>No image uploaded</CardContent>
          )}
        </Card>
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <Card>
          <CardHeader
            title="Library Item Details"
            action={
              <IconButton onClick={onEdit}>
                <Edit />
              </IconButton>
            }
          />

          <Table>
            <TableBody>
              <TableRow>
                <TableCell variant="head">Name</TableCell>
                <TableCell>{item.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Description</TableCell>
                <TableCell>{item.description}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head" style={{ whiteSpace: 'nowrap' }}>
                  Legacy ID (SW #)
                </TableCell>
                <TableCell>{item.legacy_id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Created Date</TableCell>
                <TableCell>{moment(item.created_at).format('l')}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
      </Grid>
    </Grid>
  );
}
