import { Print } from '@mui/icons-material';
import { AnchorAction } from '@/classes';
import SubMenu from '@/components/Shared/SubMenu';
import { useConfig } from '@/contexts/AppConfigContext';
import getApiUrl from '@/utils/getApiUrl';

export default function PrintMenu({
  model,
  id,
  size,
}: {
  model: string;
  id: string | number;
  size?: 'small' | 'medium';
}) {
  const printables = useConfig().printables.filter((p) => p.model === model);
  return (
    <SubMenu
      size={size}
      primaryIcon={Print}
      items={printables.map(
        (p) => new AnchorAction(p.title, getApiUrl(`/api/printables/${p.id}/render/${id}`)),
      )}
    />
  );
}
