import { ChangeEvent, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  TextField,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import sumBy from 'lodash/sumBy';
import AddressTitle from '@/components/Addresses/AddressTitle';
import PaginatedTable from '@/components/Shared/PaginatedTable';
import ShipActions from '@/components/Shipping/ShipActions';
import { Address, OrderItem, ShipmentItemPayload } from '@/types';
import { buildIncrementId } from '@/utils/notes';
import numString from '@/utils/numString';
import { canShipOrderItem, orderItemToShipmentItem } from '@/utils/shipping';

export default function OrderShippingSimple({
  orderId,
  address,
  customerId,
}: {
  orderId: number;
  customerId: number;
  address: Address;
}) {
  const [selected, setSelected] = useState<ShipmentItemPayload[]>([]);

  const {
    data: items = [],
    isLoading,
    isFetching,
    refetch,
  } = useQuery(['shippableItems', orderId], () =>
    axios
      .get<{ data: OrderItem[] }>(`/api/orders/${orderId}/items?filter[is_shippable]=1`)
      .then(({ data }) => data.data),
  );

  const shippableItems = items.filter(canShipOrderItem);

  const onShipSuccess = () => {
    setSelected([]);
    refetch();
  };

  const handleCheck = (item: OrderItem) => (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelected([...selected, orderItemToShipmentItem(item)]);
    } else {
      setSelected(selected.filter((s) => s.order_item_id !== item.id));
    }
  };

  const handleCheckAll = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelected(shippableItems.map(orderItemToShipmentItem));
    } else {
      setSelected([]);
    }
  };

  const handleSelectedChange =
    (id: number, field: keyof ShipmentItemPayload) => (e: ChangeEvent<HTMLInputElement>) => {
      setSelected(
        selected.map((item) => {
          if (item.order_item_id === id) {
            return {
              ...item,
              [field]: e.target.value,
            };
          }
          return item;
        }),
      );
    };

  if (isLoading) {
    return <CircularProgress />;
  }

  const qty = sumBy(selected, (s) => Number(s.qty_shipped));
  const actions = selected.length > 0 && address && (
    <ShipActions
      payload={{
        shippable_type: 'order',
        shippable_id: orderId,
        address_id: address.id,
        items: selected,
      }}
      customerId={customerId}
      title={`Shipping ${numString(qty, 'Items')}`}
      qty={qty}
      onSuccess={onShipSuccess}
    />
  );

  return (
    <Card>
      <CardHeader title="Items to Ship" action={actions} />
      <CardContent>
        <AddressTitle address={address} />
      </CardContent>

      <PaginatedTable
        size="small"
        searchable={['number', 'color', 'size', 'description', 'design_layout_id']}
        onReload={refetch}
        isFetching={isFetching}
        rows={items}
        columns={[
          {
            id: 'checkbox',
            enableSorting: false,
            enableHiding: false,
            header: () =>
              shippableItems.length > 0 ? (
                <Checkbox
                  onChange={(e) => handleCheckAll(e)}
                  checked={selected.length === shippableItems.length}
                  indeterminate={selected.length > 0 && selected.length < shippableItems.length}
                />
              ) : null,
            cell: ({ row }) =>
              canShipOrderItem(row.original) && (
                <Checkbox
                  onChange={handleCheck(row.original)}
                  checked={selected.some((i) => i.order_item_id === row.original.id)}
                />
              ),
          },
          { accessorKey: 'number', header: 'Item #' },
          { accessorKey: 'color', header: 'Color' },
          { accessorKey: 'size', header: 'Size' },
          { accessorKey: 'description', header: 'Description' },
          {
            accessorKey: 'design_layout_id',
            header: 'Design Layout',
            cell: ({ getValue }) => {
              const layoutId = getValue<number | null>();
              return layoutId ? buildIncrementId('LAY', layoutId) : 'Blank';
            },
          },
          { accessorKey: 'qty', header: 'Qty Req' },
          items.some((i) => i.qty_scrapped > 0) && {
            accessorKey: 'qty_scrapped',
            header: 'Qty Scrapped',
          },
          { accessorKey: 'qty_shipped', header: 'Qty Shipped' },
          {
            id: 'input',
            header: 'Qty to Ship',
            enableSorting: false,
            enableHiding: false,
            cell: ({ row }) => {
              const checked = selected.find((i) => i.order_item_id === row.original.id);
              return checked ? (
                <TextField
                  size="small"
                  style={{ width: 80 }}
                  onChange={handleSelectedChange(row.original.id, 'qty_shipped')}
                  value={checked.qty_shipped}
                  type="number"
                />
              ) : (
                <div style={{ width: 100 }}>&nbsp;</div>
              );
            },
          },
        ]}
      />
    </Card>
  );
}
