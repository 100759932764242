import { ChangeEvent, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, IconButton } from '@mui/material';
import axios from 'axios';
import ResetButton from '@/components/Buttons/ResetButton';
import DrawerButtons from '@/components/Form/DrawerButtons';
import Form from '@/components/Form/Form';
import FormErrors from '@/components/Form/FormErrors';
import ClosableDrawer from '@/components/Shared/ClosableDrawer';
import { OrderItem, OrderItemPayload, orderItemPayloadSchema, SkuResource } from '@/types';
import { useZodForm } from '@/utils/form';
import { useRecordId } from '@/utils/genericResource';
import getDirtyProperties from '@/utils/getDirtyProperties';
import useApiSegment from '@/utils/hooks/useApiSegment';
import BehaviorSection from './Create/BehaviorSection';
import PriceSection from './Create/PriceSection';
import VariantSection from './Create/VariantSection';
import VendorSection from './Create/VendorSection';

export default function OrderItemEditDrawer({
  open,
  onClose,
  onSuccess,
  title,
  onNext,
  onPrev,
  item,
}: {
  item?: Partial<OrderItem>;
  onNext?: () => void;
  onPrev?: () => void;
  onSuccess: (i: OrderItem) => void;
  open: boolean;
  onClose: () => void;
  title: string;
}) {
  const form = useZodForm(orderItemPayloadSchema, item);

  const orderId = useRecordId();
  const segment = useApiSegment(1);
  const variant = form.watch('variant') as SkuResource | undefined;
  const isCustom = form.watch('is_custom') || false;

  useEffect(() => {
    if (open && item) {
      form.reset(item);
    }
  }, [open]);

  // Handle Variant Change
  useEffect(() => {
    if (form.formState.isDirty && variant) {
      form.setValue('description', variant.product.name);
      form.setValue('vendor', variant.product.vendor);
      form.setValue('cost', variant.cost);
      form.setValue('is_shippable', variant.product.default_is_shippable);
      form.setValue('can_drop_ship', variant.product.default_can_drop_ship);
      form.setValue('can_apply_designs', variant.product.default_can_apply_designs);
      form.setValue('is_purchasable', variant.product.default_is_purchasable);
      form.setValue('show_on_invoice', variant.product.default_show_on_invoice);
      form.setValue('classification', variant.product.classification);
    }
  }, [variant]);

  const onSubmit = (values: OrderItemPayload) => {
    const promise = item?.id
      ? axios.put(`/api/${segment}/${orderId}/items/${item.id}`, getDirtyProperties(values, item))
      : axios.post(`/api/${segment}/${orderId}/items`, values);

    return promise.then(({ data }) => {
      onSuccess(data);
      onClose();
    });
  };

  const handleCustomChange = (e: ChangeEvent<HTMLInputElement>) => {
    form.setValue('is_custom', e.currentTarget.value === 'custom');
  };

  const actions = [];
  if (onPrev) {
    actions.push(
      <IconButton onClick={onPrev} size="large">
        <ChevronLeft />
      </IconButton>,
    );
  }
  if (onNext) {
    actions.push(
      <IconButton onClick={onNext} size="large">
        <ChevronRight />
      </IconButton>,
    );
  }

  let min: number | undefined = undefined;
  let max: number | undefined = undefined;
  if (item?.qty_sourced) {
    if (item.qty_sourced > 0) {
      min = item.qty_sourced;
    } else {
      max = item.qty_sourced;
    }
  }

  return (
    <ClosableDrawer open={open} onClose={onClose} title={title} icons={actions}>
      <Form form={form} onSubmit={onSubmit}>
        {item?.qty_sourced ? (
          <Alert severity="warning" sx={{ mt: -1, mb: 1 }}>
            This item has been purchased. Therefore, only some fields are editable.
          </Alert>
        ) : null}

        <VariantSection handleCustomChange={handleCustomChange} isCustom={isCustom} />

        <PriceSection minQty={min} maxQty={max} />

        <VendorSection isCustom={isCustom} />

        <BehaviorSection />

        <FormErrors form={form} />

        <DrawerButtons>
          <ResetButton />
          <LoadingButton type="submit" variant="contained" loading={form.formState.isSubmitting}>
            Save
          </LoadingButton>
        </DrawerButtons>
      </Form>
    </ClosableDrawer>
  );
}
