import { Button, Typography } from '@mui/material';
import axios from 'axios';
import { z } from 'zod';
import FieldFactory from '@/classes/FieldFactory';
import useDialogs from '@/utils/hooks/useDialogs';
import useTitle from '@/utils/hooks/useTitle';
import useToast from '@/utils/hooks/useToast';

export default function SetupGmail() {
  useTitle('Setup Gmail');
  const { prompt } = useDialogs();
  const toast = useToast();

  const onEdit = () => {
    prompt({
      title: 'Enter Gmail Password',
      fields: [
        FieldFactory.text('gmail_password').withInputProps({
          type: 'password',
        }),
      ],
      schema: z.object({
        gmail_password: z.string(),
      }),
      onSubmit: (v) => axios.put('/api/me', v),
    }).then(() => {
      toast('Successfully updated gmail password');
    });
  };

  return (
    <div>
      <Typography variant="subtitle1" gutterBottom>
        Configure Avail to send from your Gmail account.
      </Typography>
      <Button type="button" onClick={onEdit} variant="contained">
        Enter Gmail Password
      </Button>
    </div>
  );
}
