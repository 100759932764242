import { Dispatch, SetStateAction, SyntheticEvent } from 'react';
import { Button, Checkbox, FormControlLabel, MenuItem, Select } from '@mui/material';
import AddressTitle from '@/components/Addresses/AddressTitle';
import DrawerFormSection from '@/components/Form/DrawerFormSection';
import ChooseAccount from '@/components/Shipping/ChooseAccount';
import ChooseServiceLevel from '@/components/Shipping/ChooseServiceLevel';
import CustomsDeclarationBuilder from '@/components/Shipping/CustomsDeclarationBuilder';
import ParcelFields from '@/components/Shipping/ParcelFields';
import { ShipmentQuotePayload, ShippingDefaultsResponse, ThirdPartyShippingAccount } from '@/types';
import getValueFromEvent from '@/utils/getValueFromEvent';

export default function QuoteShipmentForm({
  defaults,
  values,
  errors,
  setValues,
  onSubmit,
  thirdPartyAccounts,
}: {
  defaults: ShippingDefaultsResponse;
  values: ShipmentQuotePayload;
  setValues: Dispatch<SetStateAction<ShipmentQuotePayload | undefined>>;
  errors: Record<string, any>;
  onSubmit: () => void;
  thirdPartyAccounts: ThirdPartyShippingAccount[];
}) {
  const handleChange = (key: keyof ShipmentQuotePayload) => (e: any) => {
    const value = getValueFromEvent(e);
    setValues((prev) => {
      if (!prev) return undefined;
      return { ...prev, [key]: value };
    });
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit();
  };

  return (
    <form onSubmit={handleSubmit}>
      <DrawerFormSection title="Ship From">
        {defaults.from_address_options.length === 1 ? (
          <AddressTitle address={defaults.from_address} hideMethod />
        ) : (
          <Select
            value={String(values.from_address_id)}
            onChange={handleChange('from_address_id')}
            fullWidth
            variant="standard"
          >
            {defaults.from_address_options.map((a) => (
              <MenuItem key={a.id} value={String(a.id)}>
                <AddressTitle address={a} hideMethod />
              </MenuItem>
            ))}
          </Select>
        )}
      </DrawerFormSection>

      <DrawerFormSection title="Ship To">
        <AddressTitle address={defaults.address} />
      </DrawerFormSection>

      <FormControlLabel
        control={
          <Checkbox
            size="small"
            checked={values.require_signature === true}
            onChange={handleChange('require_signature')}
          />
        }
        label="Requires Signature Confirmation"
      />
      <ChooseAccount
        accountId={values.third_party_account_id || null}
        onChange={handleChange('third_party_account_id')}
        accounts={thirdPartyAccounts}
      />
      <ChooseServiceLevel
        serviceLevel={values.service_level || null}
        onChange={handleChange('service_level')}
      />
      <CustomsDeclarationBuilder
        customs={values.customs || null}
        onChange={handleChange('customs')}
      />
      <ParcelFields parcels={values.parcels} errors={errors} onChange={handleChange('parcels')} />

      <Button type="submit" variant="contained" sx={{ mt: 3 }}>
        Get Rates
      </Button>
    </form>
  );
}
