import { useEffect } from 'react';
import { Delete } from '@mui/icons-material';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Button,
  IconButton,
  TableContainer,
} from '@mui/material';
import sumBy from 'lodash/sumBy';
import { UseControllerReturn, useFormContext } from 'react-hook-form';
import { FieldFactory } from '@/classes';
import { NonFormField } from '@/components/Form/FormField';
import { TransactionItemPayload, TransactionPayload } from '@/types';
import curr from '@/utils/curr';
import getValueFromEvent from '@/utils/getValueFromEvent';
import wrap from '@/utils/wrap';

export default function AccountItems({ field }: UseControllerReturn<TransactionPayload, 'items'>) {
  const items = wrap(field.value) as TransactionItemPayload[];
  const onChange = (value: TransactionItemPayload[]) => field.onChange(value);

  const form = useFormContext<TransactionPayload>();

  const vendor = form.watch('vendor');

  const onAppend = (value: TransactionItemPayload) => {
    onChange([...items, value]);
  };

  const onRemove = (index: number) => {
    onChange(items.filter((_, i) => i !== index));
  };

  const handleRowChange = (index: number, key: keyof TransactionItemPayload, e: any) => {
    const value = getValueFromEvent(e);
    onChange(items.map((item, i) => (i === index ? { ...item, [key]: value } : item)));
  };

  const onAdd = () =>
    onAppend({
      id: Math.min(0, ...items.map((i) => i.id!)) - 1,
      // @ts-expect-error Can't default transactable to non-null
      transactable: null,
      description: '',
      amount: 0,
    });

  useEffect(() => {
    if (form.formState.isDirty && vendor?.default_accounts && vendor.default_accounts.length > 0) {
      onChange(
        vendor.default_accounts.map((account, index) => ({
          id: index * -1,
          transactable: account,
          description: '',
          amount: 0,
        })),
      );
    } else if (items.length === 0) {
      onAdd();
    }
  }, [vendor]);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Account</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, i) => (
            <TableRow key={item.id}>
              <TableCell style={{ minWidth: 250, paddingTop: 0, paddingBottom: 4 }}>
                <NonFormField
                  field={FieldFactory.belongsTo('transactable', 'accounts').withoutLabel()}
                  value={item.transactable}
                  onChange={(e) => handleRowChange(i, 'transactable', e)}
                />
              </TableCell>
              <TableCell style={{ minWidth: 150 }}>
                <NonFormField
                  field={FieldFactory.text('description').withoutLabel()}
                  value={item.description}
                  onChange={(e) => handleRowChange(i, 'description', e)}
                />
              </TableCell>
              <TableCell style={{ minWidth: 150 }}>
                <NonFormField
                  field={FieldFactory.curr('amount').withoutLabel()}
                  value={item.amount}
                  onChange={(e) => handleRowChange(i, 'amount', e)}
                />
              </TableCell>
              <TableCell>
                {i > 0 && (
                  <IconButton onClick={() => onRemove(i)} size="large">
                    <Delete fontSize="small" />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell>
              <Button onClick={() => onAppend({} as TransactionItemPayload)}>Add Item</Button>
            </TableCell>
            <TableCell style={{ textAlign: 'right' }}>Total</TableCell>
            <TableCell>
              <b>{curr(sumBy(items, (i) => Number(i.amount || 0)))}</b>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
