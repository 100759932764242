import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Fab, styled, Tooltip } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { getOtherRowContext } from '@/utils/hooks/usePushWithContext';
import useSearchParamsObject from '@/utils/hooks/useSearchParamsObject';
import { addSearchParams } from '@/utils/query';

const LeftFab = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  left: 8,
  bottom: 8,
  zIndex: 50,
  [theme.breakpoints.up('md')]: {
    left: 8 + theme.drawerWidth,
  },
})) as typeof Fab;

const RightFab = styled(Fab)({
  position: 'fixed',
  right: 8,
  bottom: 8,
  zIndex: 50,
}) as typeof Fab;

export default function PrevNextNavigation() {
  const { state: locationState, pathname } = useLocation();
  const [searchParams] = useSearchParamsObject();
  const { tab, ...otherQueryParams } = searchParams;
  const searchParamsWithoutTab = new URLSearchParams(otherQueryParams);
  let nextResource;
  let prevResource;
  if (locationState?.context) {
    const otherRows = getOtherRowContext(locationState.context);
    const currentIndex = otherRows.findIndex((row) => {
      if (row.href.includes('?')) {
        const [rowPathname, rowSearch] = row.href.split('?');
        const rowSearchParams = new URLSearchParams(rowSearch);
        return (
          rowPathname === pathname &&
          rowSearchParams.toString() === searchParamsWithoutTab.toString()
        );
      }
      return row.href === pathname;
    });

    if (currentIndex > -1) {
      prevResource = currentIndex > 0 ? otherRows[currentIndex - 1] : null;
      nextResource = otherRows[currentIndex + 1];
    }
  }

  return (
    <>
      {prevResource && (
        <Tooltip title={prevResource.label}>
          <LeftFab
            component={Link}
            to={addSearchParams(prevResource.href, { tab })}
            state={locationState}
            size="small"
          >
            <ChevronLeft />
          </LeftFab>
        </Tooltip>
      )}

      {nextResource && (
        <Tooltip title={nextResource.label}>
          <RightFab
            component={Link}
            to={addSearchParams(nextResource.href, { tab })}
            state={locationState}
            size="small"
          >
            <ChevronRight />
          </RightFab>
        </Tooltip>
      )}
    </>
  );
}
