import { SyntheticEvent, useState } from 'react';
import { Close } from '@mui/icons-material';
import ListIcon from '@mui/icons-material/List';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import startCase from 'lodash/startCase';
import { z } from 'zod';
import { FieldFactory } from '@/classes';
import TextLink from '@/components/Text/TextLink';
import POMatchingDrawer from '@/components/Transactions/POMatchingDrawer';
import { Transaction } from '@/types';
import { genericModelReferenceSchema } from '@/types';
import curr from '@/utils/curr';
import useDialogs from '@/utils/hooks/useDialogs';

export default function PurchaseOrderMatchingCard({
  poId,
  vendorId,
}: {
  poId: number;
  vendorId: number;
}) {
  const { confirm, prompt } = useDialogs();
  const [open, setOpen] = useState(false);
  const {
    data: transactions,
    isFetching,
    refetch,
  } = useQuery(['poMatchedTransactions', poId], () =>
    axios
      .get<{ data: Transaction[] }>(`/api/purchase-orders/${poId}/transactions`)
      .then(({ data }) => data.data),
  );

  const onLink = () => {
    prompt({
      title: 'Link Transaction',
      fields: [
        FieldFactory.belongsTo('transaction', 'transactions').withRequestParams({
          'filter[purchase_order_id][null]': 1,
          'filter[vendor_id]': vendorId,
          'filter[type]': 'bill',
        }),
      ],
      schema: z.object({
        transaction: genericModelReferenceSchema,
      }),
      onSubmit: (v) => axios.post(`/api/purchase-orders/${poId}/transactions/${v.transaction.id}`),
    }).then(() => {
      refetch();
    });
  };

  const handleRemove = (trans: Transaction) => (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    confirm({ title: 'Unlink Transaction', description: 'Are you sure?' }).then(() => {
      axios.delete(`/api/purchase-orders/${poId}/transactions/${trans.id}`).then(() => refetch());
    });
  };

  return (
    <Card sx={{ mb: 2 }}>
      <CardHeader
        title="PO Matching"
        action={
          <IconButton onClick={() => setOpen(true)}>
            <ListIcon />
          </IconButton>
        }
      />
      {isFetching && <LinearProgress />}
      {transactions?.length === 0 && (
        <CardContent>
          <Typography variant="body2" color="textSecondary">
            There are no bills linked with this PO.
          </Typography>
        </CardContent>
      )}
      {transactions && transactions.length > 0 && (
        <List>
          {transactions.map((trans) => (
            <ListItem key={trans.id}>
              <ListItemText
                primary={
                  <TextLink to={`/transactions/${trans.id}`}>
                    {'vendor' in trans && trans.vendor && <span>{trans.vendor.name} </span>}
                    <span>{startCase(trans.type)} </span>
                    <span>#{trans.ref_number || trans.id}</span>
                  </TextLink>
                }
                secondary={curr(trans.total)}
              />
              <ListItemSecondaryAction>
                <IconButton onClick={handleRemove(trans)}>
                  <Close fontSize="small" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
      <CardActions>
        <Button color="secondary" size="small" onClick={onLink}>
          Link Transaction
        </Button>
      </CardActions>
      <POMatchingDrawer open={open} onClose={() => setOpen(false)} purchaseOrderId={poId} />
    </Card>
  );
}
