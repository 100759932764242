import { useState } from 'react';
import { Search, Edit } from '@mui/icons-material';
import { IconButton, Tooltip, Typography } from '@mui/material';
import omit from 'lodash/omit';
import { UseControllerReturn } from 'react-hook-form';
import { Field } from '@/classes';
import AddressBlock from '@/components/Addresses/AddressBlock';
import { FieldProps, Address, AddressPayload } from '@/types';
import AddressDrawer from './AddressDrawer';
import GenericAddressChooser from './GenericAddressChooser';

function GenericAddressFieldComponent({ field, fieldModel }: FieldProps<GenericAddressField>) {
  const address = field.value as Address | null;

  const [isChoosing, setIsChoosing] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const excludeFields = ['is_billing', 'is_taxable_address'];
  if (!fieldModel.showMethod) {
    excludeFields.push('method');
  }
  return (
    <div
      style={{
        display: 'flex',
        padding: '8px 0',
        minHeight: 56,
        alignItems: 'center',
      }}
    >
      {address ? (
        <AddressBlock address={address} showMethod={fieldModel.showMethod} />
      ) : (
        <Typography color="textSecondary">No Address</Typography>
      )}
      <div style={{ marginLeft: 'auto' }}>
        <Tooltip title="Edit Address">
          <IconButton onClick={() => setIsEditing(true)} size="large">
            <Edit fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Choose Address">
          <IconButton onClick={() => setIsChoosing(true)} size="large">
            <Search fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>
      <GenericAddressChooser
        open={isChoosing}
        orderIds={fieldModel.orderIds}
        showMethod={fieldModel.showMethod}
        onClose={() => setIsChoosing(false)}
        onChoose={(a) => {
          setIsChoosing(false);
          field.onChange(omit(a, 'id'));
        }}
      />
      <AddressDrawer
        open={isEditing}
        onClose={() => setIsEditing(false)}
        initialValues={address || {}}
        onSubmit={(a: AddressPayload) => {
          setIsEditing(false);
          field.onChange(a);
          return Promise.resolve();
        }}
        excludeFields={excludeFields}
      />
    </div>
  );
}

export default class GenericAddressField extends Field {
  orderIds?: number[];
  showMethod?: boolean;

  constructor(name: string, orderIds?: number[], showMethod?: boolean) {
    super(name);
    this.orderIds = orderIds;
    this.showMethod = showMethod;
  }

  renderEditComponent(props: UseControllerReturn) {
    return <GenericAddressFieldComponent {...props} fieldModel={this} />;
  }
}
