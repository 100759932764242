import { useEffect } from 'react';
import { Button } from '@mui/material';
import { FieldValues, useForm } from 'react-hook-form';
import { Field, StackLayout } from '@/classes';
import DrawerButtons from '@/components/Form/DrawerButtons';
import EditFormFields from '@/components/Form/EditFormFields';
import Form from '@/components/Form/Form';
import ClosableDrawer from '@/components/Shared/ClosableDrawer';
import { getInitialValues } from '@/utils/form';

export default function FilterDrawer({
  filterableFields,
  onSuccess,
  open,
  onClose,
  initialValues,
}: {
  filterableFields: Field[];
  onSuccess: (v: FieldValues) => void;
  open: boolean;
  onClose: () => void;
  initialValues?: FieldValues;
}) {
  const form = useForm();

  useEffect(() => {
    if (open) {
      form.reset(getInitialValues(filterableFields, initialValues || {}));
    }
  }, [open]);

  const onSubmit = (values: FieldValues) => {
    onSuccess(values);
    onClose();
  };

  return (
    <ClosableDrawer title="Filters" open={open} onClose={onClose}>
      <Form form={form} onSubmit={onSubmit}>
        <EditFormFields
          fields={filterableFields.map((f) => f.getFilterField())}
          defaultLayout={StackLayout}
        />

        <DrawerButtons>
          <Button type="button" onClick={() => form.reset(getInitialValues(filterableFields, {}))}>
            Reset
          </Button>

          <Button type="submit" variant="contained">
            Apply
          </Button>
        </DrawerButtons>
      </Form>
    </ClosableDrawer>
  );
}
