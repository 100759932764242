import { ReactNode, useEffect } from 'react';
import { Card, CardContent, CardHeader, LinearProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import { z } from 'zod';
import { FieldFactory } from '@/classes';
import AttachmentDropzone from '@/components/Attachments/AttachmentDropzone';
import { Attachment } from '@/types';
import { UploadedFile, uploadedFileToPayload } from '@/utils/attachments';
import useDialogs from '@/utils/hooks/useDialogs';
import useMutateQueryData from '@/utils/hooks/useMutateQueryData';
import AttachmentsList from './AttachmentsList';

export default function AttachmentsCard({
  resource,
  resourceId,
  title,
  action,
  group,
  compact,
  elevation,
  setAttachmentsCount,
}: {
  resource: string;
  resourceId: number | string;
  title?: ReactNode;
  action?: ReactNode;
  group?: string;
  compact?: boolean;
  elevation?: number;
  setAttachmentsCount?: (c: number) => void;
}) {
  const { prompt, confirm } = useDialogs();
  const endpoint = `/api/${resource}/${resourceId}/attachments`;

  const params = pickBy({ group });
  const QUERY_KEY = [endpoint, group];
  const { data: attachments, isFetching } = useQuery(QUERY_KEY, () =>
    axios.get<{ data: Attachment[] }>(endpoint, { params }).then(({ data }) => data.data),
  );
  const attachmentsCount = attachments?.length;
  const setAttachments = useMutateQueryData<Attachment[]>(QUERY_KEY);

  useEffect(() => {
    if (setAttachmentsCount && typeof attachmentsCount === 'number') {
      setAttachmentsCount(attachmentsCount);
    }
  }, [attachmentsCount]);

  const onFiles = async (files: UploadedFile[]) => {
    try {
      const promises = files.map(async (file) => {
        const { data } = await axios.post(endpoint, uploadedFileToPayload(file, group));
        setAttachments((prev) => [...prev, data]);
      });

      await Promise.all(promises);
    } catch (e) {
      console.warn(e);
    }
  };

  const onRemove = (attachment: Attachment) => {
    confirm({
      title: 'Delete Attachment',
      description: 'Are you sure you want to delete this attachment?',
    }).then(() => {
      axios.delete(`${endpoint}/${attachment.id}`).then(() => {
        setAttachments((prev) => prev.filter((a) => a.id !== attachment.id));
      });
    });
  };

  const onEdit = (attachment: Attachment) => {
    prompt({
      title: 'Edit Attachment Name',
      fields: [FieldFactory.text('name')],
      initialValues: pick(attachment, 'name'),
      schema: z.object({
        name: z.string().min(1),
      }),
      onSubmit: (v) => axios.put(`${endpoint}/${attachment.id}`, v),
    }).then(({ data }) => {
      setAttachments((prev) =>
        prev.map((a) => {
          if (a.id === attachment.id) {
            return data;
          }
          return a;
        }),
      );
    });
  };

  return (
    <Card sx={{ mb: 2 }} elevation={elevation}>
      {title && <CardHeader title={title} action={action} />}

      {isFetching && <LinearProgress />}

      {attachments && attachments.length > 0 ? (
        <AttachmentsList
          compact={compact}
          attachments={attachments}
          onEdit={onEdit}
          onRemove={onRemove}
        />
      ) : null}

      <CardContent>
        <AttachmentDropzone onFiles={onFiles} />
      </CardContent>
    </Card>
  );
}
