import { useState } from 'react';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { UseControllerReturn } from 'react-hook-form';
import FormField from '@/components/Form/FormField';
import { FieldProps } from '@/types';
import useOnChangeEffect from '@/utils/hooks/useOnChangeEffect';
import Field from '../Field';

function UseDefault({ field, fieldModel }: FieldProps<UseDefaultField>) {
  const [useDefault, setUseDefault] = useState(!field.value);

  const handleUseDefaultChange = (bool: boolean) => {
    setUseDefault(bool);
    if (bool) {
      field.onChange(null);
    } else if (!field.value && fieldModel.defaultValue) {
      field.onChange(fieldModel.defaultValue);
    }
  };

  useOnChangeEffect(() => {
    setUseDefault(!field.value);
  }, [field.value]);

  return (
    <div>
      {!useDefault && <FormField field={fieldModel.subField} />}

      <FormControlLabel
        label={
          <Typography variant={fieldModel.size === 'small' ? 'body2' : 'body1'}>
            {fieldModel.useDefaultLabel}
          </Typography>
        }
        control={
          <Checkbox
            size={fieldModel.size}
            checked={useDefault}
            onChange={(e) => handleUseDefaultChange(e.target.checked)}
          />
        }
      />
    </div>
  );
}

export default class UseDefaultField extends Field {
  subField: Field;
  useDefaultLabel = 'Use Default';
  defaultValue?: any;

  constructor(name: string, subField: Field) {
    super(name);
    this.subField = subField;
  }

  renderEditComponent(props: UseControllerReturn) {
    return <UseDefault {...props} fieldModel={this} />;
  }

  renderCell(value: any, row: Record<string, any>) {
    return this.subField.renderCell(value, row);
  }
}
