import { Edit } from '@mui/icons-material';
import { Box, CardHeader, Grid2 as Grid, IconButton, Tooltip, Typography } from '@mui/material';
import axios from 'axios';
import omit from 'lodash/omit';
import Can from '@/components/Permissions/Can';
import CardWithGutter from '@/components/Shared/CardWithGutter';
import { InventoryLocation } from '@/types';
import { useOnReloadRecord, useRecord } from '@/utils/genericResource';
import useDialogs from '@/utils/hooks/useDialogs';
import useGetResource from '@/utils/hooks/useGetResource';
import InventoryLevels from './InventoryLevels';

export default function InventoryLocationPage() {
  const location = useRecord<InventoryLocation>();
  const getResource = useGetResource();
  const { prompt } = useDialogs();
  const onReload = useOnReloadRecord();

  const onEdit = () => {
    prompt({
      title: 'Edit Location',
      fields: getResource('inventoryLocations').fields,
      schema: getResource('inventoryLocations').schema,
      initialValues: omit(location, 'id'),
      onSubmit: (v) => axios.put(`/api/inventory-locations/${location.id}`, v),
    }).then(() => {
      onReload();
    });
  };

  const action = (
    <Can permission="write:inventory_locations">
      <Tooltip title="Edit Location Details">
        <IconButton onClick={onEdit} size="large">
          <Edit />
        </IconButton>
      </Tooltip>
    </Can>
  );

  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12, md: 4 }}>
        <CardWithGutter>
          <CardHeader title={location.path} action={action} />
          <Box px={2} pb={2} mt={-1}>
            {location.location_group && (
              <Typography gutterBottom>Group: {location.location_group.name}</Typography>
            )}
            {location.description && (
              <Typography gutterBottom variant="body2">
                {location.description}
              </Typography>
            )}
          </Box>
        </CardWithGutter>
      </Grid>
      <Grid size={{ xs: 12, md: 8 }}>
        <CardWithGutter>
          <CardHeader title="SKUs in Location" />
          <InventoryLevels
            filterKey="location_id"
            filterValue={location.id}
            label={location.path}
          />
        </CardWithGutter>
      </Grid>
    </Grid>
  );
}
