import { useMemo, useState } from 'react';
import { Edit, LocalOffer, MergeType } from '@mui/icons-material';
import {
  Avatar,
  Box,
  CardContent,
  CardHeader,
  Chip,
  Grid2 as Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import axios from 'axios';
import pick from 'lodash/pick';
import { Link } from 'react-router-dom';
import { useGetVariantsForProduct } from '@/api/variants';
import { ButtonAction, FieldFactory, Resource } from '@/classes';
import DataTable from '@/components/DataTable/DataTable';
import CostLayerDrawer from '@/components/Inventory/CostLayerDrawer';
import InventoryLevels from '@/components/Inventory/InventoryLevels';
import InventoryTotalsCard from '@/components/Inventory/InventoryTotalsCard';
import Can from '@/components/Permissions/Can';
import CardWithGutter from '@/components/Shared/CardWithGutter';
import TextLink from '@/components/Text/TextLink';
import { InventoryEntry, productVariantPayloadSchema } from '@/types';
import curr from '@/utils/curr';
import { useOnReloadRecord, useRecord } from '@/utils/genericResource';
import { useHasPermission } from '@/utils/hooks/permissions';
import useDialogs from '@/utils/hooks/useDialogs';
import { OnClickProps } from '@/utils/hooks/useOnClickProps';
import InventoryLocationLabel from './InventoryLocationLabel';

export default function SkuPage() {
  const { prompt } = useDialogs();
  const record = useRecord('inventoryVariants');
  const onReload = useOnReloadRecord('inventoryVariants');
  const {
    id,
    sku,
    product,
    upc,
    gtin,
    size,
    inventory_totals: totals,
    weight,
    price,
    cost,
    value,
  } = record;
  const hasPermission = useHasPermission();

  const onEdit = () => {
    prompt({
      title: 'Update SKU',
      fields: [
        FieldFactory.number('weight').withInputProps({ step: 0.01 }),
        FieldFactory.curr('value'),
        FieldFactory.text('customs_descriptor'),
        FieldFactory.number('alert_level'),
        FieldFactory.number('par_level'),
      ],
      schema: productVariantPayloadSchema.pick({
        weight: true,
        value: true,
        customs_descriptor: true,
        alert_level: true,
        par_level: true,
      }),
      initialValues: pick(record, [
        'weight',
        'value',
        'customs_descriptor',
        'alert_level',
        'par_level',
      ]),
      onSubmit: (v) => axios.put(`/api/variants/${id}`, v),
    }).then(() => {
      onReload();
    });
  };

  const [viewingCostLayers, setViewingCostLayers] = useState(false);

  const { data: sizes = [] } = useGetVariantsForProduct(product.id);
  const otherSizes = sizes.filter((s) => s.id !== id);

  const resource = useMemo(() => {
    const onCombine = ({ selected, dialogs, onReloadTable }: OnClickProps) => {
      dialogs
        .confirm({ title: 'Combine Inventory Entries', description: 'Are you sure?' })
        .then(() => {
          axios.post(`/api/variants/${id}/inventory-entries/group`, { ids: selected }).then(() => {
            onReloadTable();
          });
        });
    };

    return new Resource<InventoryEntry>('Inventory Entries')
      .withApiEndpoint(`/api/variants/${id}/inventory-entries`)
      .withBulkActions([new ButtonAction('Combine', onCombine, MergeType)])
      .setDeletable(false)
      .withDefaultSort('-created_at')
      .withColumns([
        FieldFactory.timestamp('created_at').withLabel('Date'),
        FieldFactory.belongsTo('start_location', 'inventoryLocations')
          .sortable('start_location.path')
          .renderCellUsing((l) => <InventoryLocationLabel location={l} />),
        FieldFactory.belongsTo('end_location', 'inventoryLocations')
          .sortable('end_location.path')
          .renderCellUsing((l) => <InventoryLocationLabel location={l} />),
        FieldFactory.curr('variant_cost').withLabel('Cost').sortable(),
        FieldFactory.number('qty').sortable(),
        FieldFactory.text('description'),
        FieldFactory.number('balance').sortable(),
        FieldFactory.text('id').withLabel('ID'),
      ]);
  }, [id]);

  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12, md: 4 }}>
        <CardWithGutter>
          <CardContent>
            <Box display="flex" mb={2}>
              <Avatar
                src={product.image || undefined}
                style={{ height: 130, width: 100 }}
                variant="rounded"
              >
                <LocalOffer />
              </Avatar>
              <Box ml={2} flexGrow={1}>
                <Box display="flex" justifyContent="space-between">
                  <div>
                    <Typography variant="h6">{sku}</Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      <TextLink
                        to={`/products/${product.id}`}
                        disabled={!hasPermission('write:products')}
                      >
                        {product.name}
                      </TextLink>
                    </Typography>
                  </div>
                  <div>
                    <Can permission="write:products">
                      <IconButton onClick={onEdit}>
                        <Edit />
                      </IconButton>
                    </Can>
                  </div>
                </Box>
                <Typography variant="body2">Size: {size}</Typography>
              </Box>
            </Box>
          </CardContent>

          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell variant="head">Vendor</TableCell>
                <TableCell>{product.vendor.name}</TableCell>
              </TableRow>
              {product.customer && (
                <TableRow>
                  <TableCell variant="head">Customer</TableCell>
                  <TableCell>{product.customer.name}</TableCell>
                </TableRow>
              )}
              {product.business && (
                <TableRow>
                  <TableCell variant="head">Business</TableCell>
                  <TableCell>{product.business.name}</TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell variant="head">MSRP</TableCell>
                <TableCell>{curr(price)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Value</TableCell>
                <TableCell>{value != null ? curr(value) : '-'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Cost</TableCell>
                <TableCell>{curr(cost)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Weight</TableCell>
                <TableCell>{weight ? `${weight} lbs` : null}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">UPC</TableCell>
                <TableCell>{upc}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">GTIN</TableCell>
                <TableCell>{gtin}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardWithGutter>

        {totals && (
          <InventoryTotalsCard
            variantId={id}
            totals={totals}
            onViewCostLayers={() => setViewingCostLayers(true)}
          />
        )}

        {otherSizes?.length > 0 && (
          <CardWithGutter>
            <CardHeader title="Other Sizes" />
            <List dense disablePadding>
              {otherSizes.map((variant) => (
                <ListItemButton
                  key={variant.id}
                  component={Link}
                  to={`/inventory-variants/${variant.id}`}
                >
                  <ListItemText primary={variant.sku} secondary={`Size: ${variant.size}`} />
                  <ListItemSecondaryAction>
                    <Chip label={variant.inventory_qty || '0'} />
                  </ListItemSecondaryAction>
                </ListItemButton>
              ))}
            </List>
          </CardWithGutter>
        )}
      </Grid>
      <Grid size={{ xs: 12, md: 8 }}>
        <CardWithGutter>
          <CardHeader title="Inventory Locations" />
          <InventoryLevels filterKey="variant_id" filterValue={id} label={sku} />
        </CardWithGutter>

        <Can permission="read:inventory_adjustments">
          <CardWithGutter>
            <CardHeader title="Inventory History" />
            <DataTable resource={resource} />
          </CardWithGutter>
        </Can>
      </Grid>
      <CostLayerDrawer
        open={viewingCostLayers}
        onClose={() => setViewingCostLayers(false)}
        variantId={id}
      />
    </Grid>
  );
}
