import { Card, CardHeader } from '@mui/material';
import { useGetKitsForOrder } from '@/api/packout';
import KitsTable from '@/components/Kitting/KitsTable';
import { useRecord } from '@/utils/genericResource';

export default function PackoutCompleteStatus() {
  const { order } = useRecord('orderPackouts', true);

  const query = useGetKitsForOrder(order.id);

  return (
    <Card>
      <CardHeader title="Kits in Order" titleTypographyProps={{ variant: 'h6' }} />

      <KitsTable
        kitsQuery={query}
        columns={[]}
        columnVisibility={{
          group: false,
          email: false,
        }}
        filename={`kits-${order.increment_id.toLowerCase()}`}
      />
    </Card>
  );
}
