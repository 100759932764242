import { useRef, useState } from 'react';
import { Box } from '@mui/material';
import get from 'lodash/get';
import { UseControllerReturn } from 'react-hook-form';
import { Resource } from '@/classes';
import CreateButton from '@/components/Buttons/CreateButton';
import { DataTableHandle, default as DataTable } from '@/components/DataTable/DataTable';
import EditForm from '@/components/Form/EditForm';
import ClosableDrawer from '@/components/Shared/ClosableDrawer';
import { FieldProps, ResourceName, ResourceValueMap } from '@/types';
import { useRecord } from '@/utils/genericResource';
import { useResource } from '@/utils/hooks/useGetResource';
import Field from '../Field';
import StackLayout from '../Layouts/StackLayout';
import { RelationCell } from './RelationField';

function RelationTable<K extends ResourceName, V extends ResourceValueMap[K]>({
  fieldModel,
}: FieldProps<RelationTableField<K>>) {
  const record = useRecord();
  const resource = useResource(fieldModel.resource) as Resource<V>;

  if (fieldModel.getApiEndpointFunc) {
    resource.apiEndpoint = fieldModel.getApiEndpointFunc(record);
  }
  if (fieldModel.getQueryParamsFunc) {
    Object.assign(resource.queryParams.index, fieldModel.getQueryParamsFunc(record));
  }

  const [editing, setEditing] = useState<{ mode: 'edit' | 'create'; values: object } | undefined>();
  const dataTableRef = useRef<DataTableHandle | null>(null);

  const onCreate = () =>
    setEditing({
      mode: 'create',
      values: resource.defaultValues,
    });

  const onEdit = (values: object) =>
    resource.editable
      ? setEditing({
          mode: 'edit',
          values,
        })
      : null;

  const onDoneEditing = () => {
    setEditing(undefined);
    dataTableRef.current?.onReload();
  };

  const isCreate = get(editing, 'mode') === 'create';

  const drawer = (
    <ClosableDrawer
      open={!!editing}
      onClose={() => setEditing(undefined)}
      title={`${isCreate ? 'Create' : 'Edit'} ${resource.singularName}`}
    >
      {resource && (
        <EditForm
          onSuccess={onDoneEditing}
          initialValues={get(editing, 'values', {})}
          isCreate={isCreate}
          resource={resource}
          defaultLayout={StackLayout}
        />
      )}
    </ClosableDrawer>
  );

  return (
    <Box mt={-1} position="relative" pb={4}>
      {resource.creatable && (
        <CreateButton onClick={onCreate} size="small" position="absolute" margin={1} />
      )}

      <DataTable resource={resource} onEdit={onEdit} ref={dataTableRef} />

      {drawer}
    </Box>
  );
}

export default class RelationTableField<K extends ResourceName> extends Field {
  resource: K;
  getApiEndpointFunc?: (record: any) => string;
  getQueryParamsFunc?: (record: any) => Record<string, string>;

  constructor(name: string, resource: K, getApiEndpointFunc?: (record: any) => string) {
    super(name);
    this.resource = resource;
    this.getApiEndpointFunc = getApiEndpointFunc;
  }

  getApiEndpointUsing(func: RelationTableField<K>['getApiEndpointFunc']) {
    this.getApiEndpointFunc = func;
    return this;
  }

  getQueryParamsUsing(func: RelationTableField<K>['getQueryParamsFunc']) {
    this.getQueryParamsFunc = func;
    return this;
  }

  renderEditComponent(props: UseControllerReturn) {
    return <RelationTable {...props} fieldModel={this} />;
  }

  renderCell(value: any) {
    return <RelationCell value={value} fieldModel={this} />;
  }
}
