import { ChangeEvent, CSSProperties, useMemo, useState } from 'react';
import { PlaylistAddCheck, Refresh, Search } from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  IconButton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import filter from 'lodash/filter';
import get from 'lodash/get';
import reject from 'lodash/reject';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import { v4 as uuid } from 'uuid';
import AddressTitle from '@/components/Addresses/AddressTitle';
import BatchShippingDrawer from '@/components/BatchShipping/BatchShippingDrawer';
import DebouncedTextField from '@/components/Shared/DebouncedTextField';
import WarningIcon from '@/components/Shared/WarningIcon';
import ShipActions from '@/components/Shipping/ShipActions';
import { Address, Kit, ShipmentItemPayload } from '@/types';
import { AddressWithKits } from '@/types';
import numString from '@/utils/numString';
import searchCollection from '@/utils/searchCollection';

const toShip = (i: Kit) => !i.shipment_id;

export default function OrderShippingKit({
  orderId,
  customerId,
  addresses: allAddresses,
}: {
  orderId: number;
  customerId: number;
  addresses: AddressWithKits[];
}) {
  const [isBatchShipping, setIsBatchShipping] = useState(false);
  const [selected, setSelected] = useState<ShipmentItemPayload[]>([]);
  const [tab, setTab] = useState<'all' | 'toship' | 'shipped'>('all');
  const [selectingForAddress, setSelectingForAddress] = useState<number>();
  const [query, setQuery] = useState('');
  const [searching, setSearching] = useState(false);
  const queryClient = useQueryClient();

  const { addresses, addressesKey } = useMemo(() => {
    const results = allAddresses
      .map((address) => {
        let kits = address.kits.map((kit) => ({
          ...kit,
          address_name: address.name,
        }));
        if (tab === 'toship') {
          kits = filter(kits, toShip);
        }
        if (tab === 'shipped') {
          kits = reject(kits, toShip);
        }
        if (query) {
          kits = searchCollection(kits, query, ['number', 'name', 'address_name']);
        }
        return { ...address, kits };
      })
      .filter((a) => a.kits.length > 0);

    return { addresses: results, addressesKey: uuid() };
  }, [allAddresses, tab, query]);

  const handleCheck = (kit: Kit, address: Address) => (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      if (address.id === selectingForAddress) {
        setSelected([
          ...selected,
          {
            kit_id: kit.id,
            qty_shipped: 1,
          },
        ]);
      } else {
        setSelectingForAddress(address.id);
        setSelected([
          {
            kit_id: kit.id,
            qty_shipped: 1,
          },
        ]);
      }
    } else {
      setSelected(selected.filter((s) => s.kit_id !== kit.id));
    }
  };

  const handleCheckAll = (e: ChangeEvent<HTMLInputElement>, address: AddressWithKits) => {
    const addressId = get(address, 'id');
    if (selectingForAddress === addressId && selected.length > 0) {
      setSelected([]);
    } else {
      setSelected(
        address.kits.filter(toShip).map((k) => ({
          kit_id: k.id,
          qty_shipped: 1,
        })),
      );
    }
    setSelectingForAddress(addressId);
  };

  const refetch = () => {
    queryClient.invalidateQueries(['orderAddresses', orderId]);
  };

  const onShipSuccess = () => {
    refetch();
    setSelected([]);
    setSelectingForAddress(undefined);
  };

  const address = addresses.find((a) => a.id === selectingForAddress);

  const actions = (
    <div>
      {address && selected.length > 0 ? (
        <ShipActions
          payload={{
            shippable_type: 'order',
            shippable_id: orderId,
            address_id: address.id,
            items: selected,
          }}
          customerId={customerId}
          title={`Shipping ${numString(selected.length, 'Kits')}`}
          qty={selected.length}
          onSuccess={onShipSuccess}
          addressMethod={address.method}
        />
      ) : (
        <>
          {!searching ? (
            <IconButton onClick={() => setSearching(true)} size="large">
              <Search />
            </IconButton>
          ) : (
            <DebouncedTextField
              initialValue={query}
              onChange={setQuery}
              onBlur={() => setSearching(false)}
              autoFocus
              label="Search"
              size="small"
            />
          )}

          <IconButton onClick={refetch} size="large">
            <Refresh />
          </IconButton>
          <Tooltip title="Batch Ship">
            <IconButton onClick={() => setIsBatchShipping(true)} size="large">
              <PlaylistAddCheck />
            </IconButton>
          </Tooltip>
        </>
      )}
    </div>
  );

  const renderAddress = ({ index, style }: { index: number; style: CSSProperties }) => {
    const address = addresses[index];
    const hasGroup = address.kits.some((k) => !!k.group);
    return (
      <div style={style}>
        <CardContent>
          <AddressTitle address={address} />
        </CardContent>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  {address.kits.filter(toShip).length > 0 && (
                    <Checkbox
                      size="small"
                      onChange={(e) => handleCheckAll(e, address)}
                      checked={
                        selectingForAddress === address.id &&
                        selected.length === address.kits.filter(toShip).length
                      }
                      indeterminate={
                        selectingForAddress === address.id &&
                        selected.length > 0 &&
                        selected.length < address.kits.filter(toShip).length
                      }
                    />
                  )}
                </TableCell>
                <TableCell>Number</TableCell>
                <TableCell>Name</TableCell>
                {hasGroup && <TableCell>Group</TableCell>}
                <TableCell>Bin</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {address.kits.map((kit) => (
                <TableRow key={kit.id}>
                  <TableCell padding="checkbox">
                    {toShip(kit) && (
                      <Checkbox
                        size="small"
                        onChange={handleCheck(kit, address)}
                        checked={selected.some((s) => s.kit_id === kit.id)}
                      />
                    )}
                  </TableCell>
                  <TableCell style={{ width: 150 }}>{kit.number}</TableCell>
                  <TableCell style={{ width: 200 }}>{kit.name}</TableCell>
                  {hasGroup && <TableCell style={{ width: 200 }}>{kit.group}</TableCell>}
                  <TableCell style={{ width: 150 }}>
                    {kit.bin_string || <WarningIcon message="Not Packed Out" />}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  return (
    <Card>
      <CardHeader title="Kits to Ship" action={actions} />

      <Tabs onChange={(e, t) => setTab(t)} value={tab}>
        <Tab value="all" label="All" />
        <Tab value="toship" label="To Ship" />
        <Tab value="shipped" label="Shipped" />
      </Tabs>

      <div style={{ height: 600 }} key={addressesKey}>
        {addresses.length > 0 ? (
          <AutoSizer>
            {({ height, width }) => (
              <VariableSizeList
                height={Number(height)}
                width={Number(width)}
                itemSize={(index) => addresses[index].kits.length * 37 + 120}
                itemCount={addresses.length}
                itemKey={(index) => addresses[index].id}
              >
                {renderAddress}
              </VariableSizeList>
            )}
          </AutoSizer>
        ) : (
          <CardContent>
            <Typography variant="body2" color="textSecondary">
              No kits found
            </Typography>
          </CardContent>
        )}
      </div>

      <BatchShippingDrawer
        open={isBatchShipping}
        onClose={() => setIsBatchShipping(false)}
        orderId={orderId}
        customerId={customerId}
      />
    </Card>
  );
}
