import { useEffect, useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import pickBy from 'lodash/pickBy';
import { useFormContext } from 'react-hook-form';
import { FieldFactory } from '@/classes';
import FormField from '@/components/Form/FormField';
import { Payment } from '@/types';
import curr from '@/utils/curr';
import AmountToApplyField from './AmountToApplyField';
import CommissionHit from './CommissionHit';

export default function PaymentApplicationFields({
  paymentType,
  customerId,
  amountToApply,
}: {
  paymentType: Payment['payment_type'];
  customerId?: number | null;
  amountToApply: number;
}) {
  const [tab, setTab] = useState<'order' | 'account'>('order');
  const form = useFormContext<{
    order: { balance: number | null } | null;
    commission_hit: number | null;
    account: { id: number } | null;
  }>();
  const orderBalance = form.watch('order')?.balance;

  useEffect(() => {
    if (tab === 'account') {
      form.setValue('order', null);
      form.setValue('commission_hit', null);
    } else {
      form.setValue('account', null);
    }
  }, [tab]);

  return (
    <div>
      <Tabs value={tab} onChange={(e, t) => setTab(t)} sx={{ mb: 2 }}>
        <Tab value="order" label="Apply to Order" />
        <Tab value="account" label="Credit GL Account" />
      </Tabs>
      {tab === 'account' ? (
        <FormField
          key="account_field"
          field={FieldFactory.belongsTo('account', 'accounts').withLabel('Account to Credit')}
        />
      ) : (
        <FormField
          key="order_field"
          field={FieldFactory.belongsTo('order', 'orders')
            .withRequestParams(
              pickBy({
                has_balance: 1,
                'filter[customer_id]': customerId,
              }),
            )
            .with({
              getSubtitle: (v) => `Balance: ${curr(v.balance)}`,
            })}
        />
      )}
      <FormField field={new AmountToApplyField('amount', amountToApply, orderBalance || 0)} />
      {paymentType === 'deduction' && tab === 'order' && (
        <FormField field={FieldFactory.custom('commission_hit', CommissionHit)} />
      )}
    </div>
  );
}
