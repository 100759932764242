import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { amber, green } from '@mui/material/colors';
import pick from 'lodash/pick';
import moment from 'moment';
import { z } from 'zod';
import {
  useScheduleEvents,
  useUnscheduleProductionEvent,
  useUpdateProductionEvent,
} from '@/api/production';
import { ButtonAction, FieldFactory } from '@/classes';
import OrderLabel from '@/components/Orders/OrderLabel';
import MinutesText from '@/components/Shared/MinutesText';
import StatusChip from '@/components/Shared/StatusChip';
import SubMenu from '@/components/Shared/SubMenu';
import Text from '@/components/Text/Text';
import { PRODUCTION_EVENT_STATUS_COLORS } from '@/constants';
import {
  genericModelReferenceSchema,
  ProductionEvent,
  productionEventUpdatePayloadSchema,
} from '@/types';
import useDialogs from '@/utils/hooks/useDialogs';
import useGetResource from '@/utils/hooks/useGetResource';
import usePushWithContext from '@/utils/hooks/usePushWithContext';
import numString from '@/utils/numString';
import { maybeAddQueryParams } from '@/utils/query';
import ProductionDates from './CommittedDate';
import ScreenLocation from './ScreenLocation';

export const getPriorityColor = (priority: number) => {
  if (priority > 0) {
    return green[500];
  }
  if (priority < 0) {
    return amber[500];
  }
  return 'transparent';
};

export default function ProductionEventTable({ events }: { events: ProductionEvent[] }) {
  const hasScreenprintEvents = events.some((event) => event.event_type.unit === 'colors');
  const getResource = useGetResource();
  const { prompt, confirm } = useDialogs();

  const scheduleRequest = useScheduleEvents();
  const updateRequest = useUpdateProductionEvent();
  const unscheduleRequest = useUnscheduleProductionEvent();

  const onUnschedule = (e: ProductionEvent) => {
    confirm({
      title: 'Remove From Schedule',
      description: "Are you sure you want to remove this event from the day's schedule?",
    }).then(() => {
      unscheduleRequest.mutate(e.id);
    });
  };

  const push = usePushWithContext(
    events.map((e) => ({
      key: e.id,
      href: `/production/${e.order_id}?event_id=${e.id}`,
      label: getResource('productionEvents').getTitle(e),
    })),
  );

  const onSetPriority = (e: ProductionEvent) => {
    prompt({
      title: 'Set Priority',
      initialValues: pick(e, ['priority']),
      fields: [
        FieldFactory.select(
          'priority',
          [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((v) => ({
            label: v,
            value: v,
          })),
        ).withHelp('Larger numbers will show up first'),
      ],
      schema: productionEventUpdatePayloadSchema.pick({ priority: true }),
      onSubmit: (v) => updateRequest.mutateAsync({ id: e.id, ...v }),
    });
  };

  const onUpdateEvent = (e: ProductionEvent) => {
    prompt({
      title: 'Schedule Event',
      initialValues: {
        machine: e.machine || undefined,
        scheduled_date: e.scheduled_date || undefined,
      },
      fields: [
        FieldFactory.belongsTo('machine', 'productionMachines').withRequestParams({
          event_ids: e.id,
        }),
        FieldFactory.date('scheduled_date').withLabel('Date Scheduled'),
      ],
      schema: z.object({
        machine: genericModelReferenceSchema,
        scheduled_date: z.string().date(),
      }),
      onSubmit: (v) =>
        scheduleRequest.mutateAsync({
          event_ids: [e.id],
          date: v.scheduled_date,
          machine_id: v.machine.id,
        }),
    });
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>Design</TableCell>
            <TableCell>Order</TableCell>
            {hasScreenprintEvents && <TableCell>Scr Loc</TableCell>}
            <TableCell>Quantity</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Committed</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {events.map((e) => (
            <TableRow
              key={e.id}
              onClick={() => push(`/production/${e.order_id}?event_id=${e.id}`)}
              hover
            >
              <TableCell>
                <Avatar
                  src={maybeAddQueryParams(e.order_design.design.image, { w: 200 })}
                  alt={e.order_design.design.increment_id}
                />
              </TableCell>
              <TableCell style={{ paddingLeft: 0 }}>
                <Text
                  primary={e.order_design.design?.name}
                  secondary={e.order_design.design.location}
                />
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  <OrderLabel orderId={e.order.id}>{e.order.increment_id}</OrderLabel>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {e.order.customer?.name}
                </Typography>
              </TableCell>
              {hasScreenprintEvents && (
                <TableCell>
                  <ScreenLocation event={e} />
                </TableCell>
              )}
              <TableCell>
                <Text
                  primary={numString(e.quantity, 'imprints')}
                  secondary={<MinutesText minutes={e.job_minutes} />}
                />
              </TableCell>
              <TableCell>
                <StatusChip
                  status={e.status}
                  colors={PRODUCTION_EVENT_STATUS_COLORS}
                  size="small"
                />
              </TableCell>
              <TableCell>
                <Text
                  primary={<ProductionDates order={e.order} />}
                  secondary={
                    e.order.staged_at
                      ? `Staged ${moment(e.order.staged_at).fromNow()}`
                      : 'Not Staged'
                  }
                />
              </TableCell>
              <TableCell
                onClick={(ev) => ev.stopPropagation()}
                style={{ borderRight: `8px solid ${getPriorityColor(e.priority)}` }}
              >
                <SubMenu
                  items={[
                    new ButtonAction('Update Event', () => onUpdateEvent(e)),
                    new ButtonAction('Set Priority', () => onSetPriority(e)),
                    new ButtonAction('Remove from Schedule', () => onUnschedule(e)),
                  ]}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
