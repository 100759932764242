import { Button, CardContent, Grid2 as Grid, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useGetKitItemsForPackout } from '@/api/packout';
import EventableCard from '@/components/Notes/EventableCard';
import PackoutCompleteStatus from '@/components/Packouts/PackoutCompleteStatus';
import PackoutPendingStatus from '@/components/Packouts/PackoutPendingStatus';
import PackoutShippingStatus from '@/components/Packouts/PackoutShippingStatus';
import PackoutStartedStatus from '@/components/Packouts/PackoutStartedStatus';
import CardWithGutter from '@/components/Shared/CardWithGutter';
import StatusChip from '@/components/Shared/StatusChip';
import { PACKOUT_STATUS_COLOR } from '@/constants';
import { useShowLoading } from '@/contexts/AppContext';
import { Packout } from '@/types';
import { useOnReloadRecord, useRecord } from '@/utils/genericResource';
import useDialogs from '@/utils/hooks/useDialogs';
import numString from '@/utils/numString';
import NoteViewer from '../Notes/NoteViewer';
import OrderSummaryCard from '../Orders/OrderSummaryCard';
import PrintMenu from '../Print/PrintMenu';

export default function PackoutPage() {
  const { id, order, status, readyStatus, unproducedKitItems } = useRecord('orderPackouts', true);
  const onReload = useOnReloadRecord('orderPackouts');
  const showLoading = useShowLoading();
  const queryClient = useQueryClient();
  const { confirm } = useDialogs();

  const goToStatus = (action: Packout['status']) => {
    showLoading(axios.post(`/api/order-packouts/${id}/status/${action}`)).then(() => {
      onReload();
      queryClient.invalidateQueries(['kitsForOrder', order.id]);
    });
  };
  const { data: items = [] } = useGetKitItemsForPackout(id);
  const start = () => goToStatus('started');
  const startShipping = async () => {
    if (items.some((i) => i.picked_qty < i.qty)) {
      await confirm({
        title: 'Not all items have been picked',
        description:
          'Some items have not been picked. Are you sure you want to continue to shipping?',
        color: 'warning',
      });
    }
    goToStatus('shipping');
  };
  const complete = () => goToStatus('completed');
  const reset = () => goToStatus('pending');

  const renderForStatus = () => {
    if (status === 'pending') {
      if (readyStatus === 'Blocked') {
        return (
          <div>
            <Typography>The items in these kits are not ready to packout.</Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
              There are {numString(unproducedKitItems, 'items')} that are either unreceived or not
              produced yet.
            </Typography>
            <Button onClick={start}>Start Packout Anyways</Button>
          </div>
        );
      }

      if (readyStatus === 'Misconfigured') {
        return (
          <div>
            <Typography sx={{ mb: 2 }}>
              Items have not been assigned to kits properly. Please check the kitting configuration
              for this order.
            </Typography>
            <Button onClick={start}>Start Packout Anyways</Button>
          </div>
        );
      }
      if (readyStatus === 'Awaiting Approval') {
        return (
          <div>
            <Typography sx={{ mb: 2 }}>The order is not approved.</Typography>
            <Button onClick={start}>Start Packout Anyways</Button>
          </div>
        );
      }
      if (readyStatus === 'Ready') {
        return (
          <div>
            <Typography>All items are ready to pack out.</Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
              Start the packout to generate bin numbers. This must be done before downloading
              paperwork.
            </Typography>
            <Button variant="contained" onClick={start}>
              Start Packout
            </Button>
          </div>
        );
      }
    }

    if (status === 'started') {
      return (
        <div>
          <Typography sx={{ mb: 2 }}>
            The packout has started. Please put all items in their bin. Once complete, click "Start
            Shipping" to move to the next step.
          </Typography>

          <Button variant="contained" onClick={startShipping}>
            Start Shipping
          </Button>
          <Button onClick={reset} sx={{ ml: 1 }}>
            Reset
          </Button>
          <PrintMenu id={order.id} model="order" />
        </div>
      );
    }

    if (status === 'shipping') {
      return (
        <div>
          <Typography sx={{ mb: 2 }}>
            Please ship each kit. Once complete, click "Complete" to close out the packout.
          </Typography>
          <Button variant="contained" onClick={complete}>
            Complete
          </Button>
          <Button onClick={reset} sx={{ ml: 1 }}>
            Reset
          </Button>
        </div>
      );
    }

    if (status === 'completed') {
      return (
        <div>
          <Typography>The packout is complete.</Typography>
          <Button variant="contained" sx={{ mt: 2 }} onClick={reset}>
            Reset
          </Button>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <NoteViewer tag="packout" resource="orders" resourceId={order.id} />
      <Grid container spacing={3}>
        <Grid size={{ xs: 12, lg: 9 }}>
          {status === 'pending' ? (
            <PackoutPendingStatus />
          ) : status === 'started' ? (
            <PackoutStartedStatus />
          ) : status === 'shipping' ? (
            <PackoutShippingStatus />
          ) : status === 'completed' ? (
            <PackoutCompleteStatus />
          ) : null}
        </Grid>

        <Grid size={{ xs: 12, lg: 3 }}>
          <CardWithGutter>
            <CardContent style={{ textAlign: 'center' }}>
              <StatusChip
                status={status}
                colors={PACKOUT_STATUS_COLOR}
                style={{ marginBottom: 16, minWidth: 200 }}
              />
              {renderForStatus()}
            </CardContent>
          </CardWithGutter>
          <OrderSummaryCard order={order} />
        </Grid>

        <Grid size={12}>
          <EventableCard resource="packout" resourceId={id} />
        </Grid>
      </Grid>
    </>
  );
}
