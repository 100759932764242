import { useState } from 'react';
import { CloudDownload, Delete, Edit, FormatListNumbered } from '@mui/icons-material';
import {
  Alert,
  Badge,
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import filter from 'lodash/filter';
import pick from 'lodash/pick';
import { Field, FieldFactory, GroupLayout } from '@/classes';
import { layoutIsRoster, odHasDrops } from '@/helpers';
import { designLayoutPayloadSchema, DesignLayoutWithProofs, Vendor } from '@/types';
import getApiUrl from '@/utils/getApiUrl';
import useApiSegment from '@/utils/hooks/useApiSegment';
import useDialogs from '@/utils/hooks/useDialogs';
import DesignProofs from './DesignProofs';

export default function DesignLayoutCard({
  layout,
  orderId,
  isWebstore,
  invoicedAt,
  onLayoutUpdated,
  onDelete,
  canEdit,
  onRoster,
}: {
  layout: DesignLayoutWithProofs;
  orderId: number;
  isWebstore?: boolean;
  invoicedAt: string | null;
  onLayoutUpdated: (dl: DesignLayoutWithProofs) => void;
  onDelete: (dl: DesignLayoutWithProofs) => void;
  canEdit: (dl: DesignLayoutWithProofs) => boolean;
  onRoster: (dl: DesignLayoutWithProofs) => void;
}) {
  const { prompt } = useDialogs();
  const disabled = !!layout.subcontract_po_id || !!invoicedAt;
  const [useDecorator, setBoolean] = useState(!!layout.subcontractor_id);
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(layout.subcontract_completed_at);
  const [value, setValue] = useState<string | undefined>(
    layout.subcontractor_id ? String(layout.subcontractor_id) : undefined,
  );
  const segment = useApiSegment(1);

  const hasDecorationTypesThatNeedSubcontractor = layout.order_designs.some(
    (od) => od.design.decoration_type && !od.design.decoration_type.production_event_type_id,
  );

  const setVendor = (vendorId: string | undefined) => {
    setValue(vendorId);
    axios.put(`/api/design-layouts/${layout.id}`, {
      subcontractor: vendorId ? { id: vendorId } : null,
    });
  };

  const inputName = `vendorName-${layout.id}`;
  const onChange = (e: SelectChangeEvent) => {
    const vendorId = e.target.value;
    setVendor(vendorId);
  };

  const toggleCompletion = (type: 'complete' | 'uncomplete') => {
    setLoading(true);
    axios
      .post(`/api/design-layouts/${layout.id}/${type}`)
      .then(({ data }) => {
        setCompleted(data.subcontract_completed_at);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onEditLayout = () => {
    const fields: Field[] = [FieldFactory.boolean('sew_out_requested', 'Sew-out to be approved')];

    if (!isWebstore) {
      fields.push(FieldFactory.boolean('exempt_from_proofs', 'No customer proof needed'));
    }

    fields.push(FieldFactory.boolean('exclude_from_closet', 'Exclude from Closet'));

    prompt({
      title: 'Edit Design Layout',
      fields: [FieldFactory.text('name').withSize('medium'), new GroupLayout('settings', fields)],
      schema: designLayoutPayloadSchema.pick({
        name: true,
        exempt_from_proofs: true,
        sew_out_requested: true,
        exclude_from_closet: true,
      }),
      initialValues: pick(layout, [
        'name',
        'exempt_from_proofs',
        'sew_out_requested',
        'exclude_from_closet',
      ]),
      onSubmit: (v) => axios.put<DesignLayoutWithProofs>(`/api/design-layouts/${layout.id}`, v),
    }).then(({ data }) => {
      onLayoutUpdated(data);
    });
  };

  const { data: vendors = [] } = useQuery(
    ['vendorsForOrder', segment, orderId],
    () =>
      axios
        .get<{
          data: Vendor[];
        }>(`/api/vendors?${segment === 'quotes' ? 'quote_id' : 'order_id'}=${orderId}`)
        .then(({ data }) => data.data),
    {
      enabled: useDecorator,
    },
  );

  const layoutActions = (
    <Box mt={-1} mr={-1}>
      {layoutIsRoster(layout) && (
        <>
          {canEdit(layout) ? (
            <Tooltip title="Edit Roster For Names/Numbers">
              <IconButton onClick={() => onRoster(layout)} size="large">
                <Badge
                  badgeContent={filter(layout.order_designs, odHasDrops).length}
                  color="secondary"
                >
                  <FormatListNumbered />
                </Badge>
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Download Roster">
              <IconButton
                component="a"
                target="_blank"
                href={getApiUrl(`/api/design-layouts/${layout.id}/roster`)}
                size="large"
              >
                <CloudDownload />
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
      <IconButton onClick={onEditLayout} size="large">
        <Edit />
      </IconButton>
      {canEdit(layout) && (
        <IconButton onClick={() => onDelete(layout)} size="large">
          <Delete />
        </IconButton>
      )}
    </Box>
  );

  return (
    <Card>
      <CardHeader title={layout.increment_id} subheader={layout.name} action={layoutActions} />
      <CardContent>
        <FormControlLabel
          label="Decorate at Subcontractor (Ad Specialty or Contract Decorator)"
          control={
            <Checkbox
              disabled={disabled}
              size="small"
              checked={useDecorator}
              onChange={(e) => {
                setBoolean(e.target.checked);
                if (!e.target.checked) {
                  setVendor(undefined);
                }
              }}
            />
          }
        />
        {useDecorator ? (
          <FormControl disabled={disabled} fullWidth sx={{ mt: 2 }}>
            <InputLabel htmlFor={inputName}>Vendor</InputLabel>
            <Select
              label="Vendor"
              value={value}
              onChange={onChange}
              inputProps={{
                name: inputName,
                id: inputName,
              }}
            >
              <MenuItem aria-label="None" value={undefined} />
              {vendors.map((v) => (
                <MenuItem key={v.id} value={v.id}>
                  {v.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Art will be sent to the vendor along with the PO.</FormHelperText>
          </FormControl>
        ) : null}

        {!useDecorator && hasDecorationTypesThatNeedSubcontractor ? (
          <Alert severity="error" style={{ marginTop: 6 }}>
            Please select a subcontractor
          </Alert>
        ) : null}

        {layout.subcontract_po_id ? (
          <div>
            <FormControlLabel
              control={
                <Switch
                  disabled={loading || !!invoicedAt}
                  checked={!!completed}
                  onChange={(e) => toggleCompletion(e.target.checked ? 'complete' : 'uncomplete')}
                />
              }
              label="Subcontract Decoration Done"
            />
          </div>
        ) : null}
      </CardContent>
      <Divider />
      <CardContent>
        <Typography variant="subtitle1" gutterBottom>
          Customer Proofs
        </Typography>
        <DesignProofs layout={layout} type="customer" />
      </CardContent>
      {!useDecorator && (
        <CardContent>
          <Typography variant="subtitle1" gutterBottom>
            Production Proofs
          </Typography>
          <DesignProofs layout={layout} type="production" />
        </CardContent>
      )}
    </Card>
  );
}
