import { useEffect, useLayoutEffect, useRef } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { DefaultValues, FieldValues } from 'react-hook-form';
import { z } from 'zod';
import { StackLayout } from '@/classes';
import DrawerButtons from '@/components/Form/DrawerButtons';
import EditFormFields from '@/components/Form/EditFormFields';
import Form from '@/components/Form/Form';
import FormErrors from '@/components/Form/FormErrors';
import { PromptOptions } from '@/contexts/AppContext';
import { NullableFieldable } from '@/types';
import { getInitialValues, handleLaravelErrors, useZodForm } from '@/utils/form';
import truthy from '@/utils/truthy';

export default function PromptDialog<T extends FieldValues, Res = T>({
  onCancel,
  onSuccess,
  title,
  mode,
  open,
  description,
  submitText = 'Submit',
  cancelText = 'Cancel',
  promptFields,
  schema,
  initialValues = {} as DefaultValues<T>,
  ...props
}: {
  mode: 'drawer' | 'dialog';
  open: boolean;
  onCancel: () => void;
  onSuccess: (data: T | Res) => void;
  promptFields: NullableFieldable[];
} & Omit<PromptOptions<T, Res>, 'fields'>) {
  const form = useZodForm(schema, initialValues);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const autoFocused = useRef(false);
  const fields = truthy(promptFields);

  useEffect(() => {
    if (open) {
      form.reset(getInitialValues<z.infer<typeof schema>>(fields, initialValues));
    }
  }, [open]);

  const onSubmit = (values: T) => {
    if (props.onSubmit) {
      return props.onSubmit(values).then(onSuccess).catch(handleLaravelErrors(form));
    }
    return onSuccess(values);
  };

  useLayoutEffect(() => {
    const input = contentRef.current?.querySelector('input');
    if (input) {
      autoFocused.current = true;
      input.focus();
      input.select();
    }
  }, []);

  const content = (
    <Box>
      {description && <Box mb={2}>{description}</Box>}

      <Box ref={contentRef} py={1}>
        <EditFormFields fields={fields} defaultLayout={StackLayout} />
      </Box>

      <FormErrors form={form} />
    </Box>
  );

  if (mode === 'drawer') {
    return (
      <Form form={form} onSubmit={onSubmit}>
        {content}

        <DrawerButtons>
          <Button type="button" onClick={onCancel} disabled={form.formState.isSubmitting}>
            {cancelText}
          </Button>
          <LoadingButton type="submit" variant="contained" loading={form.formState.isSubmitting}>
            {submitText}
          </LoadingButton>
        </DrawerButtons>
      </Form>
    );
  }

  return (
    <Form form={form} onSubmit={onSubmit}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button type="button" onClick={onCancel} disabled={form.formState.isSubmitting}>
          {cancelText}
        </Button>
        <LoadingButton type="submit" variant="contained" loading={form.formState.isSubmitting}>
          {submitText}
        </LoadingButton>
      </DialogActions>
    </Form>
  );
}
