import { ChangeEvent, useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  CardHeader,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid2 as Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import get from 'lodash/get';
import includes from 'lodash/includes';
import map from 'lodash/map';
import toString from 'lodash/toString';
import without from 'lodash/without';
import moment from 'moment';
import { useGetBankAccounts } from '@/api/accounts';
import CardWithGutter from '@/components/Shared/CardWithGutter';
import Paper from '@/components/Shared/Paper';
import TextLink from '@/components/Text/TextLink';
import { Transaction } from '@/types';
import curr from '@/utils/curr';
import useDialogs from '@/utils/hooks/useDialogs';
import useSessionState from '@/utils/hooks/useSessionState';
import useTitle from '@/utils/hooks/useTitle';

export default function PrintChecks() {
  const [type, setType] = useState('check');
  const [lastPrinted, setLastPrinted] = useState<number>();
  const [bankAccountId, setBankAccountId] = useSessionState<number | undefined>(
    'checksToPrintBankAccountId',
    undefined,
  );
  const [checkNumber, setCheckNumber] = useState<string>();
  const [selected, setSelected] = useState<number[]>([]);
  const { confirm } = useDialogs();

  useTitle('Print Checks', '/transactions');

  const { data: transactions } = useQuery(
    ['checksToPrint', bankAccountId, lastPrinted],
    () => {
      const params = {
        to_print: type,
        'filter[bank_account_id]': bankAccountId,
        count: 250,
      };
      return axios
        .get<{ data: Transaction[] }>('/api/transactions', { params })
        .then(({ data }) => data.data);
    },
    {
      onSuccess: (rows) => {
        setSelected(rows.map((r) => r.id));
      },
    },
  );
  const { data: bankAccounts = [] } = useGetBankAccounts();
  const bankAccount = bankAccounts.find((a) => a.id === bankAccountId);

  useEffect(() => {
    if (bankAccount && bankAccount.next_check_number) {
      setCheckNumber(String(bankAccount.next_check_number));
    }
  }, [bankAccount?.id, lastPrinted]);

  const onToggleAll = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelected(map(transactions, 'id'));
    } else {
      setSelected([]);
    }
  };

  const onToggle = (id: number) => (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelected((prev) => [...prev, id]);
    } else {
      setSelected((prev) => without(prev, id));
    }
  };

  const onPrint = useMutation(() => {
    const payload = {
      bank_account_id: bankAccountId,
      starting_check_number: checkNumber,
      transaction_ids: selected,
      type,
    };

    return axios.post('/api/transactions/print-checks', payload).then(({ data }) => {
      if (data.url) {
        window.open(data.url, '_blank');

        confirm({
          title: 'Confirm Checks Printed',
          description:
            'Did the checks successfully print? This will remove them from the list of checks to print.',
        }).then(() => {
          axios.post('/api/transactions/mark-checks', payload).then(() => {
            setLastPrinted(Date.now());
          });
        });
      }
    });
  });

  if (!transactions) {
    return <CircularProgress />;
  }

  return (
    <div>
      <Paper>
        <Grid container spacing={3}>
          <Grid size={{ xs: 12, md: 4, lg: 3 }}>
            <FormControl variant="outlined" sx={{ mr: 2 }} fullWidth>
              <InputLabel id="bank-account-label">Type</InputLabel>
              <Select
                labelId="type-label"
                value={type}
                onChange={(e) => setType(e.target.value)}
                label="Type"
              >
                <MenuItem value="check">Check</MenuItem>
                <MenuItem value="ach">ACH</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid size={{ xs: 12, md: 4, lg: 3 }}>
            <FormControl variant="outlined" sx={{ mr: 2 }} fullWidth>
              <InputLabel id="bank-account-label">Bank Account</InputLabel>
              <Select
                labelId="bank-account-label"
                value={bankAccountId}
                onChange={(e) => setBankAccountId(Number(e.target.value))}
                label="Bank Account"
              >
                {bankAccounts.map((a) => (
                  <MenuItem value={a.id} key={a.id}>
                    {a.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {type === 'check' && (
            <Grid size={{ xs: 12, md: 4, lg: 3 }}>
              <TextField
                fullWidth
                label="Starting Check #"
                variant="outlined"
                onChange={(e) => setCheckNumber(e.target.value)}
                value={toString(checkNumber)}
              />
            </Grid>
          )}
        </Grid>
      </Paper>
      <CardWithGutter>
        <CardHeader subheader={`${selected.length} Selected to Print`} />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selected.length > 0 && transactions.length === selected.length}
                    indeterminate={selected.length > 0 && selected.length < transactions.length}
                    onChange={onToggleAll}
                  />
                </TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Transaction</TableCell>
                <TableCell>Vendor</TableCell>
                <TableCell>Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map((t) => (
                <TableRow key={t.id}>
                  <TableCell padding="checkbox">
                    <Checkbox checked={includes(selected, t.id)} onChange={onToggle(t.id)} />
                  </TableCell>
                  <TableCell>{moment(t.transaction_date).format('l')}</TableCell>
                  <TableCell>
                    <TextLink to={`/transactions/${t.id}`}>{t.label}</TextLink>
                  </TableCell>
                  <TableCell>{get(t, 'vendor.name')}</TableCell>
                  <TableCell>{curr(t.total)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardWithGutter>
      <LoadingButton
        variant="contained"
        disabled={selected.length === 0}
        onClick={() => onPrint.mutate()}
        loading={onPrint.isLoading}
      >
        Generate {type === 'ach' ? 'NACHA File' : 'PDF'}
      </LoadingButton>
    </div>
  );
}
