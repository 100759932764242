import { FieldFactory, Resource } from '@/classes';
import PackoutPage from '@/components/Packouts/PackoutPage';
import { PACKOUT_READY_STATUS_COLOR, PACKOUT_STATUS_COLOR } from '@/constants';
import { Packout } from '@/types';

const getTitle = (v: Packout) => `${v.order.increment_id} Packout`;

export default function orderPackouts() {
  return new Resource<Packout>('Order Packouts')
    .setReadOnly(true)
    .getTitleUsing(getTitle)
    .getLabelUsing(getTitle)
    .withDefaultFilters({
      bucket: 'open',
    })
    .withFilters([
      FieldFactory.radio('bucket', {
        open: 'Open',
        ready: 'Ready',
        inprogress: 'In-Progress',
        all: 'All',
      }).asQuickFilter(),
    ])
    .withColumns([
      FieldFactory.belongsTo('order', 'orders').filterable().sortable('order.increment_id'),
      FieldFactory.belongsTo('order.customer', 'customers')
        .withLabel('Customer')
        .filterable('filter[order.customer_id]'),
      FieldFactory.status('status', PACKOUT_STATUS_COLOR).filterable().sortable(),
      FieldFactory.status('ready_status', PACKOUT_READY_STATUS_COLOR).filterable('ready_status'),
      FieldFactory.number('kits_count').withLabel('# Kits').sortable(),
      FieldFactory.number('assigned_items_count').withLabel('# Items').sortable(),
      FieldFactory.date('scheduled_date').withLabel('Scheduled').sortable(),
      FieldFactory.timestamp('completed_at').sortable(),
    ])
    .editUsing(PackoutPage);
}
