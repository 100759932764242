import { useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { CircularProgress, Typography } from '@mui/material';
import axios from 'axios';
import flow from 'lodash/flow';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import DrawerButtons from '@/components/Form/DrawerButtons';
import ItemDesignTable from '@/components/Production/ItemDesignTable';
import ClosableDrawer from '@/components/Shared/ClosableDrawer';
import { OrderDesign, OrderItem, OrderItemDesign } from '@/types';
import { useOrderableApiUrl } from '@/utils/hooks/useApiSegment';
import useDialogs from '@/utils/hooks/useDialogs';

export default function ImprintsDrawer({
  orderDesign,
  onClose,
  onSave: onSaveProp,
}: {
  orderDesign?: OrderDesign;
  onClose: () => void;
  onSave: (() => void) | undefined;
}) {
  const [submitting, setSubmitting] = useState(false);
  const { confirm } = useDialogs();

  const onSave =
    onSaveProp && orderDesign
      ? async (items: OrderItem[]) => {
          const payload = flow(
            (items) => keyBy(items, 'id'),
            (dict) => pickBy(dict, (i) => i.order_design_ids[orderDesign.id]),
            (dict) => mapValues(dict, (i) => i.order_design_ids[orderDesign.id]),
          )(items) as Record<number, Partial<OrderItemDesign>>;

          setSubmitting(true);

          try {
            if (Object.keys(payload).length > 4) {
              await confirm({
                title: 'Multiple Sizes',
                description: `
          You are applying this design to more than 4 sizes. 
          Your order might require more than one size of this logo to ensure the image properly fits on the garment.
          `,
                color: 'warning',
                submitText: 'OK',
                cancelText: 'Cancel',
              });
            }
            await axios.post(`/api/order-designs/${orderDesign.id}/items`, { items: payload });

            onSaveProp();
            onClose();
          } finally {
            setSubmitting(false);
          }
        }
      : undefined;

  const [isLoading, setLoading] = useState(false);
  const [items, setItems] = useState<OrderItem[]>([]);
  const baseUrl = useOrderableApiUrl('items');

  useEffect(() => {
    setLoading(true);
    setItems([]);
    axios
      .get<{ data: OrderItem[] }>(baseUrl)
      .then(({ data }) => {
        setItems(data.data.filter((i) => i.can_apply_designs));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [baseUrl]);

  const setLocationsForItem = (itemIndex: number, locationIds: OrderItem['order_design_ids']) => {
    setItems((prev) =>
      prev.map((item, idx) => {
        if (idx === itemIndex) {
          return { ...item, order_design_ids: locationIds };
        }
        return item;
      }),
    );
  };

  const toggleLocationSelection = (itemIndex: number) => {
    const item = items[itemIndex];
    if (item.order_design_ids[orderDesign!.id]) {
      setLocationsForItem(itemIndex, omit(item.order_design_ids, orderDesign!.id));
    } else {
      setLocationsForItem(itemIndex, {
        ...item.order_design_ids,
        [orderDesign!.id]: { qty: null },
      });
    }
  };

  const onSelectAll = () => {
    setItems((prev) =>
      prev.map((i) => ({
        ...i,
        order_design_ids: { ...i.order_design_ids, [orderDesign!.id]: { qty: null } },
      })),
    );
  };

  const onDeselectAll = () => {
    setItems((prev) =>
      prev.map((i) => ({
        ...i,
        order_design_ids: omit(i.order_design_ids, orderDesign!.id),
      })),
    );
  };

  const onOrderDesignChange = (item: OrderItem, payload: OrderItemDesign) => {
    setItems((prev) =>
      prev.map((i) => {
        if (i.id === item.id) {
          return {
            ...i,
            order_design_ids: {
              ...i.order_design_ids,
              [orderDesign!.id]: {
                ...i.order_design_ids[orderDesign!.id],
                ...payload,
              },
            },
          };
        }
        return i;
      }),
    );
  };

  const onQtyChange = (item: OrderItem, qty: number | null) => onOrderDesignChange(item, { qty });

  return (
    <ClosableDrawer
      title={`Choose Items For ${orderDesign?.design.increment_id}`}
      open={!!orderDesign}
      onClose={onClose}
      width={750}
      closeOnClickOutside={false}
    >
      <div>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Each item selected will get <i>{orderDesign?.design.name}</i> imprinted on{' '}
          <i>{orderDesign?.design.location}</i>.
        </Typography>

        {isLoading ? (
          <CircularProgress />
        ) : orderDesign ? (
          <ItemDesignTable
            items={items}
            orderDesign={orderDesign}
            onToggle={onSave && toggleLocationSelection}
            onSelectAll={onSave && onSelectAll}
            onDeselectAll={onSave && onDeselectAll}
            onQtyChange={onSave && onQtyChange}
          />
        ) : null}

        {onSave && (
          <DrawerButtons>
            <LoadingButton
              type="button"
              variant="contained"
              onClick={() => onSave(items)}
              loading={submitting}
            >
              Save
            </LoadingButton>
          </DrawerButtons>
        )}
      </div>
    </ClosableDrawer>
  );
}
