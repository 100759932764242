import { useState } from 'react';
import { ArrowRightAlt, Edit } from '@mui/icons-material';
import {
  Alert,
  Box,
  CardContent,
  CardHeader,
  Divider,
  Grid2 as Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';
import { green, orange, red } from '@mui/material/colors';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import get from 'lodash/get';
import map from 'lodash/map';
import startCase from 'lodash/startCase';
import { Link } from 'react-router-dom';
import Can from '@/components/Permissions/Can';
import CardWithGutter from '@/components/Shared/CardWithGutter';
import Label from '@/components/Shared/Label';
import Paper from '@/components/Shared/Paper';
import { Order, OrderAnalysisElement, OrderAnalysisResponse } from '@/types';
import { makeResourceQueryKey, useCurrentResource, useRecord } from '@/utils/genericResource';
import useApiSegment, { useOrderableApiUrl } from '@/utils/hooks/useApiSegment';
import AnalysisDetails from './AnalysisDetails';
import EditOrderCostsDrawer from './EditOrderCostsDrawer';
import OrderCommissions from './OrderCommissions';
import OrderPayouts from './OrderPayouts';

const getColorForItem = (key: string, total: number) => {
  if (key === 'profit') {
    if (total < 0) {
      return red[500];
    }
    if (total === 0) {
      return 'inherit';
    }
    return green[500];
  }

  if (key === 'margin') {
    if (total < 0) {
      return red[500];
    }
    if (total < 0.3) {
      return orange[500];
    }
    return green[500];
  }

  return 'inherit';
};

function AnalysisCard({
  item,
  itemKey,
  setViewing,
  setEditingCosts,
}: {
  item: OrderAnalysisElement;
  itemKey: string;
  setViewing: (key: string) => void;
  setEditingCosts: (bool: boolean) => void;
}) {
  const segment = useCurrentResource();

  return (
    <CardWithGutter>
      <CardHeader
        title={startCase(itemKey)}
        action={
          segment === 'orders' &&
          itemKey === 'costs' && (
            <Can permission="orders:costs">
              <IconButton onClick={() => setEditingCosts(true)} size="small">
                <Edit fontSize="small" />
              </IconButton>
            </Can>
          )
        }
      />
      <CardContent>
        <Typography variant="h3" style={{ color: getColorForItem(itemKey, item.total) }}>
          {item.formatted}
        </Typography>
      </CardContent>
      {'items' in item && (
        <List>
          <Divider />
          {map(item.items, (i, key) => {
            const hasItems = 'items' in i;
            const onClick = hasItems ? () => setViewing(`${itemKey}.items.${key}`) : () => null;
            return (
              <ListItemButton key={key} onClick={onClick}>
                <ListItemText>
                  <span>{startCase(key)} </span>
                  {'is_estimate' in i && i.is_estimate ? <Label>Estimate</Label> : null}
                </ListItemText>
                <ListItemSecondaryAction>{i.formatted}</ListItemSecondaryAction>
              </ListItemButton>
            );
          })}
        </List>
      )}
    </CardWithGutter>
  );
}

export default function Analysis() {
  const { id: orderId, webstore_parent_id: webstoreParentId } = useRecord<Order>();
  const [viewing, setViewing] = useState<string>();
  const [isEditingCosts, setEditingCosts] = useState(false);

  const baseUrl = useOrderableApiUrl('analysis');
  const segment = useApiSegment(1) as 'orders' | 'quotes';
  const { data: analysis = {}, isLoading } = useQuery(
    makeResourceQueryKey(segment, orderId, 'analysis'),
    () => axios.get<OrderAnalysisResponse>(baseUrl).then(({ data }) => data),
  );

  const toPass = {
    setViewing,
    setEditingCosts,
  };

  const SkeletonColumn = (
    <>
      <Paper>
        <Skeleton variant="text" />
        <Skeleton variant="rounded" width={100} height={50} />
      </Paper>
      <Paper>
        <Skeleton variant="text" />
        <Skeleton variant="rounded" width={100} height={50} />
      </Paper>
    </>
  );

  return (
    <Grid container spacing={3}>
      <Grid container size={{ xs: 12, lg: 6 }}>
        {webstoreParentId && (
          <Box mb={1}>
            <Alert
              severity="info"
              action={
                <IconButton
                  component={Link}
                  color="inherit"
                  size="small"
                  to={`/orders/${webstoreParentId}?tab=analysis`}
                >
                  <ArrowRightAlt />
                </IconButton>
              }
            >
              This order is part of a webstore. All revenue and costs are pushed up to the webstore
              order.
            </Alert>
          </Box>
        )}
        {[0, 1].map((columnIndex) => (
          <Grid key={columnIndex} size={{ xs: 12, sm: 6 }}>
            {isLoading
              ? SkeletonColumn
              : Object.keys(analysis)
                  .filter((key, i) => i % 2 === columnIndex)
                  .map((key) => (
                    <AnalysisCard key={key} itemKey={key} item={analysis[key]} {...toPass} />
                  ))}
          </Grid>
        ))}
      </Grid>
      {segment === 'orders' ? (
        <Grid size={{ xs: 12, lg: 6 }}>
          <OrderPayouts />
          <OrderCommissions />
        </Grid>
      ) : null}
      <AnalysisDetails
        open={!!viewing}
        onClose={() => setViewing(undefined)}
        item={viewing ? get(analysis, viewing) : undefined}
        itemKey={viewing ? startCase(viewing.split('.').pop()) : undefined}
      />
      <EditOrderCostsDrawer open={isEditingCosts} onClose={() => setEditingCosts(false)} />
    </Grid>
  );
}
