import { Warning } from '@mui/icons-material';
import { DialogTitle, DialogContent, DialogActions, Button, AlertColor } from '@mui/material';
import { red } from '@mui/material/colors';
import { AlertOptions } from '@/contexts/AppContext';

const getIconForColor = (color?: AlertColor) => {
  if (!color) {
    return null;
  }
  return (
    <Warning
      style={{
        color: red[500],
        marginRight: 4,
        marginTop: 2,
        verticalAlign: 'top',
      }}
    />
  );
};

export default function AlertDialog({
  title = 'Alert',
  description,
  color,
  okText = 'Ok',
  onSuccess,
}: {
  onSuccess: () => void;
} & AlertOptions) {
  return (
    <>
      <DialogTitle>
        {getIconForColor(color)}
        {title}
      </DialogTitle>
      {description && <DialogContent>{description}</DialogContent>}
      <DialogActions>
        <Button type="submit" variant="contained" onClick={onSuccess} color={color || 'primary'}>
          {okText}
        </Button>
      </DialogActions>
    </>
  );
}
