import { useState } from 'react';
import { ArrowDropDown } from '@mui/icons-material';
import { Alert, Button, CircularProgress, Menu, MenuItem, Stack, Tooltip } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import pluralize from 'pluralize';
import { z } from 'zod';
import { useDesignLayoutsForOrder } from '@/api/designLayouts';
import { FieldFactory } from '@/classes';
import { useConfig } from '@/contexts/AppConfigContext';
import { layoutIsRoster } from '@/helpers';
import { Attachment } from '@/types';
import { useOnReloadRecord } from '@/utils/genericResource';
import getApiUrl from '@/utils/getApiUrl';
import useDialogs from '@/utils/hooks/useDialogs';

function useReviewPdf() {
  const { printables } = useConfig();
  return printables.find((p) => p.template === 'ProductionReviewTemplate');
}

export default function OrderProductionApproval({ orderId }: { orderId: number }) {
  const [loading, setLoading] = useState(false);
  const { confirm, prompt } = useDialogs();
  const reviewPdf = useReviewPdf();
  const onReload = useOnReloadRecord();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [reviewedPdf, setReviewedPdf] = useState<boolean>(!reviewPdf);
  const [reviewedRosters, setReviewedRosters] = useState<number>(0);

  const onApprove = () => {
    confirm({
      title: 'Approve Production',
      description: 'Are you sure? This will release the order to production.',
    }).then(() => {
      setLoading(true);
      axios
        .post(`/api/orders/${orderId}/approve-production`)
        .then(() => {
          onReload();
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const onDecline = () => {
    prompt({
      title: 'Decline Production',
      description: 'Are you sure? This will send the order back to art for another release.',
      fields: [FieldFactory.textarea('reason').withLabel('Reason for Decline')],
      schema: z.object({
        reason: z.string(),
      }),
      onSubmit: (v) => axios.post(`/api/orders/${orderId}/decline-production`, v),
    }).then(() => {
      onReload();
    });
  };

  const { data: sewOuts = [] } = useQuery(['sewOuts', orderId], () =>
    axios
      .get<{ data: Attachment[] }>(`/api/orders/${orderId}/sew-outs`)
      .then(({ data }) => data.data),
  );

  const { data: designLayouts = [] } = useDesignLayoutsForOrder(orderId);

  const designLayoutsWithDrops = designLayouts.filter((l) => layoutIsRoster(l));

  const isDisabled: boolean = !reviewedPdf || reviewedRosters < designLayoutsWithDrops.length;

  const tooltipMsg = (): string | undefined => {
    const numOfRosters = designLayoutsWithDrops.length;
    const rosterPlural = pluralize('roster', numOfRosters);

    if (numOfRosters) {
      if (!reviewedPdf && !reviewedRosters) {
        return `Please review the ${rosterPlural} and PDFs for your order`;
      } else if (reviewedPdf && !reviewedRosters) {
        return `Please review the ${rosterPlural} for your order`;
      } else if (!reviewedPdf && reviewedRosters === numOfRosters) {
        return `Please review the PDF for your order`;
      }
    } else if (!reviewedPdf) {
      return `Please review the PDF for your order`;
    }
  };

  return (
    <Alert
      severity="info"
      sx={{
        mt: 1,
        '& .MuiAlert-action': {
          pt: 0,
        },
      }}
      action={
        <Stack spacing={1} direction="row">
          {designLayoutsWithDrops.map((dl, idx, arr) => {
            return (
              <Button
                key={`review-roster-${idx}`}
                component="a"
                target="_blank"
                onClick={() => setReviewedRosters((prev) => prev + 1)}
                href={getApiUrl(`/api/design-layouts/${dl.id}/roster`)}
              >
                {arr.length > 1 ? `Review Roster ${idx + 1}` : 'Review Roster'}
              </Button>
            );
          })}

          {reviewPdf && (
            <Button
              component="a"
              target="_blank"
              onClick={() => setReviewedPdf(true)}
              href={getApiUrl(`/api/printables/${reviewPdf.id}/render/${orderId}`)}
            >
              Review PDF
            </Button>
          )}

          {sewOuts.length > 0 && (
            <Button onClick={(e) => setAnchorEl(e.currentTarget)}>
              Sew Outs <ArrowDropDown />
            </Button>
          )}

          <Menu
            id="sew-out-menu"
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {sewOuts.map((s) => (
              <MenuItem key={s.id} component="a" target="_blank" href={s.url}>
                {s.name}
              </MenuItem>
            ))}
          </Menu>

          {loading ? (
            <CircularProgress size={20} />
          ) : reviewedPdf ? (
            <>
              <Tooltip title={tooltipMsg()} disableHoverListener={!isDisabled}>
                <span className="inline-block">
                  <Button onClick={onApprove} variant="contained" disabled={isDisabled}>
                    Approve
                  </Button>
                </span>
              </Tooltip>
              <Button onClick={onDecline} variant="outlined">
                Decline
              </Button>
            </>
          ) : null}
        </Stack>
      }
    >
      This order is pending final sign off to be released to production.
    </Alert>
  );
}
