import { Snooze } from '@mui/icons-material';
import axios from 'axios';
import moment from 'moment';
import { z } from 'zod';
import { ButtonAction, CustomAction, FieldFactory, Resource } from '@/classes';
import PrintMenu from '@/components/Print/PrintMenu';
import { getOrderColumns } from '@/resources/orders';
import { Order } from '@/types';
import { OnClickProps } from '@/utils/hooks/useOnClickProps';

const onSnooze = ({ dialogs, selected, onReloadTable }: OnClickProps) => {
  dialogs
    .prompt({
      title: selected.length > 1 ? 'Snooze Orders' : 'Snooze Order',
      description: `How long would you like to snooze ${
        selected.length > 1 ? 'these orders' : 'this order'
      } from at-risk?`,
      initialValues: {
        snooze_until: moment().add(12, 'hours').toISOString(),
      },
      schema: z.object({
        snooze_until: z.string(),
      }),
      onSubmit: (values) =>
        axios.post('/api/at-risk-orders/snooze', {
          ids: selected,
          ...values,
        }),
      fields: [
        FieldFactory.select('snooze_until', [
          {
            label: '10 minutes',
            value: moment().add(10, 'minutes').toISOString(),
          },
          {
            label: '30 minutes',
            value: moment().add(30, 'minutes').toISOString(),
          },
          {
            label: '1 hour',
            value: moment().add(1, 'hours').toISOString(),
          },
          {
            label: '2 hours',
            value: moment().add(2, 'hours').toISOString(),
          },
          {
            label: '6 hours',
            value: moment().add(6, 'hours').toISOString(),
          },
          {
            label: '12 hours',
            value: moment().add(12, 'hours').toISOString(),
          },
          {
            label: '1 day',
            value: moment().add(1, 'day').toISOString(),
          },
          {
            label: '2 days',
            value: moment().add(2, 'days').toISOString(),
          },
          {
            label: '1 week',
            value: moment().add(1, 'week').toISOString(),
          },
        ]).withoutLabel(),
      ],
    })
    .then(() => {
      onReloadTable();
    });
};

export default function atRiskOrders() {
  return new Resource<Order>('At-Risk Orders')
    .setReadOnly()
    .getTitleUsing((o) => `At Risk: ${o.increment_id}`)
    .withDefaultSort('committed_ship_date')
    .withBulkActions([new ButtonAction('Snooze', onSnooze).withIcon(Snooze)])
    .setCanExport()
    .getSingleActionsUsing((values) => {
      const onSingleSnooze = (props: OnClickProps) =>
        onSnooze({ ...props, selected: [values.id], onReloadTable: props.onReloadRecord });
      return [
        new ButtonAction('Snooze', onSingleSnooze).withIcon(Snooze),
        new CustomAction('Print', () => <PrintMenu model="order" id={values.id} />),
      ];
    })
    .withInitialColumns([
      'increment_id',
      'customer',
      'order_type',
      'status',
      'committed_ship_date',
      'total',
    ])
    .withColumns(
      getOrderColumns().map((c) => {
        if (c.name === 'sales_rep') {
          return c.quickFilterable('user_id');
        }
        return c;
      }),
    );
}
