import { FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import {
  useGetInventoryLevelsForLocation,
  useGetInventoryLevelsForVariant,
} from '@/api/inventoryLevels';
import { FieldFactory } from '@/classes';
import FormField from '@/components/Form/FormField';
import { InventoryAdjustmentPayload, InventoryLevel, InventoryLocation } from '@/types';
import numString from '@/utils/numString';

export default function MoveInventoryLocation({ level }: { level: InventoryLevel }) {
  const form = useFormContext<InventoryAdjustmentPayload>();
  const endLocation = form.watch('end_location');
  const setEndLocation = (loc: InventoryLocation | null) => form.setValue('end_location', loc);
  const endLocationId = endLocation?.id;

  const { data: levels = [] } = useGetInventoryLevelsForVariant(level.variant_id);
  const otherLevels = levels.filter((l) => l.location_id !== level.location_id);
  const { data: locationPreview } = useGetInventoryLevelsForLocation(endLocationId);

  let somethingSelected = false;

  return (
    <div>
      <p>
        <span>
          Moving <b>{level.variant.sku}</b> from <b>{level.location.path}</b>.
        </span>
        <span>
          There is currently <b>{level.qty}</b> in this location.
        </span>
      </p>

      {otherLevels.length > 0 && (
        <RadioGroup>
          {otherLevels.map((l) => {
            const isChecked = endLocation?.id === l.location_id;
            if (!somethingSelected && isChecked) {
              somethingSelected = true;
            }
            return (
              <FormControlLabel
                key={l.id}
                control={<Radio checked={isChecked} onClick={() => setEndLocation(l.location)} />}
                label={l.location.path}
              />
            );
          })}

          <FormControlLabel
            control={
              <Radio
                checked={!somethingSelected && endLocation !== undefined}
                onClick={() => setEndLocation(null)}
              />
            }
            label="Other"
          />
        </RadioGroup>
      )}

      <Stack spacing={3}>
        <div>
          {!somethingSelected && (
            <FormField
              field={FieldFactory.belongsTo('end_location', 'inventoryLocations').withRequestParams(
                { count: 100 },
              )}
            />
          )}

          {locationPreview && endLocation && (
            <Typography variant="body2">
              <span>
                There is currently {numString(locationPreview.length, 'SKUs')} in{' '}
                <b>{endLocation.path}</b>
              </span>
              {locationPreview.length > 0 && (
                <span>: {locationPreview.map((l) => l.variant.sku).join(', ')}</span>
              )}
            </Typography>
          )}
        </div>

        <FormField field={FieldFactory.number('qty')} />
      </Stack>
    </div>
  );
}
