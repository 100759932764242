import { Backdrop, CircularProgress } from '@mui/material';
import { useAppContext } from '@/contexts/AppContext';

export default function LoadingBackdrop({ loading }: { loading?: boolean }) {
  const context = useAppContext();

  const isLoading = loading == null ? context.isLoading : loading;

  return (
    <Backdrop
      sx={{
        color: 'background.paper',
        zIndex: 'drawer',
      }}
      open={isLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
