import { FileCopy } from '@mui/icons-material';
import { Avatar } from '@mui/material';
import axios from 'axios';
import {
  ButtonAction,
  CardLayout,
  FieldFactory,
  GroupLayout,
  Resource,
  SidebarLayout,
} from '@/classes';
import ArrayBullets from '@/components/Text/ArrayBullets';
import { ITEM_CLASSIFICATIONS } from '@/constants';
import { ProductDetail, productPayloadSchema } from '@/types';
import curr from '@/utils/curr';
import { maybeAddQueryParams } from '@/utils/query';

export default function products(): Resource<ProductDetail> {
  return new Resource<ProductDetail>('Products')
    .withSchema(productPayloadSchema)
    .getAvatarUsing((p) => (
      <Avatar variant="square" src={maybeAddQueryParams(p.image, { w: 200 })}>
        {p.name ? p.name[0] : '?'}
      </Avatar>
    ))
    .getSubtitleUsing((p) => (
      <ArrayBullets
        elements={{
          style: p.style_number,
          cost: p.cost ? curr(p.cost) : null,
          vendor: p.vendor.name,
        }}
      />
    ))
    .withDefaultSort('-id')
    .getSingleActionsUsing((values) => [
      new ButtonAction('Duplicate', ({ dialogs, setIsLoading, navigate }) => {
        dialogs
          .confirm({
            title: 'Duplicate Product',
            description: 'Are you sure you want to duplicate this product?',
          })
          .then(() => {
            setIsLoading(true);
            axios
              .post(`/api/products/${values.id}/duplicate`)
              .finally(() => {
                setIsLoading(false);
              })
              .then(({ data }) => navigate(`/products/${data.id}`));
          });
      }).withIcon(FileCopy),
    ])
    .withFilters([FieldFactory.text('size').withFilterKey('size')])
    .withColumns([
      FieldFactory.text('id').withLabel('#').sortable(),
      FieldFactory.image('image'),
      FieldFactory.number('vendor_inventory_qty'),
      FieldFactory.number('inventory_qty'),
    ])
    .addFieldsAndColumns([
      new SidebarLayout('grid')
        .withFields([
          new CardLayout('', [
            FieldFactory.text('name').withSize('medium').withColumnSpan(8),
            FieldFactory.select('type', {
              product: 'Blank Product',
              decorated: 'Decorated Product',
              service: 'Service',
              decoration: 'Decoration',
            }).filterable(),
            FieldFactory.text('style_number'),
            FieldFactory.text('color'),
            FieldFactory.text('color_code'),
            FieldFactory.belongsTo('vendor', 'vendors').filterable().withColumnSpan(3),
            FieldFactory.belongsTo('brand', 'brands').filterable().withColumnSpan(3),
            FieldFactory.belongsTo('customer', 'customers').filterable().withColumnSpan(3),
            FieldFactory.belongsTo('business', 'businesses').filterable().withColumnSpan(3),
          ]),
          new CardLayout('variants')
            .withoutPadding()
            .withFields([
              FieldFactory.hasManyCRUD(
                'variants',
                'productVariants',
                (p) => `/api/products/${p.id}/variants`,
              ).withColumnSpan(12),
            ])
            .setCreatable(false),
          new CardLayout('images', [
            FieldFactory.images('images').withColumnSpan(12).with({
              uploadType: 'product_image',
            }),
          ]),
        ])
        .withSidebarFields([
          new CardLayout('sidebar', [
            FieldFactory.select('gender', [
              'Mens',
              'Womens',
              'Boys',
              'Girls',
              'Adult',
              'Youth',
              'Unisex',
            ]).withColumnSpan(12),
            FieldFactory.select('classification', ITEM_CLASSIFICATIONS).withColumnSpan(12),
            FieldFactory.hasMany('tags', 'tags').with({ createsOptions: true }).withColumnSpan(12),
            FieldFactory.boolean('is_quick_ship', 'Quick Ship').withColumnSpan(12),
            new GroupLayout('Default Line Item Behavior', [
              FieldFactory.boolean('default_is_purchasable', 'Purchasable'),
              FieldFactory.boolean('default_can_drop_ship', 'Can Drop Ship'),
              FieldFactory.boolean('default_can_apply_designs', 'Can Apply Designs'),
              FieldFactory.boolean('default_is_shippable', 'Shippable'),
              FieldFactory.boolean('default_show_on_invoice', 'Show on Invoice'),
            ]).withColumnSpan(12),
          ]),
        ]),
    ]);
}
