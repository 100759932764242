import { ReactNode, useState } from 'react';
import { Person } from '@mui/icons-material';
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
} from '@mui/material';
import { useConfig } from '@/contexts/AppConfigContext';

export default function UserMenuSection({ children }: { children?: ReactNode }) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { user } = useConfig();

  if (!user) {
    return null;
  }

  const open = Boolean(anchorEl);
  const id = open ? 'user-popover' : undefined;

  return (
    <>
      <ListItemButton onClick={(e) => setAnchorEl(e.currentTarget)} sx={{ flexGrow: 0 }}>
        <ListItemIcon
          sx={{
            color: 'inherit',
            minWidth: 48,
          }}
        >
          <Person />
        </ListItemIcon>
        <ListItemText primary={user.name} color="inherit" />
      </ListItemButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List component="div" disablePadding>
          <ListItem>
            <ListItemAvatar>
              <Avatar src={user.avatar || undefined}>{user.name.trim()[0]}</Avatar>
            </ListItemAvatar>
            <ListItemText primary={user.name} secondary={user.email} />
          </ListItem>
          {children}
          <ListItem href={import.meta.env.VITE_BASE_URL + 'api/logout'} component="a">
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Popover>
    </>
  );
}
