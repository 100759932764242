import { useState, useRef, SyntheticEvent, ReactNode } from 'react';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { Refresh, Add, Fullscreen } from '@mui/icons-material';
import {
  Grid2 as Grid,
  CircularProgress,
  IconButton,
  Hidden,
  styled,
  Box,
  Tooltip,
  Toolbar,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import arrayMove from 'array-move';
import axios from 'axios';
import { FieldFactory } from '@/classes';
import DashboardAdminActions from '@/components/Dashboard/DashboardAdminActions';
import DashboardCard from '@/components/Dashboard/DashboardCard';
import { NonFormField } from '@/components/Form/FormField';
import Can from '@/components/Permissions/Can';
import EditReportDrawer from '@/components/Reports/EditReportDrawer';
import Paper from '@/components/Shared/Paper';
import { coerceReportResults } from '@/helpers';
import { Dashboard as DashboardModel, DashboardReport, reportPayloadSchema } from '@/types';
import getValueFromEvent from '@/utils/getValueFromEvent';
import useDialogs from '@/utils/hooks/useDialogs';
import useMutateQueryData from '@/utils/hooks/useMutateQueryData';
import useSessionState from '@/utils/hooks/useSessionState';
import { getFilterDefaults, getFilterFields } from '@/utils/reports';

const ReportsSection = styled('div')(({ theme }) => ({
  '&:fullscreen': {
    padding: 24,
    background: theme.palette.background.default,
    overflowY: 'auto',
  },
}));

export default function Dashboard({
  dashboard,
  children,
}: {
  dashboard: DashboardModel;
  children: ReactNode;
}) {
  const [filters, setFilters] = useSessionState<Record<string, any>>(
    `dashboardFilters.${dashboard.id}`,
    () => getFilterDefaults(dashboard.filters || []),
  );
  const [editing, setEditing] = useState<DashboardReport>();

  const reportsSection = useRef<HTMLDivElement | null>(null);
  const { confirm, prompt } = useDialogs();

  const queryKey = ['dashboardResults', dashboard.id, JSON.stringify(filters)];
  const setReports = useMutateQueryData<DashboardReport[]>(queryKey);
  const {
    data: reports,
    refetch,
    isFetching,
  } = useQuery(
    queryKey,
    () =>
      axios
        .post<{ reports: DashboardReport[] }>(`/api/dashboards/${dashboard.id}/results`, {
          filters,
        })
        .then(({ data }) =>
          data.reports.map((r) => ({
            ...r,
            results: r.results && coerceReportResults(r.results),
            compare_to_results: r.compare_to_results && coerceReportResults(r.compare_to_results),
          })),
        ),
    {
      refetchInterval: 1000 * 5 * 60, // 5 mins
    },
  );

  const onSortEnd = ({ active, over }: DragEndEvent) => {
    if (!over || !reports) {
      return;
    }

    const oldIndex = reports.findIndex((r) => r.id === active.id);
    const newIndex = reports.findIndex((r) => r.id === over.id);

    setReports((prev) => {
      const newResults = arrayMove(prev, oldIndex, newIndex);
      axios.post(`/api/dashboards/${dashboard.id}/reorder`, { ids: newResults.map((r) => r.id) });
      return newResults;
    });
  };

  const onDelete = (r: DashboardReport) => {
    confirm({
      title: 'Delete Report',
      description: 'Are you sure you want to delete this report?',
    }).then(() => {
      axios.delete(`/api/dashboards/${dashboard.id}/reports/${r.id}`).then(() => {
        refetch();
      });
    });
  };

  const addReport = () => {
    prompt({
      title: 'Create Report for Dashboard',
      fields: [FieldFactory.text('name'), FieldFactory.text('title')],
      schema: reportPayloadSchema,
      submitText: 'Create',
      onSubmit: (v) => axios.post(`/api/dashboards/${dashboard.id}/reports`, v),
    }).then(({ data }) => {
      setEditing(data);
      refetch();
    });
  };

  const onDownload = (report: DashboardReport) => {
    axios
      .post(`/api/dashboards/${dashboard.id}/reports/${report.id}/results`, {
        download: true,
        filters,
      })
      .then(({ data }) => {
        window.open(data.url);
      });
  };

  const createChangeHandler = (fieldName: string) => (e: SyntheticEvent) => {
    const value = getValueFromEvent(e);
    setFilters((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  return (
    <div>
      <Toolbar disableGutters>
        <Box flexGrow={1}>{children}</Box>
        {isFetching ? (
          <Box px={2}>
            <CircularProgress size={20} />
          </Box>
        ) : (
          <IconButton onClick={() => refetch()} size="large">
            <Refresh />
          </IconButton>
        )}
        <Can permission="write:reports">
          <Tooltip title="New Report">
            <IconButton onClick={addReport} size="large">
              <Add />
            </IconButton>
          </Tooltip>
        </Can>
        <Hidden lgDown>
          <IconButton onClick={() => reportsSection.current?.requestFullscreen()} size="large">
            <Fullscreen />
          </IconButton>
        </Hidden>
        <DashboardAdminActions dashboard={dashboard} />
      </Toolbar>
      {dashboard.filters && dashboard.filters.length > 0 && (
        <Paper>
          <Grid container spacing={3}>
            {getFilterFields(dashboard.filters).map((field) => {
              return (
                <Grid key={field.name} size={{ xs: 12, lg: 6, xl: field.columnSpan }}>
                  <NonFormField
                    value={filters[field.name]}
                    onChange={createChangeHandler(field.name)}
                    field={field}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Paper>
      )}
      {reports && (
        <DndContext onDragEnd={onSortEnd}>
          <SortableContext items={reports.map((r) => r.id)}>
            <ReportsSection ref={reportsSection}>
              <Grid container spacing={3}>
                {reports.map((r) => (
                  <DashboardCard
                    key={r.id}
                    report={r}
                    onEdit={setEditing}
                    onDelete={onDelete}
                    onDownload={onDownload}
                  />
                ))}
              </Grid>
            </ReportsSection>
          </SortableContext>
        </DndContext>
      )}
      <EditReportDrawer
        open={!!editing}
        onClose={() => setEditing(undefined)}
        report={editing}
        onSuccess={() => {
          setEditing(undefined);
          refetch();
        }}
        dashboardId={dashboard.id}
      />
    </div>
  );
}
