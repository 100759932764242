import { Box, Button, CircularProgress, Grid2 as Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import camelCase from 'lodash/camelCase';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import { plural } from 'pluralize';
import { useForm } from 'react-hook-form';
import Field from '@/classes/Field';
import FieldFactory from '@/classes/FieldFactory';
import DateField from '@/classes/Fields/DateField';
import RelationField from '@/classes/Fields/RelationField';
import SelectField from '@/classes/Fields/SelectField';
import ResetButton from '@/components/Buttons/ResetButton';
import SaveButton from '@/components/Buttons/SaveButton';
import Form from '@/components/Form/Form';
import FormField from '@/components/Form/FormField';
import Paper from '@/components/Shared/Paper';
import { ResourceName } from '@/types';
import useTitle from '@/utils/hooks/useTitle';

const getResourceFromModel = (model: string | undefined | null) => {
  if (!model) {
    return null;
  }
  const last = model.split('\\').at(-1)!;
  return camelCase(plural(last)) as ResourceName;
};

interface ConfigItem {
  key: string;
  value: any;
  label: string;
  group: string | null;
  model?: string | null;
  type: 'select' | 'text' | 'belongsTo' | 'number' | 'date';
  options?: Record<string, string>;
}

const getFieldTypeFromConfig = (config: ConfigItem): Field => {
  switch (config.type) {
    case 'belongsTo':
      return new RelationField(config.key, getResourceFromModel(config.model) || 'users');
    case 'select':
      return new SelectField(config.key, config.options || {});
    case 'date':
      return new DateField(config.key);
    case 'number':
      return FieldFactory.number(config.key);
    case 'text':
    default:
      return FieldFactory.text(config.key);
  }
};

const getFieldFromConfig = (config: ConfigItem): Field => {
  const field = getFieldTypeFromConfig(config);
  if (config.label) {
    field.label = config.label;
  }
  return field;
};

const toForm = (data: ConfigItem[]) =>
  data.reduce(
    (agg, row) => {
      agg[row.key] = row.value;
      return agg;
    },
    {} as Record<string, any>,
  );

export default function Config() {
  const form = useForm();

  const { data: fields } = useQuery(
    ['globalConfig'],
    () => axios.get<{ data: ConfigItem[] }>('/api/config').then(({ data }) => data.data),
    {
      onSuccess: (data) => {
        form.reset(toForm(data));
      },
    },
  );

  const onSubmit = (values: Record<string, any>) => {
    return axios.post('/api/config', values).then(({ data }) => {
      form.reset(toForm(data.data));
    });
  };

  useTitle('Global Configuration', undefined, <SaveButton form={form} onSubmit={onSubmit} />);

  if (!fields) {
    return <CircularProgress />;
  }

  return (
    <Form form={form} onSubmit={onSubmit} style={{ paddingBottom: 100 }}>
      {map(groupBy(fields, 'group'), (groupedFields, group) => (
        <Paper title={group} key={group}>
          <Grid container spacing={3}>
            {groupedFields.map((f) => (
              <Grid key={f.key} size={{ xs: 12, lg: 4 }}>
                <FormField field={getFieldFromConfig(f)} />
              </Grid>
            ))}
          </Grid>
        </Paper>
      ))}
      <Box display="flex" justifyContent="flex-end">
        <div>
          <ResetButton />
          <Button type="submit" variant="contained" sx={{ ml: 2 }}>
            Save
          </Button>
        </div>
      </Box>
    </Form>
  );
}
