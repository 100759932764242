import { useEffect, useState } from 'react';
import {
  Alert,
  CardContent,
  CardHeader,
  Grid2 as Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import axios from 'axios';
import partial from 'lodash/partial';
import pick from 'lodash/pick';
import { useDesignLayoutsForOrder, useSetDesignLayouts } from '@/api/designLayouts';
import { FieldFactory } from '@/classes';
import BrandGuidelinesCard from '@/components/Customers/BrandGuidelinesCard';
import DesignProofs from '@/components/Designs/DesignProofs';
import InkChangeField from '@/components/Designs/InkChangeField';
import OrderDesignListItem from '@/components/Designs/OrderDesignListItem';
import OrderDesignPurchasing from '@/components/Designs/OrderDesignPurchasing';
import CardWithGutter from '@/components/Shared/CardWithGutter';
import LoadingBackdrop from '@/components/Shared/LoadingBackdrop';
import {
  Design,
  DesignLayoutWithProofs,
  OrderDesign,
  orderDesignUpdatePayloadSchema,
  Proof,
  ProofType,
} from '@/types';
import { useRecord } from '@/utils/genericResource';
import getApiUrl from '@/utils/getApiUrl';
import useDialogs from '@/utils/hooks/useDialogs';
import AttachmentsCard from '../Attachments/AttachmentsCard';
import EventableCard from '../Notes/EventableCard';
import NoteAlert from '../Notes/NoteAlert';
import NoteViewer from '../Notes/NoteViewer';
import OrderSummaryCard from '../Orders/OrderSummaryCard';
import ArtRequestActivityDrawer from './ArtRequestActivityDrawer';
import ArtRequestDesignDrawer from './ArtRequestDesignDrawer';
import ArtRequestFields from './ArtRequestFields';

export default function ArtRequestEditor() {
  const { note, order, id, type, label, createdByUser, createdAt, status } = useRecord(
    'artRequests',
    true,
  );

  const [editing, setEditing] = useState<Design>();
  const [showArtActivities, setShowArtActivities] = useState<Design>();
  const { prompt } = useDialogs();
  const orderId = order.id;

  const setDesignLayouts = useSetDesignLayouts(orderId);
  const { data: designLayouts = [], isFetching, refetch } = useDesignLayoutsForOrder(orderId);

  // Push these changes up, so the appbar actions know
  useEffect(() => {
    if (designLayouts) {
      window.artRequestMeta = {
        _customerProofCount: designLayouts.map((d) => d.customer_proofs.length),
        _productionProofCount: designLayouts.map((d) => d.production_proofs.length),
        _completedAts: designLayouts.flatMap((d) =>
          d.order_designs.map((od) => od.design.completed_at),
        ),
      };
    }
  }, [designLayouts]);

  const onUpdateProofs = (
    layout: DesignLayoutWithProofs,
    proofType: ProofType,
    func: (prevProofs: Proof[]) => Proof[],
  ) => {
    const proofKey = `${proofType}_proofs` as 'production_proofs' | 'customer_proofs';
    setDesignLayouts((prevLayouts) =>
      prevLayouts.map((pl) => {
        if (pl.id === layout.id) {
          return {
            ...pl,
            [proofKey]: func(pl[proofKey]),
          };
        }
        return pl;
      }),
    );
  };

  const onEditOrderDesign = (orderDesign: OrderDesign) => {
    prompt({
      title: 'Edit Linked Design',
      fields: [
        FieldFactory.belongsTo('design', 'designs'),
        FieldFactory.custom('ink_changes', InkChangeField).withPermission('write:designs'),
      ],
      initialValues: pick(orderDesign, ['design', 'ink_changes']),
      schema: orderDesignUpdatePayloadSchema,
      onSubmit: (v) => axios.put(`/api/order-designs/${orderDesign.id}`, v),
    }).then(() => {
      refetch();
    });
  };

  const onUncomplete = (orderDesign: OrderDesign) => {
    axios
      .post(`/api/designs/${orderDesign.design.id}/uncomplete`, {
        uncompleted_via: id,
      })
      .then(() => refetch());
  };

  const renderNameNumber = (layout: DesignLayoutWithProofs, orderDesign: OrderDesign) => {
    const hasDrops = orderDesign?.design?.decoration_type?.has_drops || false;
    return (
      <>
        <OrderDesignPurchasing orderDesign={orderDesign} status={status} onReload={refetch} />
        {hasDrops && (
          <TableRow>
            <TableCell colSpan={3} sx={{ borderBottom: 0 }}>
              <Typography variant="subtitle2">Name/Number Drop</Typography>
              <Typography variant="body2" gutterBottom>
                If this design requires files specific to the name/number list, please attach here.
              </Typography>
              <a href={getApiUrl(`/api/design-layouts/${orderDesign.design_layout_id}/roster`)}>
                Roster.xlsx
              </a>
            </TableCell>
            <TableCell colSpan={3} sx={{ borderBottom: 0 }}>
              <AttachmentsCard
                resource="order-designs"
                resourceId={orderDesign.id}
                title={null}
                compact
              />
            </TableCell>
          </TableRow>
        )}
      </>
    );
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 9 }}>
          {note && (
            <NoteAlert
              note={{
                id: -1,
                created_by_user: createdByUser || { id: -1, name: 'From Customer', avatar: null },
                note,
                created_at: createdAt,
                attachments: [],
                reactions: [],
              }}
            />
          )}
          <NoteViewer resource="orders" resourceId={order.id} tag="art" />

          {designLayouts.map((layout) => (
            <CardWithGutter key={layout.id}>
              <CardHeader
                title={layout.increment_id}
                subheader={
                  <>
                    <span>{layout.name}</span>
                    {layout.subcontractor_id && <span> &#40;Subcontracted&#41;</span>}
                  </>
                }
              />

              {layout.sew_out_requested && (
                <CardContent sx={{ py: 1 }}>
                  <Alert severity="info">
                    The sew-out for designs in this layout will be shown to the sales rep when they
                    are reviewing the final production specs before the order is released.
                  </Alert>
                </CardContent>
              )}

              <TableContainer>
                <Table>
                  {layout.order_designs.map((orderDesign) => (
                    <TableBody key={orderDesign.id} style={{ borderBottom: '1px solid #ccc' }}>
                      <OrderDesignListItem
                        orderDesign={orderDesign}
                        onEdit={() => onEditOrderDesign(orderDesign)}
                        onEditDesign={() => setEditing(orderDesign.design)}
                        onShowArtActivities={() => setShowArtActivities(orderDesign.design)}
                        onUncomplete={partial(onUncomplete, orderDesign)}
                      />
                      {type === 'release' && renderNameNumber(layout, orderDesign)}
                    </TableBody>
                  ))}
                </Table>
              </TableContainer>
              <CardContent>
                <Typography variant="subtitle1" gutterBottom>
                  Customer Proofs
                </Typography>
                <DesignProofs
                  layout={layout}
                  type="customer"
                  onUpdateProofs={type === 'proof' ? onUpdateProofs : undefined}
                />
              </CardContent>
              <CardContent>
                <Typography variant="subtitle1" gutterBottom>
                  Production Proofs
                </Typography>
                <DesignProofs
                  layout={layout}
                  type="production"
                  onUpdateProofs={type === 'release' ? onUpdateProofs : undefined}
                />
              </CardContent>
            </CardWithGutter>
          ))}
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <CardWithGutter>
            <CardHeader title={label} />
            <ArtRequestFields />
          </CardWithGutter>
          <BrandGuidelinesCard customerId={order.customer.id} />
          <OrderSummaryCard order={order} />
          <EventableCard resource="art-requests" resourceId={id} size="small" />
        </Grid>
      </Grid>
      <ArtRequestDesignDrawer
        artRequestId={id}
        design={editing}
        onSave={() => refetch()}
        onClose={() => setEditing(undefined)}
      />
      <ArtRequestActivityDrawer
        artRequestId={id}
        design={showArtActivities}
        onClose={() => setShowArtActivities(undefined)}
      />
      <LoadingBackdrop loading={isFetching} />
    </div>
  );
}
