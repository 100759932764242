import CloseIcon from '@mui/icons-material/Close';
import DuplicateIcon from '@mui/icons-material/FileCopy';
import {
  Button,
  IconButton,
  styled,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { z } from 'zod';
import { FieldFactory } from '@/classes';
import FormField from '@/components/Form/FormField';
import InventoryText from '@/components/Products/InventoryText';
import { SkuResource } from '@/types';
import { orderItemWizardRowSchema } from '@/types';
import curr from '@/utils/curr';

const ItemsBody = styled(TableBody)({
  '& tr:not(:last-child) td': {
    borderBottom: 'none',
    paddingBottom: 0,
  },
  '& tr:last-child td': {
    paddingTop: 2,
  },
  '& td': {
    padding: '6px 12px 6px 0',
    verticalAlign: 'middle',
  },
  '& td:first-child': {
    textAlign: 'center',
  },
});

type OrderItemPayload = z.infer<typeof orderItemWizardRowSchema>;

export default function ItemsSection({
  items,
  setItems,
  isCustom,
}: {
  items: OrderItemPayload[];
  setItems: (i: OrderItemPayload[]) => void;
  isCustom: boolean;
}) {
  const onAdd = () => {
    setItems([...items, { size: 'OS', price: 0, qty: 0 }]);
  };
  const onRemove = (i: number) => {
    setItems(items.filter((_, index) => index !== i));
  };
  const onDuplicate = (i: number) => {
    setItems([...items, items[i]]);
  };

  return (
    <>
      {items.map((item, i) => {
        const variant = item.variant as SkuResource | undefined;
        const parentName = `items.${i}`;
        return (
          <ItemsBody key={item.id || i}>
            <TableRow>
              <TableCell>
                {isCustom ? (
                  <FormField
                    field={FieldFactory.text('size').withoutLabel()}
                    parentName={parentName}
                  />
                ) : variant ? (
                  variant.size
                ) : (
                  '?'
                )}
              </TableCell>
              <TableCell>
                <FormField
                  field={FieldFactory.number('qty').withoutLabel()}
                  parentName={parentName}
                />
              </TableCell>
              <TableCell>
                <FormField
                  field={FieldFactory.curr('price').withoutLabel().with({ maxDecimals: 4 })}
                  parentName={parentName}
                />
              </TableCell>
              <TableCell>
                {isCustom ? (
                  <FormField
                    field={FieldFactory.curr('cost').withoutLabel().with({ maxDecimals: 4 })}
                    parentName={parentName}
                  />
                ) : variant ? (
                  curr(variant.cost)
                ) : (
                  '?'
                )}
              </TableCell>
              {isCustom ? (
                <TableCell style={{ width: 80 }}>
                  <IconButton onClick={() => onDuplicate(i)} size="small">
                    <DuplicateIcon fontSize="small" />
                  </IconButton>
                  <IconButton onClick={() => onRemove(i)} size="small">
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              ) : (
                <TableCell />
              )}
            </TableRow>
            {!isCustom && variant && (
              <TableRow>
                <TableCell />
                <TableCell colSpan={4}>
                  <InventoryText variant={variant as SkuResource} />
                </TableCell>
              </TableRow>
            )}
          </ItemsBody>
        );
      })}

      {isCustom && (
        <TableRow>
          <TableCell colSpan={4} style={{ borderBottom: 0 }}>
            <Button onClick={onAdd}>Add Size</Button>
          </TableCell>
        </TableRow>
      )}

      {!isCustom && items.length === 0 && (
        <TableRow>
          <TableCell colSpan={4} style={{ borderBottom: 0 }}>
            <Typography variant="body2" color="textSecondary">
              Choose a product to get started
            </Typography>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
