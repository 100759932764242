import { ReactNode, useEffect } from 'react';
import { useAppContext } from '@/contexts/AppContext';

export default function useTitle(title: string, back?: string, actions?: ReactNode) {
  const { setAppBarState } = useAppContext();

  useEffect(() => {
    setAppBarState({ title, back, actions });
  }, [title, back, actions]);
}
