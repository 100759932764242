import { ChangeEvent } from 'react';
import { NotificationImportant, Warning, Store } from '@mui/icons-material';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  IconButton,
  TableContainer,
  Checkbox,
} from '@mui/material';
import { red } from '@mui/material/colors';
import get from 'lodash/get';
import OrderItemProduct from '@/components/Orders/Items/OrderItemProduct';
import Can from '@/components/Permissions/Can';
import InventoryText from '@/components/Products/InventoryText';
import { abscomp } from '@/helpers';
import { OrderItemToPurchase } from '@/types';
import { InventoryCheckResponse } from '@/types';
import InventoryCheckIcon from './InventoryCheckIcon';

export default function OrderItemsForPurchasing({
  items,
  onPurchase,
  onPull,
  toPurchase,
  inventory,
  onSelect,
  selected = [],
}: {
  items: OrderItemToPurchase[];
  onPurchase?: (items: OrderItemToPurchase[]) => void;
  onPull?: (items: OrderItemToPurchase[]) => void;
  toPurchase?: boolean;
  inventory?: InventoryCheckResponse;
  onSelect?: (id: number, e: ChangeEvent<HTMLInputElement>) => void;
  selected?: number[];
}) {
  if (items.length === 0) {
    return null;
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {onSelect && <TableCell padding="checkbox" />}
            <TableCell>Item</TableCell>
            {toPurchase ? <TableCell>Inventory</TableCell> : <TableCell>Vendor</TableCell>}
            <TableCell>Qty Req</TableCell>
            <TableCell>Qty Pur</TableCell>
            <TableCell>Qty Pulled</TableCell>
            <Can permission="write:purchase_orders">
              <TableCell />
            </Can>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <TableRow key={item.id}>
              {onSelect && (
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selected.includes(item.id)}
                    onChange={(e) => onSelect(item.id, e)}
                  />
                </TableCell>
              )}
              <TableCell>
                <OrderItemProduct item={item} showSize />
              </TableCell>
              <TableCell>
                {toPurchase ? (
                  !item.is_custom && item.variant && <InventoryText variant={item.variant} />
                ) : (
                  <div>{get(item, 'vendor.name', '(none)')}</div>
                )}
              </TableCell>
              <TableCell>{item.qty}</TableCell>
              <TableCell>{item.qty_purchased}</TableCell>
              <TableCell>{item.qty_pulled}</TableCell>

              <Can permission="write:purchase_orders">
                <TableCell style={{ whiteSpace: 'nowrap' }}>
                  {abscomp(item.qty_sourced, '<', item.qty) && onPurchase ? (
                    <Tooltip title="Purchase from Vendor">
                      <IconButton onClick={() => onPurchase([item])} size="small">
                        <Store fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  ) : null}

                  {Number(item.qty_in_inventory) > 0 && item.qty_sourced < item.qty && onPull && (
                    <Tooltip title={`Pull from Inventory (${item.qty_in_inventory} available)`}>
                      <IconButton onClick={() => onPull([item])} size="small">
                        <NotificationImportant color="primary" fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}

                  {item.open_issues && item.open_issues[0] && (
                    <Tooltip title={`Has Issue: ${item.open_issues[0].details}`}>
                      <IconButton size="small" onClick={() => null}>
                        <Warning fontSize="small" style={{ color: red[500] }} />
                      </IconButton>
                    </Tooltip>
                  )}

                  <InventoryCheckIcon inventory={inventory} sku={item.variant?.sku} />
                </TableCell>
              </Can>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
