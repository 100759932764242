import { SyntheticEvent } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { useContextKey, setOtherRowContext, MappedRow } from '@/utils/hooks/usePushWithContext';

export default function LinkWithContext({
  to,
  otherRows,
  children,
  ...other
}: {
  otherRows: MappedRow[];
} & LinkProps) {
  const contextKey = useContextKey();
  const handleClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    setOtherRowContext(contextKey, otherRows);
  };

  return (
    <Link to={to} state={{ context: contextKey }} onClick={handleClick} {...other}>
      {children}
    </Link>
  );
}
