import { ChangeEvent, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  LinearProgress,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import axios from 'axios';
import groupBy from 'lodash/groupBy';
import keys from 'lodash/keys';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { useOrderKitItems } from '@/api/kitting';
import { FieldFactory } from '@/classes';
import OrderLabel from '@/components/Orders/OrderLabel';
import { Kit, OrderItem } from '@/types';
import useDialogs from '@/utils/hooks/useDialogs';
import { buildIncrementId } from '@/utils/notes';
import numString from '@/utils/numString';
import KitItemsActionMenu from './KitItemsActionMenu';
import OrderKitAddItemsDrawer from './OrderKitAddItemsDrawer';
import OrderKitItemRow from './OrderKitItemRow';

export default function OrderKitItems({ orderId, kit }: { orderId: number; kit?: Kit }) {
  const { prompt } = useDialogs();
  const [isAdding, setAdding] = useState<boolean>(false);
  const [selected, setSelected] = useState<number[]>([]);
  const [fromOrder, setFromOrder] = useState(orderId);
  const navigate = useNavigate();

  const { data: items = [], isFetching } = useOrderKitItems(orderId);

  useEffect(() => {
    if (!items.some((i) => i.order_id === fromOrder)) {
      setFromOrder(items[0]?.order_id);
    }
  }, [items]);

  const groupedItems = groupBy(items, 'order_id');

  const handleCheck = (i: OrderItem) => (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelected((prev) => [...prev, i.id]);
    } else {
      setSelected((prev) => prev.filter((id) => id !== i.id));
    }
  };

  const onBackorder = () => {
    prompt({
      title: `Create Kit Backorder for ${numString(selected.length, 'Items')}`,
      initialValues: {
        copy: true,
      },
      schema: z.object({
        copy: z.boolean(),
      }),
      fields: [FieldFactory.boolean('copy', 'Keep original kit items and mark as backordered')],
      onSubmit: (v) =>
        axios.post(`/api/orders/${orderId}/kit-backorder`, { order_item_ids: selected, ...v }),
    }).then(({ data }) => {
      navigate(`/orders/${data.id}?tab=shipping`);
    });
  };

  let actions = null;

  if (selected.length > 0) {
    actions = <KitItemsActionMenu onBackorder={onBackorder} />;
  }

  return (
    <Card>
      <CardHeader title="Available Line Items" action={actions} />
      {isFetching && <LinearProgress />}
      {groupedItems ? (
        <>
          <Box p={2} display="flex" alignItems="center">
            <Typography variant="subtitle1">
              Items from <OrderLabel orderId={fromOrder} />
            </Typography>

            <Box ml="auto">
              <FormControl>
                <Select
                  variant="outlined"
                  value={fromOrder}
                  onChange={(e) => setFromOrder(Number(e.target.value))}
                >
                  {keys(groupedItems).map((oid) => (
                    <MenuItem key={oid} value={oid}>
                      {buildIncrementId('SO', Number(oid))}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          {groupedItems[fromOrder] && (
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox" />
                    <TableCell>Description</TableCell>
                    <TableCell>Alloc</TableCell>
                    <TableCell padding="checkbox" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupedItems[fromOrder].map((i) => (
                    <OrderKitItemRow
                      key={i.id}
                      item={i}
                      orderId={orderId}
                      selectedKit={kit}
                      onSelect={handleCheck(i)}
                      isSelected={selected.includes(i.id)}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      ) : (
        <CardContent>
          <CircularProgress />
        </CardContent>
      )}
      <CardActions>
        <Button color="secondary" onClick={() => setAdding(true)}>
          Add from Other Orders
        </Button>
      </CardActions>
      <OrderKitAddItemsDrawer open={isAdding} onClose={() => setAdding(false)} orderId={orderId} />
    </Card>
  );
}
