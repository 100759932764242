import { UploadFile } from '@mui/icons-material';
import { Typography } from '@mui/material';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { z } from 'zod';
import { ButtonAction, FieldFactory, Resource } from '@/classes';
import InboundShipmentMatches from '@/components/InboundShipments/InboundShipmentMatches';
import { InboundShipment, PurchaseOrder, inboundShipmentPayloadSchema } from '@/types';
import { OnClickProps } from '@/utils/hooks/useOnClickProps';

const renderPoCell = (po: PurchaseOrder | undefined | null) => {
  if (!po) {
    return (
      <Typography variant="body2" color="textSecondary">
        (none)
      </Typography>
    );
  }
  return (
    <Link
      style={{ color: 'inherit' }}
      to={`/purchase-orders/${po.id}`}
      onClick={(e) => e.stopPropagation()}
    >
      <Typography variant="body2">
        {po.vendor.name} {po.increment_id}
      </Typography>
    </Link>
  );
};

const onImport = ({ dialogs }: OnClickProps) => {
  dialogs.prompt({
    title: 'Import Inbound Shipments',
    fields: [FieldFactory.file('file').withLabel('File')],
    schema: z.object({
      file: z.string(),
    }),
    onSubmit: (v) => axios.post('/api/inbound-shipments/import', v),
  });
};

export default function inboundShipments() {
  return new Resource<InboundShipment>('Inbound Shipments')
    .withSchema(inboundShipmentPayloadSchema)
    .withDefaultSort('-id')
    .setUseDrawer()
    .withFilters([FieldFactory.boolean('matched').withFilterKey('matched')])
    .withIndexActions([new ButtonAction('Import', onImport, UploadFile)])
    .withInitialColumns([
      'id',
      'invoice_date',
      'shipment_date',
      'cost',
      'po_number',
      'purchase_order',
      'shipper',
    ])
    .withColumns([FieldFactory.text('id').withLabel('#').sortable()])
    .addFieldsAndColumns([
      FieldFactory.curr('cost').sortable(),
      FieldFactory.belongsTo('purchase_order', 'purchaseOrders')
        .sortable('purchase_order.id')
        .filterable()
        .renderCellUsing(renderPoCell),
    ])
    .addFields([FieldFactory.custom('matches', InboundShipmentMatches).setCreatable(false)])
    .addFieldsAndColumns([
      FieldFactory.text('shipper').filterable(),
      FieldFactory.text('recipient_name').filterable(),
      FieldFactory.text('recipient_company').filterable(),
      FieldFactory.text('recipient_address').filterable(),
      FieldFactory.text('recipient_city').filterable(),
      FieldFactory.text('recipient_state').filterable(),
      FieldFactory.text('recipient_zip').filterable(),
      FieldFactory.date('shipment_date').filterable().sortable(),
      FieldFactory.date('invoice_date').filterable().sortable(),
      FieldFactory.text('po_number').withLabel('PO Number').sortable(),
      FieldFactory.timestamp('created_at').sortable(),
      FieldFactory.text('tracking_number').sortable(),
    ]);
}
