import Input from '@mui/material/OutlinedInput';
import { UseControllerReturn } from 'react-hook-form';
import MaskedInput from 'react-text-mask';
import InputWrapper from '@/components/Form/InputWrapper';
import { FieldProps } from '@/types';
import Field from '../Field';

function Phone({ fieldModel, fieldState, field }: FieldProps<PhoneField>) {
  return (
    <InputWrapper fieldState={fieldState} fieldModel={fieldModel}>
      <MaskedInput
        {...field}
        mask={fieldModel.mask}
        guide={false}
        placeholder={fieldModel.placeholder}
        render={(ref, props) => (
          <Input
            {...props}
            type="text"
            inputRef={ref}
            label={fieldModel.label}
            size={fieldModel.size}
            margin={fieldModel.margin}
            disabled={fieldModel.disabled}
            required={fieldModel.required}
            fullWidth={fieldModel.fullWidth}
          />
        )}
      />
    </InputWrapper>
  );
}

export default class PhoneField extends Field {
  mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  renderEditComponent(props: UseControllerReturn) {
    return <Phone {...props} fieldModel={this} />;
  }
}
