import { ElementType, ReactElement, ReactNode } from 'react';
import * as Types from 'avail-types';
import { FieldValues, UseControllerReturn, UseFieldArrayReturn } from 'react-hook-form';
import { z } from 'zod';
import { Resource } from '@/classes';
import Action from '@/classes/Action';
import Field from '@/classes/Field';
import Layout from '@/classes/Layout';
import { EditFormFieldsProps } from '@/components/Form/EditFormFields';
import resources from '@/resources';
import { OnClickProps } from '@/utils/hooks/useOnClickProps';

declare module '@mui/material/styles' {
  interface Theme {
    drawerWidth: number;
    drawerColor?: string;
  }

  interface ThemeOptions {
    drawerWidth?: number;
    drawerColor?: string;
  }
}

export * from 'avail-types';

export type MaybeWith<T, With extends (keyof T)[] | undefined> = With extends (keyof T)[]
  ? Types.PartiallyRequired<T, With[number]>
  : T;
export type ResourceFuncMap = typeof resources;
export type ResourceMap = { [K in keyof ResourceFuncMap]: ReturnType<ResourceFuncMap[K]> };
export type ResourceName = keyof ResourceMap;
export type GetResourceShape<C extends Resource> = C extends Resource<infer T> ? T : unknown;
export type GetResourceWith<C extends Resource> = C extends Resource<any, infer W> ? W : unknown;
export type Resources = ResourceMap[ResourceName];

export type ResourceValueMap = {
  [K in keyof ResourceMap]: MaybeWith<
    GetResourceShape<ResourceMap[K]>,
    // @ts-expect-error this is fine
    GetResourceWith<ResourceMap[K]>
  >;
};
export type ResourceValues = ResourceValueMap[ResourceName];

export type SnakeToCamelCase<S extends string> = S extends `${infer T}_${infer U}`
  ? `${T}${Capitalize<SnakeToCamelCase<U>>}`
  : S;

export type CamelValues<T> = T extends object
  ? { [K in keyof T as SnakeToCamelCase<K & string>]: T[K] }
  : T;

interface BaseNavItem extends RequiresPermission {
  name: string;
  icon?: ElementType;
  image?: string;
  isGlobal?: boolean;
}

export interface Page<K extends ResourceName = any> extends BaseNavItem {
  href: string;
  component: ElementType;
  resource?: K;
  group?: string;
  title?: string;
  getHref?: (v: any) => string;
}

export interface Folder extends BaseNavItem {
  children: Page[];
}

export type NavItem = Page | Folder;

export interface DataTableState {
  selected: (number | string)[];
  query: string;
  filters: FieldValues;
  columns: string[];
  page: number;
  count: number;
  sort: string | null;
  aggregations: {
    sum: string[];
    avg: string[];
  };
}

export type FieldProps<M extends Field = Field> = { fieldModel: M } & UseControllerReturn;

export type UseFieldArrayWithName = UseFieldArrayReturn & { name: string };

export type FieldArrayProps<M extends Field = Field> = {
  fieldModel: M;
} & UseFieldArrayWithName;

export interface CellProps<T extends Field = Field> {
  value: any;
  row?: Record<string, any>;
  fieldModel: T;
}

export type Actions = Action[] | ElementType<OnClickProps>;

export type NullableFieldable = Field | Layout | null | false;

export type Fieldable = Field | Layout;

export interface LayoutProps<T extends Layout = Layout> {
  model: T;
  forwardProps?: Partial<EditFormFieldsProps>;
}

export interface RequiresPermission {
  requiresPermission?: string;
  requiresRole?: string;
}

export type CellComponent<T extends Field = Field> = (props: CellProps<T>) => ReactElement | null;

export type EditComponent<T extends Field = Field> = (props: FieldProps<T>) => ReactElement | null;

export type SelectOption = {
  value: string | number | boolean;
  label: ReactNode;
  requiresPermission?: string;
  requiresRole?: string;
};

export type OptionsProp =
  | SelectOption[]
  | string[]
  | Record<string | number, ReactNode>
  | (() => SelectOption[]);

export interface ReportTypeProps {
  results: Types.ReportResultsRow[];
  compareTo?: Types.CompareTo;
  compareToResults?: Types.ReportResultsRow[];
  summary?: Types.Report['summary'];
  columns?: Types.Report['columns'];
  type?: Types.Report['type'];
}

export type OrderTotals = Pick<
  Types.Order,
  | 'total'
  | 'paid'
  | 'balance'
  | 'subtotal'
  | 'shipping'
  | 'tax'
  | 'inbound_freight'
  | 'last_tax_hash'
  | 'current_tax_hash'
  | 'total_exempt'
  | 'items_sum'
  | 'precollected_tax'
  | 'total_tax'
>;

export type PendingPayout = Types.PartiallyRequired<Types.Business, 'balance' | 'commissions_sum'>;

export type AddressWithKits = Types.PartiallyRequired<Types.Address, 'kits'>;

export type ProductWithVariants = Types.PartiallyRequired<Types.ProductDetail, 'variants'>;

export type DesignLayoutWithProofs = Types.PartiallyRequired<
  Types.DesignLayout,
  'customer_proofs' | 'production_proofs' | 'order_designs'
>;

export type OrderWithItems = Types.PartiallyRequired<Types.Order, 'items'>;

export type PurchaseOrderWithItems = Types.PartiallyRequired<Types.PurchaseOrder, 'items'>;

export type ShipmentWithDetails = Types.PartiallyRequired<Types.Shipment, 'items' | 'kits'>;

export type InventoryPickWithItems = Types.InventoryPick & { items: Types.InventoryPickItem[] };

export type PaymentWithOps = Types.PartiallyRequired<Types.Payment, 'order_payments'>;

export type OrderWithDetails = Required<Types.Order>;

export type TransactionWithItems = Types.PartiallyRequired<Types.Transaction, 'items'>;

export type PurchaseOrderForMatching = Types.PartiallyRequired<
  Types.PurchaseOrder,
  'match_issues' | 'is_match' | 'items'
> & {
  matched_transactions: TransactionWithItems[];
};

export type ContactWithCustomer = Types.PartiallyRequired<Types.CustomerContact, 'customer'>;

export type DesignLayoutWithOrder = Types.PartiallyRequired<Types.DesignLayout, 'order'>;

export const orderItemWizardRowSchema = z.object({
  id: z.number().optional(),
  variant: Types.genericModelReferenceSchema.nullish(),
  size: z.string().nullish(),
  qty: z.coerce.number().int(),
  price: z.coerce.number(),
  cost: z.coerce.number().nullish(),
});

export type DesignColorPayloadWithRelations = Types.DesignColorPayload & {
  stock_color?: Types.StockColor | null;
  ink_changes_count?: number;
};

export type DesignPayloadWithRelations = Types.DesignPayload & {
  colors: DesignColorPayloadWithRelations[];
};
