import { ChevronRight } from '@mui/icons-material';
import { Box, FormLabel, IconButton } from '@mui/material';
import moment from 'moment';
import { Link } from 'react-router-dom';
import StatusChip from '@/components/Shared/StatusChip';
import Text from '@/components/Text/Text';
import { FieldProps } from '@/types';
import { useRecord } from '@/utils/genericResource';

export default function PaymentDepositDetails({ field }: FieldProps) {
  const deposit = field.value;
  const { paymentType } = useRecord('payments', true);

  return (
    <div>
      <FormLabel>Deposit Status</FormLabel>

      {deposit ? (
        <Box display="flex" mt={1}>
          <Text
            primary={`Deposit #${deposit.id}`}
            secondary={moment(deposit.deposit_date).format('L')}
          />
          <Box ml="auto">
            <IconButton component={Link} to={`/deposits/${deposit.id}`}>
              <ChevronRight />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <Box mt={1}>
          {paymentType === 'payment' ? (
            <StatusChip status="Not Deposited" colors={{ 'Not Deposited': 'red' }} />
          ) : (
            <StatusChip status="Not Required" colors={{ 'Not Required': 'gray' }} />
          )}
        </Box>
      )}
    </div>
  );
}
