import { SyntheticEvent, useCallback, useState } from 'react';
import {
  Close,
  CloudUpload,
  Description,
  GridOn,
  Image,
  InsertDriveFile,
  Slideshow,
  Upload,
} from '@mui/icons-material';
import {
  alpha,
  Avatar,
  Box,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  LinearProgress,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material';
import last from 'lodash/last';
import { useDropzone } from 'react-dropzone';
import { UseControllerReturn } from 'react-hook-form';
import { onUpload } from '@/AuthenticatedApp';
import StaticFormControl from '@/components/Form/StaticFormControl';
import { FieldProps } from '@/types';
import Field from '../Field';

export const getIconFromFileName = (file: string) => {
  const ext = last(file.split('.'))?.toLowerCase() || '';

  switch (ext) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'webp':
    case 'gif':
    case 'tif':
      return Image;

    case 'xlsx':
    case 'xls':
    case 'csv':
      return GridOn;

    case 'doc':
    case 'docx':
    case 'txt':
      return Description;

    case 'ppt':
    case 'pptx':
      return Slideshow;

    default:
      return InsertDriveFile;
  }
};

export function FileComponent({ field, fieldModel, fieldState }: FieldProps<FileField>) {
  const [loading, setLoading] = useState<false | number>(false);
  const theme = useTheme();
  const fileUrl = field.value;
  const isImage = fieldModel.image;

  const handleProgress = (progress: number) => {
    setLoading(Math.round(progress * 100));
  };

  const onDrop = useCallback((files: FileList | File[]) => {
    setLoading(0);
    onUpload(files, fieldModel.uploadType, handleProgress)
      .then((data) => {
        field.onChange(data[0]?.url);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: isImage ? 'image/*' : fieldModel.accept,
  });

  const basename = fileUrl && fileUrl.split('/').pop();
  const Icon = fileUrl && getIconFromFileName(fileUrl);

  return (
    <StaticFormControl fieldModel={fieldModel} fieldState={fieldState}>
      {fileUrl && isImage && (
        <CardMedia
          component="img"
          height={fieldModel.height || 150}
          image={fileUrl}
          alt={basename}
        />
      )}

      {fileUrl && !isImage && (
        <List>
          <ListItemButton dense component="a" target="_blank" href={fileUrl}>
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText>{basename}</ListItemText>
          </ListItemButton>
        </List>
      )}

      {loading !== false ? (
        <CardContent>
          <LinearProgress variant="determinate" value={loading} />
        </CardContent>
      ) : (
        !fileUrl && (
          <CardContent
            {...getRootProps()}
            style={{
              textAlign: 'center',
              backgroundColor: isDragActive
                ? alpha(theme.palette.primary.main, 0.08)
                : theme.palette.background.paper,
            }}
          >
            <input {...getInputProps()} />
            <CloudUpload fontSize="large" sx={{ color: 'text.secondary' }} />
            <Box mt={2}>
              <Box component="label" fontWeight={500} style={{ cursor: 'pointer' }}>
                Choose a file
              </Box>
              <span> or drag it here.</span>
            </Box>
          </CardContent>
        )
      )}

      {fileUrl && (
        <CardHeader
          title={isImage ? basename : ''}
          titleTypographyProps={{ variant: 'caption' }}
          action={
            <>
              <IconButton component="label" onClick={(e: SyntheticEvent) => e.stopPropagation()}>
                <Upload />
                <input {...getInputProps()} />
              </IconButton>
              <IconButton onClick={() => field.onChange(null)}>
                <Close />
              </IconButton>
            </>
          }
        />
      )}
    </StaticFormControl>
  );
}

export default class FileField extends Field {
  image = false;
  uploadType = 'file';
  height = 150;
  accept?: string;

  renderEditComponent(props: UseControllerReturn) {
    return <FileComponent {...props} fieldModel={this} />;
  }

  renderCell(value: any) {
    if (this.image) {
      return <Avatar src={value} alt={this.label} variant="square" />;
    }
    return value;
  }
}
