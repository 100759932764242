import { Delete } from '@mui/icons-material';
import { Resource } from '@/classes';
import ButtonAction from '@/classes/Actions/ButtonAction';
import ActionsRenderer from '@/components/Actions/ActionsRenderer';
import { useAppContext } from '@/contexts/AppContext';
import useDialogs from '@/utils/hooks/useDialogs';

export default function SingleAppBarActions({
  resource,
  values,
  onDeleted,
}: {
  resource: Resource;
  values: Record<string, any>;
  onDeleted: () => void;
}) {
  const { setIsLoading } = useAppContext();
  const { confirm } = useDialogs();

  const handleDelete = () => {
    confirm({
      title: `Delete ${resource.singularName}`,
      description: 'Are you sure? This action may not be able to be undone.',
      color: 'error',
    }).then(() => {
      setIsLoading(true);
      resource
        .getDeleteRequest(values[resource.primaryKey])
        .then(() => {
          onDeleted();
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  const actionsArray = resource.getSingleActionsFunction(values);
  if (resource.deletable && Array.isArray(actionsArray)) {
    actionsArray.push(new ButtonAction('Delete', handleDelete, Delete));
  }

  return <ActionsRenderer resource={resource} actions={actionsArray} />;
}
