import { Menu } from '@mui/icons-material';
import { CustomAction, FieldFactory, LinkAction, Resource, SidebarLayout } from '@/classes';
import PrintMenu from '@/components/Print/PrintMenu';
import PurchaseOrderSummaryCard from '@/components/Purchasing/PurchaseOrderSummaryCard';
import ReceivePurchaseOrderField from '@/components/Receiving/ReceivePurchaseOrderField';
import ReceivingsField from '@/components/Receiving/ReceivingsField';
import SubMenu from '@/components/Shared/SubMenu';
import { PurchaseOrder } from '@/types';
import { useRecord } from '@/utils/genericResource';
import purchaseOrders from './purchaseOrders';

export default function purchaseOrdersToReceive() {
  return new Resource<PurchaseOrder>('Purchase Orders to Receive')
    .setReadOnly()
    .getTitleUsing((po) => `Receiving: ${po.increment_id}`)
    .withApiEndpoint('/api/purchase-orders')
    .setCanExport()
    .getSingleActionsUsing((values) => [
      new CustomAction('Print', () => <PrintMenu model="po" id={values.id} />),
      new CustomAction('Navigation', () => (
        <SubMenu
          primaryIcon={Menu}
          items={[
            new LinkAction('Purchase Order', `/purchase-orders/${values.id}`),
            new LinkAction('Receiving', '').setDisabled(),
          ]}
        />
      )),
    ])
    .withQueryParams({
      index: {
        to_receive: '1',
      },
    })
    .withInitialColumns([
      'increment_id',
      'vendor',
      'description',
      'invoice_number',
      'status',
      'issued_date',
      'expected_date',
      'type',
      'party',
    ])
    .withFilters([FieldFactory.text('tracking_number').withFilterKey('tracking_number')])
    .withColumns(purchaseOrders().columns)
    .withFields([
      new SidebarLayout('grid')
        .withoutDefaultLayout()
        .withFields([
          FieldFactory.custom('items', ReceivePurchaseOrderField),
          FieldFactory.custom('receivings', ReceivingsField),
        ])
        .withSidebarFields([
          FieldFactory.custom('summary', () => (
            <PurchaseOrderSummaryCard purchaseOrder={useRecord('purchaseOrdersToReceive')} />
          )),
        ]),
    ]);
}
