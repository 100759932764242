import { Alert, Box, Grid2 as Grid, Typography } from '@mui/material';
import axios from 'axios';
import startCase from 'lodash/startCase';
import { z } from 'zod';
import { FieldFactory } from '@/classes';
import OrderInventoryMapping from '@/components/Inventory/OrderInventoryMapping';
import OrderKitting from '@/components/Kitting/OrderKitting';
import RequiresScreenWidth from '@/components/Shared/RequiresScreenWidth';
import OrderShipmentsCard from '@/components/Shipping/OrderShipmentsCard';
import TextButton from '@/components/Text/TextButton';
import { SHIPPING_MODE_OPTIONS } from '@/constants';
import { Order, shippingModeSchema } from '@/types';
import { useOnReloadRecord, useRecord } from '@/utils/genericResource';
import useDialogs from '@/utils/hooks/useDialogs';
import { isInventory } from '@/utils/shipping';
import OrderShippingAddresses from './OrderShippingAddresses';

function ShippingModePreview() {
  const shippingMode = useRecord<Order>().shipping_mode;

  if (shippingMode === 'kits') {
    return (
      <Alert severity="info">
        This shipping mode allows items from this order and/or other production orders to be kitted
        and split shipped to mulitple addresses.
      </Alert>
    );
  }
  if (isInventory(shippingMode)) {
    return (
      <Alert severity="info">
        This shipping mode specifies that the items in the order be added to inventory. In the case
        of decorated inventory, you will be able to specify how the items in the order map to SKUs
        in Avail inventory.
      </Alert>
    );
  }

  return null;
}

export default function OrderShippingTab() {
  const order = useRecord<Order>();
  const { prompt } = useDialogs();
  const onReload = useOnReloadRecord();

  const onShippingMode = () => {
    prompt({
      title: 'Update Shipping Mode',
      fields: [
        FieldFactory.radio('shipping_mode', SHIPPING_MODE_OPTIONS).withoutLabel(),
        FieldFactory.custom('preview', ShippingModePreview),
      ],
      schema: z.object({
        shipping_mode: shippingModeSchema,
      }),
      initialValues: { shipping_mode: order.shipping_mode },
      onSubmit: (v) => axios.put(`/api/orders/${order.id}/shipping-mode`, v),
    }).then(() => {
      onReload();
    });
  };

  return (
    <div>
      <Box mb={2} textAlign="right">
        <Typography>
          Shipping Mode:{' '}
          <TextButton disabled={!!order.invoiced_at} onClick={onShippingMode}>
            {startCase(order.shipping_mode)}
          </TextButton>
        </Typography>
      </Box>
      {order.shipping_mode === 'bulk' && (
        <Grid container spacing={1}>
          <Grid size={{ xs: 12, lg: 5 }}>
            <OrderShippingAddresses />
          </Grid>
          <Grid size={{ xs: 12, lg: 7 }}>
            <OrderShipmentsCard showActions={false} />
          </Grid>
        </Grid>
      )}
      {order.shipping_mode === 'kits' && (
        <RequiresScreenWidth width={1400}>
          <OrderKitting />
        </RequiresScreenWidth>
      )}
      {isInventory(order.shipping_mode) && <OrderInventoryMapping />}
    </div>
  );
}
