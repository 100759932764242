import { Edit } from '@mui/icons-material';
import { Box, IconButton, styled, Typography } from '@mui/material';
import axios from 'axios';
import get from 'lodash/get';
import { Field, FieldFactory } from '@/classes';
import AddressBlock from '@/components/Addresses/AddressBlock';
import BillingAddressChooser from '@/components/Addresses/BillingAddressChooser';
import ContactChooser from '@/components/Orders/Dashboard/ContactChooser';
import OrderTypeLabel from '@/components/Orders/OrderTypeLabel';
import Can from '@/components/Permissions/Can';
import StatusChip from '@/components/Shared/StatusChip';
import TextLink from '@/components/Text/TextLink';
import UserLabel from '@/components/Users/UserLabel';
import { OrderUpdatePayload, orderUpdatePayloadSchema, OrderWithDetails, Quote } from '@/types';
import { useUpdateRecord } from '@/utils/genericResource';
import { useHasPermission } from '@/utils/hooks/permissions';
import useApiSegment from '@/utils/hooks/useApiSegment';
import useDialogs from '@/utils/hooks/useDialogs';
import renderCell from '@/utils/renderCell';

const Row = styled('div')(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.action.selected}`,
  padding: '12px 24px',
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
  },
}));

const Value = styled('div')({
  flexGrow: 1,
});

const Label = styled('div')({
  width: 150,
  marginRight: 16,
  alignItems: 'center',
  display: 'flex',
  flexShrink: 0,
});

export default function OrderDetails({
  isReadOnly,
  order,
}: {
  isReadOnly: boolean;
  order: OrderWithDetails | Quote;
}) {
  const { customer, id: orderId, order_type: orderType } = order;
  const segment = useApiSegment(1);
  const updateRecord = useUpdateRecord();
  const hasPermission = useHasPermission();
  const { drawerPrompt } = useDialogs();

  const fields: Field[] = [
    FieldFactory.belongsTo('customer', 'customers')
      .withRequestParams({ is_disabled: 0 })
      .withColumnSpan(12),
    FieldFactory.belongsTo('order_type', 'orderTypes')
      .withRequestParams({ count: 100 })
      .withColumnSpan(12),
    FieldFactory.belongsTo('department', 'departments')
      .withRequestParams({ customer: customer.id, with: 'set' })
      .withColumnSpan(12),
    FieldFactory.text('contact_first_name').withColumnSpan(6),
    FieldFactory.text('contact_last_name').withColumnSpan(6),
    FieldFactory.email('contact_email').withColumnSpan(12),
    FieldFactory.phone('contact_phone').withColumnSpan(12),
    FieldFactory.text('contact_title').withColumnSpan(12),
    FieldFactory.text('description').withColumnSpan(12),
    FieldFactory.belongsTo('sales_rep', 'users')
      .withColumnSpan(6)
      .renderCellUsing((u) => <UserLabel user={u} />),
  ];

  if (segment === 'quotes') {
    fields.push(FieldFactory.date('placed_at').withLabel('Date').withColumnSpan(12));
  } else {
    fields.push(
      FieldFactory.belongsTo('terms', 'terms').withColumnSpan(12),
      FieldFactory.text('customer_po').withLabel('Customer PO').withColumnSpan(12),
    );
  }

  fields.push(
    FieldFactory.textarea('memo')
      .withLabel('Invoice Memo')
      .withColumnSpan(12)
      .withHelp('This message will show on the invoice PDF as well as the approval link.'),
    FieldFactory.custom('billing_address', BillingAddressChooser)
      .withColumnSpan(12)
      .renderCellUsing((v) => (v ? <AddressBlock address={v} /> : '(none)')),
  );

  const columns = fields.filter((f) =>
    [
      'department',
      'description',
      'sales_rep',
      'terms',
      'customer_po',
      'placed_at',
      'billing_address',
    ].includes(f.name),
  );

  const onEdit = () => {
    drawerPrompt({
      title: 'Edit Order Details',
      fields: fields.filter((f) => f.editable),
      schema: orderUpdatePayloadSchema,
      initialValues: order as unknown as OrderUpdatePayload,
      onSubmit: (v) => axios.put(`/api/${segment}/${orderId}`, v),
    }).then(({ data }) => {
      updateRecord(data);
    });
  };

  return (
    <Box borderRight={1} borderColor="grey.300">
      <Box display="flex" px={3} py={2} alignItems="center">
        <div>
          <TextLink disabled={!hasPermission('write:customers')} to={`/customers/${customer.id}`}>
            <Typography
              variant="subtitle1"
              style={{ fontSize: '1.1rem', display: 'inline-block' }}
              gutterBottom
            >
              {customer.name}
            </Typography>
          </TextLink>
          <OrderTypeLabel type={orderType} variant="h5" />
        </div>

        {'parent_id' in order && order.parent_id && (
          <StatusChip
            status="Backorder"
            colors={{
              Backorder: 'amber',
            }}
            style={{ marginLeft: 32 }}
          />
        )}

        {'is_scrap' in order && order.is_scrap && (
          <StatusChip
            status="Scrap"
            colors={{
              Scrap: 'purple',
            }}
            style={{ marginLeft: 32 }}
          />
        )}

        {!isReadOnly && (
          <Can permission="write:orders">
            <Box ml="auto">
              <IconButton onClick={onEdit} size="large">
                <Edit />
              </IconButton>
            </Box>
          </Can>
        )}
      </Box>
      <div>
        <Row>
          <Label>
            <Typography variant="subtitle2">Contact</Typography>
          </Label>
          <Value>
            <ContactChooser isReadOnly={isReadOnly} />
          </Value>
        </Row>
        {columns.map((f) => (
          <Row key={f.name}>
            <Label>
              <Typography variant="subtitle2">{f.label}</Typography>
            </Label>
            <Value>
              <Typography variant="body1">{renderCell(f, get(order, f.name), order)}</Typography>
            </Value>
          </Row>
        ))}
      </div>
    </Box>
  );
}
