import { Button, Typography } from '@mui/material';
import axios from 'axios';
import pick from 'lodash/pick';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { FieldFactory } from '@/classes';
import { useShowLoading } from '@/contexts/AppContext';
import { PurchaseOrder, PurchaseOrderPayload } from '@/types';
import { useOnReloadRecord, useRecord } from '@/utils/genericResource';
import { useHasPermission } from '@/utils/hooks/permissions';
import useDialogs from '@/utils/hooks/useDialogs';

export default function PurchaseOrderActions() {
  const {
    submitted_at: submittedAt,
    integration_name: integrationName,
    id,
    invoice_number: invoiceNumber,
    submit_method: submitMethod,
  } = useRecord<PurchaseOrder>();
  const { confirm, prompt } = useDialogs();
  const form = useFormContext<PurchaseOrderPayload>();
  const onReload = useOnReloadRecord();
  const showLoading = useShowLoading();
  const hasPermission = useHasPermission();

  const maybeSaveFirst = () => {
    const {
      formState: { dirtyFields },
      reset,
      watch,
    } = form;
    const dirtyKeys = Object.keys(dirtyFields);
    if (dirtyKeys.length === 0) {
      return Promise.resolve();
    }
    const dirty = pick(watch(), dirtyKeys);
    return axios.put<PurchaseOrder>(`/api/purchase-orders/${id}`, dirty).then(({ data }) => {
      reset({
        ...data,
        description: data.description || '',
      });
    });
  };

  const markUnsubmitted = () => {
    confirm({ title: 'Mark PO Unsubmitted', description: 'Are you sure?', color: 'error' }).then(
      () => {
        showLoading(
          maybeSaveFirst()
            .then(() => axios.post(`/api/purchase-orders/${id}/mark-unsubmitted`))
            .then(() => {
              onReload();
            }),
        );
      },
    );
  };
  const markSubmitted = () => {
    prompt({
      title: 'Mark PO Submitted',
      fields: [
        FieldFactory.select('submit_method', {
          online: 'Online',
          email: 'Email',
          phone: 'Phone',
          other: 'Other',
        }).withHelp('How did you submit this PO?'),
        FieldFactory.text('invoice_number'),
      ],
      schema: z.object({
        submit_method: z.string(),
        invoice_number: z.string().nullish(),
      }),
      initialValues: {
        submit_method: submitMethod || undefined,
        invoice_number: invoiceNumber,
      },
      onSubmit: (values) =>
        maybeSaveFirst().then(() =>
          axios.post(`/api/purchase-orders/${id}/mark-submitted`, values),
        ),
    }).then(() => {
      onReload();
    });
  };

  const submitUsingIntegration = () => {
    confirm({
      title: 'Submit PO Automatically',
      description: (
        <div>
          <span>Are you sure you want to submit this PO? This will use</span>
          <i> {integrationName} </i>
          <span>
            to submit the PO to the vendor. Please make sure you have checked inventory first.
          </span>
        </div>
      ),
    }).then(() => {
      showLoading(
        maybeSaveFirst()
          .then(() => axios.post(`/api/purchase-orders/${id}/submit`))
          .then(() => {
            onReload();
          }),
      );
    });
  };

  if (submittedAt) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Button onClick={markUnsubmitted} size="small">
          Mark Unsubmitted
        </Button>
      </div>
    );
  }

  if (integrationName && hasPermission('purchase_orders:auto_submit')) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Typography variant="body2" gutterBottom>
          This PO can be submitted automatically.
        </Typography>
        <Button onClick={submitUsingIntegration} variant="outlined" size="small" sx={{ mr: 1 }}>
          Auto Submit
        </Button>
        <Button onClick={markSubmitted} size="small">
          Mark Submitted
        </Button>
      </div>
    );
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <Typography variant="body2" gutterBottom>
        This PO needs to be manually submitted.
      </Typography>
      <Button onClick={markSubmitted}>Mark Submitted</Button>
    </div>
  );
}
