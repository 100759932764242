import { useMemo } from 'react';
import { Resource, FieldFactory } from '@/classes';
import DataTable from '@/components/DataTable/DataTable';
import { PAYMENT_METHODS } from '@/constants';
import { DataTableState, FieldProps } from '@/types';
import wrap from '@/utils/wrap';

export default function DepositPaymentSelector({ field }: FieldProps) {
  const selected = wrap(field.value) as { id: number }[];

  const resource = useMemo(
    () =>
      new Resource('Payments')
        .withQueryParams({
          index: {
            undeposited: 'true',
            'filter[payment_type]': 'payment',
          },
          single: {},
        })
        .withColumns([
          FieldFactory.date('payment_date').filterable().sortable(),
          FieldFactory.belongsTo('customer', 'customers').filterable(),
          FieldFactory.text('payment_number').sortable(),
          FieldFactory.curr('amount').sortable(),
          FieldFactory.select('method', PAYMENT_METHODS).filterable(),
        ]),
    [],
  );

  const onStateUpdated = (s: DataTableState) => {
    field.onChange(s.selected.map((id) => ({ id })));
  };

  return (
    <DataTable
      resource={resource}
      onStateUpdated={onStateUpdated}
      initialState={{
        selected: selected.map((s) => s.id),
      }}
    />
  );
}
