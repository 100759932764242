import { Close } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import sumBy from 'lodash/sumBy';
import moment from 'moment';
import { z } from 'zod';
import { FieldFactory } from '@/classes';
import CustomerCommissions from '@/components/Customers/CustomerCommissions';
import Can from '@/components/Permissions/Can';
import ColoredCurrency from '@/components/Shared/ColoredCurrency';
import Label from '@/components/Shared/Label';
import Paper from '@/components/Shared/Paper';
import { useConfig } from '@/contexts/AppConfigContext';
import { useShowLoading } from '@/contexts/AppContext';
import { shortenNum } from '@/helpers';
import { OrderCommission } from '@/types';
import { genericModelReferenceSchema, orderCommissionUpdatePayloadSchema } from '@/types';
import curr from '@/utils/curr';
import { makeResourceQueryKey, useOnReloadRecord, useRecordId } from '@/utils/genericResource';
import { useHasPermission } from '@/utils/hooks/permissions';
import useDialogs from '@/utils/hooks/useDialogs';

interface SalesRepGroup {
  id: number;
  name: string;
  email: string;
  method: 'gp' | 'cogs';
  percent: number;
  earned: number;
  realized: number;
  dinged: number;
  pending: number;
  lines: OrderCommission[];
}

export default function OrderCommissions() {
  const orderId = useRecordId();
  const { confirm, prompt } = useDialogs();
  const { user } = useConfig();
  const onReload = useOnReloadRecord();
  const hasPermission = useHasPermission();
  const showLoading = useShowLoading();
  const {
    data: commissions,
    refetch,
    isFetching,
  } = useQuery(
    makeResourceQueryKey('orders', orderId, 'commissions'),
    () =>
      axios
        .get<{
          sales_reps: SalesRepGroup[];
          data: OrderCommission[];
          next: number;
        }>(`/api/orders/${orderId}/commissions`)
        .then(({ data }) => data),
    { enabled: hasPermission('read:order_commissions') },
  );

  if (!commissions) {
    return null;
  }

  const onAdjustment = () => {
    prompt({
      title: 'Add Commission Adjustment',
      fields: [
        FieldFactory.belongsTo('user', 'users'),
        FieldFactory.curr('amount'),
        FieldFactory.text('description'),
      ],
      schema: z.object({
        user: genericModelReferenceSchema,
        amount: z.coerce.number(),
        description: z.string(),
      }),
      onSubmit: (values) =>
        axios.post(`/api/orders/${orderId}/commissions`, {
          ...values,
          user_id: values.user.id,
        }),
    }).then(() => {
      refetch();
    });
  };

  const onRemove = (commission: OrderCommission) => {
    confirm({
      title: 'Delete Commission Adjustment',
      description: 'Are you sure you want to delete this adjustment?',
    }).then(() => {
      axios.delete(`/api/orders/${orderId}/commissions/${commission.id}`).then(() => {
        refetch();
      });
    });
  };

  const onReset = () =>
    confirm({
      title: 'Reset Commissions',
      description:
        "Are you sure? This will replace the current commission settings for this order with the customer's commission settings.",
    }).then(() => {
      axios.post(`/api/orders/${orderId}/reset-commissions`).then(() => {
        onReload();
      });
    });

  const onManage = async () => {
    const rows = await showLoading(
      axios
        .get(`/api/orders/${orderId}/commission-users`)
        .then(({ data }) => data.commission_users),
    );

    prompt({
      title: 'Manage Commissionable Users',
      fields: [FieldFactory.custom('commission_users', CustomerCommissions).withColumnSpan(12)],
      schema: orderCommissionUpdatePayloadSchema,
      initialValues: { commission_users: rows },
      maxWidth: 'md',
      onSubmit: (v) => axios.put(`/api/orders/${orderId}/commission-users`, v),
    }).then(() => {
      onReload();
    });
  };

  return (
    <Can permission="read:order_commissions">
      <Box mb={3}>
        {isFetching && <LinearProgress />}
        {commissions.sales_reps.length === 0 && (
          <Paper title="Commission">
            No sales reps are set up to receive commission for this order.
          </Paper>
        )}
        {commissions.sales_reps
          .filter((rep) => hasPermission('write:order_commissions') || rep.id === user.id)
          .map((rep) => (
            <Card key={rep.id} sx={{ mb: 1 }}>
              <CardContent>
                <Box display="flex" justifyContent="space-between">
                  <div>
                    <Typography variant="h5">Commission: {rep.name}</Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      {shortenNum(rep.percent)}%{' '}
                      {{ gp: 'of Gross Profit', cogs: 'Markup on Cost' }[rep.method] || 'of ?'}
                    </Typography>
                  </div>
                  <Box textAlign="right">
                    <Typography variant="h3" color="primary" sx={{ mb: 1 }}>
                      {curr(rep.earned)}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      <span>{curr(rep.realized)} Realized</span>
                      <span> + </span>
                      <span>{curr(rep.pending)} Pending</span>
                    </Typography>
                  </Box>
                </Box>

                {rep.dinged > 0 && (
                  <Box my={1}>
                    <Alert severity="warning">
                      {curr(rep.dinged)} dinged due to late customer payment.
                    </Alert>
                  </Box>
                )}
              </CardContent>
              {rep.lines.length > 0 ? (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableCell>Date</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell />
                    </TableHead>
                    <TableBody>
                      {rep.lines.map((commission) => (
                        <TableRow key={commission.id}>
                          <TableCell style={{ minWidth: 160 }}>
                            {moment(commission.created_at).format('lll')}
                          </TableCell>
                          <TableCell>{commission.description}</TableCell>
                          <TableCell>
                            <ColoredCurrency bold amount={commission.amount} />
                          </TableCell>
                          <TableCell style={{ whiteSpace: 'nowrap' }}>
                            {commission.created_by_user && (
                              <>
                                <Tooltip title={`Adjusted By ${commission.created_by_user.name}`}>
                                  <div>
                                    <Label>Adjustment</Label>
                                  </div>
                                </Tooltip>
                                <Can permission="write:order_commissions">
                                  <IconButton
                                    size="small"
                                    onClick={() => onRemove(commission)}
                                    sx={{ ml: 1 }}
                                  >
                                    <Close fontSize="small" />
                                  </IconButton>
                                </Can>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Typography variant="subtitle2">Total</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {curr(sumBy(commissions.data, 'amount'))}
                        </Typography>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </Table>
                </TableContainer>
              ) : null}
            </Card>
          ))}
        <Can permission="write:order_commissions">
          <Stack direction="row" spacing={2}>
            <Button type="button" size="small" onClick={onAdjustment}>
              Add Commission Adjustment
            </Button>
            <Button type="button" size="small" onClick={onManage}>
              Manage
            </Button>
            <Button type="button" size="small" onClick={onReset}>
              Reset
            </Button>
          </Stack>
        </Can>
      </Box>
    </Can>
  );
}
