import { ForwardedRef, forwardRef, SyntheticEvent, useImperativeHandle, useRef } from 'react';
import { Tag } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, IconButton } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import AttachmentButton from '@/components/Attachments/AttachmentButton';
import AttachmentsList from '@/components/Attachments/AttachmentsList';
import { Attachment, AttachmentPayload, NotePayload } from '@/types';
import {
  attachmentToPayload,
  payloadToAttachment,
  uploadedFileToPayload,
} from '@/utils/attachments';
import useSessionState from '@/utils/hooks/useSessionState';
import { MentionUser } from '@/utils/notes';
import MentionTextArea, { EditorHandle } from './MentionTextArea';

function NoteForm(
  {
    formKey,
    onSubmit,
    initialValue = '',
    initialAttachments = [],
    placeholder = 'Add a note',
    allowedTags,
    users,
  }: {
    formKey: string;
    onSubmit: (v: NotePayload) => Promise<any>;
    initialValue?: string;
    initialAttachments?: Attachment[];
    placeholder?: string;
    allowedTags: string[];
    users: MentionUser[];
  },
  ref: ForwardedRef<EditorHandle>,
) {
  const editorRef = useRef<EditorHandle | null>(null);
  const [note, setNote] = useSessionState(`${formKey}.note`, initialValue);
  const [attachments, setAttachments] = useSessionState<AttachmentPayload[]>(
    `${formKey}.attachments`,
    initialAttachments.map(attachmentToPayload),
  );

  useImperativeHandle(ref, () => editorRef.current!);

  const { mutateAsync, isLoading } = useMutation((note: NotePayload) => onSubmit(note));

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (!note) {
      return;
    }

    mutateAsync({ note, attachments }).then(() => {
      setNote('');
      setAttachments([]);
    });
  };

  return (
    <form onSubmitCapture={handleSubmit} id="note-form">
      <MentionTextArea
        ref={editorRef}
        value={note}
        onChange={setNote}
        placeholder={placeholder}
        allowedTags={allowedTags}
        users={users}
      />

      {attachments && attachments.length > 0 ? (
        <AttachmentsList
          attachments={attachments.map(payloadToAttachment)}
          onRemove={(a) => setAttachments((prev) => prev.filter((p) => p.file !== a.url))}
        />
      ) : null}

      <Box display="flex" alignItems="center" mt={1}>
        <AttachmentButton
          onFiles={(files) => {
            setAttachments((prev) => [...prev, ...files.map((f) => uploadedFileToPayload(f))]);
          }}
        />
        <IconButton onClick={() => editorRef.current?.insertText('@')} sx={{ width: 40 }}>
          <span style={{ fontSize: 20 }}>@</span>
        </IconButton>
        <IconButton onClick={() => editorRef.current?.insertText('#')} sx={{ flexShrink: 0 }}>
          <Tag />
        </IconButton>

        <LoadingButton type="submit" variant="contained" loading={isLoading} sx={{ ml: 'auto' }}>
          Submit
        </LoadingButton>
      </Box>
    </form>
  );
}

export default forwardRef(NoteForm);
