import { zodResolver } from '@hookform/resolvers/zod';
import { Alert } from '@mui/material';
import { AxiosError } from 'axios';
import { FieldValues, UseFormReturn, useForm, DefaultValues } from 'react-hook-form';
import { z } from 'zod';
import type { Field, Layout } from '@/classes';

export const handleLaravelErrors =
  <T extends FieldValues = FieldValues>(form: Pick<UseFormReturn<T>, 'setError'>) =>
  (error: AxiosError<{ message?: string; errors?: Record<string, string[]> }>) => {
    const data = error.response?.data;
    if (!data) {
      return;
    }
    if (data.message) {
      form.setError('root', {
        type: 'server',
        message: data.message,
      });
    }
    if (data.errors) {
      for (const [key, value] of Object.entries(data.errors)) {
        // @ts-expect-error Some weird TS generic issue
        form.setError(key, {
          type: 'server',
          message: value[0],
        });
      }
    }
  };

export function getInitialValues<T extends FieldValues = FieldValues>(
  filterableFields: (Field | Layout)[],
  initialValues: DefaultValues<T>,
): T {
  return filterableFields.reduce((acc, f) => {
    if ('inputProps' in f) {
      acc[f.name] = f.name in initialValues ? initialValues[f.name] : null;
    }
    if ('fields' in f) {
      Object.assign(acc, getInitialValues(f.fields, initialValues));
    }
    return acc;
  }, {} as FieldValues) as T;
}

export function maybeRenderRootError<T extends FieldValues>(form: UseFormReturn<T>) {
  if (form.formState.errors.root) {
    return <Alert color="error">{form.formState.errors.root.message}</Alert>;
  }
  return null;
}

export function generateFormName(resourceName: string): string {
  return 'generic_' + resourceName;
}

export function useZodForm<TFieldValues extends FieldValues>(
  zodSchema: z.ZodType<TFieldValues>,
  defaultValues?: DefaultValues<TFieldValues>,
): UseFormReturn<TFieldValues> {
  return useForm<TFieldValues>({
    resolver: zodResolver(zodSchema),
    defaultValues,
    criteriaMode: 'all',
  });
}
