import { CustomAction, FieldFactory, Resource, SidebarLayout } from '@/classes';
import OrderSummaryField from '@/components/Orders/OrderSummaryField';
import PrintMenu from '@/components/Print/PrintMenu';
import ReceivePurchaseOrderField from '@/components/Receiving/ReceivePurchaseOrderField';
import ReceivingsField from '@/components/Receiving/ReceivingsField';
import { Order } from '@/types';
import { getOrderColumns } from './orders';

export default function ordersToReceive() {
  return new Resource<Order>('Orders to Receive')
    .getTitleUsing((o) => `Receiving: ${o.increment_id}`)
    .withApiEndpoint('/api/orders')
    .getSingleActionsUsing((v) => [
      new CustomAction('Print', () => <PrintMenu model="order" id={v.id} />),
    ])
    .setReadOnly()
    .setCanExport()
    .withDefaultSort('committed_ship_date')
    .withQueryParams({
      index: {
        to_receive: '1',
      },
      single: {
        with: 'purchase_order_items',
      },
    })
    .withInitialColumns([
      'increment_id',
      'customer',
      'order_type',
      'status',
      'drop_dead_date',
      'committed_ship_date',
      'actions',
    ])
    .withColumns(getOrderColumns())
    .withFields([
      new SidebarLayout('grid')
        .withoutDefaultLayout()
        .withFields([
          FieldFactory.custom('purchase_order_items', ReceivePurchaseOrderField),
          FieldFactory.custom('receivings', ReceivingsField),
        ])
        .withSidebarFields([FieldFactory.custom('summary', OrderSummaryField)]),
    ]);
}
