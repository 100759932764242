import { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Grid2 as Grid } from '@mui/material';
import axios from 'axios';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';
import { z } from 'zod';
import { FieldFactory } from '@/classes';
import DrawerButtons from '@/components/Form/DrawerButtons';
import Form from '@/components/Form/Form';
import FormErrors from '@/components/Form/FormErrors';
import FormField from '@/components/Form/FormField';
import PricingResults from '@/components/PricingEngine/PricingResults';
import Paper from '@/components/Shared/Paper';
import { genericModelReferenceSchema } from '@/types';
import { useZodForm } from '@/utils/form';

const formSchema = z.object({
  items: z.array(
    z.object({
      name: z.string(),
      qty: z.coerce.number().int().min(1),
      cost: z.coerce.number(),
    }),
  ),
  designs: z.array(
    z.object({
      decoration_type: genericModelReferenceSchema,
      complexity: z.coerce.number().nullish(),
      special_design_type: genericModelReferenceSchema.nullish(),
    }),
  ),
  art_costs: z.array(
    z.object({
      item: genericModelReferenceSchema,
      qty: z.coerce.number().int().min(1),
    }),
  ),
});

export default function PricingEngine() {
  const form = useZodForm(formSchema, {
    items: [{ name: '', qty: 12, cost: 5 }],
    designs: [],
    art_costs: [],
  });
  const { formState, watch } = form;
  const items = watch('items');
  const [results, setResults] = useState<{ unit_cost: number }>();

  const onSubmit = (values: z.infer<typeof formSchema>) =>
    axios
      .post<{ unit_cost: number }>('/api/estimate', {
        qty: sumBy(values.items, (i) => Number(i.qty)),
        decoration: map(values.designs, (d) => ({
          decoration_type_id: d.decoration_type.id,
          complexity: d.complexity,
          special_design_type_id: d.special_design_type?.id,
        })),
        art_costs: values.art_costs.map((v) => ({
          art_cost_item_id: v.item.id,
          qty: v.qty,
        })),
      })
      .then(({ data }) => {
        setResults(data);
      });

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Paper title="Blank Product">
            <FormField
              field={FieldFactory.list('items', [
                FieldFactory.text('name')
                  .withLabel('Garment Name')
                  .withPlaceholder('e.g. White Locker Tees')
                  .withColumnSpan(6),
                FieldFactory.number('qty').withColumnSpan(2),
                FieldFactory.curr('cost').withColumnSpan(3),
              ])}
            />
          </Paper>
          <Paper title="Decoration">
            <FormField
              field={FieldFactory.list('designs', [
                FieldFactory.belongsTo('decoration_type', 'decorationTypes'),
                FieldFactory.number('complexity').withLabel('Complexity (Colors/Stitches)'),
                FieldFactory.belongsTo('special_design_type', 'specialDesignTypes').withColumnSpan(
                  3,
                ),
              ])}
            />
          </Paper>
          <Paper title="Art Costs">
            <FormField
              field={FieldFactory.list('art_costs', [
                FieldFactory.belongsTo('item', 'artCostItems').withColumnSpan(6),
                FieldFactory.number('qty').withLabel('Quantity').withColumnSpan(5),
              ])}
            />
          </Paper>

          <FormErrors form={form} />

          {formState.isDirty && (
            <DrawerButtons>
              <LoadingButton type="submit" variant="contained" loading={formState.isSubmitting}>
                Calculate
              </LoadingButton>
            </DrawerButtons>
          )}
        </Grid>

        <Grid size={{ xs: 12, md: 6 }}>
          {results && items && (
            <PricingResults items={items} decorationUnitCost={results.unit_cost} />
          )}
        </Grid>
      </Grid>
    </Form>
  );
}
