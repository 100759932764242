import { ChangeEvent, SyntheticEvent, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Alert,
  Drawer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import axios from 'axios';
import get from 'lodash/get';
import map from 'lodash/map';
import sum from 'lodash/sum';
import toString from 'lodash/toString';
import values from 'lodash/values';
import { useNavigate } from 'react-router-dom';
import DrawerButtons from '@/components/Form/DrawerButtons';
import { OrderItem } from '@/types';
import curr from '@/utils/curr';
import useToast from '@/utils/hooks/useToast';
import OrderItemProduct from './OrderItemProduct';

export default function CreateBackorderDrawer({
  open,
  onClose,
  items,
  orderId,
}: {
  open: boolean;
  onClose: () => void;
  items: OrderItem[];
  orderId: number;
}) {
  const [quantities, setQuantities] = useState<Record<string, number>>({});
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const toast = useToast();

  const handleQtyChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    setQuantities((prevState) => ({
      ...prevState,
      [id]: Number(value),
    }));
  };

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const payload = {
      items: map(quantities, (v, k) => ({
        order_item_id: k,
        qty: v || 0,
      })),
    };

    setSubmitting(true);
    setError(null);
    axios
      .post(`/api/orders/${orderId}/backorder`, payload)
      .then(({ data }) => {
        onClose();
        navigate(`/orders/${data.id}`);
        toast('The backorder was successfully created');
      })
      .catch((err) => {
        setError(
          get(err, 'response.data.message', 'Something went wrong. Unable to create backorder'),
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const itemsSum = sum(values(quantities));

  return (
    <Drawer anchor="bottom" open={open} onClose={onClose}>
      <div
        style={{
          padding: '24px',
          maxHeight: 600,
          overflowY: 'auto',
        }}
      >
        Please specify which items to move to the backorder.
        <form onSubmit={onSubmit}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Qty</TableCell>
                <TableCell>Qty Rec</TableCell>
                <TableCell>Qty Shp</TableCell>
                <TableCell>Qty to Move to Backorder</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>
                    <OrderItemProduct item={item} showSize />
                  </TableCell>
                  <TableCell>{curr(item.price, { maxDecimals: 4 })}</TableCell>
                  <TableCell>{item.qty}</TableCell>
                  <TableCell>{item.qty_received}</TableCell>
                  <TableCell>{item.qty_shipped}</TableCell>
                  <TableCell>
                    <TextField
                      inputProps={{
                        id: String(item.id),
                        max: item.qty,
                        type: 'number',
                      }}
                      variant="outlined"
                      onChange={handleQtyChange}
                      value={quantities[toString(item.id)]}
                      size="small"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {error && <Alert severity="error">{error}</Alert>}

          <DrawerButtons>
            <LoadingButton
              type="submit"
              variant="contained"
              disabled={itemsSum <= 0}
              loading={submitting}
            >
              Create Backorder
            </LoadingButton>
          </DrawerButtons>
        </form>
      </div>
    </Drawer>
  );
}
