import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import moment from 'moment';
import { z } from 'zod';
import { FieldFactory } from '@/classes';
import { CustomerUser, membershipRoleSchema } from '@/types';
import { useRecordId } from '@/utils/genericResource';
import useDialogs from '@/utils/hooks/useDialogs';
import useMutateQueryData from '@/utils/hooks/useMutateQueryData';
import useToast from '@/utils/hooks/useToast';

export default function CustomerUsers() {
  const customerId = useRecordId();
  const { confirm, drawerPrompt } = useDialogs();

  const toast = useToast();
  const setUsers = useMutateQueryData<CustomerUser[]>(['customerUsers', customerId]);
  const { data: users } = useQuery(['customerUsers', customerId], () =>
    axios
      .get<{ data: CustomerUser[] }>(`/api/customers/${customerId}/users`)
      .then(({ data }) => data.data),
  );

  const onCreate = () => {
    drawerPrompt({
      title: 'Invite User',
      description:
        'This will either send an invite for a new user, or add an existing user to this customer.',
      fields: [
        FieldFactory.email('email'),
        FieldFactory.select('role', ['admin', 'member', 'guest', 'avail']),
      ],
      schema: z.object({
        email: z.string().email(),
        role: membershipRoleSchema,
      }),
      onSubmit: (v) =>
        axios.post<CustomerUser | { email: string }>(`/api/customers/${customerId}/users`, v),
    }).then(({ data }) => {
      if ('name' in data) {
        setUsers((prev) => [...prev, data]);
      } else {
        toast(`An invite has been sent to ${data.email}.`);
      }
    });
  };

  const onRemove = (user: CustomerUser) => {
    confirm({
      title: 'Remove User',
      description: 'Are you sure you want to remove this user?',
    }).then(() => {
      axios.delete(`/api/customers/${customerId}/users/${user.id}`);
      setUsers((prev) => prev.filter((u) => u.id !== user.id));
    });
  };

  if (!users) {
    return <CircularProgress />;
  }

  return (
    <div>
      {users.length > 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Last Logged In</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.role}</TableCell>
                <TableCell>
                  {user.last_logged_in
                    ? `Last logged in ${moment(user.last_logged_in).format('lll')}`
                    : 'Never logged in'}
                </TableCell>
                <TableCell>
                  <IconButton edge="end" aria-label="delete" onClick={() => onRemove(user)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Typography color="textSecondary" gutterBottom>
          There are no users for this customer.
        </Typography>
      )}

      <Button type="button" onClick={onCreate} sx={{ mt: 1 }}>
        Add New
      </Button>
    </div>
  );
}
