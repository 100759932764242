import { OpenInNew } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid2 as Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import startCase from 'lodash/startCase';
import moment from 'moment';
import { CustomAction, FieldFactory, Resource, SidebarLayout } from '@/classes';
import OrderListItem from '@/components/Orders/OrderListItem';
import PrintMenu from '@/components/Print/PrintMenu';
import StatusChip from '@/components/Shared/StatusChip';
import TextLink from '@/components/Text/TextLink';
import { PROJECT_STATUS_COLORS, SHIPPING_MODE_OPTIONS } from '@/constants';
import { getEventableConfigForResource } from '@/helpers';
import { AvailProject, Order } from '@/types';
import curr from '@/utils/curr';
import { useRecord } from '@/utils/genericResource';

export function ProjectView() {
  const project = useRecord<AvailProject>();

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid size={12}>
            <Typography variant="subtitle2" gutterBottom>
              Description:
            </Typography>
            <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
              {project.description}
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, md: 6, lg: 4 }}>
            <Typography variant="subtitle2">Customer</Typography>
            <Typography variant="body1">
              <TextLink to={`/customers/${project.customer.id}`}>{project.customer.name}</TextLink>
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, md: 6, lg: 4 }}>
            <Typography variant="subtitle2">Status</Typography>
            <Typography variant="body1">
              <StatusChip status={project.status} colors={PROJECT_STATUS_COLORS} />
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, md: 6, lg: 4 }}>
            <Typography variant="subtitle2">In-Hands Date</Typography>
            <Typography variant="body1">{moment(project.in_hands_date).format('L')}</Typography>
          </Grid>
          <Grid size={{ xs: 12, md: 6, lg: 4 }}>
            <Typography variant="subtitle2">Total Budget</Typography>
            <Typography variant="body1">{curr(project.budget_total)}</Typography>
          </Grid>
          <Grid size={{ xs: 12, md: 6, lg: 4 }}>
            <Typography variant="subtitle2">Shipping Mode</Typography>
            <Typography variant="body1">{startCase(project.shipping_mode)}</Typography>
          </Grid>
          <Grid size={{ xs: 12, md: 6, lg: 4 }}>
            <Typography variant="subtitle2" gutterBottom>
              Customer PO:
            </Typography>
            <Typography variant="body1">{project.customer_po || '(none)'}</Typography>
          </Grid>
          <Grid size={{ xs: 12, md: 6, lg: 4 }}>
            <Typography variant="subtitle2" gutterBottom>
              Terms:
            </Typography>
            <Typography variant="body1">{project.terms.name}</Typography>
          </Grid>
          {project.total != null && (
            <Grid size={{ xs: 12, md: 6, lg: 4 }}>
              <Typography variant="subtitle2" gutterBottom>
                Total:
              </Typography>
              <Typography variant="body1">{curr(project.total)}</Typography>
            </Grid>
          )}
          {project.balance != null && (
            <Grid size={{ xs: 12, md: 6, lg: 4 }}>
              <Typography variant="subtitle2" gutterBottom>
                Balance:
              </Typography>
              <Typography variant="body1">{curr(project.balance)}</Typography>
            </Grid>
          )}
        </Grid>

        <Button
          sx={{ mt: 4 }}
          component="a"
          target="_blank"
          href={project.portal_url}
          variant="contained"
        >
          View in the Portal <OpenInNew sx={{ ml: 1 }} />
        </Button>
      </CardContent>
    </Card>
  );
}

export function ProjectOrders() {
  const { id } = useRecord<AvailProject>();
  const { data: orders, isFetching } = useQuery(['projectOrders', id], () =>
    axios
      .get<{
        data: Order[];
      }>(`/api/orders?filter[project_id]=${id}`)
      .then(({ data }) => data.data),
  );

  return (
    <Card>
      <CardHeader title="Orders" />
      {isFetching && <LinearProgress />}
      {orders && orders.length === 0 && (
        <CardContent>
          <Typography color="textSecondary">No orders found.</Typography>
        </CardContent>
      )}
      {orders?.map((o) => <OrderListItem key={o.id} order={o} />)}
    </Card>
  );
}

export default function projects() {
  return new Resource<AvailProject>('Projects')
    .setCanExport()
    .setReadOnly()
    .withDefaultSort('-id')
    .getTitleUsing((d) => d.name)
    .withDefaultFilters({
      bucket: 'open',
    })
    .getSingleActionsUsing((p) => [
      new CustomAction('Print', () => <PrintMenu model="project" id={p.id} />),
    ])
    .withFilters([
      FieldFactory.radio('bucket', [
        {
          value: 'open',
          label: 'Open',
        },
        {
          value: 'complete',
          label: 'Complete',
        },
        {
          value: 'all',
          label: 'All',
        },
      ]).asQuickFilter(),
    ])
    .withColumns([
      FieldFactory.text('id').withLabel('#').sortable(),
      FieldFactory.timestamp('created_at').filterable(),
      FieldFactory.text('name').sortable(),
      FieldFactory.belongsTo('customer', 'customers').filterable(),
      FieldFactory.status('status', PROJECT_STATUS_COLORS).filterable().sortable(),
      FieldFactory.date('in_hands_date').filterable().sortable(),
      FieldFactory.select('shipping_mode', SHIPPING_MODE_OPTIONS).sortable().filterable(),
      FieldFactory.curr('budget_amount').sortable(),
      FieldFactory.number('budget_qty').sortable(),
      FieldFactory.select('budget_type', {
        total: 'All-In',
        per_person: 'Per-Person',
      }).sortable(),
      FieldFactory.curr('budget_total'),
      FieldFactory.curr('total'),
      FieldFactory.curr('balance'),
      FieldFactory.date('due_date'),
      FieldFactory.timestamp('updated_at').sortable(),
    ])
    .withFields([
      new SidebarLayout(
        'layout',
        [FieldFactory.custom('_', ProjectView), getEventableConfigForResource('projects')],
        [FieldFactory.custom('orders', ProjectOrders)],
      ),
    ]);
}
