import { Delete, OpenInNew } from '@mui/icons-material';
import { CardContent, CardHeader, IconButton, Skeleton, Tooltip } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import CardWithGutter from '@/components/Shared/CardWithGutter';
import PaginatedTable from '@/components/Shared/PaginatedTable';
import StatusChip from '@/components/Shared/StatusChip';
import { APPLIED_STATUS_COLORS, APPLIED_STATUS_LABELS } from '@/constants';
import { PartiallyRequired, Payment } from '@/types';
import curr from '@/utils/curr';
import useDialogs from '@/utils/hooks/useDialogs';

export default function BusinessPayoutDeductions({
  payments,
  onReload,
}: {
  payments: PartiallyRequired<Payment, 'order_payments'>[];
  onReload: () => void;
}) {
  const { confirm } = useDialogs();

  const onDelete = (payment: Payment) => {
    confirm({
      title: 'Delete Payout Deduction',
      description: 'Are you sure you want to delete this payout deduction?',
    }).then(() => {
      axios.delete(`/api/payments/${payment.id}`).then(() => {
        onReload();
      });
    });
  };

  return (
    <CardWithGutter>
      <CardHeader title="Payout Deductions" />

      {payments ? (
        <PaginatedTable
          rows={payments}
          columns={[
            {
              header: 'Date',
              accessorKey: 'payment_date',
              cell: ({ getValue }) => moment(getValue<string>()).format('l'),
            },
            {
              header: 'Amount',
              accessorKey: 'amount',
              cell: ({ getValue }) => curr(getValue<number>()),
            },
            {
              header: 'Number',
              accessorKey: 'payment_number',
            },
            {
              header: 'Customer',
              accessorKey: 'customer.name',
            },
            {
              header: 'Status',
              accessorKey: 'applied_status',
              cell: ({ getValue }) => (
                <StatusChip
                  status={getValue<string>()}
                  colors={APPLIED_STATUS_COLORS}
                  labels={APPLIED_STATUS_LABELS}
                />
              ),
            },
            {
              header: '',
              id: 'actions',
              cell: ({ row }) => (
                <>
                  {row.original.order_payments
                    .filter((op) => op.order_id)
                    .map((op) => (
                      <Tooltip key={op.id} title={`View Order #${op.order_id}`}>
                        <IconButton
                          component="a"
                          href={`/orders/${op.order_id}?tab=payments`}
                          target="_blank"
                        >
                          <OpenInNew fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    ))}

                  {row.original.order_payments.length === 0 && (
                    <IconButton onClick={() => onDelete(row.original)}>
                      <Delete fontSize="small" />
                    </IconButton>
                  )}
                </>
              ),
            },
          ]}
        />
      ) : (
        <CardContent>
          <Skeleton />
        </CardContent>
      )}
    </CardWithGutter>
  );
}
