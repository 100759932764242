import { ChangeEvent, useState } from 'react';
import { Edit, Delete } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Typography, Badge, Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import pick from 'lodash/pick';
import reject from 'lodash/reject';
import { z } from 'zod';
import { FieldFactory, ButtonAction } from '@/classes';
import TextButton from '@/components/Text/TextButton';
import { useShowLoading } from '@/contexts/AppContext';
import { DesignLayoutWithProofs, Proof, ProofType } from '@/types';
import useDialogs from '@/utils/hooks/useDialogs';
import ExistingProofDrawer from './ExistingProofDrawer';
import ProofCard from './ProofCard';
import ProofItemDialog from './ProofItemDialog';

type SetProofs = (prevProofs: Proof[]) => Proof[];

export default function DesignProofs({
  layout,
  type,
  onUpdateProofs,
}: {
  layout: DesignLayoutWithProofs;
  type: ProofType;
  onUpdateProofs?: (l: DesignLayoutWithProofs, t: ProofType, setProofs: SetProofs) => void;
}) {
  const proofs = layout[`${type}_proofs`];
  const [showProofItems, setShowProofItems] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [choosing, setChoosing] = useState(false);
  const { confirm, prompt } = useDialogs();
  const showLoading = useShowLoading();
  const setProofs = (s: SetProofs) => (onUpdateProofs ? onUpdateProofs(layout, type, s) : null);

  const { data: matches = [] } = useQuery(
    ['matchingProofs', layout.id],
    () =>
      axios
        .get<{ data: Proof[] }>(`/api/design-layouts/${layout.id}/matching-proofs`)
        .then(({ data }) => data.data),
    {
      enabled: Boolean(onUpdateProofs),
    },
  );

  const onUpdate = (proof: Proof) => {
    setProofs((prev) =>
      prev.map((p) => {
        if (p.id === proof.id) {
          return proof;
        }
        return proof;
      }),
    );
  };

  const onDelete = (proof: Proof) => {
    confirm({
      title: 'Delete Proof',
      description: 'Are you sure you want to delete this proof?',
      color: 'error',
    }).then(() => {
      axios.delete(`/api/design-layouts/${layout.id}/${type}-proofs/${proof.id}`);
      setProofs((prev) => reject(prev, ['id', proof.id]));
    });
  };

  const onRename = (proof: Proof) => {
    prompt({
      title: 'Rename Proof',
      fields: [FieldFactory.text('file_name').withSize('medium')],
      schema: z.object({
        file_name: z.string().min(1),
      }),
      initialValues: pick(proof, 'file_name'),
      onSubmit: (v) => axios.put(`/api/design-layouts/${layout.id}/${type}-proofs/${proof.id}`, v),
    }).then(({ data }) => {
      onUpdate({ ...proof, ...data });
    });
  };

  const uploadProof = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files) {
      return;
    }
    setUploading(true);
    await Promise.all(
      [...files].map((file) => {
        const form = new FormData();
        form.append('file', file);
        return axios
          .post(`/api/design-layouts/${layout.id}/${type}-proofs`, form)
          .then(({ data }) => setProofs((prev) => [...prev, data]))
          .catch(() => null);
      }),
    );
    setUploading(false);
  };

  const onChoose = (proof: Proof) => {
    setChoosing(false);
    showLoading(
      axios
        .post(`/api/design-layouts/${layout.id}/production-proofs/${proof.id}/copy`)
        .then(({ data }) => {
          setProofs((prev) => [...prev, data]);
        }),
    );
  };

  return (
    <div>
      {proofs.length > 0 ? (
        <div>
          {proofs.map((proof) => (
            <Box display="inline-block" width={225} key={proof.id} mr={2}>
              <ProofCard
                proof={proof}
                actions={
                  onUpdateProofs
                    ? [
                        new ButtonAction('Rename', () => onRename(proof)).withIcon(Edit),
                        new ButtonAction('Delete', () => onDelete(proof)).withIcon(Delete),
                      ]
                    : []
                }
              />
            </Box>
          ))}
        </div>
      ) : (
        <Typography variant="body2" color="textSecondary">
          There are no {type} proofs for this layout.
        </Typography>
      )}

      {onUpdateProofs && (
        <Box mt={2} display="flex" alignItems="center">
          <LoadingButton
            size="small"
            variant="contained"
            sx={{ mr: 2 }}
            component="label"
            loading={uploading}
          >
            {uploading ? 'Uploading...' : 'Upload'}
            <input
              type="file"
              accept="application/pdf"
              multiple
              onChange={uploadProof}
              style={{ display: 'none' }}
            />
          </LoadingButton>

          {matches.length > 0 && (
            <Badge badgeContent={matches.length} color="secondary" sx={{ mr: 2 }}>
              <Button size="small" onClick={() => setChoosing(true)}>
                Existing Proofs
              </Button>
            </Badge>
          )}

          <Box ml="auto">
            <TextButton onClick={() => setShowProofItems(true)}>
              What item to proof this layout on?
            </TextButton>
          </Box>
        </Box>
      )}

      <ProofItemDialog
        open={showProofItems}
        onClose={() => setShowProofItems(false)}
        layoutId={layout.id}
        orderId={layout.order_id}
      />

      <ExistingProofDrawer
        open={choosing}
        onClose={() => setChoosing(false)}
        onChoose={type === 'production' ? onChoose : undefined}
        proofs={matches}
      />
    </div>
  );
}
