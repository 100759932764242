import { ElementType } from 'react';
import { OnClickProps } from '@/utils/hooks/useOnClickProps';
import Action from '../Action';

export default class ButtonAction extends Action {
  onClick: (p: OnClickProps) => void;

  constructor(label: string, onClick: (p: OnClickProps) => void, icon?: ElementType) {
    super(label, icon);
    this.onClick = onClick;
  }
}
