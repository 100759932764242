import { ReactElement } from 'react';
import { UseControllerReturn } from 'react-hook-form';
import { FieldProps } from '@/types';
import Field from '../Field';

export default class CustomField extends Field {
  component: (props: FieldProps) => ReactElement | null;

  constructor(name: string, component: (props: FieldProps) => ReactElement | null) {
    super(name);
    this.component = component;
  }

  renderEditComponent(props: UseControllerReturn) {
    const Component = this.component;
    return <Component {...props} fieldModel={this} />;
  }
}
