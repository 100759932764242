import { ChangeEvent } from 'react';
import { FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { FieldFactory } from '@/classes';
import DrawerFormSection from '@/components/Form/DrawerFormSection';
import FormField from '@/components/Form/FormField';
import ProductField from '@/components/Products/ProductField';

export default function ProductSection({
  isCustom,
  handleCustomChange,
}: {
  isCustom: boolean;
  handleCustomChange: (e: ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <DrawerFormSection title="Product">
      <FormField field={FieldFactory.text('description').setRequired().withSize('medium')} />

      <RadioGroup
        row
        value={isCustom ? 'custom' : 'database'}
        sx={{ my: 1 }}
        onChange={handleCustomChange}
      >
        <FormControlLabel value="database" control={<Radio />} label="Database" />
        <FormControlLabel value="custom" control={<Radio />} label="Custom" />
      </RadioGroup>

      {isCustom ? (
        <Stack>
          <FormField field={FieldFactory.text('number')} />
          <FormField field={FieldFactory.text('color')} />
        </Stack>
      ) : (
        <FormField
          field={new ProductField('product').with({
            requestParams: { with: 'variants,images' },
          })}
        />
      )}
    </DrawerFormSection>
  );
}
