import {
  CircularProgress,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import get from 'lodash/get';
import { useFormContext } from 'react-hook-form';
import { useGetVariantsForProduct } from '@/api/variants';
import InventoryText from '@/components/Products/InventoryText';
import { FieldProps } from '@/types';
import curr from '@/utils/curr';

export default function SizeSelector({ field }: FieldProps) {
  const variant = useFormContext<{ variant: { product: { id: number } } }>().watch('variant');

  const { data: variants } = useGetVariantsForProduct(variant?.product.id);

  if (!variants) {
    return <CircularProgress />;
  }

  return (
    <Table size="small" style={{ whiteSpace: 'nowrap' }}>
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" />
          <TableCell>Size</TableCell>
          <TableCell>SKU</TableCell>
          <TableCell>Cost</TableCell>
          <TableCell>Inventory</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {variants.map((variant) => (
          <TableRow key={variant.id}>
            <TableCell>
              <Radio
                checked={get(field.value, 'id') === variant.id}
                onClick={() => field.onChange(variant)}
              />
            </TableCell>
            <TableCell>{variant.size}</TableCell>
            <TableCell>{variant.sku}</TableCell>
            <TableCell>{curr(variant.cost)}</TableCell>
            <TableCell>
              <InventoryText variant={variant} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
