import { AlertColor } from '@mui/material';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { useFormContext } from 'react-hook-form';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import Resource from '@/classes/Resource';
import { useDataTable } from '@/components/DataTable/DataTableContext';
import { useAppContext, useShowLoading } from '@/contexts/AppContext';
import {
  useMaybeCurrentResource,
  useOnReloadRecord,
  useRecordId,
  useUpdateRecord,
} from '@/utils/genericResource';
import useGetResource from '@/utils/hooks/useGetResource';
import useDialogs, { UseDialogsResult } from './useDialogs';
import useToast from './useToast';

export interface OnClickProps {
  change: (name: string, value: any) => void;
  dialogs: UseDialogsResult;
  navigate: NavigateFunction;
  back?: string | null;
  toast: (message: string, status?: AlertColor) => void;
  setIsLoading: (l: boolean) => void;
  showLoading: <T>(p: Promise<T>) => Promise<T>;

  selected: (string | number)[];
  onReloadTable: () => void;

  resource?: Resource;
  form?: string;
  onReloadRecord: () => void;
  recordId: string;
  queryClient: QueryClient;
  updateRecord: <T>(updated: Partial<T>) => void;
}

export default function useOnClickProps(): OnClickProps {
  const { appBar, setIsLoading } = useAppContext();
  const showLoading = useShowLoading();
  const dialogs = useDialogs();
  const navigate = useNavigate();
  const toast = useToast();
  const form = useFormContext();
  const queryClient = useQueryClient();

  const { selected, onReload: onReloadTable } = useDataTable();

  const resourceKey = useMaybeCurrentResource();
  const getResource = useGetResource();
  const resource = resourceKey ? getResource(resourceKey) : undefined;
  const recordId = useRecordId();
  const updateRecord = useUpdateRecord();
  const onReloadRecord = useOnReloadRecord();

  return {
    change: form ? form.setValue : () => {},
    dialogs,
    navigate,
    back: appBar.back,
    toast,
    setIsLoading,
    showLoading,

    selected,
    onReloadTable,

    resource,
    form: resource?.getFormName(),
    onReloadRecord,

    queryClient,
    recordId,
    updateRecord,
  };
}
