import { SyntheticEvent, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Button, LinearProgress } from '@mui/material';
import { UseMutationResult, useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import take from 'lodash/take';
import DrawerButtons from '@/components/Form/DrawerButtons';
import RateList from '@/components/Shipping/RateList';
import { ShipmentQuotePayload, ShippoRate } from '@/types';

const INIT_LIMIT = 5;

export default function ChooseRates({
  values,
  submitRequest,
  onError,
}: {
  values: ShipmentQuotePayload;
  submitRequest: UseMutationResult<unknown, unknown, { rate_id: string }>;
  onError: (e: AxiosError) => void;
}) {
  const [showAll, setShowAll] = useState(false);
  const [selectedRate, setSelectedRate] = useState<string>();

  const { isLoading, data: rates = [] } = useQuery(
    ['rates', JSON.stringify(values)],
    () =>
      axios
        .post<{ rates: ShippoRate[] }>('/api/shipments/quote', values)
        .then(({ data }) => data.rates),
    {
      retry: false,
      onError,
    },
  );

  const ratesMatchingMethod = rates.filter((r) => r.matches_shipping_method);

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (selectedRate) {
      submitRequest.mutate({ rate_id: selectedRate });
    }
  };

  let ratesToShow = rates;

  if (!showAll) {
    ratesToShow = ratesMatchingMethod.length > 0 ? ratesMatchingMethod : take(rates, INIT_LIMIT);
  }

  if (isLoading) {
    return <LinearProgress sx={{ mt: 4 }} />;
  }

  return (
    <form onSubmit={onSubmit}>
      {ratesToShow.length === 0 ? (
        <Alert severity="warning">No rates found</Alert>
      ) : (
        <RateList
          rates={ratesToShow}
          selectedRate={selectedRate}
          setSelectedRate={setSelectedRate}
        />
      )}

      {!showAll && rates.length > ratesToShow.length ? (
        <Button size="small" onClick={() => setShowAll(true)}>
          Show All Rates ({rates.length})
        </Button>
      ) : null}

      <DrawerButtons>
        <LoadingButton
          type="submit"
          loading={submitRequest.isLoading}
          disabled={!selectedRate}
          variant="contained"
        >
          Purchase Label
        </LoadingButton>
      </DrawerButtons>
    </form>
  );
}
