import {
  Card,
  CardHeader,
  Grid2 as Grid,
  List,
  ListItemText,
  ListItemButton,
  Typography,
  CardContent,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  Area,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  AreaChart,
} from 'recharts';
import { primaryColor, secondaryColor, drawerColor } from '@/MuiProvider';
import BusinessPayoutDeductions from '@/components/MyBusiness/BusinessPayoutDeductions';
import BusinessPayouts from '@/components/MyBusiness/BusinessPayouts';
import BusinessUsers from '@/components/MyBusiness/BusinessUsers';
import StatCard from '@/components/Stats/StatCard';
import { useConfig } from '@/contexts/AppConfigContext';
import { Business, PaymentWithOps } from '@/types';
import curr from '@/utils/curr';
import useTitle from '@/utils/hooks/useTitle';

interface MyBusiness extends Business {
  revenue: number;
  profit: number;
  past_due: number;
  payout_deductions: PaymentWithOps[];
  chart: {
    month: string;
    revenue: number;
    costs: number;
    profit: number;
    payouts: number;
    commissions: number;
  }[];
}

export default function MyBusiness() {
  useTitle('My Business');

  const { business: businessFromConfig } = useConfig();

  const { data: business, refetch } = useQuery(
    ['myBusiness'],
    () => axios.get<MyBusiness>('/api/businesses/mine').then(({ data }) => data),
    {
      enabled: Boolean(businessFromConfig),
    },
  );

  if (!businessFromConfig) {
    return (
      <Typography color="textSecondary">Please select a business to view this page.</Typography>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12, md: 8 }}>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, md: 4 }}>
            <StatCard
              title="Revenue MTD"
              number={business?.revenue}
              format="curr"
              loading={!business}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <StatCard
              title="Gross Profit MTD"
              number={business?.profit}
              format="curr"
              loading={!business}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <StatCard
              title="Past Due"
              number={business?.past_due}
              format="curr"
              loading={!business}
            />
          </Grid>

          {business && (
            <Grid size={12}>
              <Card>
                <CardContent>
                  <ResponsiveContainer width="100%" aspect={2}>
                    <AreaChart data={business.chart}>
                      <XAxis dataKey="month" />
                      <YAxis
                        domain={[0, Math.max(...business.chart.map((r) => r.profit))]}
                        tickFormatter={(v) => `$${Math.round(v).toLocaleString()}`}
                      />
                      <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                      <Area dataKey="profit" stroke={primaryColor} fill={primaryColor} />
                      <Area dataKey="payouts" stroke={secondaryColor} fill={secondaryColor} />
                      <Area dataKey="commissions" stroke={drawerColor} fill={drawerColor} />
                      <Legend />
                      <Tooltip formatter={(v) => curr(Number(v))} />
                    </AreaChart>
                  </ResponsiveContainer>
                </CardContent>
              </Card>
            </Grid>
          )}

          {business && (
            <Grid size={12}>
              <BusinessPayoutDeductions payments={business.payout_deductions} onReload={refetch} />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <BusinessUsers />
        <BusinessPayouts />

        <Card>
          <CardHeader title="Settings" />
          <List>
            <ListItemButton component={Link} to="/commission-models">
              <ListItemText>Commission Models</ListItemText>
            </ListItemButton>
          </List>
        </Card>
      </Grid>
    </Grid>
  );
}
