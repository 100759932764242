import { useCallback } from 'react';
import { Portal } from '@mui/base';
import { Snackbar, Alert } from '@mui/material';
import { useAppContext } from '@/contexts/AppContext';

export default function ToastContainer() {
  const { toast, setToast } = useAppContext();
  const onCloseToast = useCallback(() => {
    setToast(undefined);
  }, [setToast]);

  return (
    <Portal>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={!!toast}
        autoHideDuration={5000}
        onClose={onCloseToast}
      >
        {toast && (
          <Alert
            onClose={onCloseToast}
            severity={toast.status}
            sx={{ width: '100%' }}
            variant="filled"
            elevation={6}
          >
            {toast.message}
          </Alert>
        )}
      </Snackbar>
    </Portal>
  );
}
