import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Edit, Undo, Close as CloseIcon, ImageSearch, Link, DragHandle } from '@mui/icons-material';
import { Typography, IconButton, Avatar, Tooltip, TableRow, TableCell, Box } from '@mui/material';
import get from 'lodash/get';
import { primaryColor } from '@/MuiProvider';
import StatusChip from '@/components/Shared/StatusChip';
import WarningIcon from '@/components/Shared/WarningIcon';
import TextButton from '@/components/Text/TextButton';
import { OrderDesign } from '@/types';
import { maybeAddQueryParams } from '@/utils/query';
import OrderDesignLabel from '../Designs/OrderDesignLabel';

export default function OrderDesignListItem({
  orderDesign,
  onEdit,
  onEditDesign,
  onEditImprints,
  onRemove,
  onShowArtActivities,
  onUncomplete,
  sortable,
}: {
  orderDesign: OrderDesign;
  onEdit?: () => void;
  onEditDesign?: () => void;
  onEditImprints?: () => void;
  onRemove?: () => void;
  onShowArtActivities?: () => void;
  onUncomplete?: () => void;
  sortable?: boolean;
}) {
  const { design } = orderDesign;

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: orderDesign.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <TableRow key={design.id} ref={setNodeRef} style={style}>
      <TableCell style={{ width: 90 }}>
        <Avatar
          variant="square"
          component={design.image ? 'a' : 'div'}
          href={design.image || undefined}
          target="_blank"
          src={maybeAddQueryParams(design.image, { w: 200 })}
          alt={design.increment_id}
          style={{
            width: 56,
            height: 56,
            background: primaryColor,
            color: 'white',
          }}
        >
          {design.increment_id.substr(-2)}
        </Avatar>
      </TableCell>
      <TableCell style={{ width: 160 }}>
        <OrderDesignLabel orderDesign={orderDesign} />
        <StatusChip
          status={design.status}
          colors={{
            Complete: 'green',
            Incomplete: 'amber',
          }}
          style={{
            padding: 0,
            marginLeft: 0,
            marginRight: 0,
            marginTop: 4,
            height: 26,
          }}
        />
      </TableCell>
      <TableCell style={{ minWidth: 200 }}>
        <Typography variant="body2">{design.name}</Typography>
        <Typography variant="body2" color="textSecondary">
          {design.location}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{get(design, 'decoration_type.name')}</Typography>
        <Typography variant="body2" color="textSecondary">
          {design.complexity_label}
        </Typography>
      </TableCell>
      <TableCell style={{ whiteSpace: 'nowrap' }}>
        <TextButton onClick={onEditImprints}>
          {orderDesign.imprint_qty === 0 ? (
            <WarningIcon message="This decoration will not be applied to any items" />
          ) : null}
          <span>{`${orderDesign.imprint_qty} Imprints`}</span>
        </TextButton>
      </TableCell>
      <TableCell style={{ whiteSpace: 'nowrap' }}>
        <Box display="flex" alignItems="center">
          {onEdit && (
            <Tooltip title="Edit Linked Design">
              <IconButton onClick={onEdit}>
                <Link />
              </IconButton>
            </Tooltip>
          )}
          {onEditDesign && !design.completed_at ? (
            <Tooltip title="Edit Requested Design">
              <IconButton onClick={onEditDesign}>
                <Edit />
              </IconButton>
            </Tooltip>
          ) : null}
          {onUncomplete && design.completed_at ? (
            <Tooltip title="Mark As Incomplete (will make editable)">
              <IconButton onClick={onUncomplete}>
                <Undo />
              </IconButton>
            </Tooltip>
          ) : null}
          {onRemove ? (
            <Tooltip title="Remove design">
              <IconButton onClick={onRemove}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          ) : null}
          {onShowArtActivities && (
            <Tooltip title="Art Activities">
              <IconButton onClick={onShowArtActivities}>
                <ImageSearch />
              </IconButton>
            </Tooltip>
          )}
          {sortable && (
            <Box p={1} {...attributes} {...listeners}>
              <DragHandle style={{ verticalAlign: 'middle' }} />
            </Box>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
}
