import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Close, DragHandle } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';
import { FieldFactory } from '@/classes';
import ColorChooserField from '@/components/Designs/ColorChooserField';
import StockColorIcon from '@/components/Designs/StockColorIcon';
import FormField from '@/components/Form/FormField';
import WarningIcon from '@/components/Shared/WarningIcon';
import { isScreenPrint } from '@/helpers';
import { ComplexityUnit, Customer, DesignColorPayload, DesignPayloadWithRelations } from '@/types';
import numString from '@/utils/numString';

const ALPHA = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P'];
const SQUEEGEE = ['Red', 'Green', 'Yellow', 'Blue', 'Orange'];

function ColorRow({
  id,
  index,
  onRemove,
  decorationUnit,
  customer,
  freeSolo,
  control,
}: {
  id: string;
  index: number;
  onRemove: () => void;
  decorationUnit: ComplexityUnit | null;
  customer?: Customer;
  freeSolo?: boolean;
  control: Control<DesignPayloadWithRelations>;
}) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
  });
  const field = `colors.${index}` as const;

  const theme = useTheme();

  return (
    <TableRow
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
      }}
    >
      <TableCell padding="checkbox">
        <DragHandle {...attributes} {...listeners} />
      </TableCell>
      <TableCell style={{ width: 100 }}>
        <FormField field={FieldFactory.select('code', ALPHA).withoutLabel()} parentName={field} />
      </TableCell>
      <TableCell>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <FormField
              field={new ColorChooserField('color').withoutLabel().with({
                freeSolo,
                customer,
                decorationUnit,
              })}
              parentName={field}
            />
          </Box>
          <Controller
            name={`${field}.ink_changes_count`}
            control={control}
            render={({ field }) => (
              <>
                {field.value ? (
                  <Box ml={1} pt={1}>
                    <WarningIcon
                      message={`This color is being changed in ${numString(field.value, 'designs')}`}
                    />
                  </Box>
                ) : null}
              </>
            )}
          />
          <Controller
            name={`${field}.stock_color`}
            control={control}
            render={({ field }) => (
              <>{field.value && <StockColorIcon stockColor={field.value} />}</>
            )}
          />
        </Box>
      </TableCell>
      {isScreenPrint(decorationUnit) && (
        <>
          <TableCell>
            <FormField field={FieldFactory.number('mesh').withoutLabel()} parentName={field} />
          </TableCell>
          <TableCell padding="none">
            <FormField
              field={FieldFactory.select('squeegee', SQUEEGEE).withoutLabel()}
              parentName={field}
            />
          </TableCell>
          <TableCell>
            <FormField field={FieldFactory.boolean('flash').withoutLabel()} parentName={field} />
          </TableCell>
        </>
      )}

      <TableCell
        style={{
          position: 'sticky',
          right: '0',
          width: 'auto',
          backgroundColor: theme.palette.mode === 'dark' ? '#1e1e1e' : '#fff',
          zIndex: '1',
        }}
      >
        <IconButton onClick={onRemove} size="large">
          <Close fontSize="small" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default function DesignColors({
  decorationUnit,
  customer,
  freeSolo = true,
}: {
  decorationUnit: ComplexityUnit | null;
  customer?: Customer;
  freeSolo?: boolean;
}) {
  const form = useFormContext<DesignPayloadWithRelations>();
  const { append, move, fields, remove } = useFieldArray({
    control: form.control,
    name: 'colors',
  });

  const onSortEnd = ({ active, over }: DragEndEvent) => {
    if (!over) {
      return;
    }
    const oldIndex = fields.findIndex((c) => c.id === active.id);
    const newIndex = fields.findIndex((c) => c.id === over.id);
    move(oldIndex, newIndex);
  };

  const getNewColor = (): DesignColorPayload => ({
    code: ALPHA[fields.length],
    color: '',
    mesh: null,
    squeegee: null,
    flash: false,
  });

  return (
    <div>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Code</TableCell>
              <TableCell style={{ minWidth: 300 }}>Color</TableCell>
              {isScreenPrint(decorationUnit) && (
                <>
                  <TableCell style={{ minWidth: 100 }}>Mesh</TableCell>
                  <TableCell>Squeegee</TableCell>
                  <TableCell>Flash</TableCell>
                </>
              )}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <DndContext onDragEnd={onSortEnd}>
              <SortableContext items={fields.map((c) => c.id)}>
                {fields.map((color, idx) => (
                  <ColorRow
                    key={color.id}
                    id={color.id}
                    index={idx}
                    decorationUnit={decorationUnit}
                    customer={customer}
                    freeSolo={freeSolo}
                    onRemove={() => remove(idx)}
                    control={form.control}
                  />
                ))}
              </SortableContext>
            </DndContext>
          </TableBody>
        </Table>
      </TableContainer>

      <Button
        onClick={() => append(getNewColor())}
        style={{ marginTop: 4 }}
        size="small"
        color="secondary"
      >
        Add New
      </Button>
    </div>
  );
}
