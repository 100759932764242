import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  CardActions,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import TableField from '@/classes/Fields/TableField';
import { FieldArrayProps } from '@/types';
import { getInitialValues } from '@/utils/form';
import { useRequiresPermissionAndRole } from '@/utils/hooks/permissions';
import useDialogs from '@/utils/hooks/useDialogs';

export default function TableElement({
  fieldModel,
  fields,
  remove,
  append,
  update,
  name,
}: FieldArrayProps<TableField>) {
  const form = useFormContext();
  const { prompt } = useDialogs();
  const requiresPermissionAndRole = useRequiresPermissionAndRole();
  const children = fieldModel.children.filter(requiresPermissionAndRole);

  const onNew = () => {
    prompt({
      title: 'Add',
      fields: children,
      schema: fieldModel.schema,
      initialValues: getInitialValues(children, fieldModel.addNewValue),
    }).then((values) => {
      append(values);
    });
  };

  const onEdit = (index: number) => {
    const initialValues = getInitialValues(children, form.watch(`${name}.${index}`));

    prompt({
      title: 'Edit',
      fields: children,
      schema: fieldModel.schema,
      initialValues,
    }).then((values) => {
      update(index, values);
    });
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            {children.map((child) => (
              <TableCell key={child.name}>{child.label}</TableCell>
            ))}
            {!fieldModel.readOnly && <TableCell />}
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((row, index) => (
            <TableRow key={row.id}>
              {children.map((child) => (
                <TableCell key={child.name}>
                  <Controller
                    name={`${name}.${index}.${child.name}`}
                    control={form.control}
                    render={({ field }) => <>{child.renderCell(field.value)}</>}
                  />
                </TableCell>
              ))}

              {!fieldModel.readOnly && (
                <TableCell>
                  <IconButton size="small" onClick={() => onEdit(index)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton size="small" onClick={() => remove(index)}>
                    <CloseIcon />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}

          {fields.length === 0 && (
            <TableRow>
              <TableCell colSpan={children.length + 1}>
                <Typography variant="body2" color="textSecondary">
                  No rows found
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {!fieldModel.readOnly && (
        <CardActions>
          <Button color="secondary" onClick={onNew}>
            {fieldModel.addNewLabel}
          </Button>
        </CardActions>
      )}
    </>
  );
}
