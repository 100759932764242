import { Avatar, Box, Typography } from '@mui/material';
import { FieldFactory, Resource } from '@/classes';
import DesignField from '@/components/Designs/DesignField';
import { DESIGN_STATUS_COLORS } from '@/constants';
import { getEventableConfigForResource } from '@/helpers';
import { Design } from '@/types';
import { designPayloadSchema } from '@/types';
import { maybeAddQueryParams } from '@/utils/query';

export default function designs() {
  return new Resource<Design>('Designs')
    .withSchema(designPayloadSchema)
    .getTitleUsing((d) => d.increment_id)
    .getSubtitleUsing((d) => `${d.location} * ${d.complexity || '?'}`)
    .getAvatarUsing((d) => (
      <Avatar src={maybeAddQueryParams(d.image, { w: 200 })}>{d.increment_id.substr(-1)}</Avatar>
    ))
    .withDefaultSort('-increment_id')
    .withColumns([
      FieldFactory.text('increment_id').withLabel('#').sortable(),
      FieldFactory.text('name')
        .renderCellUsing((value, row: Design) => (
          <Box display="flex" alignItems="center">
            <Avatar variant="square" src={maybeAddQueryParams(row.image, { w: 200 })}>
              {row.increment_id.substr(-2)}
            </Avatar>
            <Box ml={2}>
              <Typography variant="body2">{value}</Typography>
              <Typography variant="body2" color="textSecondary">
                {row.location}
              </Typography>
            </Box>
          </Box>
        ))
        .sortable(),
      FieldFactory.belongsTo('decoration_type', 'decorationTypes')
        .filterable()
        .sortable('decoration_type.name'),
      FieldFactory.belongsTo('customer', 'customers').filterable().sortable('customer.name'),
      FieldFactory.status('status', DESIGN_STATUS_COLORS).filterable(),
      FieldFactory.belongsTo('product', 'products').withLabel('Name/Number Product').filterable(),
    ])
    .withFields([
      FieldFactory.custom('_design', DesignField).withColumnSpan(12),
      getEventableConfigForResource('designs'),
    ]);
}
