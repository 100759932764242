import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Button, Grid2 as Grid } from '@mui/material';
import ListField from '@/classes/Fields/ListField';
import { FieldArrayProps } from '@/types';
import { getInitialValues } from '@/utils/form';
import FormField from './FormField';

export default function ListElement({
  fieldModel,
  fields,
  name,
  remove,
  append,
}: FieldArrayProps<ListField>) {
  return (
    <div>
      {fields.map((f, index) => (
        <Grid container key={f.id} spacing={2} sx={{ mb: 1 }} alignItems="start">
          {fieldModel.children.map((child) => (
            <Grid key={child.name} size={{ xs: 12, lg: child.columnSpan }}>
              <FormField field={child} parentName={`${name}.${index}`} />
            </Grid>
          ))}
          <Grid>
            <IconButton size="small" onClick={() => remove(index)} sx={{ marginTop: '10.5px' }}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Button
        color="secondary"
        onClick={() => append(getInitialValues(fieldModel.children, fieldModel.addNewValue))}
        size="small"
      >
        {fieldModel.addNewLabel}
      </Button>
    </div>
  );
}
