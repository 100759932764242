import {
  Alert,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import axios from 'axios';
import startCase from 'lodash/startCase';
import sumBy from 'lodash/sumBy';
import moment from 'moment';
import { z } from 'zod';
import { useCostLayers } from '@/api/variants';
import { FieldFactory } from '@/classes';
import ClosableDrawer from '@/components/Shared/ClosableDrawer';
import TextButton from '@/components/Text/TextButton';
import { InventoryCostLayer } from '@/types';
import curr from '@/utils/curr';
import { useHasPermission } from '@/utils/hooks/permissions';
import useDialogs from '@/utils/hooks/useDialogs';

export default function CostLayerDrawer({
  open,
  onClose,
  variantId,
}: {
  open: boolean;
  onClose: () => void;
  variantId?: number;
}) {
  const { prompt } = useDialogs();
  const hasPermission = useHasPermission();
  const { data: layers, isLoading, refetch } = useCostLayers(variantId);

  const onRevalue = (layer: InventoryCostLayer) => {
    prompt({
      title: 'Revalue Cost Layer',
      fields: [FieldFactory.curr('cost').withSize('medium')],
      schema: z.object({
        cost: z.coerce.number().min(0),
      }),
      initialValues: {
        cost: layer.cost,
      },
      onSubmit: (v) => axios.put(`/api/variants/${variantId}/cost-layers/${layer.id}`, v),
    }).then(() => {
      refetch();
    });
  };

  return (
    <ClosableDrawer open={open} onClose={onClose} title="Cost Layers for SKU">
      {isLoading && <CircularProgress />}
      {layers?.length === 0 && (
        <Alert severity="info">There are no active cost layers for this SKU.</Alert>
      )}
      {layers && layers.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Source</TableCell>
              <TableCell>Qty</TableCell>
              <TableCell>Cost</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {layers.map((l) => (
              <TableRow key={l.id}>
                <TableCell>{moment(l.created_at).format('l')}</TableCell>
                <TableCell>
                  {startCase(l.source_type)} #{l.source_id}
                </TableCell>
                <TableCell>{l.qty_remaining}</TableCell>
                <TableCell>
                  <TextButton
                    onClick={() => onRevalue(l)}
                    disabled={!hasPermission('write:inventory_adjustments')}
                  >
                    {curr(l.cost)}
                  </TextButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={2} />
              <TableCell>{sumBy(layers, 'qty_remaining')}</TableCell>
              <TableCell />
            </TableRow>
          </TableFooter>
        </Table>
      )}
    </ClosableDrawer>
  );
}
