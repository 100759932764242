import { useEffect } from 'react';
import { Edit, ExpandMore, Image, Undo } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import orderBy from 'lodash/orderBy';
import sumBy from 'lodash/sumBy';
import moment from 'moment';
import QRCode from 'qrcode.react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import OrderLabel from '@/components/Orders/OrderLabel';
import Can from '@/components/Permissions/Can';
import TextLink from '@/components/Text/TextLink';
import UserLabel from '@/components/Users/UserLabel';
import { Receiving, ReceivingItem, ReceivingPayload, receivingPayloadSchema } from '@/types';
import {
  makeResourceQueryKey,
  useCurrentResource,
  useOnReloadRecord,
  useRecord,
  useRecordId,
} from '@/utils/genericResource';
import useDialogs from '@/utils/hooks/useDialogs';
import numString from '@/utils/numString';
import { receivingFields } from './ReceivePurchaseOrder';

export default function ReceivingsField() {
  const record = useRecord();
  const paramName = 'issued_date' in record ? 'purchase_order_id' : 'order_id';
  const resourceKey = useCurrentResource();
  const paramId = useRecordId();
  const { prompt, confirm } = useDialogs();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const editReceiving = searchParams.get('editReceiving');
  const onReload = useOnReloadRecord();

  const {
    data: receivings,
    refetch,
    isFetching,
  } = useQuery(makeResourceQueryKey(resourceKey, paramId, 'receivings'), () =>
    axios
      .get<{ data: Receiving[] }>('/api/receivings', {
        params: {
          [paramName]: paramId,
        },
      })
      .then(({ data }) => data.data),
  );
  const isEmpty = !receivings;

  const onDeleteReceiving = (r: Receiving) => {
    confirm({
      title: 'Undo Receiving',
      description: 'Are you sure you want to delete this receiving?',
      color: 'error',
    }).then(() => {
      axios.delete(`/api/receivings/${r.id}`).then(() => {
        onReload();
      });
    });
  };

  const onUnreceiveItem = (r: Receiving, i: ReceivingItem) => {
    confirm({
      title: 'Unreceive Item',
      description: 'Are you sure you want to unreceive this item?',
      color: 'error',
    }).then(() => {
      axios.delete(`/api/receivings/${r.id}/items/${i.id}`).then(() => {
        onReload();
      });
    });
  };

  const baseUrl = `${window.location.protocol}//${window.location.hostname}${location.pathname}`;

  const onEditReceiving = (r: Receiving) => {
    prompt({
      title: `Edit Receiving #${r.id}`,
      initialValues: {
        images: r.images ? r.images.map((url) => ({ url })) : [],
        barcodes: r.barcodes || [],
      },
      schema: receivingPayloadSchema.pick({ barcodes: true }).extend({
        images: z.array(
          z.object({
            url: z.string(),
          }),
        ),
      }),
      fields: receivingFields,
      onSubmit: (v) => {
        const input: Partial<ReceivingPayload> = {
          ...v,
          images: v.images.map((i) => i.url),
        };
        return axios.put<Receiving>(`/api/receivings/${r.id}`, input).then(({ data }) => data);
      },
    }).then(() => {
      refetch();
    });
  };

  useEffect(() => {
    if (!isEmpty && editReceiving) {
      const matchingRec = receivings.find((r) => Number(r.id) === Number(editReceiving));
      if (matchingRec) {
        onEditReceiving(matchingRec);
      }
    }
  }, [isEmpty, editReceiving]);

  return (
    <div>
      {isFetching && <LinearProgress />}
      {receivings &&
        orderBy(receivings, ['id'], ['desc']).map((r, index) => (
          <Accordion key={r.id}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant="h6">Receiving #{r.id}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ display: 'block', paddingTop: 0 }}>
              <Box display="flex" alignItems="flex-start" mb={2}>
                <Box flexGrow={1}>
                  <Typography variant="body1" gutterBottom>
                    <span>{numString(sumBy(r.items, 'qty_received'))}</span>
                    <span> received by </span>
                    <span>
                      {r.created_by_user ? <UserLabel user={r.created_by_user} /> : 'System'}
                    </span>
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {moment(r.created_at).format('llll')}
                  </Typography>

                  {r.tracking_numbers ? (
                    <Box mt={2} fontSize="small">
                      <Typography variant="subtitle2">Tracking Numbers</Typography>
                      {r.tracking_numbers.map((num) => (
                        <div key={num}>{num}</div>
                      ))}
                    </Box>
                  ) : null}
                </Box>
                {index === 0 && (
                  <Box flexGrow={0} mx={2}>
                    <QRCode value={`${baseUrl}?editReceiving=${r.id}`} size={56} />
                  </Box>
                )}
                <Box flexGrow={0}>
                  {r.images &&
                    r.images.map((image, imageIdx) => (
                      <Tooltip title={`View Image #${imageIdx + 1}`} key={image}>
                        <IconButton component="a" href={image} target="_blank" size="large">
                          <Image />
                        </IconButton>
                      </Tooltip>
                    ))}
                  <Can permission="write:receivings">
                    <Tooltip title="Edit Receivings">
                      <IconButton onClick={() => onEditReceiving(r)} size="large">
                        <Edit />
                      </IconButton>
                    </Tooltip>
                    <IconButton onClick={() => onDeleteReceiving(r)} size="large">
                      <Undo />
                    </IconButton>
                  </Can>
                </Box>
              </Box>

              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Order #</TableCell>
                      <TableCell>PO #</TableCell>
                      <TableCell>Number</TableCell>
                      <TableCell>Color</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Size</TableCell>
                      <TableCell>Qty Rec.</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {r.items?.map((i) => {
                      const poItem = i.purchase_order_item;
                      if (!poItem) {
                        return null;
                      }
                      return (
                        <TableRow key={i.id}>
                          <TableCell>
                            <OrderLabel orderId={poItem.order_id} />
                          </TableCell>
                          <TableCell>
                            <TextLink to={`/purchase-orders/${poItem.purchase_order_id}`}>
                              {poItem.purchase_order_id}
                            </TextLink>
                          </TableCell>
                          <TableCell>{poItem.number}</TableCell>
                          <TableCell>{poItem.color}</TableCell>
                          <TableCell>{poItem.description}</TableCell>
                          <TableCell>{poItem.size}</TableCell>
                          <TableCell>{i.qty_received}</TableCell>
                          <TableCell>
                            <IconButton onClick={() => onUnreceiveItem(r, i)}>
                              <Undo fontSize="small" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        ))}
    </div>
  );
}
