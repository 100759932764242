import moment from 'moment';
import PaginatedTable from '@/components/Shared/PaginatedTable';
import TextLink from '@/components/Text/TextLink';
import { FieldProps, Payment } from '@/types';
import curr from '@/utils/curr';
import wrap from '@/utils/wrap';

export default function DepositPayments({ field }: FieldProps) {
  const payments = wrap(field.value) as Payment[];

  return (
    <PaginatedTable
      searchable={['payment_number', 'customer.name']}
      rows={payments}
      columns={[
        {
          header: 'Date',
          accessorKey: 'payment_date',
          cell: ({ getValue }) => moment(getValue<string>()).format('l'),
        },
        {
          header: 'Customer',
          accessorKey: 'customer.id',
          cell: ({
            row: {
              original: { customer },
            },
          }) =>
            customer ? <TextLink to={`/customers/${customer.id}`}>{customer.name}</TextLink> : '-',
        },
        {
          header: 'Payment Type',
          accessorKey: 'payment_type',
          cell: ({ row }) => row.original.type_title,
        },
        {
          header: 'Payment Number',
          accessorKey: 'payment_number',
          cell: ({ getValue, row }) => (
            <TextLink to={`/payments/${row.original.id}`}>{getValue<string>()}</TextLink>
          ),
        },
        {
          header: 'Amount',
          accessorKey: 'amount',
          cell: ({ getValue }) => curr(getValue<number>()),
        },
        { header: 'Method', accessorKey: 'method' },
      ]}
    />
  );
}
