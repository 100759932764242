import { SwapHoriz } from '@mui/icons-material';
import {
  AlertColor,
  Button,
  CardContent,
  CardHeader,
  Grid2 as Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import axios from 'axios';
import sumBy from 'lodash/sumBy';
import moment from 'moment';
import { z } from 'zod';
import { ButtonAction, FieldFactory } from '@/classes';
import ChooseLocationForVariant from '@/components/Inventory/ChooseLocationForVariant';
import InventoryLocationLabel from '@/components/Inventory/InventoryLocationLabel';
import EventableCard from '@/components/Notes/EventableCard';
import SkuLabel from '@/components/Products/SkuLabel';
import CardWithGutter from '@/components/Shared/CardWithGutter';
import StatusChip from '@/components/Shared/StatusChip';
import SubMenu from '@/components/Shared/SubMenu';
import WithLabel from '@/components/Shared/WithLabel';
import ShipActions from '@/components/Shipping/ShipActions';
import ShipmentsCard from '@/components/Shipping/ShipmentsCard';
import TextButton from '@/components/Text/TextButton';
import TextLink from '@/components/Text/TextLink';
import { RETURN_STATUS_COLORS } from '@/constants';
import { useShowLoading } from '@/contexts/AppContext';
import { genericModelReferenceSchema, InventoryReturn, InventoryReturnItem } from '@/types';
import { useOnReloadRecord, useRecord } from '@/utils/genericResource';
import { useHasPermission } from '@/utils/hooks/permissions';
import useDialogs from '@/utils/hooks/useDialogs';

type ReturnAction = 'start' | 'complete' | 'reset' | 'cancel';
export default function InventoryReturnPage() {
  const inventoryReturn = useRecord('inventoryReturns');
  const fulfillmentOrder = inventoryReturn.fulfillment_order;
  const { confirm, prompt } = useDialogs();
  const onReload = useOnReloadRecord();
  const showLoading = useShowLoading();
  const hasPermission = useHasPermission();

  const actions: Record<ReturnAction, [title: string, desc?: string, color?: AlertColor]> = {
    start: [
      'Start Return',
      'This will specify bins to put items back into and move the status to "Processing". Are you sure you want to proceed?',
    ],
    complete: [
      'Complete Return',
      'This will put the items into the specified bins and complete the return.',
    ],
    reset: [
      'Reset',
      'This will revert any inventory entries and move the return back to the "Awaiting" status. Are you sure you want to proceed?',
      'warning',
    ],
    cancel: [
      'Cancel',
      'This will permanently cancel the pick. Are you sure you want to proceed?',
      'error',
    ],
  };

  const transitions: Record<InventoryReturn['status'], ReturnAction[]> = {
    awaiting: ['start', 'cancel'],
    processing: ['complete', 'reset', 'cancel'],
    completed: ['reset', 'cancel'],
    cancelled: [],
  };

  const onMethod = (method: ReturnAction) => () => {
    confirm({
      title: actions[method][0],
      description: actions[method][1] || 'Are you sure you want to proceed?',
      color: actions[method][2],
    }).then(() => {
      showLoading(axios.post(`/api/inventory-returns/${inventoryReturn.id}/${method}`)).then(() => {
        onReload();
      });
    });
  };

  const [primary, ...other] = transitions[inventoryReturn.status];

  const action = (
    <>
      {primary && (
        <Button variant="contained" onClick={onMethod(primary)} sx={{ mr: 1 }}>
          {actions[primary][0]}
        </Button>
      )}
      {other.length > 0 && (
        <SubMenu items={other.map((m) => new ButtonAction(actions[m][0], onMethod(m)))} />
      )}
    </>
  );

  const onChangeLocation = (item: InventoryReturnItem) => {
    prompt({
      title: 'Return to Another Location',
      fields: [FieldFactory.custom('location', ChooseLocationForVariant)],
      initialValues: item,
      schema: z.object({ location: genericModelReferenceSchema }),
      onSubmit: (v) =>
        axios.put(`/api/inventory-return-items/${item.id}`, { location_id: v.location.id }),
    }).then(() => {
      onReload();
    });
  };

  const onUpdateQty = (item: InventoryReturnItem) => {
    prompt({
      title: 'Update Return Qty',
      fields: [FieldFactory.number('qty').withSize('medium')],
      initialValues: { qty: item.qty },
      schema: z.object({ qty: z.coerce.number().int().min(1) }),
      onSubmit: (v) => axios.put(`/api/inventory-return-items/${item.id}`, v),
    }).then(() => {
      onReload();
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12, md: 9 }}>
        <CardWithGutter>
          <CardHeader
            title={inventoryReturn.number}
            action={
              <Stack direction="row" spacing={3}>
                {inventoryReturn.status === 'awaiting' && (
                  <ShipActions
                    payload={{
                      shippable_type: 'return',
                      shippable_id: inventoryReturn.id,
                    }}
                    customerId={inventoryReturn.customer_id}
                    title="Creating Return Label"
                    qty={sumBy(inventoryReturn.items, 'qty')}
                    onSuccess={() => null}
                    shipButtonProps={{
                      variant: 'text',
                      children: 'Create Return Label',
                    }}
                  />
                )}
                {hasPermission('write:inventory_picks') && action}
              </Stack>
            }
          />
          <CardContent>
            <Grid container spacing={3}>
              <Grid size={{ xs: 12, md: 3 }}>
                <WithLabel label="Status">
                  <StatusChip status={inventoryReturn.status} colors={RETURN_STATUS_COLORS} />
                </WithLabel>
              </Grid>

              {fulfillmentOrder && (
                <Grid size={{ xs: 12, md: 3 }}>
                  <WithLabel label="Original Order">
                    <TextLink to={`/fulfillment-orders/${fulfillmentOrder.id}`}>
                      {fulfillmentOrder.number}
                    </TextLink>
                  </WithLabel>
                </Grid>
              )}

              <Grid size={{ xs: 12, md: 3 }}>
                <WithLabel label="Created">
                  <Typography>{moment(inventoryReturn.created_at).format('lll')}</Typography>
                </WithLabel>
              </Grid>
            </Grid>
          </CardContent>
        </CardWithGutter>
        <CardWithGutter>
          <TableContainer>
            <Table style={{ whiteSpace: 'nowrap' }}>
              <TableHead>
                <TableRow>
                  <TableCell>SKU</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Qty</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {inventoryReturn.items.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      <SkuLabel variant={item.variant} withoutDescription />
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'initial', minWidth: 200 }}>
                      {item.variant.description}
                    </TableCell>
                    <TableCell>
                      <TextButton onClick={() => onUpdateQty(item)} disabled={!!item.received_at}>
                        {item.qty}
                      </TextButton>
                    </TableCell>
                    <TableCell>
                      {item.location && <InventoryLocationLabel location={item.location} />}
                    </TableCell>
                    <TableCell>
                      {item.location && !item.received_at && (
                        <Tooltip title="Pull From Another Location">
                          <IconButton onClick={() => onChangeLocation(item)} size="large">
                            <SwapHoriz fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardWithGutter>
        <ShipmentsCard shippableId={inventoryReturn.id} shippableType="return" />
      </Grid>
      <Grid size={{ xs: 12, md: 3 }}>
        <EventableCard resourceId={inventoryReturn.id} resource="returns" size="small" />
      </Grid>
    </Grid>
  );
}
