import { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { UseControllerReturn } from 'react-hook-form';
import FormField from '@/components/Form/FormField';
import { FieldProps } from '@/types';
import Field from '../Field';

function OptIn({ field, fieldState, fieldModel }: FieldProps<OptInField>) {
  const [optIn, setOptIn] = useState(!!field.value);

  useEffect(() => {
    if (!optIn) {
      field.onChange(null);
    } else if (!field.value && fieldModel.initialValue) {
      field.onChange(fieldModel.initialValue);
    }
  }, [optIn]);

  useEffect(() => {
    setOptIn(!!field.value);
  }, [field.value]);

  return (
    <div>
      {optIn && <FormField field={fieldModel.subField} />}
      <FormControlLabel
        label={
          <Typography variant={fieldModel.size === 'small' ? 'body2' : 'body1'}>
            {fieldModel.optInLabel}
          </Typography>
        }
        control={
          <Checkbox
            size={fieldModel.size}
            checked={optIn}
            onChange={(e) => setOptIn(e.target.checked)}
          />
        }
      />
    </div>
  );
}

export default class OptInField extends Field {
  subField: Field;
  optInLabel = 'Enable';
  initialValue?: any;

  constructor(name: string, subField: Field) {
    super(name);
    this.subField = subField;
  }

  renderEditComponent(props: UseControllerReturn) {
    return <OptIn {...props} fieldModel={this} />;
  }

  renderCell(value: any, row: Record<string, any>) {
    return this.subField.renderCell(value, row);
  }
}
