import { ZodSchema } from 'zod';
import TableElement from '@/components/Form/TableElement';
import { UseFieldArrayWithName } from '@/types';
import Field from '../Field';

export default class TableField extends Field {
  children: Field[];
  schema: ZodSchema<any>;
  addNewValue = {};
  addNewLabel = 'Add New';
  isArray = true;

  constructor(name: string, children: Field[], schema: ZodSchema<any>) {
    super(name);
    this.children = children;
    this.schema = schema;
  }

  renderArrayComponent(props: UseFieldArrayWithName) {
    return <TableElement {...props} fieldModel={this} />;
  }
}
