import { Box, Checkbox, FormControlLabel, Switch as BaseSwitch, Typography } from '@mui/material';
import { UseControllerReturn } from 'react-hook-form';
import SelectField from '@/classes/Fields/SelectField';
import StatusChip from '@/components/Shared/StatusChip';
import { FieldProps } from '@/types';
import Field from '../Field';

function Toggle({ field, fieldModel }: FieldProps<ToggleField>) {
  const ToggleInput = fieldModel.switch ? BaseSwitch : Checkbox;

  const Control = (
    <ToggleInput
      checked={!!field.value}
      onChange={field.onChange}
      value="1"
      disabled={fieldModel.disabled}
      size={fieldModel.size}
    />
  );

  if (!fieldModel.label) {
    return Control;
  }

  return (
    <Box width="100%">
      <FormControlLabel
        control={Control}
        label={
          <Typography
            fontSize={fieldModel.size === 'small' ? 'inherit' : undefined}
            component="span"
          >
            {fieldModel.label}
          </Typography>
        }
      />
    </Box>
  );
}

export default class ToggleField extends Field {
  switch = false;

  getFilterFieldForType(): Field {
    return new SelectField(this.name, {
      0: 'No',
      1: 'Yes',
    }).with({
      includeBlank: true,
    });
  }

  renderEditComponent(props: UseControllerReturn) {
    return <Toggle {...props} fieldModel={this} />;
  }

  renderCell(value: any) {
    return (
      <StatusChip
        status={value ? 'Yes' : 'No'}
        colors={{
          Yes: 'green',
          No: 'red',
        }}
      />
    );
  }
}
