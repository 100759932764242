import { ChangeEvent, useEffect, useState } from 'react';
import { Refresh } from '@mui/icons-material';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  TextField,
  CircularProgress,
  IconButton,
  CardHeader,
  TableContainer,
  Card,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import sumBy from 'lodash/sumBy';
import { useShowLoading } from '@/contexts/AppContext';
import { Order, OrderItem, ShipmentItemPayload } from '@/types';
import { makeResourceQueryKey, useOnReloadRecord } from '@/utils/genericResource';
import useDialogs from '@/utils/hooks/useDialogs';
import numString from '@/utils/numString';
import {
  canShipOrderItem,
  getKitQty,
  isInventoryKit,
  orderItemToShipmentItem,
} from '@/utils/shipping';

export default function OrderShippingInventory({ order }: { order: Order }) {
  const orderId = order.id;
  const isKit = isInventoryKit(order.shipping_mode);
  const [selected, setSelected] = useState<ShipmentItemPayload[]>([]);
  const { confirm } = useDialogs();
  const onReload = useOnReloadRecord();
  const showLoading = useShowLoading();

  useEffect(() => {
    setSelected([]);
  }, [orderId]);

  const {
    data: items = [],
    refetch,
    isLoading,
  } = useQuery(makeResourceQueryKey('orders', orderId, 'shippableItems'), () =>
    axios
      .get<{ data: OrderItem[] }>(`/api/orders/${orderId}/items?filter[is_shippable]=1`)
      .then(({ data }) => data.data),
  );

  const hasScrap = items.some((i) => i.qty_scrapped > 0);
  const shippableItems = items.filter(canShipOrderItem);

  const onAddToInventory = () => {
    let title = `Add ${numString(sumBy(selected, 'qty_shipped'), 'Items')} to Inventory`;
    let description =
      'This will mark the items as shipped and add them to the default inventory location. Are you sure you want to proceed?';

    if (isKit) {
      const kitQty = getKitQty(selected, shippableItems);
      if (typeof kitQty === 'object') {
        alert(
          `The total quantity selected for ${kitQty.item} and the quantities per kit cannot create an even amount of kits. Please adjust either the quantities selected or the quantity per kit values.`,
        );
        return;
      }
      title = `Add ${numString(kitQty as number, 'Kits')} to Inventory`;
      description =
        'This will mark the kits as shipped and add them to the default inventory location. Are you sure you want to proceed?';
    }

    confirm({ title, description }).then(() => {
      showLoading(
        axios.post(`/api/shipments`, {
          shippable_id: orderId,
          shippable_type: 'order',
          items: selected,
        }),
      ).then(() => {
        onReload();
      });
    });
  };

  const handleCheck = (item: OrderItem) => (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelected([...selected, orderItemToShipmentItem(item)]);
    } else {
      setSelected(selected.filter((s) => s.order_item_id !== item.id));
    }
  };

  const handleCheckAll = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelected(shippableItems.map(orderItemToShipmentItem));
    } else {
      setSelected([]);
    }
  };

  const handleSelectedChange =
    (id: number, field: keyof ShipmentItemPayload) => (e: ChangeEvent<HTMLInputElement>) => {
      setSelected(
        selected.map((item) => {
          if (item.order_item_id === id) {
            return {
              ...item,
              [field]: e.target.value,
            };
          }
          return item;
        }),
      );
    };

  if (isLoading) {
    return <CircularProgress />;
  }

  const actions = (
    <div>
      <IconButton onClick={() => refetch()} size="large">
        <Refresh />
      </IconButton>
      {selected.length > 0 && (
        <Button onClick={onAddToInventory} variant="contained" sx={{ ml: 1 }}>
          Add to Inventory
        </Button>
      )}
    </div>
  );

  return (
    <Card>
      <CardHeader title="Items for Inventory" action={actions} />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                {shippableItems.length > 0 && (
                  <Checkbox
                    size="small"
                    onChange={(e) => handleCheckAll(e)}
                    checked={selected.length === shippableItems.length}
                    indeterminate={selected.length > 0 && selected.length < shippableItems.length}
                  />
                )}
              </TableCell>
              <TableCell>Item #</TableCell>
              <TableCell>Color</TableCell>
              <TableCell>Size</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Qty Req</TableCell>
              {hasScrap && <TableCell>Qty Scrapped</TableCell>}
              <TableCell>Qty Shipped</TableCell>
              {selected.length > 0 ? <TableCell>Qty to Ship</TableCell> : <TableCell colSpan={2} />}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => {
              const checked = selected.find((i) => i.order_item_id === item.id);
              return (
                <TableRow key={item.id}>
                  <TableCell padding="checkbox">
                    {canShipOrderItem(item) && (
                      <Checkbox size="small" onChange={handleCheck(item)} checked={!!checked} />
                    )}
                  </TableCell>
                  <TableCell>{item.number}</TableCell>
                  <TableCell>{item.color}</TableCell>
                  <TableCell>{item.size}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>{item.qty}</TableCell>
                  {hasScrap && <TableCell>{item.qty_scrapped}</TableCell>}
                  <TableCell>{item.qty_shipped}</TableCell>
                  {checked ? (
                    <TableCell>
                      <TextField
                        size="small"
                        style={{ width: 80 }}
                        onChange={handleSelectedChange(item.id, 'qty_shipped')}
                        value={checked.qty_shipped}
                        type="number"
                      />
                    </TableCell>
                  ) : (
                    <TableCell>
                      <div style={{ width: 100 }}>&nbsp;</div>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
