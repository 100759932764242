import { Avatar } from '@mui/material';
import { FieldFactory, Resource } from '@/classes';
import ArrayBullets from '@/components/Text/ArrayBullets';
import { DesignLayoutWithOrder } from '@/types';
import { maybeAddQueryParams } from '@/utils/query';

export default function designLayouts() {
  return (
    new Resource<DesignLayoutWithOrder>('Design Layouts')
      .withRelations(['order'])
      // .withSchema(designLayoutPayloadSchema) // We don't edit layouts using the resource
      .getSubtitleUsing((d) => <ArrayBullets elements={[d.increment_id, d.order.increment_id]} />)
      .getAvatarUsing((d) => (
        <Avatar src={maybeAddQueryParams(d.image, { w: 200 })}>
          {d.increment_id.substring(-1)}
        </Avatar>
      ))
      .setReadOnly()
      .withQueryParams({
        index: { with: 'order' },
      })
      .withDefaultSort('-increment_id')
      .withFilters([FieldFactory.belongsTo('customer', 'customers').withFilterKey('customer_id')])
      .withFieldsAndColumns([
        FieldFactory.text('increment_id').withLabel('#').sortable(),
        FieldFactory.image('image'),
        FieldFactory.text('name').sortable(),
        FieldFactory.belongsTo('order', 'orders').filterable('order_id').sortable('order_id'),
      ])
  );
}
