import { ReactElement } from 'react';
import { ZodSchema } from 'zod';
import { FieldProps, OptionsProp, ResourceName } from '@/types';
import Field from './Field';
import AutocompleteField from './Fields/AutocompleteField';
import ColorField from './Fields/ColorField';
import CurrencyField from './Fields/CurrencyField';
import CustomField from './Fields/CustomField';
import DateField from './Fields/DateField';
import DateRangeField from './Fields/DateRangeField';
import FileField from './Fields/FileField';
import ImagesField from './Fields/ImagesField';
import ListField from './Fields/ListField';
import OptInField from './Fields/OptInField';
import PercentField from './Fields/PercentField';
import PhoneField from './Fields/PhoneField';
import QtyField from './Fields/QtyField';
import ReadField from './Fields/ReadField';
import RelationField from './Fields/RelationField';
import RelationTableField from './Fields/RelationTableField';
import SelectField from './Fields/SelectField';
import StatusField from './Fields/StatusField';
import TableField from './Fields/TableField';
import TextAreaField from './Fields/TextAreaField';
import TextInputField from './Fields/TextInputField';
import ToggleField from './Fields/ToggleField';
import UseDefaultField from './Fields/UseDefaultField';

export default class FieldFactory {
  static read(name: string) {
    return new ReadField(name);
  }

  static text(name: string) {
    return new TextInputField(name);
  }

  static number(name: string) {
    return new TextInputField(name, 'number');
  }

  static email(name: string) {
    return new TextInputField(name, 'email');
  }

  static url(name: string) {
    return new TextInputField(name, 'url');
  }

  static textarea(name: string) {
    return new TextAreaField(name);
  }

  static custom(name: string, component: (p: FieldProps) => ReactElement | null) {
    return new CustomField(name, component);
  }

  static boolean(name: string, label?: string) {
    const field = new ToggleField(name);
    if (label) {
      field.label = label;
    }
    return field;
  }

  static select(name: string, options: OptionsProp) {
    return new SelectField(name, options);
  }

  static radio(name: string, options: OptionsProp) {
    const field = new SelectField(name, options);
    field.isRadio = true;
    return field;
  }

  static autocomplete(name: string, options: string[]) {
    return new AutocompleteField(name, options);
  }

  static belongsTo<K extends ResourceName>(name: string, resource: K) {
    return new RelationField<K>(name, resource);
  }

  static hasMany<K extends ResourceName>(name: string, resource: K) {
    const field = new RelationField<K>(name, resource);
    field.hasMany = true;
    return field;
  }

  static hasManyCRUD<K extends ResourceName>(
    name: string,
    resource: K,
    getApiEndpointFunc?: RelationTableField<K>['getApiEndpointFunc'],
  ) {
    return new RelationTableField<K>(name, resource, getApiEndpointFunc);
  }

  static curr(name: string) {
    return new CurrencyField(name);
  }

  static status(name: string, colors = {}, labels = {}) {
    return new StatusField(name, colors, labels);
  }

  static percent(name: string) {
    return new PercentField(name);
  }

  static phone(name: string) {
    return new PhoneField(name);
  }

  static date(name: string) {
    return new DateField(name);
  }

  static timestamp(name: string) {
    const field = new DateField(name);
    field.format = 'lll';
    return field;
  }

  static daterange(name: string) {
    return new DateRangeField(name);
  }

  static color(name: string) {
    return new ColorField(name);
  }

  static qty(name: string) {
    return new QtyField(name);
  }

  static file(name: string) {
    return new FileField(name);
  }

  static image(name: string) {
    return this.file(name).with({ image: true });
  }

  static images(name: string) {
    return new ImagesField(name);
  }

  static useDefault(name: string, subField: Field) {
    return new UseDefaultField(name, subField);
  }

  static optIn(name: string, subField: Field) {
    return new OptInField(name, subField);
  }

  static list(name: string, children: Field[] = []) {
    return new ListField(name, children);
  }

  static table(name: string, children: Field[] = [], schema: ZodSchema<any>) {
    return new TableField(name, children, schema);
  }
}
