import {
  CircularProgress,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useGetOtherVendorsForVariant } from '@/api/variants';
import InventoryText from '@/components/Products/InventoryText';
import { FieldProps, SkuResource } from '@/types';
import curr from '@/utils/curr';

export default function CompareVendors({ field }: FieldProps) {
  const variant = useFormContext<{ variant: Pick<SkuResource, 'id'> }>().watch('variant');

  const { data: variants } = useGetOtherVendorsForVariant(variant?.id);

  if (!variants) {
    return <CircularProgress />;
  }

  return (
    <Table size="small" style={{ whiteSpace: 'nowrap' }}>
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" />
          <TableCell>Vendor</TableCell>
          <TableCell>SKU</TableCell>
          <TableCell>Cost</TableCell>
          <TableCell>Vendor Inventory</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {variants.map((variant) => (
          <TableRow key={variant.id}>
            <TableCell>
              <Radio
                checked={field.value?.id === variant.id}
                onClick={() => field.onChange(variant)}
              />
            </TableCell>
            <TableCell>{variant.product.vendor.name}</TableCell>
            <TableCell>{variant.sku}</TableCell>
            <TableCell>{curr(variant.cost)}</TableCell>
            <TableCell>
              <InventoryText variant={variant} hideAvail />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
