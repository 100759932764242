import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, CardActions, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import moment from 'moment';
import StatusChip from '@/components/Shared/StatusChip';
import { useOnReloadRecord, useRecord } from '@/utils/genericResource';
import AttachmentsCard from '../Attachments/AttachmentsCard';
import PartyMessage from '../Notes/PartyMessage';

export default function ArtActivityCompleteForm() {
  const { id, completedUser, completedAt, note, type, sewOutRequested } = useRecord(
    'artRequestActivities',
    true,
  );
  const onReload = useOnReloadRecord('artRequestActivities');

  const { mutate, isLoading } = useMutation(() =>
    axios.post(`/api/art-request-activities/${id}/complete`).then(() => {
      onReload();
    }),
  );

  return (
    <>
      <PartyMessage message={note} />

      {type === 'sew_out' && sewOutRequested && (
        <Alert severity="info">
          This sew-out will be shown to the sales rep when they are reviewing the final production
          specs before the order is released.
        </Alert>
      )}

      {completedAt ? (
        <Table>
          <TableBody>
            <TableRow>
              <TableCell variant="head">Status</TableCell>
              <TableCell>
                <StatusChip status="Completed" colors={{ Completed: 'green' }} />{' '}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Date</TableCell>
              <TableCell>{moment(completedAt).format('lll')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">User</TableCell>
              <TableCell>{completedUser?.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Files</TableCell>
              <TableCell>See Attachments</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      ) : (
        <>
          <AttachmentsCard
            resourceId={id}
            resource="art-request-activities"
            elevation={0}
            title={null}
          />

          <CardActions>
            <LoadingButton
              type="button"
              variant="contained"
              sx={{ mr: 2 }}
              loading={isLoading}
              onClick={() => mutate()}
            >
              Submit
            </LoadingButton>
            <p>This will notify the requester.</p>
          </CardActions>
        </>
      )}
    </>
  );
}
