import { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, CircularProgress } from '@mui/material';
import { UseMutationResult, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import AddressBlock from '@/components/Addresses/AddressBlock';
import DrawerFormSection from '@/components/Form/DrawerFormSection';
import ParcelFields from '@/components/Shipping/ParcelFields';
import RateList from '@/components/Shipping/RateList';
import { Address, Parcel, ShippoRate } from '@/types';
import { useOrderableApiUrl } from '@/utils/hooks/useApiSegment';
import useDebounce from '@/utils/hooks/useDebounce';

export default function EstimateShippingCost({
  updateRequest,
}: {
  updateRequest: UseMutationResult<unknown, unknown, { shipping: number }>;
}) {
  const [selectedRateId, setSelectedRateId] = useState<string>();
  const [parcels, setParcels] = useState<Parcel[]>();
  const apiUrl = useOrderableApiUrl('estimate-shipping');
  const debouncedParcels = useDebounce(parcels, 1000);

  const { data, isFetching } = useQuery(
    [apiUrl, JSON.stringify(debouncedParcels)],
    () =>
      axios
        .post<{
          from_address: Address;
          to_address: Address;
          parcels: Parcel[];
          rates: ShippoRate[];
        }>(apiUrl, { parcels: debouncedParcels })
        .then(({ data }) => data),
    {
      keepPreviousData: true,
    },
  );

  if (!data) {
    return <CircularProgress />;
  }

  const selectedRate = data?.rates.find((r) => r.id === selectedRateId);

  return (
    <div>
      <Alert severity="info" sx={{ mt: -2, mb: 2 }}>
        This tool is meant to assist in estimating domestic outbound shipping costs. It is purely an
        estimate, and the final outbound shipping cost may vary.
      </Alert>
      <DrawerFormSection title="From Address">
        <AddressBlock address={data.from_address} />
      </DrawerFormSection>

      <DrawerFormSection title="To Address">
        <AddressBlock address={data.to_address} />
      </DrawerFormSection>

      <ParcelFields parcels={parcels || data.parcels} onChange={setParcels} />

      <DrawerFormSection title="Rates">
        {isFetching ? (
          <CircularProgress />
        ) : (
          <RateList
            rates={data.rates}
            selectedRate={selectedRateId}
            setSelectedRate={setSelectedRateId}
          />
        )}
      </DrawerFormSection>

      {selectedRate && (
        <LoadingButton
          variant="contained"
          loading={updateRequest.isLoading}
          onClick={() => updateRequest.mutate({ shipping: selectedRate.cost })}
        >
          Set as Shipping Price
        </LoadingButton>
      )}
    </div>
  );
}
