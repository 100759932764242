import { CSSProperties } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Edit, Delete, DragIndicator, CloudDownload } from '@mui/icons-material';
import { Grid2 as Grid, Card, CardHeader, CardContent, Alert } from '@mui/material';
import filter from 'lodash/filter';
import Markdown from 'react-markdown';
import { Action, ButtonAction } from '@/classes';
import SubMenu from '@/components/Shared/SubMenu';
import { DashboardReport } from '@/types';
import { useHasPermission } from '@/utils/hooks/permissions';
import ReportResults from '../Reports/ReportResults';

export default function DashboardCard({
  report,
  onEdit,
  onDelete,
  onDownload,
}: {
  report: DashboardReport;
  onEdit: (r: DashboardReport) => void;
  onDelete: (r: DashboardReport) => void;
  onDownload: (r: DashboardReport) => void;
}) {
  const hasPermission = useHasPermission();

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: report.id,
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const columns = report.pivot.columns || 3;

  const items = filter([
    !['number', 'progress'].includes(report.type) &&
      new ButtonAction('Download', () => onDownload(report)).withIcon(CloudDownload),
    new ButtonAction('Edit', () => onEdit(report)).withIcon(Edit).withPermission('write:reports'),
    new ButtonAction('Delete', () => onDelete(report))
      .withIcon(Delete)
      .withPermission('write:reports'),
  ]) as Action[];

  return (
    <Grid size={{ xs: 12, md: columns <= 4 ? 6 : 12, lg: columns }}>
      <Card ref={setNodeRef} style={style}>
        <CardHeader
          title={report.title || report.name || 'Untitled'}
          titleTypographyProps={{ variant: 'h5' }}
          subheader={report.description}
          avatar={
            hasPermission('write:reports') ? <DragIndicator {...attributes} {...listeners} /> : null
          }
          action={items.length ? <SubMenu items={items} /> : null}
        />
        {report.error && (
          <CardContent>
            <Alert severity="error">{report.error}</Alert>
          </CardContent>
        )}
        {report.summary_result && (
          <CardContent style={{ paddingTop: 0 }}>
            <Markdown components={{ p: 'div' }}>{report.summary_result}</Markdown>
          </CardContent>
        )}
        {report.results && (
          <ReportResults
            report={report}
            results={report.results}
            compareTo={report.compare_to}
            compareToResults={report.compare_to_results || undefined}
          />
        )}
      </Card>
    </Grid>
  );
}
